import { Permission } from '@lib/types/permission'
import { useCallback, useMemo } from 'react'
import { useAuthorization } from './use-authorization'

export const usePermissions = () => {
  const { isLoading, data: authData } = useAuthorization()

  const permissions = useMemo(() => authData?.permissions || [], [authData])

  const hasPermission = useCallback(
    (permission: Permission | Permission[]) => {
      return [permission].flat().some((p) => permissions.includes(p))
    },
    [permissions]
  )

  return { hasPermission, isLoading, permissions }
}

export const usePermission = (permission: Permission | Permission[]) => {
  const { hasPermission, isLoading } = usePermissions()
  return {
    isLoading,
    hasPermission: [permission].flat().some(hasPermission)
  }
}

export const useIsSuperAdmin = () => {
  const { hasPermission, isLoading } = usePermissions()
  return {
    isLoading,
    isSuperAdmin: hasPermission(Permission.HOODIE_SUPER_ADMIN)
  }
}
