import {
  BRAND_INDEX,
  BrandHit,
  DISPENSARY_INDEX,
  DispensaryHit,
  ProductHit,
  VARIANT_INDEX
} from '@client/services/algolia'

export type SearchableHit = ProductHit | DispensaryHit | BrandHit

export type SearchableHitKey = keyof ProductHit | keyof DispensaryHit | keyof BrandHit

interface AttributeMap {
  nameAttribute: SearchableHitKey
  imageAttribute: SearchableHitKey
  categoryAttribute?: SearchableHitKey
  variantsAttribute?: SearchableHitKey
}

export type SearchableIndex = typeof DISPENSARY_INDEX | typeof VARIANT_INDEX | typeof BRAND_INDEX

export const indexAttributes: Record<SearchableIndex, AttributeMap> = {
  [VARIANT_INDEX]: {
    nameAttribute: 'NAME',
    imageAttribute: 'IMG',
    categoryAttribute: 'CATEGORY_2',
    variantsAttribute: 'VARIANTS'
  },
  [DISPENSARY_INDEX]: {
    nameAttribute: 'DISPENSARY_NAME',
    imageAttribute: 'LOGO'
  },
  [BRAND_INDEX]: {
    nameAttribute: 'BRAND_NAME',
    imageAttribute: 'BRAND_LOGO_URL'
  }
}
