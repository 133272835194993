import { useSessionTracker } from '@client/hooks/use-session-tracker'
import { Module } from '@lib/types/modules'
import { TrackingEvent } from '@lib/types/tracking'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'

export const moduleFromPathname = (pathname: string): Module | undefined => {
  if (pathname.startsWith('/brand/where-to-buy')) {
    return Module.WTB
  }
  if (pathname.startsWith('/dispensary-analytics')) {
    return Module.DA
  }
  if (pathname.startsWith('/market-intelligence')) {
    return Module.MI
  }
  if (pathname.startsWith('/wholesale')) {
    return Module.WS
  }
  if (pathname.startsWith('/brand-catalog')) {
    return Module.BC
  }
  if (
    pathname.startsWith('/brand/sales-enablement') ||
    pathname.startsWith('/territory-builder') ||
    pathname.startsWith('/products') ||
    pathname.startsWith('/brands') ||
    pathname.startsWith('/dispensaries') ||
    pathname.startsWith('/events')
  ) {
    return Module.SE
  }
  if (pathname.startsWith('/settings')) {
    return Module.ST
  }
  return undefined
}

export const ModuleTracker: React.FC = () => {
  const { pathname } = useLocation()
  const { trackOnce } = useSessionTracker()
  const module = useMemo(() => moduleFromPathname(pathname), [pathname])

  useEffect(() => {
    if (module) {
      trackOnce({ key: `module-view-${module}`, eventName: TrackingEvent.moduleView, properties: { module } })
    }
  }, [module, trackOnce])

  return null
}
