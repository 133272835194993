import { mergeArrays } from '@client/helpers/lists'
import { InvalidFiltersWithDataMap, mergeFilterByKey } from '@client/types/filterset'
import {
  FilterBy,
  FilterKey,
  FilterKeys,
  InvalidFiltersMap,
  generateInvalidFilterMap
} from '@hoodie/hoodie-filters/lib/filterset'
import clone from 'fast-clone'
import deepEqual from 'fast-deep-equal'
import produce from 'immer'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export type InvalidContext = {
  invalidate: InvalidFiltersMap
  instances: number
  message?: string
  components?: string[]
}

export type InvalidFilterInput = {
  context: string
  invalidateKeys?: FilterKeys
  invalidateValues?: FilterBy
  message?: string
  component?: string
}

export interface InvalidFiltersStore {
  invalidFilters?: InvalidFiltersWithDataMap
  invalidByContext?: Record<string, InvalidContext>
  addInvalidFilters: (options: InvalidFilterInput) => void
  removeInvalidFilters: (options: { context: string; component?: string }) => void
}

export const mergeInvalidFilters = (
  invalidFilterA?: InvalidFiltersWithDataMap,
  invalidFilterB?: InvalidContext
): InvalidFiltersWithDataMap => {
  const invalidFilters = clone(invalidFilterA) ?? {}
  if (invalidFilterB) {
    for (const [key, invalidDataB] of Object.entries(invalidFilterB.invalidate)) {
      const invalidateKey = key as FilterKey
      // If the invalidateKey is true, regardless of the existing value
      if (invalidDataB.invalidateKey) {
        invalidFilters[invalidateKey] = { ...invalidFilters[invalidateKey], invalidateKey: true }
      }
      // If the invalidateValues, merge it with the existing values
      if (invalidDataB.invalidateValues) {
        let mergedValues: FilterBy[keyof FilterBy] = invalidDataB.invalidateValues
        const invalidDataA = invalidFilters[invalidateKey]
        if (invalidDataA) {
          mergedValues = mergeFilterByKey(invalidateKey, invalidDataA.invalidateValues, invalidDataB.invalidateValues)
        }
        invalidFilters[invalidateKey] = { ...invalidDataA, invalidateValues: mergedValues }
      }
      // If a message is set, merge it with the existing messages
      if (invalidFilterB.message) {
        invalidFilters[invalidateKey] = {
          ...invalidFilters[invalidateKey],
          messages: mergeArrays(invalidFilters[invalidateKey]?.messages, [invalidFilterB.message])
        }
      }
      // If a component is set, merge it with the existing components
      if (invalidFilterB.components?.length) {
        invalidFilters[invalidateKey] = {
          ...invalidFilters[invalidateKey],
          components: mergeArrays(invalidFilters[invalidateKey]?.components, invalidFilterB.components)
        }
      }
    }
  }
  return invalidFilters
}

export const useInvalidFiltersStore = create(
  devtools<InvalidFiltersStore>(
    (set) => ({
      invalidFilters: undefined,
      invalidByContext: undefined,
      addInvalidFilters: ({ context, invalidateKeys, invalidateValues, message, component }) =>
        set(
          produce<InvalidFiltersStore>((state) => {
            const invalidByContext = state.invalidByContext
            const invalidate = generateInvalidFilterMap({ invalidateKeys, invalidateValues })
            if (invalidByContext?.[context] && !deepEqual(invalidate, invalidByContext[context].invalidate)) {
              throw new Error(`The same context (${context}) cannot have different invalid filters`)
            }
            // Adds the component to the context
            const components = invalidByContext?.[context]?.components ?? []
            if (component) {
              components.push(component)
            }
            // Completly replace the invalid filters for the context
            state.invalidByContext = {
              ...invalidByContext,
              [context]: {
                message: message || invalidByContext?.[context]?.message,
                components,
                invalidate,
                instances: (invalidByContext?.[context]?.instances ?? 0) + 1
              }
            }
            // Recreate the invalid filters for all contexts
            state.invalidFilters = Object.values(state.invalidByContext).reduce<InvalidFiltersMap>((acc, context) => {
              return mergeInvalidFilters(acc, context)
            }, {})
          }),
          false,
          'add'
        ),
      removeInvalidFilters: ({ context, component }) => {
        set(
          produce<InvalidFiltersStore>((state) => {
            const invalidByContext = state.invalidByContext
            const invalidContext = invalidByContext?.[context]
            let recalculateInvalidFilters = false
            if (invalidContext) {
              if (invalidContext.instances === 1) {
                // Remove the context from the invalidByContext
                delete invalidByContext[context]
                // Recreate the invalid filters for all contexts
                recalculateInvalidFilters = true
              } else {
                invalidByContext[context].instances -= 1
                const components = invalidByContext[context].components
                if (component && components?.length) {
                  components.splice(components.indexOf(component), 1)
                  // Only recalculate the invalid filters if the component does not exist anymore in the context
                  recalculateInvalidFilters = !components.some((comp) => comp === component)
                }
              }
              // Recreate the invalid filters for all contexts
              if (recalculateInvalidFilters) {
                state.invalidFilters = Object.values(invalidByContext).reduce<InvalidFiltersMap>((acc, context) => {
                  return mergeInvalidFilters(acc, context)
                }, {})
              }
              state.invalidByContext = invalidByContext
            }
          }),
          false,
          'removeInvalidFilters'
        )
      }
    }),
    {
      name: `@hoodie:invalid-filters-store`
    }
  )
)
