import { zBoolEnvVar } from '@lib/helpers/zod'
import { z } from 'zod'

// Remember - all env vars here must start with 'REACT_APP_'
const envSchema = z.object({
  REACT_APP_DEBUG_ONBOARDING: zBoolEnvVar().default('false'),
  REACT_APP_MUIX_LICENSE_KEY: z.string().default(''),
  REACT_APP_DISABLE_STRICT_MODE: zBoolEnvVar().default('false'),
  REACT_APP_API_DOMAIN: z.string().default('') // Defaults to same domain as front-end
})

export const env = envSchema.parse(process.env)
