import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  toggleButtonClasses
} from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components'

const _ToggleButtonGroup = styled(MuiToggleButtonGroup)(spacing)

export const ToggleButtonGroup = styled(_ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))

export const ToggleButton = styled(MuiToggleButton)`
  margin: 0 !important;
`

export const ListDetailToggleButtonGroup = styled(MuiToggleButtonGroup)`
  flex-grow: 1;
  margin: ${({ theme }) => theme.spacing(-2, -2, 1, -2)};
  order: -1;
  width: 100%;

  .${toggleButtonClasses.root} {
    flex-grow: 1;
    border-top: 0;
    border-radius: 0;
    &:first-of-type {
      border-left: 0;
    }
    &:last-of-type {
      border-right: 0;
    }
  }
`
