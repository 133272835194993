import { Notification } from '@client/components/notifications-dropdown/Notification'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { usePermission } from '@client/hooks/use-permissions'
import { useHasura } from '@client/providers/hasura'
import { Permission } from '@lib/types/permission'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { format } from 'timeago.js'

interface NotificationContextData {
  notifications: Notification[]
  isLoading: boolean
  hasMoreRecords: boolean
  totalUnread: number
  increaseLimit(): void
  changeNotificationStatus(notification: Notification, isRead: boolean): Promise<void>
  markAllNotificationsAsRead(): Promise<void>
  setFilterByUnread(onlyUnread: boolean): void
}

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData)

const MAX_RECORDS = 10

const mapToNotification = (notification: Record<string, any>): Notification => ({
  uri:
    notification.data?.url.startsWith('http') || notification.data?.url.startsWith('/')
      ? notification.data.url
      : `/events${notification.data?.url}`,
  isRead: notification.web_read_at !== null,
  timeAgo: format(new Date(notification.created_at)),
  frequency: notification.data?.frequency ?? '',
  title: notification.subject,
  categories: notification.data?.raw?.categories?.map(({ name }: { name: string }) => name) ?? [],
  description: notification.message,
  notification_id: notification.notification_id,
  notificationPreferenceId: notification.data?.notificationPreferenceId,
  filtersetId: notification.data?.raw?.filtersetId
})

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { hasuraClient } = useHasura()
  const [limit, setLimit] = useState(MAX_RECORDS)
  const [filterByUnread, setFilterByUnread] = useState(false)
  const [hasMoreRecords, setHasMoreRecords] = useState<boolean>(true)
  const [allNotifications, setAllNotifications] = useState<Notification[]>([])
  const { value: isLoading, setTrue: startLoading, setFalse: stopLoading } = useBooleanState(false)
  const { hasPermission } = usePermission(Permission.NOTIFICATION_PREFERENCE_CREATE)

  const unreadNotifications = useMemo(
    () => allNotifications.filter((notice: Notification) => !notice.isRead),
    [allNotifications]
  )
  const notifications = useMemo(
    () => (filterByUnread ? unreadNotifications : allNotifications).slice(0, limit),
    [unreadNotifications, allNotifications, filterByUnread, limit]
  )
  const totalNotifications = useMemo(
    () => (filterByUnread ? unreadNotifications : allNotifications).length,
    [unreadNotifications, allNotifications, filterByUnread]
  )

  const totalUnread = useMemo(() => unreadNotifications.length, [unreadNotifications])

  const notificationSubscription = useRef<any>()
  useEffect(() => {
    if (hasuraClient && hasPermission) {
      if (notificationSubscription.current) {
        notificationSubscription.current.unsubscribe()
      }

      startLoading()
      hasuraClient.notifications().then((observer) => {
        notificationSubscription.current = observer.subscribe({
          next({ data }) {
            const allNotices = data?.notification ?? []
            const mappedNotices = allNotices.map((notification: Record<string, any>) => mapToNotification(notification))
            setAllNotifications(mappedNotices)
            stopLoading()
          }
        })
      })
    }

    return () => notificationSubscription?.current?.unsubscribe()
  }, [hasPermission, hasuraClient, startLoading, stopLoading])

  useEffect(() => {
    setHasMoreRecords(totalNotifications > notifications.length)
  }, [totalNotifications, notifications.length])

  const changeNotificationStatus = useCallback(
    async (notification: Notification, isRead: boolean) => {
      if (!hasuraClient) {
        return
      }

      return await hasuraClient.changeNotificationStatus(notification.notification_id, isRead)
    },
    [hasuraClient]
  )
  const markAllNotificationsAsRead = useCallback(async () => {
    if (!hasuraClient) {
      return
    }
    return await hasuraClient.markAllNotificationsAsRead()
  }, [hasuraClient])

  const increaseLimit = useCallback(
    () => setLimit((limit) => limit + (hasMoreRecords ? MAX_RECORDS : 0)),
    [hasMoreRecords]
  )

  return (
    <NotificationContext.Provider
      value={{
        isLoading,
        notifications,
        hasMoreRecords,
        totalUnread,
        increaseLimit,
        changeNotificationStatus,
        markAllNotificationsAsRead,
        setFilterByUnread
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotifications = () => {
  const context = useContext(NotificationContext)

  if (!context) {
    throw new Error('useNotification must be used within an NotificationProvider.')
  }

  return context
}
