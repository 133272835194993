import { useFeatureToggle } from '@client/hooks/use-feature-toggle'
import { useHelpLinksStore } from '@client/stores/use-help-links-store'
import { useSpeedDialTooltipStyles } from '@client/styles/theme/speed-dial'
import { Article, CommentOutlined, QuestionMark } from '@mui/icons-material'
import { MouseEvent, memo, useCallback, useEffect, useMemo } from 'react'
import * as S from './HelpOverlay.style'

export interface HelpOverlayProps {
  isOpen?: boolean
  onFeedbackClick: () => void
  onClose: () => void
}

type WithOrder = { order: number }

export const sortByOrder = (a: WithOrder, b: WithOrder) => a.order - b.order

export const HelpOverlay: React.FC<HelpOverlayProps> = memo(function HelpOverlay({
  isOpen = false,
  onFeedbackClick,
  onClose
}) {
  const { isEnabled, toggles } = useFeatureToggle()

  const classesTooltip = useSpeedDialTooltipStyles()
  const classesDivisor = S.useSpeedDialDivisor()

  const helpLinks = useHelpLinksStore((state) => state.links)

  const links = useMemo(() => [...helpLinks.values()].sort(sortByOrder).reverse(), [helpLinks])
  const linksCount = useMemo(
    () => (isEnabled(toggles.feedback_widget) ? 2 : 1) + links.length,
    [isEnabled, links.length, toggles.feedback_widget]
  )

  const getFabProps = useCallback(
    (link: string) => ({
      href: link,
      rel: 'noopener noreferrer',
      target: '_blank'
    }),
    []
  )

  /**
   * When clicking the tooltip, we want to trigger the click on the item fab
   */
  const handleTooltipClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement | null
    if (target?.classList.contains('MuiSpeedDialAction-staticTooltipLabel')) {
      const fab = target.nextSibling as HTMLElement
      fab.click()
    }
  }, [])

  const handleKeyboardClose = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyboardClose, false)
      return () => {
        document.removeEventListener('keydown', handleKeyboardClose, false)
      }
    }
  }, [handleKeyboardClose, isOpen])

  return (
    <S.Backdrop open={isOpen} onClick={onClose} data-testid="help-overlay">
      <S.SpeedDial ariaLabel="Help on this page" open={isOpen} direction="up" $linksCount={linksCount}>
        {isEnabled(toggles.feedback_widget) && (
          <S.FeedbackSpeedDialAction
            classes={classesTooltip}
            icon={<CommentOutlined />}
            tooltipTitle="Contact Us"
            tooltipOpen
            data-testid="help-overlay--feedback-button"
            onClick={handleTooltipClick}
            FabProps={{ onClick: onFeedbackClick }}
          />
        )}
        <S.HelpSpeedDialAction
          classes={{ ...classesTooltip, ...classesDivisor }}
          icon={<QuestionMark />}
          tooltipTitle="Knowledge Center"
          tooltipOpen
          data-testid="help-overlay--knowledge-center-button"
          onClick={handleTooltipClick}
          FabProps={getFabProps('https://docs.hoodieanalytics.com/')}
        />
        {links.map(({ tooltip, icon: Icon, helpLink }) => (
          <S.HelpSpeedDialAction
            key={tooltip}
            classes={classesTooltip}
            icon={Icon ? <Icon /> : <Article />}
            tooltipTitle={tooltip}
            tooltipOpen
            onClick={handleTooltipClick}
            data-testid="help-overlay--link-button"
            FabProps={getFabProps(`https://docs.hoodieanalytics.com/docs/${helpLink}`)}
          />
        ))}
      </S.SpeedDial>
    </S.Backdrop>
  )
})
