export enum Module {
  BC = 'Brand Catalog',
  SE = 'Sales Enablement',
  DA = 'Dispensary Analytics',
  MI = 'Market Intelligence',
  WS = 'Wholesale',
  WTB = 'Where To Buy',
  ST = 'Settings'
}

export type DataDisplay = 'SE' | 'MI'

export type DataDisplayExact<T extends DataDisplay> = Extract<DataDisplay, T>

export type ModuleId = 'SE' | 'DA' | 'MI' | 'WS'

export const mappedModuleCodeNames: Record<ModuleId, string> = {
  SE: 'Sales Enablement',
  DA: 'Dispensary Analytics',
  MI: 'Market Intelligence',
  WS: 'Wholesale'
}
