import { Help } from '@mui/icons-material'
import { IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material'

export interface HelpLinkProps {
  tooltip?: string
  helpLink: string
  tooltipProps?: Omit<TooltipProps, 'title'>
  buttonProps?: Omit<IconButtonProps<'a'>, 'href' | 'target' | 'rel'>
}

export const HelpLink: React.FC<HelpLinkProps> = ({
  tooltip = 'Open knowledge center',
  helpLink,
  tooltipProps,
  buttonProps
}) => {
  // HACK: ensure tooltip appears on top
  const style = { zIndex: 3000 }
  return (
    <Tooltip {...tooltipProps} title={tooltip || ''} PopperProps={{ style }}>
      <IconButton
        size="small"
        color="help"
        {...buttonProps}
        href={`https://docs.hoodieanalytics.com/docs/${helpLink}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Help fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
