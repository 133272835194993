import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpLink } from '@client/components/help-link/HelpLink'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerToggleButton,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { SearchInput } from '@client/components/search-input/SearchInput'
import { useCustomTerritories } from '@client/hooks/use-custom-territories'
import { useDrawers } from '@client/hooks/use-drawers'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useNumActiveFilters } from '@client/hooks/use-num-active-filters'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { Box } from '@client/styles/theme/box'
import { ALL_PRODUCT_FILTERS } from '@client/types/filterset'
import { TERRITORY_NEW_URL_KEY } from '@client/types/territories'
import { DraftFilterset, View } from '@hoodie/hoodie-filters/lib/filterset'
import { Close } from '@mui/icons-material'
import { Alert, AlertTitle, Drawer, IconButton, Link, ToggleButtonGroup, Typography } from '@mui/material'
import { MouseEvent, memo, useCallback, useMemo, useState } from 'react'
import { DraftFiltersetListItem } from './components/draft-filterset-list-item/DraftFiltersetListItem'
import { ViewListItem } from './components/view-list-item/ViewListItem'

type ViewDrawerFilter = 'views' | 'territories' | undefined

const filterLabels: Record<NonNullable<ViewDrawerFilter>, string> = {
  views: 'saved views',
  territories: 'territories'
}

interface ViewsDrawerAlertProps {
  title: string
  descriptionStart: string
  descriptionEnd: string
  buttonText: string
  onClick: () => void
}
export const ViewsDrawerAlert: React.FC<ViewsDrawerAlertProps> = memo(function ViewsDrawerAlert({
  title,
  descriptionStart,
  descriptionEnd,
  buttonText,
  onClick
}) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" m={4}>
      <Alert severity="info">
        <AlertTitle>{title}</AlertTitle>
        {`${descriptionStart}`}
        <Link onClick={onClick} sx={{ cursor: 'pointer' }} data-testid="views-drawer-alert--link">
          {buttonText}
        </Link>
        {descriptionEnd}
      </Alert>
    </Box>
  )
})

export interface ViewsDrawerProps {
  globalFilterset: DraftFilterset
  savedViews: View[]
}

interface ViewsDrawerContentProps extends ViewsDrawerProps {
  onClose: () => void
}

const sortViews = (views: View[], globalFiltersetId?: string) => {
  // Return the global filterset first, then the saved views in alphabetical order
  return [...views].sort((a, b) => {
    if (globalFiltersetId && a.id === globalFiltersetId) {
      return -1
    }
    return a.name.localeCompare(b.name)
  })
}

const ViewsDrawerContent: React.FC<ViewsDrawerContentProps> = ({ onClose, globalFilterset, savedViews }) => {
  const { clearDrawer } = useDrawerState()
  const { openFiltersDrawer } = useDrawers()
  const { isTerritory } = useCustomTerritories()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filter, setFilter] = useState<ViewDrawerFilter>()
  const navigate = useNavigateRef()

  const orderedViews = useMemo(() => {
    return sortViews(savedViews, globalFilterset.id)
  }, [globalFilterset.id, savedViews])

  const filteredViews = useMemo(() => {
    const views = filter
      ? orderedViews.filter((view) => (filter === 'territories' ? isTerritory(view) : !isTerritory(view)))
      : orderedViews
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()
      return views.filter((view) => view.name.toLowerCase().includes(lowerCaseSearchTerm))
    }
    return views
  }, [filter, orderedViews, isTerritory, searchTerm])

  const numActiveFilters = useNumActiveFilters(globalFilterset.filters, ALL_PRODUCT_FILTERS)

  const handleSelectFilters = useCallback(() => {
    openFiltersDrawer()
  }, [openFiltersDrawer])

  const handleNewTerritory = useCallback(() => {
    navigate.current(`/territory-builder/${TERRITORY_NEW_URL_KEY}`)
    clearDrawer()
  }, [clearDrawer, navigate])

  const hasDraftFilterset = useMemo(
    () => !globalFilterset.id && numActiveFilters > 0,
    [globalFilterset.id, numActiveFilters]
  )

  const handleFilterChange = useCallback((event: MouseEvent<HTMLElement>, filter: ViewDrawerFilter) => {
    setFilter(filter)
  }, [])

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            Views
            <Box display="flex" alignItems="center">
              <HelpLink helpLink="sales-enablement/filters-views#the-views-drawer" tooltip="View help on saved views" />
              <IconButton edge="end" onClick={onClose} data-testid="close-drawer-button">
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <ToggleButtonGroup
          color="primary"
          value={filter}
          exclusive
          onChange={handleFilterChange}
          size="small"
          sx={{ width: '100%' }}
        >
          <DrawerToggleButton value="views" data-testid="views-drawer--toggle-views">
            Saved Views
          </DrawerToggleButton>
          <DrawerToggleButton value="territories" data-testid="views-drawer--toggle-territories">
            Territories
          </DrawerToggleButton>
        </ToggleButtonGroup>
        <Box px={3} pt={2}>
          <SearchInput searchTerm={searchTerm} onSearch={setSearchTerm} margin="none" />
        </Box>
        {hasDraftFilterset && filter !== 'territories' && !searchTerm && (
          <ErrorBoundary>
            <DraftFiltersetListItem filterset={globalFilterset} />
          </ErrorBoundary>
        )}
        {filteredViews.map((view) => (
          <ErrorBoundary key={view.id}>
            <ViewListItem view={view} isActive={globalFilterset.id === view.id} onClick={onClose} />
          </ErrorBoundary>
        ))}
        {orderedViews.length === 0 && filter !== 'territories' && !hasDraftFilterset && (
          <ViewsDrawerAlert
            title="No views saved!"
            descriptionStart="Start by "
            buttonText="selecting some filters"
            descriptionEnd=", then select the 'Save as new view' option"
            onClick={handleSelectFilters}
          />
        )}
        {filteredViews.length === 0 && filter === 'territories' && !searchTerm && (
          <ViewsDrawerAlert
            title="No territories saved!"
            descriptionStart="Start by "
            buttonText="setting up"
            descriptionEnd=" a new one in the Territory Builder page"
            onClick={handleNewTerritory}
          />
        )}
        {orderedViews.length > 0 && filteredViews.length === 0 && searchTerm && (
          <Typography p={4} fontStyle="italic" data-testid="views-drawer--no-matches" textAlign="center">
            No {filter ? filterLabels[filter] : 'views'} found for <strong>{searchTerm}</strong>
          </Typography>
        )}
      </DrawerContent>
    </>
  )
}

export const ViewsDrawer: React.FC<ViewsDrawerProps> = (props) => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  return (
    <Drawer
      open={drawer?.id === DrawerId.views}
      anchor="right"
      classes={drawerStyles}
      onClose={clearDrawer}
      data-testid="views-drawer"
    >
      <ErrorBoundary>
        <ViewsDrawerContent {...props} onClose={clearDrawer} />
      </ErrorBoundary>
    </Drawer>
  )
}
