import {
  CollapsibleIconButton,
  CollapsibleIconButtonProps
} from '@client/components/CollapsibleIconButton/CollapsibleIconButton'
import { useFavorites } from '@client/hooks/use-favorites'
import { useHelpLink } from '@client/hooks/use-help-link'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useFavoritesStore } from '@client/stores/use-favorites-store'
import { DraftFavorite, FavoriteKey, favoriteKeyToName } from '@client/types/favorites'
import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'

type BaseButtonProps = Omit<CollapsibleIconButtonProps, 'startIcon' | 'endIcon' | 'onClick' | 'type'>
interface FavoriteButtonInnerProps extends BaseButtonProps {
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => Promise<void>
  isFavorited?: boolean
  iconOnly?: boolean
  edge?: IconButtonProps['edge']
}

export const FavoriteButtonInner: React.FC<FavoriteButtonInnerProps> = memo(function FavoriteButtonInner({
  onClick,
  isFavorited = false,
  iconOnly = false,
  edge = 'end',
  preventCollapse,
  autoCollapse,
  ...props
}) {
  const { isMobile } = useResponsiveState()

  const Icon = isFavorited ? Favorite : FavoriteBorder

  const text = isFavorited ? 'Remove from favorites' : 'Add to favorites'

  return isMobile || iconOnly ? (
    <Tooltip title={!isMobile ? text : ''} arrow>
      <span>
        <IconButton color="primary" size="small" {...props} onClick={onClick} data-testid="favorite-button" edge={edge}>
          <Icon fontSize={props.size ?? 'small'} />
        </IconButton>
      </span>
    </Tooltip>
  ) : (
    <CollapsibleIconButton
      size="small"
      {...props}
      onClick={onClick}
      endIcon={<Icon />}
      data-testid="favorite-button"
      preventCollapse={preventCollapse}
      autoCollapse={autoCollapse}
    >
      {text}
    </CollapsibleIconButton>
  )
})

export interface FavoriteButtonProps extends BaseButtonProps {
  favoriteKey: FavoriteKey
  id: string
  name: string
  iconOnly?: boolean
  edge?: IconButtonProps['edge']
  /**
   * Whether the favorite button is in a list of items, if so, it will not change the pageFavoriteId state
   */
  isList?: boolean
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = memo(function FavoriteButton({
  favoriteKey,
  id,
  name,
  isList = false,
  ...props
}) {
  const aborterRef = useRef<AbortController>()
  const { isReady, getFavoriteByKey, addFavorite, removeFavorite } = useFavorites()
  const setPageFavoriteId = useFavoritesStore((state) => state.setPageFavoriteId)
  const favoritedData = useMemo(() => getFavoriteByKey(id, favoriteKey), [getFavoriteByKey, favoriteKey, id])
  const [isFavorited, setIsFavorited] = useState(!!favoritedData)

  const handleClick = useCallback(
    async (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      // Quick toggle the favorite button to give the user immediate feedback
      setIsFavorited((prevState) => !prevState)
      // Abort any pending previous request and create a new abort controller
      aborterRef.current?.abort()
      aborterRef.current = new AbortController()
      // Add or remove the favorite
      let hasError = false
      if (favoritedData) {
        hasError = !(await removeFavorite(favoritedData, aborterRef.current.signal))
      } else {
        hasError = !(await addFavorite(
          { name, [favoriteKey]: id } as unknown as DraftFavorite,
          aborterRef.current.signal
        ))
      }
      // If there was an error, toggle the favorite back to its original state
      if (hasError) {
        setIsFavorited((prevState) => !prevState)
      }
    },
    [aborterRef, favoritedData, removeFavorite, addFavorite, name, favoriteKey, id]
  )

  // Keep the favorite button in sync with the favorited data
  useUpdateEffect(() => {
    setIsFavorited(!!favoritedData)
  }, [favoritedData])

  // If the favorite button is not in a list, store the favorited data's favoriteId in the pageFavoriteId state
  useEffect(() => {
    if (!isList && favoritedData?.favoriteId) {
      setPageFavoriteId(favoritedData?.favoriteId)
    }
    // Clean up the pageFavoriteId state when the component unmounts
    return () => {
      if (!isList) {
        setPageFavoriteId(null)
      }
    }
  }, [isList, favoritedData?.favoriteId, setPageFavoriteId])

  useHelpLink(
    !isList
      ? {
          tooltip: `Favoriting a ${favoriteKeyToName[favoriteKey]}`,
          helpLink: 'sales-enablement/favorites#favoriting-an-item',
          order: 2,
          icon: Favorite
        }
      : undefined
  )

  return <FavoriteButtonInner {...props} onClick={handleClick} isFavorited={isFavorited} disabled={!isReady} />
})
