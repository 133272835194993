import { TrackableButton } from '@client/components/Trackables'
import { Badge, Box, Chip as MuiChip } from '@mui/material'
import styled from 'styled-components'
export const Indicator = styled(Badge)`
  & > .MuiBadge-badge {
    color: ${(props) => props.theme.palette.common.white};
    background: ${(props) => props.theme.header.indicator.background};
  }
`

export const NotificationHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

export const UnreadLabel = styled.span`
  font-size: ${({ theme }) => theme.typography.size.s1};
`

export const Chip = styled(MuiChip)`
  min-width: 58px;
  max-width: 100%;
  margin: 0 0.5rem 0 0;
  color: #fff;
  background-color: ${(props) =>
    props.label === 'daily' ? props.theme.palette.primaryGreen : props.theme.palette.purple};
  .MuiChip-label {
    text-transform: capitalize;
  }
`

export const WhatsNewButton = styled(TrackableButton).attrs({ color: 'success', variant: 'contained' })``
