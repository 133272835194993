import { usersLocalStorage } from '@client/helpers/usersLocalStorage'
import { Favorite, FavoriteType, FavoritesSortField, favoritesTypes } from '@client/types/favorites'
import { SortOrder } from '@lib/types/orderBy'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type FavoriteSortBy = { field: FavoritesSortField; direction: SortOrder }

interface FavoritesStore {
  favorites: Favorite[]
  favoritesByType: Record<FavoriteType, Favorite[]>
  sortBy: FavoriteSortBy
  pageFavoriteId: string | null
  setSortBy: (sortBy: FavoriteSortBy) => void
  setPageFavoriteId: (id: string | null) => void
  setFavorites: (favorites: Favorite[]) => void
  addFavorite: (favorite: Favorite) => void
  removeFavorite: (id: string) => void
}

export const useFavoritesStore = create(
  devtools(
    persist<FavoritesStore, [], [], { sortBy: FavoriteSortBy }>(
      (set, get) => ({
        favorites: [],
        pageFavoriteId: null,
        favoritesByType: new Proxy(favoritesTypes, {
          get: (target, prop: FavoriteType) => {
            return get().favorites.filter((favorite) => {
              if (prop in target) {
                return favorite.type === prop
              }
              return undefined
            })
          }
        }) as unknown as Record<FavoriteType, Favorite[]>,
        sortBy: { field: FavoritesSortField.CREATION_DATE, direction: 'desc' },
        setPageFavoriteId: (id) => {
          set({ pageFavoriteId: id })
        },
        addFavorite: (favorite) => {
          set({ favorites: [...get().favorites, favorite] })
        },
        setFavorites: (favorites) => {
          set({ favorites })
        },
        removeFavorite: (id) => {
          set({ favorites: get().favorites.filter(({ favoriteId }: Favorite) => favoriteId !== id) })
        },
        setSortBy: (sortBy) => {
          set({ sortBy })
        }
      }),
      {
        name: '@hoodie:favorites-sort-preference',
        getStorage: () => usersLocalStorage,
        partialize: ({ sortBy }) => ({ sortBy })
      }
    ),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: 'use-favorites-store'
    }
  )
)
