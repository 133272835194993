import { TrackablePopupMenuItem } from '@client/components/Trackables'
import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { MenuItemIcon, MenuItemText } from '@client/components/menu'
import { EventArgs } from '@client/configs/tracking'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { Relationship, RelationshipTypeLabels } from '@client/types/relationships'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { CallMade, CallReceived, Delete, List, MoreVert } from '@mui/icons-material'
import { DialogContentText, IconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { memo, useCallback, useMemo, useState } from 'react'

export interface RelationshipListItemProps {
  onClick: () => void
  onOpenDetails: (relationship: Relationship) => void
  onDelete?: (relationship: Relationship) => Promise<boolean>
  item: Relationship
  isActive?: boolean | ((relationship: Relationship) => boolean)
}

export const RelationshipListItem: React.FC<RelationshipListItemProps> = memo(function RelationshipListItem({
  item: relationship,
  isActive,
  onClick,
  onDelete,
  onOpenDetails
}) {
  const { toast } = useToastNotification()

  const { handleClose: closeMenu, handleClick: openMenu, menuAnchor } = useMenuAnchor()
  const { value: confirmingDelete, setTrue: confirmDelete, setFalse: closeDeleteConfirmation } = useBooleanState(false)
  const [busy, setBusy] = useState(false)

  const active = useMemo(
    () => (typeof isActive === 'function' ? isActive(relationship) : !!isActive),
    [isActive, relationship]
  )

  const detailsGaEvent: EventArgs = {
    category: TrackableCategory.relationships,
    action: TrackableAction.relationshipDetailsClicked,
    dimension1: relationship.id
  }

  const handleOpenDetails = useCallback(() => {
    onOpenDetails(relationship)
    closeMenu()
  }, [onOpenDetails, relationship, closeMenu])

  const handleDelete = useCallback(async () => {
    if (!onDelete) {
      return
    }
    const relationshipName = relationship.name
    setBusy(true)
    const deleted = await onDelete(relationship)
    if (deleted) {
      toast.success(`Deleted relationship "${relationshipName}"`, { autoHideDuration: 2000 })
    } else {
      toast.error(`Could not delete relationship "${relationshipName}"`)
      setBusy(false)
      closeMenu()
      closeDeleteConfirmation()
    }
  }, [onDelete, relationship, toast, closeMenu, closeDeleteConfirmation])

  return (
    <>
      <ListItemButton
        dense
        data-testid={`relationshiplistitem--item-${relationship.id}`}
        selected={active}
        key={relationship.id}
        onClick={onClick}
        divider
      >
        <Tooltip arrow title={relationship.direction.toLowerCase()}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            {relationship.direction === 'INBOUND' ? <CallReceived fontSize="small" /> : <CallMade fontSize="small" />}
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primaryTypographyProps={{
            component: 'div'
          }}
          primary={relationship.name}
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={RelationshipTypeLabels[relationship.type]}
        />
        <IconButton edge="end" onClick={openMenu} data-testid="relationshiplistitem--menu">
          <MoreVert />
        </IconButton>
      </ListItemButton>
      <PopupMenu
        id={`relationshiplistitem--menu-${relationship.id}`}
        anchorEl={menuAnchor}
        onClose={() => closeMenu()}
        open={!!menuAnchor}
      >
        <TrackablePopupMenuItem
          onClick={handleOpenDetails}
          data-testid="relationshiplistitem--menu-details"
          gaEvent={detailsGaEvent}
        >
          <MenuItemIcon>
            <List fontSize="small" />
          </MenuItemIcon>
          <MenuItemText>Details</MenuItemText>
        </TrackablePopupMenuItem>
        {!!onDelete && (
          <PopupMenuItem onClick={confirmDelete} data-testid="relationshiplistitem--menu-delete">
            <MenuItemIcon>
              <Delete fontSize="small" />
            </MenuItemIcon>
            <MenuItemText>Delete relationship</MenuItemText>
          </PopupMenuItem>
        )}
      </PopupMenu>
      <ConfirmationDialog
        open={confirmingDelete}
        title="Delete relationship"
        confirmButtonText="Delete"
        loading={busy}
        loadingIndicator="Deleting..."
        content={
          <DialogContentText>
            Are you sure you want to delete the relationship <strong>{relationship.name}</strong>?
          </DialogContentText>
        }
        onCancel={closeDeleteConfirmation}
        onConfirm={handleDelete}
      />
    </>
  )
})
