import { tracking } from '@client/configs/tracking'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const TrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    tracking.trackPageView(location.pathname)
  }, [location.pathname])

  return children
}
