import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box } from '@client/styles/theme/box'
import { ClickAwayListener } from '@mui/material'
import { memo, useCallback, useState } from 'react'
import { SearchBox } from './components/search-box/SearchBox'
import { SearchResults } from './components/search-results'

export type GlobalSearchProps = {
  onActivated?: () => void
  onDeactivated?: () => void
}

export const GlobalSearch: React.FC<GlobalSearchProps> = memo(function GlobalSearch({ onActivated, onDeactivated }) {
  const [searchTerm, setSearchText] = useState<string>('')
  const { value: isActive, setTrue: activate, setFalse: deactivate } = useBooleanState(false)

  const onActivate = useCallback(() => {
    activate()
    onActivated && onActivated()
  }, [activate, onActivated])

  const onDeactivate = useCallback(() => {
    setSearchText('')
    deactivate()
    onDeactivated && onDeactivated()
  }, [deactivate, onDeactivated])

  return (
    <ClickAwayListener onClickAway={onDeactivate}>
      <Box position="relative">
        <SearchBox
          onActivate={onActivate}
          onDeactivate={onDeactivate}
          searchTerm={searchTerm}
          onSearch={setSearchText}
          isActive={isActive}
        />
        {isActive && <SearchResults searchTerm={searchTerm} />}
      </Box>
    </ClickAwayListener>
  )
})
