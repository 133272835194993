import { algoliaPropsWithItemsAreEqual } from '@client/helpers/algolia/algolia-helpers'
import { Box } from '@client/styles/theme/box'
import { BoxProps, Link, Typography } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { CurrentRefinementsProvided } from 'react-instantsearch-core'

export type BlockedByFilterProps = {
  boxProps?: BoxProps
  textLinkPrefix: string
  textLinkSuffix: string
  textLink: string
  attribute: string
  filterCallback: (item: string) => boolean
}

export const BlockedByFilter = memo(function BlockedByFilter({
  filterCallback,
  textLinkPrefix,
  textLinkSuffix,
  textLink,
  refine,
  attribute,
  items,
  boxProps
}: BlockedByFilterProps & CurrentRefinementsProvided) {
  const currentRefinements = useMemo(
    () => (items?.find((item) => item.attribute === attribute)?.currentRefinement as string[]) ?? [],
    [attribute, items]
  )

  const handleClearBlockedFilter = useCallback(() => {
    refine((state) => ({
      ...state,
      refinementList: {
        ...state.refinementList,
        [attribute]: currentRefinements.filter(filterCallback)
      }
    }))
  }, [refine, attribute, currentRefinements, filterCallback])

  return (
    <Box py={2} {...boxProps} data-testid="blocked-by-filter">
      <Typography variant="body1" fontStyle="italic">
        {textLinkPrefix}
        <Link
          onClick={handleClearBlockedFilter}
          sx={{ cursor: 'pointer' }}
          data-testid={`clear-blocked-filter-${attribute}`}
        >
          {textLink}
        </Link>
        {textLinkSuffix}
      </Typography>
    </Box>
  )
},
algoliaPropsWithItemsAreEqual)
