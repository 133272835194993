import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { FilterPillSwitchBoolean } from '@client/components/algolia/filter-pill/filter-pill-switch-boolean'
import { FilterPillSwitchBooleanProps } from '@client/components/algolia/filter-pill/filter-pill-switch-boolean/FilterPillSwitchBoolean'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'

interface FilterSwitchGroupProps {
  label?: string
  attribute: string
  attributeFalseLabel?: string
  attributeTrueLabel?: string
  hideLabel?: boolean
  hideClear?: boolean
  pl?: number
  onSelected?: (val: boolean | null) => void
}

export const FilterSwitchGroup: React.FC<FilterSwitchGroupProps> = ({
  attribute,
  attributeFalseLabel,
  attributeTrueLabel,
  label,
  hideLabel,
  hideClear,
  pl = 0,
  onSelected
}) => {
  const options: FilterPillSwitchBooleanProps['options'] = useMemo(
    () => [
      [attributeFalseLabel ?? 'No', false],
      [attributeTrueLabel ?? 'Yes', true]
    ],
    [attributeFalseLabel, attributeTrueLabel]
  )

  const handleChange = useCallback(
    (value: boolean | null) => {
      onSelected?.(value)
    },
    [onSelected]
  )

  const handleCleared = useCallback(() => {
    handleChange(null)
  }, [handleChange])

  return (
    <Box py={hideClear ? 0 : 2} pl={pl} alignItems="center" flexDirection="row" display="flex">
      {!hideLabel && (
        <Typography mr={4} data-testid="switch-group-label">
          {label}
        </Typography>
      )}
      <FilterPillSwitchBoolean
        attribute={attribute}
        options={options}
        onClick={handleChange}
        onCleared={handleCleared}
      />
      {!hideClear && <ClearRefinements attribute={[attribute]} buttonProps={{ ml: 2 }} />}
    </Box>
  )
}
