import { toggles } from '@client/hooks/use-feature-toggle'
import { Permission } from '@lib/types/permission'
import React from 'react'
import { TrackableAction } from './tracking'

export type RouteComponent = React.FunctionComponent<any> | React.ComponentClass<any>

type OnboardingSummary = {
  title: string
  content: string
  expiry?: Date
}

export type RouteBase = {
  path: string
  gaAction: TrackableAction
  icon?: JSX.Element
  badge?: string | number
  guard?: any
  featureFlag?: toggles
  hideIfNoPermission?: boolean
  permission?: Permission | Permission[]
  onboarding?:
    | OnboardingSummary
    | ((hasPermission: (permission: Permission | Permission[]) => boolean) => OnboardingSummary | undefined)
}

export type Route = RouteBase & {
  id: string
  title?: RouteComponent
  gaAction?: TrackableAction
  children?: Array<RouteChild>
  mobileChildren?: Array<RouteChild>
  component: RouteComponent | null
  containsHome?: boolean
  open?: boolean
  header?: string
}

export type RouteChild = RouteBase & {
  name: string
  gaAction: TrackableAction
  component: RouteComponent
  isActive?: boolean
}

export type RouteParams = {
  id: string
  category: string
  workbookContentUrl: string
  viewUrlName: string
}

export const EXPIRED_SESSION_ROUTE = '/expired-session'
