import { Box } from '@client/styles/theme/box'
import { Fab } from '@mui/material'
import styled from 'styled-components'

export const HelpButtonWrapper = styled(Box)`
  position: fixed;
  right: 0;
  bottom: ${({ theme }) => theme.spacing(13)};
  z-index: 1119;
  cursor: pointer;
  /* On mobile, add a bit of extra padding to make the button more accessible */
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(2, 0, 2, 3)};
    bottom: 92px;
  }
`

export const FabHelpButton = styled(Fab).attrs({
  color: 'help',
  variant: 'extended'
})`
  height: 56px;
  border-radius: 112px;
`

export const HelpButton = styled(Box)`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.help.main};
  border-radius: 16px 0 0 16px;
  transition: background-color 0.3s ease;
  svg {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: ${({ theme }) => theme.spacing(1)};
    border-radius: 14px 0 0 14px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.help.dark};
  }
`
