import { slugify } from '@client/helpers/strings'
import { Launch } from '@mui/icons-material'
import { Checkbox, IconButton, Link, ListItemText, Radio, SxProps, Theme } from '@mui/material'
import { ReactNode, memo, useMemo } from 'react'
import * as S from './SelectorHitItem.style'

export type SelectorHitItemProps = {
  disabled?: boolean
  imageCategory?: string
  imageUrl?: string
  itemURL?: string
  onClick?: () => void
  itemName: string
  searchIndex?: string
  selected?: boolean
  subtitle?: ReactNode
  sx?: SxProps<Theme>
  title: ReactNode
  singleSelect?: boolean
}

export const SelectorHitItem = memo(function SelectorHitItem({
  disabled,
  imageCategory,
  imageUrl,
  itemURL,
  onClick,
  itemName,
  searchIndex,
  selected,
  subtitle,
  sx,
  title,
  singleSelect
}: SelectorHitItemProps) {
  const selector = useMemo(() => {
    if (!onClick) {
      return null
    }
    const selectorProps = {
      checked: selected,
      color: 'success' as const,
      disabled,
      tabIndex: -1,
      'data-testid': `facet-${singleSelect ? 'radio' : 'checkbox'}-${slugify(itemName)}`
    }
    return singleSelect ? <Radio {...selectorProps} /> : <Checkbox {...selectorProps} />
  }, [onClick, selected, disabled, singleSelect, itemName])

  return (
    <S.ListItem
      secondaryAction={
        itemURL && (
          <Link
            href={itemURL}
            target="_blank"
            sx={{ opacity: 1, paddingRight: 1, pointerEvents: 'all' }}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton>
              <Launch sx={{ fontSize: 16 }} />
            </IconButton>
          </Link>
        )
      }
    >
      <S.ListItemButton onClick={onClick} disabled={disabled} data-testid="selector-hit-item" sx={sx}>
        {selector}
        <S.Image src={imageUrl} category={imageCategory} searchIndex={searchIndex} />
        <ListItemText
          primaryTypographyProps={{
            component: 'div',
            sx: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '30px', gap: 1 }
          }}
          secondaryTypographyProps={{ component: 'div' }}
          primary={title}
          secondary={subtitle}
        />
      </S.ListItemButton>
    </S.ListItem>
  )
})
