import { useAuth0 } from '@auth0/auth0-react'
import { useHasura } from '@client/providers/hasura'
import { TagType, TagWithItems, getUniqueTags } from '@client/types/tags'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const TAG_ITEMS_QUERY_KEY = 'item-tags'

export const useItemTagsByType = (type: TagType, options?: { ids?: string[]; preserveItemTags?: boolean }) => {
  const { hasuraClient } = useHasura()
  const { user } = useAuth0()

  const query = useQuery(
    [TAG_ITEMS_QUERY_KEY, type, options?.ids],
    () => {
      return hasuraClient?.getTagsByType(type, options?.ids)
    },
    {
      enabled: !!hasuraClient && (!options?.ids || options.ids.length > 0)
    }
  )

  const itemTags = useMemo(() => {
    if (options?.preserveItemTags) {
      return query.data
    }
    return query.data?.filter(({ tag }) => !tag.archivedAt)
  }, [query.data, options?.preserveItemTags])

  // Get unique list of tags
  const tags = useMemo(
    () =>
      user?.sub && itemTags
        ? getUniqueTags(
            itemTags.map(({ tag, ...itemTag }) => ({ ...tag, items: [itemTag] } as TagWithItems)),
            user.sub,
            { preserveItemTags: options?.preserveItemTags }
          )
        : [],
    [itemTags, user?.sub, options?.preserveItemTags]
  )

  return {
    isLoading: query.isFetching,
    error: query.error,
    itemTags,
    tags
  }
}
