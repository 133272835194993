import { Favorite } from '@mui/icons-material'
import { SpacingProps } from '@mui/system'
import { TrackableButton } from '@client/components/Trackables'
import { EventArgs } from '@client/configs/tracking'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Typography } from '@client/styles/theme/typography'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'

const gaEvent: EventArgs = {
  category: TrackableCategory.favorites,
  action: TrackableAction.open
}

export interface FavoritesDrawerButtonProps extends SpacingProps {
  onClick: () => void
}

export const FavoritesDrawerButton: React.FC<FavoritesDrawerButtonProps> = ({ onClick, ...rest }) => {
  const { isMobile } = useResponsiveState()
  return (
    <TrackableButton
      data-testid="favorites-drawer-button"
      onClick={onClick}
      size="small"
      variant="outlined"
      color="secondary"
      gaEvent={gaEvent}
      {...rest}
      sx={{ minHeight: 30, minWidth: 'auto' }}
    >
      <Favorite fontSize="small" />
      {!isMobile && (
        <Typography ml={1} noWrap>
          Favorites
        </Typography>
      )}
    </TrackableButton>
  )
}
