import { Variant } from '@client/services/algolia'
import { DraftFilterset } from '@client/types/filterset'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { format } from 'timeago.js'
import { toHumanString } from './strings'

dayjs.extend(utc)
dayjs.extend(timezone)

export const mileToMeters = 1609
export const trafficBaseUrl = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination='
export const inStock = 'in-stock'
export const lowStock = 'low-stock'
export const outOfStock = 'out-of-stock'

const stockStatusMap: Record<Variant['STOCK_STATUS'], string> = {
  'In Stock': inStock,
  'Low Stock': lowStock,
  'Out of Stock': outOfStock
}

export const getDiscount = (discountedPrice: number | null, originalPrice: number | null) => {
  const hasDiscount = !!(discountedPrice && originalPrice && discountedPrice < originalPrice)

  const discountPercentage =
    discountedPrice && originalPrice ? (((originalPrice - discountedPrice) * 100) / originalPrice).toFixed(0) : '0'

  return { hasDiscount, discountPercentage }
}

export const getStockQtyClass = (stocking: number, actualStock: number | null, userFriendly = false) => {
  if (!!actualStock && actualStock > 0 && actualStock < 10) {
    return userFriendly ? 'Low Stock' : lowStock
  } else if (stocking === 0) {
    return userFriendly ? 'Out of Stock' : outOfStock
  }
  return userFriendly ? 'In Stock' : inStock
}

export const getStockStatusClass = (stockStatus: Variant['STOCK_STATUS']) => stockStatusMap[stockStatus] || inStock

export const getStockStatusText = (stockStatus: Variant['STOCK_STATUS'], daySinceOOS: number) => {
  const oosText = daySinceOOS >= 0 ? `OOS ${daySinceOOS}+` : 'OOS'
  return stockStatus === 'Out of Stock' ? oosText : stockStatus
}

export const pickCorrectCategoryLevel = (cat0: string, cat1: string, cat2: string) => {
  const catLevels = (cat2 || '').split(' > ')
  if (catLevels[0] === catLevels[1]) {
    return cat0
  } else if (catLevels[1] === catLevels[2]) {
    return cat1
  } else {
    return cat2
  }
}

//TODO add unit test
export const parseCannabinoidsLabel = (key: string) => {
  //key e.g. PERCENT_THC || THC_MG (units are swapped positions)
  const textParts = key.split('_')
  let unit = ''
  let cannabinoid = ''
  if (textParts[1] === 'MG') {
    cannabinoid = textParts[0]
    unit = 'mg'
  } else if (textParts[0] === 'PERCENT') {
    cannabinoid = textParts[1]
    unit = '%'
  } else {
    console.error('bad key passed to parseCannabinoidsLabel', key)
  }
  return { cannabinoid, unit }
}

// TODO: replace with a more generic nullable integer comparison!
export const sortVariantsByQuantity = (a: { QUANTITY?: number | null }, b: { QUANTITY?: number | null }) => {
  return (a.QUANTITY || 0) < (b.QUANTITY || 0) ? -1 : (a.QUANTITY || 0) > (b.QUANTITY || 0) ? 1 : 0
}

export function formatQuantity(quantity: number | null): string | number {
  const formattedQtyFloat: string = quantity ? quantity.toFixed(1) : ''
  return Number.isInteger(quantity) ? (quantity as number) : formattedQtyFloat
}

export const formatPhoneLink = (phone: string) => {
  let call
  if (phone) {
    call = (phone || '')?.match(/\d+/g)?.join('')
    if (call) {
      call = `${call.slice(0, 3)}-${call.slice(3, 6)}-${call.slice(6)}`
      return call
    }
  }
}

export const formatPhoneDisplay = (phone?: string) => {
  //TODO: May not work in international or all cases, need regex or better string parsing
  return phone && !phone.includes('(') ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}` : phone
}

export const getTZDayMilitaryTime = (tz?: string, hours: string[] = []) => {
  let dt
  // TODO this keeps from page breaking if bad tz, should we use local tz or not show closed at all?
  // most cases local tz should work for users looking in same area, but tz should be updated in algolia
  try {
    dt = dayjs().tz(tz)
  } catch {
    dt = dayjs()
    console.error('bad tz in getTZDayMilitaryTime', tz)
  }
  const militaryTime = dt.hour() * 100 + dt.minute()
  const weekday = dt.day()
  const hoursShifted = [...hours.slice(-1), ...hours.slice(0, -1)]
  return { weekday, militaryTime, hoursShifted }
}

export const getDispensaryOpeningHours = (
  weekday: number,
  militaryTime: number,
  periods: any[],
  weekday_text: any[]
) => {
  if (periods.length == 1 && !periods[0].close) {
    return ''
  }
  const days = periods.filter((x) => x.open.day == weekday || x.close.day == weekday)
  let closed = true
  let closedMessage
  let weekdayOpenClose
  if (days.length == 0) {
    weekdayOpenClose = weekday_text[weekday]
  } else {
    for (let i = 0; i < days.length; i++) {
      const day = days[i]
      const openTime = parseInt(day.open.time)
      const closeTime = parseInt(day.close.time)
      if (militaryTime < openTime) {
        weekdayOpenClose = weekday_text[weekday]
      }
      if (day.close.day == weekday && militaryTime > closeTime) {
        weekdayOpenClose = weekday_text[weekday + 1] || weekday_text[0]
      }
      if (closed) {
        if (
          day.open.day == weekday &&
          militaryTime >= openTime &&
          day.close.day == weekday &&
          militaryTime <= closeTime
        ) {
          closed = false
        }
        if (day.open.day == weekday && militaryTime >= openTime && day.close.day != weekday) {
          closed = false
        }
        if (day.close.day == weekday && militaryTime <= closeTime && day.open.day != weekday) {
          closed = false
        }
      }
    }
  }
  if (closed && weekdayOpenClose) {
    //TODO doesn't account if that weeday is closed for full day to go to next open day.
    const openTimeFormatted = (weekdayOpenClose.match(/:\s(.*?M)/) || [])[1]
    closedMessage = openTimeFormatted ? `Opens at ${openTimeFormatted}` : 'Closed today'
    return closedMessage
  }
  return ''
}

export type DateSource = string | number | Date | dayjs.Dayjs | null | undefined

export const getRangeOfDatesPriorToDate = (days: number[], date?: DateSource): { on: string }[] => {
  return days.map((subDays) => ({
    on: dayjs(date).subtract(subDays, 'day').format('YYYY-MM-DD')
  }))
}

export const getOneWeekDateRange = (date: DateSource) => {
  const days = [7, 0]
  return getRangeOfDatesPriorToDate(days, date)
}

export const MONTHLY_SALES_RANGE_SIZE = 250000
export const MAX_AVERAGE_MONTHLY_THRESHOLD = 3000000

/**
 * Get a range of values based on an interval. If the maxValue is provided, the range will be capped at that value.
 */
export const getPriceRangeByInterval = (value: number, interval: number, maxValue?: number) => {
  const rangeIndex = Math.floor(value / interval)
  return rangeIndex === 0
    ? `under $${toHumanString(interval)}`
    : maxValue === undefined || value < maxValue
    ? `$${toHumanString(rangeIndex * interval)}-$${toHumanString(rangeIndex * interval + interval)}`
    : `over $${toHumanString(maxValue)}`
}

export const MAX_COMPETITION_BRANDS_COUNT = 10

export const getBrandCompetitionDescription = (
  targetBrand: string,
  filterset: DraftFilterset,
  maxBrandsCount = MAX_COMPETITION_BRANDS_COUNT
) => {
  const brands = filterset?.filters?.filterBy?.brands?.filter((b) => targetBrand !== b) ?? []
  const versus = [targetBrand]
  if (!brands.length || brands.length < maxBrandsCount) {
    versus.push('Top brands by total sales')
  }
  if (brands.length) {
    versus.push('Filtered brands')
  }
  return versus.join(' vs ')
}

export const licenseNumberDisplay = (licenseNumber?: string | null) => {
  return licenseNumber || 'Unspecified'
}

export const formatLastUpdatedAt = (lastUpdatedAt: number) => {
  const lastUpdatedAtDate = new Date(lastUpdatedAt * 1000)
  // If lastUpdatedAtDate is 2 days or more in the past, return null
  if (dayjs().diff(lastUpdatedAtDate, 'day') >= 2) {
    return null
  }
  return format(lastUpdatedAtDate)
}

export const formatNumericRefinementLabel = ({ label, prefix = '' }: { label: string; prefix?: string }) => {
  const trimmedLabel = label.trim()

  // Number pattern: matches integers and decimals, positive and negative
  const numberPattern = '[-+]?\\d*\\.?\\d+'

  // Variable pattern: matches variable names with letters, numbers, underscores, and periods
  const variablePattern = '[A-Za-z_][\\w\\.]*'

  // Regular expression patterns
  const rangePattern = new RegExp(`^(${numberPattern})\\s*<=\\s*${variablePattern}\\s*<=\\s*(${numberPattern})$`)
  const lowerBoundPattern = new RegExp(`^(${numberPattern})\\s*<=\\s*${variablePattern}$`)
  const upperBoundPattern = new RegExp(`^${variablePattern}\\s*<=\\s*(${numberPattern})$`)

  // Check for range pattern "number <= variable <= number"
  const rangeMatch = trimmedLabel.match(rangePattern)
  if (rangeMatch) {
    return `${prefix}${rangeMatch[1]} - ${prefix}${rangeMatch[2]}`
  }

  // Check for lower bound pattern "number <= variable"
  const lowerMatch = trimmedLabel.match(lowerBoundPattern)
  if (lowerMatch) {
    return `>= ${prefix}${lowerMatch[1]}`
  }

  // Check for upper bound pattern "variable <= number"
  const upperMatch = trimmedLabel.match(upperBoundPattern)
  if (upperMatch) {
    return `<= ${prefix}${upperMatch[1]}`
  }

  // Return the original label if no pattern matches
  return label
}
