import { Alert, Checkbox, Collapse, FormControlLabel } from '@mui/material'
import { ReactNode, memo } from 'react'

export interface ShareCheckboxProps {
  isShared?: boolean
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  accountName?: string
  disclaimer?: ReactNode
}

export const ShareCheckbox: React.FC<ShareCheckboxProps> = memo(function ShareCheckbox({
  isShared = false,
  label,
  onChange,
  accountName,
  disclaimer
}) {
  return accountName ? (
    <>
      <FormControlLabel
        control={
          <Checkbox
            data-testid="share-checkbox"
            checked={isShared}
            onChange={onChange}
            color="success"
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        }
        label={
          <span data-testid="share-label">
            Share this {label} with everyone in the <strong>{accountName}</strong> account
          </span>
        }
      />
      <Collapse in={isShared} data-testid="share-info-collapse">
        <Alert severity="info" sx={{ mt: 2 }}>
          {disclaimer || (
            <>
              Other account members will be able to select this {label} and create alerts for themselves, but they will
              not be allowed to share, delete, or update it.
            </>
          )}
        </Alert>
      </Collapse>
    </>
  ) : (
    <Alert severity="warning" data-testid="share-alert-cannot-share">
      You cannot share this {label} as you are not a member of an account.
    </Alert>
  )
})
