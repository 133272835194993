import { useMediaQuery, useTheme } from '@mui/material'
import { Breakpoint } from '@mui/material/styles'

export const useResponsiveState = (mobileBreakpoint: Breakpoint = 'md') => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint), { noSsr: true })

  return {
    isMobile
  }
}
