import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ErrorDisplay } from '@client/components/ErrorDisplay/ErrorDisplay'
import { useFeatureToggle } from '@client/hooks/use-feature-toggle'
import { SidebarLayout } from '@client/layouts/Sidebar'
import { AuthenticatedProviders } from '@client/providers'
import { lazy } from '@client/routes/lazy'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ModuleTracker } from './ModuleTracker'
import { PageTracker } from './PageTracker'

const UnsubscribePage = lazy(() => import('@client/pages/public/unsubscribe/UnsubscribePage'))
const LandingPage = lazy(() => import('@client/pages/landing/Landing'))
const HomePage = lazy(() => import('@client/pages/home/Home'))
const BrandCatalogPage = lazy(() => import('@client/pages/brand-catalog/BrandCatalogPage'))
const IntegrationsPage = lazy(() => import('@client/pages/integrations/Integrations'))
const UsageMetricsPage = lazy(() => import('@client/pages/settings/usage-metrics/UsageMetricsPage'))
const ActivityLogPage = lazy(() => import('@client/pages/settings/activity-log/ActivityLogPage'))
const WholesalePage = lazy(() => import('@client/pages/wholesale/Wholesale'))
const EventsPage = lazy(() => import('@client/pages/events/Events'))
const AccountsPage = lazy(() => import('@client/pages/settings/accounts/Accounts'))
const AccountDetailsPage = lazy(() => import('@client/pages/settings/account-details/AccountDetails'))
const UserDetailsPage = lazy(() => import('@client/pages/settings/user-details/UserDetails'))
const UsersPage = lazy(() => import('@client/pages/settings/users/Users'))
const Details = lazy(() => import('@client/layouts/details/Details'))
const WtbList = lazy(() => import('@client/pages/where-to-buy/Wtbs'))
const WtbRules = lazy(() => import('@client/pages/where-to-buy/WtbRules'))
const WtbAnalytics = lazy(() => import('@client/pages/where-to-buy/WtbAnalytics'))
const WtbDetail = lazy(() => import('@client/pages/where-to-buy/WtbDetail'))
const ReportsPage = lazy(() => import('@client/pages/reports/ReportsPage'))
const DispensaryAnalyticsPage = lazy(
  () => import('@client/pages/dispensary-analytics/DispensaryAnalyticsPage/DispensaryAnalyticsPage')
)
const ComparisonPage = lazy(() => import('@client/pages/dispensary-analytics/ComparisonPage/ComparisonPage'))
const AssortmentsPage = lazy(() => import('@client/pages/dispensary-analytics/AssortmentsPage/AssortmentsPage'))
const DetailedComparisonPage = lazy(
  () => import('@client/pages/dispensary-analytics/DetailedComparisonPage/DetailedComparisonPage')
)
const VolumeDriverAnalysisPage = lazy(() => import('@client/pages/market-intelligence/VolumeDriverAnalysisPage'))
const TagManagerPage = lazy(() => import('@client/pages/tag-manager/TagManagerPage'))
const TerritoryBuilderPage = lazy(() => import('@client/pages/territory-builder/TerritoryBuilderPage'))
const RelationshipManagerPage = lazy(
  () => import('@client/pages/settings/relationship-manager/RelationshipManagerPage')
)
const UserProfilePage = lazy(() => import('@client/pages/user-profile/UserProfilePage'))

function AuthenticatedRoutes() {
  const { isEnabled, toggles } = useFeatureToggle()
  return (
    <AuthenticatedProviders>
      <ModuleTracker />
      <PageTracker />
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
          {isEnabled(toggles.brand_catalog) && <Route path="brand-catalog/catalog" element={<BrandCatalogPage />} />}
          <Route path="brand/sales-enablement" element={<HomePage />} />
          <Route path="brand/where-to-buy">
            <Route path="analytics" element={<WtbAnalytics />} />
            <Route path=":id" element={<WtbDetail />} />
            <Route path=":id/rules" element={<WtbRules />} />
            <Route index element={<WtbList />} />
          </Route>
          <Route path="territory-builder">
            <Route index element={<TerritoryBuilderPage />} />
            <Route path=":id" element={<TerritoryBuilderPage />} />
            <Route path=":id/details" element={<TerritoryBuilderPage details />} />
          </Route>
          <Route path="dispensary-analytics">
            <Route index element={<DispensaryAnalyticsPage />} />
            <Route path=":id" element={<DispensaryAnalyticsPage />} />
            <Route path="reports" element={<ReportsPage moduleId="DA" key="DA" />} />
            <Route path="comparison/:id" element={<ComparisonPage />} />
            {isEnabled(toggles.assortment_manager) && (
              <Route path="assortments">
                <Route index element={<AssortmentsPage />} />
                <Route path=":id" element={<AssortmentsPage />} />
                <Route path=":id/details" element={<AssortmentsPage details />} />
              </Route>
            )}
            <Route path="data/:kpi" element={<DetailedComparisonPage />} />
          </Route>
          <Route path="market-intelligence">
            <Route path="reports" element={<ReportsPage moduleId="MI" key="MI" />} />
            <Route path="volume-driver-analysis" element={<VolumeDriverAnalysisPage />} />
          </Route>
          <Route path="wholesale">
            <Route index element={<WholesalePage />} />
            <Route path="reports" element={<ReportsPage moduleId="WS" key="WS" />} />
          </Route>
          <Route path="settings">
            <Route path="accounts" element={<AccountsPage />} />
            <Route path="accounts/:id" element={<AccountDetailsPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="users/:id" element={<UserDetailsPage />} />
            <Route path="integrations" element={<IntegrationsPage />} />
            <Route path="activity-log" element={<ActivityLogPage />} />
            <Route path="usage" element={<UsageMetricsPage />} />
            <Route path="tag-manager">
              <Route index element={<TagManagerPage />} />
              <Route path=":id" element={<TagManagerPage />} />
              <Route path=":id/details" element={<TagManagerPage details />} />
            </Route>
            {isEnabled(toggles.relationship_manager) && (
              <Route path="relationships">
                <Route index element={<RelationshipManagerPage />} />
                <Route path=":id" element={<RelationshipManagerPage />} />
                <Route path=":id/details" element={<RelationshipManagerPage details />} />
              </Route>
            )}
          </Route>
          <Route path="products/:id" element={<Details />} />
          <Route path="brand/reports" element={<ReportsPage moduleId="SE" key="SE" />} />
          <Route path="dispensaries/:id" element={<Details />} />
          <Route path="brands/:id" element={<Details />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="dispensary/competitive-intelligence" element={<HomePage />} />

          <Route path="reports" element={<ReportsPage key="all" />} />

          <Route path="user-profile" element={<UserProfilePage />} />

          <Route path="*" element={<ErrorDisplay />} />
          <Route index element={<LandingPage />} />
        </Route>
        <Route path="/sign-in" element={<Navigate to="/" replace />} />
      </Routes>
    </AuthenticatedProviders>
  )
}

export default withAuthenticationRequired(AuthenticatedRoutes)
