import { Box, styled, Typography } from '@mui/material'

export const FormTitle = styled(Typography)`
  @media (width < 380px) {
    font-size: 1.125rem !important;
  }
`

export const FeedbackButtonWrapper = styled(Box)`
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 1119;
  letter-spacing: 0.06em;
  cursor: pointer;
  transform: rotate(270deg);
  transform-origin: right bottom;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-top: ${({ theme }) => theme.spacing(3)};
  }
`

export const FeedbackButton = styled('div')`
  padding: ${({ theme }) => theme.spacing(0, 2)};
  line-height: 1.3;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.success.main};
  border-radius: 4px 4px 0 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.palette.success.dark};
  }
`
