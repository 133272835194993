import { ArrowBackIosOutlined } from '@mui/icons-material'
import { SpacingProps } from '@mui/system'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import * as S from './BackButton.style'

interface BackButtonProps extends SpacingProps {
  onClick?: () => void
  state?: any
}

export const BackButton: React.FC<BackButtonProps> = ({ onClick, state, ...rest }) => {
  const navigate = useNavigateRef()

  return (
    <S.Button
      {...rest}
      size="small"
      data-testid="back-button"
      variant="text"
      onClick={
        onClick ??
        (() =>
          state?.from ? navigate.current(state.from, { state: { ...state, from: undefined } }) : navigate.current(-1))
      }
      color="secondary"
      startIcon={<ArrowBackIosOutlined />}
    >
      Back
    </S.Button>
  )
}
