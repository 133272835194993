import { GetTokenSilentlyOptions, IdToken, useAuth0 } from '@auth0/auth0-react'
import { monitor } from '@client/configs/monitoring'
import { Sentry } from '@client/configs/sentry'
import { tracking } from '@client/configs/tracking'
import { localStorageUserKey } from '@client/helpers/usersLocalStorage'
import { useLogout } from '@client/hooks/use-logout'
import { useHasura } from '@client/providers/hasura'
import { setRefreshTokenCallback, setToken } from '@client/services/api/api-client'
import type { UserData } from '@client/services/hasura'
import { AccountSummaryWithTier, SubscriptionTier } from '@client/types/account'
import { HoodieJwtPayload, appClaimsKey } from '@lib/types/auth'
import { useQuery } from '@tanstack/react-query'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SetOptional } from 'type-fest'

type RefreshJwtOptions = Pick<GetTokenSilentlyOptions, 'ignoreCache'>

interface UserContextData {
  userData: UserData | null
  roles: Role[] | null
  userToken: string | null
  idToken: any | null
  account: SetOptional<AccountSummaryWithTier, 'subscriptionTier'> | null
  refreshJwt: (options?: RefreshJwtOptions) => Promise<string | null>
  refreshUserData: () => Promise<void>
}

const UserContext = createContext<UserContextData>({} as UserContextData)

export const UserRole = 'HoodieWholesaleUser'
export const AdminRole = 'HoodieWholesaleAdmin'
export type Role = 'HoodieWholesaleAdmin' | 'HoodieWholesaleUser'

const hasuraClaimsKey = 'https://hasura.io/jwt/claims'
const xHasuraRolesKey = 'x-hasura-allowed-roles'

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const { hasuraClient, updateApolloClient } = useHasura()
  const [roles, setRoles] = useState<Role[] | null>(null)
  const [idToken, setIdToken] = useState<IdToken | null>(null)
  const { logout } = useLogout()

  const userToken: string | null = useMemo(() => {
    if (idToken?.__raw) {
      setToken(idToken.__raw)
    }
    return idToken?.__raw ?? null
  }, [idToken?.__raw])

  const { data: userData, refetch: refetchUserData } = useQuery(
    ['user-data', user?.sub],
    async () => {
      const userId = user!.sub as string
      return await hasuraClient?.getUser(userId)
    },
    { enabled: !!hasuraClient && !!isAuthenticated && !!user?.sub, keepPreviousData: true, cacheTime: Infinity }
  )

  useEffect(() => {
    const userId = user?.sub
    if (!idToken && isAuthenticated && userId) {
      getIdTokenClaims().then((newIdToken) => {
        if (newIdToken) {
          tracking.setUser(newIdToken.sub, newIdToken.email)
          localStorage.setItem(localStorageUserKey, newIdToken.sub)
          Sentry.setUser({ id: newIdToken.sub, name: newIdToken.name, email: newIdToken.email })
          setIdToken(newIdToken)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, getIdTokenClaims, isAuthenticated, user?.sub])

  const refreshJwt = useCallback(
    async (options?: RefreshJwtOptions) => {
      await getAccessTokenSilently(options)
      const newIdToken = await getIdTokenClaims()
      if (newIdToken && newIdToken.__raw !== idToken?.__raw) {
        setIdToken(newIdToken)
        updateApolloClient(newIdToken.__raw)
      }
      return newIdToken?.__raw ?? null
    },
    [getAccessTokenSilently, getIdTokenClaims, idToken, updateApolloClient]
  )

  useEffect(
    // Check for a new token every n seconds, renew it if expired and refresh the idToken.
    function keepAlive() {
      let id: ReturnType<typeof setInterval> | undefined = undefined

      if (isAuthenticated) {
        id = setInterval(async () => {
          try {
            await refreshJwt()
          } catch (e: any) {
            if (e.error === 'login_required' || e.error === 'consent_required') {
              console.error('Authentication Error', e.error)
              logout()
            }
          }
        }, 5000) // Check for a new token every 5 seconds
      } else if (id) {
        clearInterval(id)
      }

      return () => {
        id && clearInterval(id)
      }
    },
    [isAuthenticated, logout, refreshJwt]
  )

  useEffect(() => {
    if (user) {
      setRoles(user[hasuraClaimsKey][xHasuraRolesKey])
      monitor.init(user, roles)
    }
  }, [roles, user])

  // Update the API client with the new refresh token callback
  useEffect(() => {
    setRefreshTokenCallback(refreshJwt)
  }, [refreshJwt])

  const account = useMemo(() => {
    const userClaim = user?.[appClaimsKey] as HoodieJwtPayload[typeof appClaimsKey] | undefined
    const acc =
      userClaim?.accountId && userClaim?.accountName
        ? {
            id: userClaim.accountId,
            name: userClaim.accountName,
            subscriptionTier: userClaim.tierId
              ? ({
                  id: userClaim.tierId,
                  code: userClaim.tierCode,
                  name: userClaim.tierName
                } as SubscriptionTier)
              : undefined
          }
        : null
    if (acc) {
      tracking.set({ account: acc.name, tier: acc.subscriptionTier?.code })
    }
    return acc
  }, [user])

  const refreshUserData = useCallback(async () => {
    await refetchUserData()
  }, [refetchUserData])

  return (
    <UserContext.Provider
      value={{
        userData: userData || null,
        roles,
        idToken,
        userToken,
        refreshJwt,
        account,
        refreshUserData
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within an UserProvider.')
  }

  return context
}

export { UserContext, UserProvider, useUser }
