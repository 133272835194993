/**
 In this file we use a set of Custom Dimensions from Google Analytics.
 A dimension is an attribute used for grouping data on GA reports.
 List of dimensions:
 - dimension1: user email domain
*/
import { featureToggleMap, toggles } from '@client/hooks/use-feature-toggle'
import { TrackableAction, TrackableCategory, TrackableLabel } from '@client/types/tracking'
import { isProductionEnv } from '@lib/env'
import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import ttiPolyfill from 'tti-polyfill'

const GaTrackingId: string | undefined = process.env.REACT_APP_GA4_TRACKING_ID

// Tracking is enabled if the 'analytics_tracking' feature toggle is set
const isTrackingEnabled = featureToggleMap[toggles.analytics_tracking]
// Performance monitoring is enabled if the 'performance_monitoring' feature toggle is set
const isPerformanceMonitoringEnabled = featureToggleMap[toggles.performance_monitoring]

// Set the environment variable 'REACT_APP_DEBUG_TRACKING' to true to enable debugging Google Analytics
const debugTracking = process.env.REACT_APP_DEBUG_TRACKING === 'true'

export const isAllowedToTrack = (isTrackingEnabled: boolean, userEmail?: string): boolean => {
  if (!isTrackingEnabled) {
    return false
  }

  // The exclude list is a comman-separated list of regex patterns.
  // If the user's email matches any of the patterns, they will not be monitored.
  const trackingExcludeList = process.env.REACT_APP_TRACKING_EXCLUDE_LIST
  if (!trackingExcludeList || !userEmail) {
    return true
  }
  const excludeList = trackingExcludeList.split(',')
  const notExcludedByEmail = !excludeList.some((pattern) => new RegExp(pattern, 'i').test(userEmail))
  return notExcludedByEmail
}

export type EventArgs = UaEventOptions & {
  dimension1?: string
  dimension2?: string
  dimension3?: string
  dimension4?: string
  dimension5?: string
  dimension6?: string
  dimension7?: string
  dimension8?: string
  dimension9?: string
  dimension10?: string
  dimension11?: string
}

/**
 * This class encapsulates our event and performance tracking functionality.
 *
 * Currently uses Google Analytics.
 */
class Tracking {
  isEnabled = false
  isAllowed = false

  constructor() {
    if (isTrackingEnabled) {
      if (!GaTrackingId) {
        throw new Error('Google Analytics tracking ID is not set')
      }
      this.isEnabled = true
      ReactGA.initialize(GaTrackingId, {
        // Test mode just stores events in memory, so they are not sent to GA
        // Ref: https://github.com/react-ga/react-ga#test-mode
        testMode: !isProductionEnv() && !debugTracking
      })

      if (isPerformanceMonitoringEnabled) {
        ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
          if (tti) {
            ReactGA.event({
              action: 'timing_complete',
              category: 'Load Performace',
              label: 'Time to Interactive',
              value: tti
            })
          }
        })

        const loadPerformanceCallback = (list: any) => {
          list.getEntries().forEach((entry: PerformanceNavigationTiming) => {
            ReactGA.event({
              action: 'timing_complete',
              category: 'Load Performace',
              label: 'Server Latency',
              value: entry.responseStart - entry.requestStart
            })
            ReactGA.event({
              action: 'timing_complete',
              category: 'Load Performace',
              label: 'Download time',
              value: entry.responseEnd - entry.responseStart
            })
            ReactGA.event({
              action: 'timing_complete',
              category: 'Load Performace',
              label: 'Total app load time',
              value: entry.responseEnd - entry.requestStart
            })
          })
        }

        const renderPerformanceCallback: PerformanceObserverCallback = (list) => {
          list.getEntries().forEach((entry) => {
            if (entry.name.includes('App')) {
              ReactGA.event({
                action: 'timing_complete',
                category: 'App Render Performace',
                label: entry.name,
                value: entry.duration
              })
            }
          })
        }

        const paintPerformanceCallback: PerformanceObserverCallback = (list) => {
          list.getEntries().forEach((entry) => {
            ReactGA.event({
              action: 'timing_complete',
              category: 'Paint',
              label: entry.name,
              value: entry.startTime
            })
          })
        }

        const loadPerformanceObserver = new PerformanceObserver(loadPerformanceCallback)
        loadPerformanceObserver.observe({ entryTypes: ['navigation'] })

        const renderPerformanceObserver = new PerformanceObserver(renderPerformanceCallback)
        renderPerformanceObserver.observe({ entryTypes: ['mark', 'measure'] })

        const paintPerformanceObserver = new PerformanceObserver(paintPerformanceCallback)
        paintPerformanceObserver.observe({ entryTypes: ['paint'] })
      } else {
        // eslint-disable-next-line no-console
        console.info('Performance monitoring is disabled')
      }
    }
  }

  set(fieldsObject: any) {
    if (this.isAllowed) {
      ReactGA.set(fieldsObject)
    }
  }

  setUser(userId: string | undefined, email?: string) {
    this.isAllowed = isAllowedToTrack(this.isEnabled, email)
    if (this.isAllowed) {
      ReactGA.set({ userId })
      const emailDomain = email?.split('@')?.[1]
      if (emailDomain) {
        ReactGA.set({ dimension1: emailDomain })
      }
    }
  }

  trackPageView(pathname: string) {
    if (this.isAllowed) {
      ReactGA._gtag('set', { page: pathname })
      ReactGA.send({ hitType: 'pageview', page: pathname })
    }
  }

  trackModalView(modalName: string) {
    if (this.isAllowed) {
      ReactGA.send({ hitType: 'pageview', page: `/modal/${modalName}` })
    }
  }

  trackOutboundLink(label: TrackableLabel) {
    if (this.isAllowed) {
      ReactGA.event({ action: TrackableAction.clickOutboundLink, category: TrackableCategory.navigation, label: label })
    }
  }

  trackEvent(args: EventArgs) {
    if (this.isAllowed) {
      const { action, category, label, value, nonInteraction, transport, ...rest } = args
      ReactGA.event({ action, category, label, value, nonInteraction, transport }, rest)
    }
  }

  trackException(description: string, fatal = false) {
    if (this.isAllowed) {
      ReactGA._gtag('event', 'exception', {
        description,
        fatal
      })
    }
  }
}

export const tracking = new Tracking()
