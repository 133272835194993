import orderBy from '@client/helpers/orderBy'
import { useHasura } from '@client/providers/hasura'
import { useFavoritesStore } from '@client/stores/use-favorites-store'
import {
  DraftFavorite,
  Favorite,
  FavoriteKey,
  FavoriteType,
  favoriteKeys,
  favoritesTypes
} from '@client/types/favorites'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { useEventTracker } from './use-event-tracker'
import { useToastNotification } from './use-toast-notification'

const queryKey = 'get-favorites'

export const getFavoriteTrackingCategory = (favorite: Favorite): TrackableCategory | undefined => {
  const favoriteKey = favoriteKeys.find((key) => !!favorite[key])
  if (!favoriteKey) {
    return undefined
  }
  return Object.values(favoritesTypes).find(({ keys }) => keys.includes(favoriteKey))?.trackableCategory
}

export const useFavorites = (type?: FavoriteType) => {
  const { hasuraClient } = useHasura()
  const { toast } = useToastNotification()
  const queryClient = useQueryClient()
  const { eventTracker } = useEventTracker()

  const [favorites, setFavorites] = useFavoritesStore((state) => [state.favorites, state.setFavorites])
  const favoritesByType = useFavoritesStore((state) => state.favoritesByType)
  const sortBy = useFavoritesStore((state) => state.sortBy)
  const setFavorite = useFavoritesStore((state) => state.addFavorite)
  const unsetFavorite = useFavoritesStore((state) => state.removeFavorite)

  const filteredFavorites = useMemo(() => {
    const filtered = type ? favoritesByType[type] : favorites
    return orderBy(filtered, sortBy.field, sortBy.direction)
  }, [favorites, favoritesByType, sortBy.direction, sortBy.field, type])

  const { isLoading, error } = useQuery(
    [queryKey],
    async () => {
      const favs = (await hasuraClient?.getFavorites()) ?? []
      setFavorites(favs)
      return favs
    },
    {
      enabled: !!hasuraClient
    }
  )

  const addFavorite = useCallback(
    async (favorite: DraftFavorite, abortSignal?: AbortSignal) => {
      if (hasuraClient) {
        try {
          const addedFavorite = await hasuraClient.insertFavorite(favorite, abortSignal)
          setFavorite(addedFavorite)
          const trackableCategory = getFavoriteTrackingCategory(addedFavorite)
          if (trackableCategory) {
            eventTracker({
              category: trackableCategory,
              action: TrackableAction.favorited,
              dimension1: favorite.name
            })
          }
          toast.success(`${favorite.name} added to favorites`)
          return true
        } catch (error) {
          toast.error(`Could't add ${favorite.name} to favorites`)
          return false
        }
      }
    },
    [eventTracker, setFavorite, toast, hasuraClient]
  )

  const removeFavorite = useCallback(
    async (favorite: Favorite, abortSignal?: AbortSignal, silently?: boolean) => {
      if (hasuraClient) {
        try {
          await hasuraClient.deleteFavorite(favorite.favoriteId, abortSignal)
          unsetFavorite(favorite.favoriteId)
          if (!silently) {
            const trackableCategory = getFavoriteTrackingCategory(favorite)
            if (trackableCategory) {
              eventTracker({
                category: trackableCategory,
                action: TrackableAction.unfavorited,
                dimension1: favorite.name
              })
            }
            toast.success(`${favorite.name} removed from favorites`)
          }
          return true
        } catch (error) {
          if (!silently) {
            toast.error(`Unable to remove ${favorite.name} from favorites`)
          }
          return false
        }
      }
    },
    [eventTracker, toast, hasuraClient, unsetFavorite]
  )

  /**
   * Returns a favorite by the given key and id
   */
  const getFavoriteByKey = useCallback(
    (id: string, key: FavoriteKey) => favorites.find((favorite) => favorite[key] === id),
    [favorites]
  )

  const updateFavoriteName = useCallback(
    async (key: FavoriteKey, id: string, name: string) => {
      const favorite = getFavoriteByKey(id, key)
      if (favorite && hasuraClient) {
        await hasuraClient.updateFavoriteName(favorite.favoriteId, name)
        setFavorites(favorites.map((fav) => (fav.favoriteId === favorite.favoriteId ? { ...fav, name } : fav)))
      }
    },
    [favorites, hasuraClient, getFavoriteByKey, setFavorites]
  )

  // Keep the favorites in sync with the query client
  useUpdateEffect(() => {
    queryClient.setQueryData([queryKey], favorites)
  }, [favorites, queryClient])

  return {
    isLoading,
    isReady: !isLoading && !error,
    error,
    favorites: filteredFavorites,
    favoritesCount: favorites.length,
    getFavoriteByKey,
    addFavorite,
    updateFavoriteName,
    removeFavorite
  }
}
