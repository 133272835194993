import { SortBy, SortOption } from '@client/components/sort-by/SortBy'
import { useFavoritesStore } from '@client/stores/use-favorites-store'
import { FavoritesSortField } from '@client/types/favorites'
import { SortOrder } from '@lib/types/orderBy'
import { memo, useCallback } from 'react'

export const favoriteSortOptions: SortOption[] = [
  {
    label: 'Date added',
    value: FavoritesSortField.CREATION_DATE
  },
  {
    label: 'Name',
    value: FavoritesSortField.NAME
  }
]

export const FavoritesSortBy: React.FC = memo(function FavoritesSortBy() {
  const [sortBy, setSortBy] = useFavoritesStore((state) => [state.sortBy, state.setSortBy])

  const handleSortFieldChange = useCallback(
    (newSortField: string) => {
      setSortBy({ field: newSortField as FavoritesSortField, direction: sortBy.direction })
    },
    [setSortBy, sortBy.direction]
  )

  const handleSortOrderChange = useCallback(
    (newSortOrder: SortOrder) => {
      setSortBy({ field: sortBy.field, direction: newSortOrder })
    },
    [setSortBy, sortBy.field]
  )

  return (
    <SortBy
      sortField={sortBy.field}
      sortOrder={sortBy.direction}
      options={favoriteSortOptions}
      sx={{
        backgroundColor: 'white',
        flexShrink: 0
      }}
      color="inherit"
      variant="text"
      size="small"
      onSortFieldChange={handleSortFieldChange}
      onSortOrderChange={handleSortOrderChange}
    />
  )
})
