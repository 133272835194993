import { useTags } from '@client/providers/tags-provider'
import { TagTargetTagsMap, TagType } from '@client/types/tags'
import { Filters, TagFilter, convertTagFiltersToFilterBySync } from '@hoodie/hoodie-filters/lib/filterset'
import { ItemTag } from '@hoodie/hoodie-filters/lib/tags'
import { convertToMap } from '@lib/helpers/objects'
import { useCallback, useMemo } from 'react'
import { useItemTagsByType } from './use-item-tags-by-type'

export const useFiltersWithTags = () => {
  const { getTargetItemTagsCount } = useTags()

  const { tags: brandItemTags, isLoading: brandTagsLoading } = useItemTagsByType(TagType.brand, {
    ids: getTargetItemTagsCount('brand') ? undefined : [],
    preserveItemTags: true
  })
  const { tags: dispensaryItemTags, isLoading: dispensaryTagsLoading } = useItemTagsByType(TagType.dispensary, {
    ids: getTargetItemTagsCount('dispensary') ? undefined : [],
    preserveItemTags: true
  })
  const { tags: productItemTags, isLoading: productTagsLoading } = useItemTagsByType(TagType.product, {
    ids: getTargetItemTagsCount('product') ? undefined : [],
    preserveItemTags: true
  })
  const tagTargetsMap: TagTargetTagsMap = useMemo(
    () => ({
      brand: convertToMap(brandItemTags, 'id'),
      dispensary: convertToMap(dispensaryItemTags, 'id'),
      product: convertToMap(productItemTags, 'id')
    }),
    [brandItemTags, dispensaryItemTags, productItemTags]
  )

  const isLoading = useMemo(
    () => brandTagsLoading || dispensaryTagsLoading || productTagsLoading,
    [brandTagsLoading, dispensaryTagsLoading, productTagsLoading]
  )

  const convertTagFilterToFilterBy = useCallback(
    (tagFilter: TagFilter): ItemTag[] => {
      const itemTags: ItemTag[] = []
      tagFilter.types.forEach((type) => {
        const items = tagTargetsMap[type][tagFilter.tagId]?.items
        if (items?.length) {
          itemTags.push(...items)
        }
      })
      return itemTags
    },
    [tagTargetsMap]
  )

  const normalizeFilterWithTags = useCallback(
    (filters?: Filters): Omit<Filters, 'tags'> | undefined => {
      if (!filters?.tags) {
        return filters
      }
      return convertTagFiltersToFilterBySync({ filters, getItemTagsCallback: convertTagFilterToFilterBy })
    },
    [convertTagFilterToFilterBy]
  )

  return {
    normalizeFilterWithTags,
    isLoading,
    tagTargetsMap
  }
}
