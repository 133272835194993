import { ProductSkuFacet } from '@client/components/ProductSkuFacet/ProductSkuFacet'
import { slugify } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { ProductHit } from '@client/services/algolia'
import { ProductSkuKey } from '@client/types/products'
import { Popover, PopoverProps } from '@mui/material'
import { memo, useCallback, useRef } from 'react'
import * as S from './ProductChip.style'
interface ProductChipProps {
  item: ProductHit
  popoverProps?: PopoverProps
  onRemove?: (value: string, key: ProductSkuKey) => void
}

export const ProductChip: React.FC<ProductChipProps> = memo(function ProductChip({ item, popoverProps, onRemove }) {
  const { value: isOpen, setTrue: openPopover, setFalse: handleClose } = useBooleanState(false)
  const { isMobile } = useResponsiveState()
  const menuAnchor = useRef<HTMLDivElement>(null)

  const removeCallback = useCallback(
    () => onRemove?.(item.CM_ID || item.objectID, item.CM_ID ? 'CM_ID' : 'objectID'),
    [item.CM_ID, item.objectID, onRemove]
  )

  return (
    <>
      <S.Chip
        ref={menuAnchor}
        onMouseEnter={!isMobile ? openPopover : undefined}
        onMouseLeave={!isMobile ? () => handleClose() : undefined}
        key={item.NAME}
        size="small"
        label={item.NAME}
        sx={{ maxWidth: '120px !important' }}
        onDelete={removeCallback}
        aria-label={`current-refinement--${slugify(item.NAME)}`}
      />
      {!isMobile && (
        <Popover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ zIndex: 1301, pointerEvents: 'none' }}
          disableRestoreFocus
          hideBackdrop
          {...popoverProps}
          anchorEl={menuAnchor.current}
          open={isOpen}
          slotProps={{
            ...popoverProps?.slotProps,
            paper: {
              ...popoverProps?.slotProps?.paper,
              sx: {
                overflow: 'visible'
              },
              onClick: (e) => e.stopPropagation(),
              onMouseEnter: openPopover,
              onMouseLeave: () => handleClose()
            }
          }}
        >
          <S.PopoverBody>
            <ProductSkuFacet item={item} onRemove={removeCallback} />
          </S.PopoverBody>
        </Popover>
      )}
    </>
  )
})
