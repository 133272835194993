import { useAuth0 } from '@auth0/auth0-react'
import { Box, Link } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import * as S from './ErrorDisplay.style'

interface ErrorDisplayProps {
  title?: string
  message?: string
  /**The links to be included to help the user reach a better destination*/
  children?: React.ReactNode
}

export const ErrorDisplayContainer: FC<PropsWithChildren> = ({ children }) => (
  <Box display="flex" flex={1} justifyContent="center" alignItems="center" height="100%">
    {children}
  </Box>
)

export const ErrorDisplay = ({
  title = 'Uh oh...',
  message = `We can't find the page you are looking for.`,
  children
}: ErrorDisplayProps) => {
  const { isAuthenticated } = useAuth0()

  return (
    <S.ErrorDisplayWrapper data-testid="error-display">
      <S.ErrorDisplayMessageWrapper>
        <S.ErrorDisplayHeader>{title}</S.ErrorDisplayHeader>
        <S.ErrorDisplayMessage>{message}</S.ErrorDisplayMessage>
        {children ?? (
          <Link href={isAuthenticated ? '/#' : '/sign-in'} underline="hover" color="inherit" data-testid="back-home">
            Go back to Home
          </Link>
        )}
      </S.ErrorDisplayMessageWrapper>
      <div></div>
    </S.ErrorDisplayWrapper>
  )
}
