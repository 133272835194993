import { filterByTag } from '@hoodie/hoodie-filters/lib/tags'
import { useCallback, useMemo } from 'react'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { useSavedViews } from '@client/providers/saved-views'
import { useTags } from '@client/providers/tags-provider'
import { DraftFilterset, View } from '@client/types/filterset'

export const TERRITORY_TAG = 'Territory'

export const useCustomTerritories = () => {
  const { savedViews, insertSavedView, updateSavedView, deleteSavedView } = useSavedViews()
  const { deleteAlertsByFiltersetID } = useNotificationPreferences()
  const territories = filterByTag(savedViews, TERRITORY_TAG)

  const { getTagByLabel } = useTags()
  const territoryTag = useMemo(() => getTagByLabel(TERRITORY_TAG), [getTagByLabel])

  const isTerritory = useCallback(
    (savedView: View) => savedView.tags?.some(({ id }) => id === territoryTag?.id),
    [territoryTag?.id]
  )

  const getTerritoryById = useCallback(
    (territoryId: string) => territories.find((territory) => territory.id === territoryId),
    [territories]
  )

  const saveTerritory = useCallback(
    async (territory: DraftFilterset) => {
      const territoryToSave = {
        ...territory,
        tags: [territoryTag, ...(territory.tags?.filter((tag) => tag.id !== territoryTag?.id) ?? [])].filter(Boolean)
      }
      // Validate if the territory contains dispensaries
      if (!territoryToSave.filters.filterBy?.dispensaries?.length) {
        throw new Error('Territory must contain at least one dispensary')
      }
      // Update or insert the territory
      const savedTerritory = territory.id
        ? await updateSavedView(territoryToSave as View)
        : await insertSavedView(territoryToSave as View)
      return savedTerritory
    },
    [territoryTag, insertSavedView, updateSavedView]
  )

  const deleteTerritory = useCallback(
    async (territoryId: string) => {
      const deleted = await deleteSavedView(territoryId)
      if (deleted) {
        await deleteAlertsByFiltersetID(territoryId)
      }
      return deleted
    },
    [deleteSavedView, deleteAlertsByFiltersetID]
  )

  return {
    territories,
    isTerritory,
    getTerritoryById,
    saveTerritory,
    deleteTerritory
  }
}
