import { useHasura } from '@client/providers/hasura'
import { useUser } from '@client/providers/user'
import { setLogiToken, setPermissionsKey, setSubscriptionKey, trpc } from '@client/services/api/api-client'
import { Permission } from '@lib/types/permission'
import { KnownRoleCodes } from '@lib/types/role'
import { useEffect } from 'react'

export const useAuthorization = () => {
  const { idToken } = useUser()
  const { hasuraClient } = useHasura()
  const query = trpc.account.authorize.useQuery(undefined, {
    enabled: !!idToken
  })

  useEffect(() => {
    if (query.data) {
      const { subscriptionKey, permissionsKey, logiComposer } = query.data
      if (logiComposer?.access_token) {
        setLogiToken(logiComposer.access_token)
      }
      if (subscriptionKey) {
        setSubscriptionKey(subscriptionKey)
      }
      if (permissionsKey) {
        setPermissionsKey(permissionsKey)
      }
      if (query.data.permissions.includes(Permission.HOODIE_SUPER_ADMIN)) {
        hasuraClient!.role = Permission.HOODIE_SUPER_ADMIN
      } else if (query.data.roles.includes(KnownRoleCodes.SUBSCRIPTION_OWNER)) {
        hasuraClient!.role = KnownRoleCodes.SUBSCRIPTION_OWNER
      }
    }
  }, [hasuraClient, query.data])

  return query
}
