import { FilterChip } from '@client/components/FilterChip/FilterChip'
import { InvalidFiltersInfo } from '@client/components/invalid-filters/InvalidFiltersInfo/InvalidFiltersInfo'
import { facetToValue } from '@client/helpers/algolia/algolia-helpers'
import { formatNumericRefinementLabel } from '@client/helpers/domain-utils'
import { omit } from '@client/helpers/objects'
import { ALGOLIA_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import { useInvalidFiltersStore } from '@client/stores/use-invalid-filters-store'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import { InvalidFiltersWithDataMap, getAlgoliaInvalidFilterKeys } from '@client/types/filterset'
import { FilterKey } from '@hoodie/hoodie-filters/lib/filterset'
import { toSorted } from '@lib/helpers/objects'
import { BoxProps, Tooltip } from '@mui/material'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { CurrentRefinementsProvided, Refinement, RefinementValue, SearchState } from 'react-instantsearch-core'
import * as S from './CurrentRefinement.style'

export type CurrentRefinementProps = CurrentRefinementsProvided & {
  index?: ALGOLIA_INDEX
  attribute?: string | string[]
  maxToDisplay?: number
  onClickMore?: () => void
  boxProps?: BoxProps
  children?: React.ReactNode
  hideSecondaryFilterLabels?: boolean
  invalidFilters?: InvalidFiltersWithDataMap
}

type RefinementItem = {
  attribute: string
  label: string
  invalidKeys: FilterKey[]
  value: RefinementValue
  currentRefinement?: string
}

type Refinements = {
  refinements: RefinementItem[]
  productSkus: {
    CM_ID: RefinementItem[]
    objectID: RefinementItem[]
  }
}

export const InvalidFiltersPopover: React.FC<{
  invalidKeys: FilterKey[]
  invalidFilters?: InvalidFiltersWithDataMap
}> = React.memo(function InvalidFiltersInfoByFilterKey({ invalidKeys, invalidFilters }) {
  const messages = [...new Set(invalidKeys?.flatMap((key) => invalidFilters?.[key]?.messages || []) ?? [])]
  const components = [...new Set(invalidKeys?.flatMap((key) => invalidFilters?.[key]?.components || []) ?? [])]

  return <InvalidFiltersInfo messages={messages} components={components} isGlobal />
})

export const CurrentRefinement: React.FC<CurrentRefinementProps> = React.memo(function CurrentRefinement({
  attribute,
  invalidFilters,
  maxToDisplay,
  onClickMore,
  boxProps,
  items,
  refine,
  hideSecondaryFilterLabels,
  children,
  index = VARIANT_INDEX
}) {
  const { refinements, productSkus } = useMemo(() => {
    return items
      .filter((item: Refinement) => {
        const attrValue = item.id === 'query' ? 'query' : item.attribute
        return !attribute || attrValue === attribute || (Array.isArray(attribute) && attribute.includes(attrValue))
      })
      .reduce(
        (acc, ref) => {
          const refinement = ['CM_ID', 'objectID'].includes(ref.attribute)
            ? acc.productSkus[ref.attribute as keyof typeof acc.productSkus]
            : acc.refinements
          if (ref.items) {
            refinement.push(
              ...ref.items.map((item) => {
                const label = facetToValue(item.label)
                return {
                  ...omit(ref, ['items', 'value']),
                  attribute: ref.attribute,
                  invalidKeys: getAlgoliaInvalidFilterKeys({
                    attribute: ref.attribute,
                    value: label,
                    index,
                    invalidFilters
                  }),
                  value: item.value || ref.value,
                  label
                }
              })
            )
          } else if (ref.attribute === 'VARIANTS.ACTUAL_PRICE') {
            const label = formatNumericRefinementLabel({ label: ref.label as string, prefix: '$' })
            refinement.push({
              ...omit(ref, ['items']),
              value: ref.value,
              attribute: ref.attribute,
              label,
              invalidKeys: getAlgoliaInvalidFilterKeys({
                attribute: ref.attribute,
                value: label,
                index,
                invalidFilters
              })
            })
          } else {
            const label = facetToValue(ref.label)
            refinement.push({
              ...ref,
              invalidKeys: getAlgoliaInvalidFilterKeys({
                attribute: ref.attribute,
                value: label,
                index,
                invalidFilters
              }),
              label
            })
          }
          return acc
        },
        {
          refinements: [],
          productSkus: {
            CM_ID: [],
            objectID: []
          }
        } as Refinements
      )
  }, [attribute, index, invalidFilters, items])

  const { filtered, productSkuCount } = useMemo(() => {
    const productSkuIds = [...productSkus.CM_ID, ...productSkus.objectID]
    if (!productSkuIds.length) {
      return { filtered: refinements, productSkuCount: 0 }
    }
    return {
      filtered: [
        ...refinements,
        {
          // Can be either CM_ID or objectID - just need to set one of them for the filter chip to work with
          attribute: productSkus.CM_ID.length ? 'CM_ID' : 'objectID',
          label: 'Products',
          invalidKeys: [...new Set(productSkuIds.map(({ invalidKeys }) => invalidKeys).flat())],
          value: (searchState: SearchState) => {
            return {
              ...searchState,
              refinementList: {
                ...searchState.refinementList,
                CM_ID: [],
                objectID: []
              }
            }
          },
          currentRefinement: `${productSkuIds.length} ${pluralize('product', productSkuIds.length)}`
        }
      ],
      productSkuCount: productSkuIds.length
    }
  }, [productSkus.CM_ID, productSkus.objectID, refinements])

  const { trimmed, hiddenProductCount } = useMemo(() => {
    const sortedByInvalid = toSorted(filtered, (a, b) => (!b.invalidKeys.length && a.invalidKeys.length ? -1 : 0))
    if (maxToDisplay && filtered.length > maxToDisplay) {
      return {
        trimmed: sortedByInvalid.slice(0, maxToDisplay),
        hiddenProductCount: productSkuCount > 0 ? productSkuCount - 1 : 0 // -1 because we're already counting the item as one
      }
    }
    return { trimmed: sortedByInvalid, hiddenProductCount: 0 }
  }, [filtered, maxToDisplay, productSkuCount])

  const notDisplayedCount = useMemo(() => {
    return filtered.length - trimmed.length + hiddenProductCount
  }, [filtered, trimmed, hiddenProductCount])

  return trimmed.length ? (
    <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" py={1} gap={1} {...boxProps}>
      {trimmed.map(({ attribute, label, value, currentRefinement, invalidKeys }) => (
        <FilterChip
          key={`${attribute}-${label}`}
          attribute={attribute}
          label={label}
          currentRefinement={currentRefinement}
          color={!invalidKeys.length ? 'success' : 'error'}
          popoverContent={
            invalidKeys.length ? (
              <InvalidFiltersPopover invalidKeys={invalidKeys} invalidFilters={invalidFilters} />
            ) : undefined
          }
          onDelete={() => refine(value)}
          hideSecondaryLabel={hideSecondaryFilterLabels}
        />
      ))}
      {!!notDisplayedCount &&
        (onClickMore ? (
          <Tooltip title="View all filters">
            <S.MoreLink ml={2} onClick={onClickMore} data-testid="current-refinement-more-button">
              <Typography variant="body2">...and {notDisplayedCount} more</Typography>
            </S.MoreLink>
          </Tooltip>
        ) : (
          <Typography variant="body2" color="textSecondary" ml={2} data-testid="current-refinement-more-text">
            {`...and ${notDisplayedCount} more`}
          </Typography>
        ))}
      {children}
    </Box>
  ) : null
})

export const CurrentRefinementWithInvalidFilters: React.FC<CurrentRefinementProps> = (props) => {
  const invalidFilters = useInvalidFiltersStore(useCallback((state) => state.invalidFilters, []))
  return <CurrentRefinement {...props} invalidFilters={invalidFilters} />
}
