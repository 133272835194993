import { Alert, Collapse, Typography } from '@mui/material'
import { memo, useMemo } from 'react'
import { useIsSuperAdmin } from '@client/hooks/use-permissions'
import {
  AllUsersSelect,
  SubscriptionUserSelect,
  UserSelectOption
} from '@client/components/form/user-select/UserSelect'

export interface TransferOwnershipProps {
  label: string
  accountName?: string
  transferTarget?: UserSelectOption | UserSelectOption[] | null
  onTransferTargetChange: (value: UserSelectOption | UserSelectOption[] | null) => void
}

export const TransferOwnership: React.FC<TransferOwnershipProps> = memo(function TransferOwnership({
  accountName,
  transferTarget,
  label,
  onTransferTargetChange
}) {
  const { isSuperAdmin } = useIsSuperAdmin()

  const displayWarning = useMemo(
    () => (Array.isArray(transferTarget) ? !!transferTarget.length : !!transferTarget?.id),
    [transferTarget]
  )
  const TransferSelect = useMemo(() => (isSuperAdmin ? AllUsersSelect : SubscriptionUserSelect), [isSuperAdmin])

  return accountName ? (
    <>
      <Typography variant="body1" paragraph data-testid="transfer-ownership--instruction">
        Transfer the ownership of this {label} to another{' '}
        {!isSuperAdmin && (
          <>
            <strong>{accountName}</strong>{' '}
          </>
        )}
        user:
      </Typography>
      <TransferSelect
        size="small"
        data-testid="transfer-ownership--select"
        excludeSelf
        excludeSuspended
        label="Transfer to..."
        value={transferTarget}
        onChange={onTransferTargetChange}
      />
      <Collapse in={displayWarning} data-testid="transfer-ownership--collapse">
        <Alert severity="info">
          You will no longer see this {label} on your list, unless it&apos;s shared with your account.
        </Alert>
        <Alert severity="warning" sx={{ mt: 4 }} data-testid="transfer-ownership--alert-warning">
          <strong>Caution:</strong> This action cannot be undone.
        </Alert>
      </Collapse>
    </>
  ) : (
    <Alert severity="warning" data-testid="transfer-ownership--alert-cannot-transfer">
      You cannot transfer this {label} as you are not a member of an account.
    </Alert>
  )
})
