import { Sentry } from '@client/configs/sentry'
import GlobalStyle from '@client/styles/global'
import { CssBaseline } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { Chart, registerables } from 'chart.js'
import ChartAnnotation from 'chartjs-plugin-annotation'
import ZoomPlugin from 'chartjs-plugin-zoom'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { env } from './env'
import { PublicProviders } from './providers'
import reportWebVitals from './reportWebVitals'
import Routes from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

if (env.REACT_APP_MUIX_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(env.REACT_APP_MUIX_LICENSE_KEY)
}

Sentry.init()

Chart.register(...registerables, ChartAnnotation, ZoomPlugin)

const container = document.getElementById('root')
const root = createRoot(container!)

// When running StrictMode on react 18, on development, it mounts, unmounts, and then remounts the components to ensure that they are side-effect free.
// This will cause effects to be fired twice. This is not a bug, but a feature of StrictMode. You can disable it by setting the REACT_APP_DISABLE_STRICT_MODE env var to true.
// You can find a more detailed explanation here:
// https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react/72238236#72238236
const AppWrapper = env.REACT_APP_DISABLE_STRICT_MODE ? React.Fragment : React.StrictMode
if (env.REACT_APP_DISABLE_STRICT_MODE) {
  console.warn(
    'StrictMode is disabled on your environment! Please enable it on development to ensure that your components are side-effect free. You can enable it by setting the REACT_APP_DISABLE_STRICT_MODE env var to false or removing it from your .env file.'
  )
}

root.render(
  <AppWrapper>
    <PublicProviders>
      <>
        <GlobalStyle />
        <CssBaseline />
        <Routes />
      </>
    </PublicProviders>
  </AppWrapper>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
