import type { AppRouter } from '@api/_trpcRouter'
import { env } from '@client/env'
import { EXPIRED_SESSION_ROUTE } from '@client/types/routes'
import { TRPCClientError, createTRPCProxyClient, httpLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'

let token: string
let subscriptionKey: string
let permissionsKey: string
let logiToken: string
let refreshTokenCallback: () => Promise<string | null>

export function setRefreshTokenCallback(newRefreshTokenCallback: () => Promise<string | null>) {
  refreshTokenCallback = newRefreshTokenCallback
}

export function setToken(newToken: string) {
  token = newToken
}

export function setSubscriptionKey(newSubscriptionKey: string) {
  subscriptionKey = newSubscriptionKey
}

export function setPermissionsKey(newPermissionsKey: string) {
  permissionsKey = newPermissionsKey
}

if (env.REACT_APP_API_DOMAIN !== '') {
  console.warn(`Using ${env.REACT_APP_API_DOMAIN} as the API domain`)
}

export function setLogiToken(newLogiToken: string) {
  logiToken = newLogiToken
}

export const getHeaders = () => {
  const headers: Record<string, string> = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  if (subscriptionKey) {
    headers['X-Subscription-Key'] = subscriptionKey
  }
  if (permissionsKey) {
    headers['X-Permissions-Key'] = permissionsKey
  }
  if (logiToken) {
    headers['X-Logi-Auth'] = logiToken
  }
  return headers
}

export const fetchWithInterceptor = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  try {
    const response = await fetch(input, init)
    // RPC errors are not thrown automatically, so we'll throw them manually and handle them in the catch block
    if (!response.ok) {
      const error = await response.json()
      throw new TRPCClientError(error.error.message, {
        cause: error,
        result: error
      })
    }
    return response
  } catch (error) {
    // If error message is "jwt expired", we should redirect to the expired session page
    if (error instanceof TRPCClientError && error.message === 'jwt expired') {
      const token = await refreshTokenCallback()
      // Retry the request with the new token
      if (token) {
        return await fetchWithInterceptor(input, {
          ...init,
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${token}`
          }
        })
      } else {
        window.location.href = `/#${EXPIRED_SESSION_ROUTE}?redirectTo=${encodeURIComponent(window.location.href)}`
        window.location.reload()
      }
    }
    throw error
  }
}

const createClientOptions = {
  links: [
    httpLink({
      url: `${env.REACT_APP_API_DOMAIN.replace(/\/$/, '')}/api`,
      headers: getHeaders,
      fetch: fetchWithInterceptor
    })
  ]
}

export const apiClient = createTRPCProxyClient<AppRouter>(createClientOptions)

export const trpc = createTRPCReact<AppRouter>()
export const trpcClient = trpc.createClient(createClientOptions)
