import { RefinementNumericRange } from '@client/components/RefinementNumericRange'
import { BlockedByFilter } from '@client/components/algolia/blocked-by-filter'
import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { ClearRefinementsProps, QUERY_ATTRIBUTE } from '@client/components/algolia/clear-refinements/ClearRefinements'
import { FilterSwitchGroup } from '@client/components/algolia/filter-switch-group/FilterSwitchGroup'
import { FilterToggleGroup } from '@client/components/algolia/filter-toggle-group/FilterToggleGroup'
import { HierarchicalMenu } from '@client/components/algolia/hierarchical-menu'
import { RefinementList } from '@client/components/algolia/refinement-list'
import { SearchBox } from '@client/components/algolia/search-box'
import { FilterProductSku } from '@client/components/edit-filterset-dialog/components/filter-product-sku'
import { TagsHierarchicalRefinement } from '@client/components/tags/TagsHierarchicalRefinement'
import { slugify } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box } from '@client/styles/theme/box'
import {
  FilterOption,
  PRODUCT_SKUS_VARIANT_FIELDS,
  TAG_ALGOLIA_SEPARATOR,
  TagFilterOption,
  TagFilterTarget,
  filtersFromSearchState,
  getTagTargetsFromFilterOptions
} from '@client/types/filterset'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, InputAdornment } from '@mui/material'
import { SpacingProps } from '@mui/system'
import { FC, PropsWithChildren, ReactNode, memo, useCallback, useMemo } from 'react'
import type { RefinementListExposed } from 'react-instantsearch-core'
import { SearchState } from 'react-instantsearch-core'
import { RequireAllOrNone } from 'type-fest'
import * as S from './FilterSection.style'

const buttonProps: ClearRefinementsProps['buttonProps'] = { py: 0, mr: 1 }
const productSkuSpacingProps: SpacingProps = { my: -2, mx: -4, px: 4, py: 2 }

const refinementListProps: Partial<RefinementListExposed> = {
  showMore: true,
  limit: 5,
  searchable: true
}

const numericRangeInputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> }

type ControlledFilterSectionProps = RequireAllOrNone<
  {
    collapsed: boolean
    onToggleCollapse: (attribute: string, isCollapsed: boolean) => void
  },
  'collapsed' | 'onToggleCollapse'
>

type BaseFilterSectionProps = {
  clearComponent?: ReactNode
  disableExpandCollapse?: boolean
  disabled?: boolean
  filterKey: string
  hidden?: boolean
  initiallyCollapsed?: boolean
  isPopulated?: boolean
  label: string
  index?: number
}

export const BaseFilterSection: FC<PropsWithChildren<BaseFilterSectionProps & ControlledFilterSectionProps>> = memo(
  function BaseFilterSection({
    children,
    clearComponent,
    collapsed,
    disableExpandCollapse,
    disabled,
    filterKey,
    isPopulated,
    hidden,
    initiallyCollapsed,
    label,
    onToggleCollapse,
    index = 0
  }) {
    const isControlled = collapsed !== undefined

    const {
      value: internalIsExpanded,
      setTrue: expand,
      setFalse: collapse
    } = useBooleanState(!disabled && (!initiallyCollapsed || !!isPopulated))

    const isExpanded = disableExpandCollapse || (isControlled ? !disabled && !collapsed : internalIsExpanded)

    const handleClick = useCallback(() => {
      if (isControlled) {
        return onToggleCollapse(label, !collapsed)
      }
      return isExpanded ? collapse() : expand()
    }, [isControlled, isExpanded, collapse, expand, onToggleCollapse, label, collapsed])

    return (
      <S.FilterSection
        data-id={`filter-section-index--${index}`}
        data-testid={`filter-section--${slugify(filterKey)}`}
        hidden={hidden}
      >
        <S.FilterSectionTitleBar>
          <S.FilterSectionTitle
            variant="subtitle1"
            disabled={disabled}
            onClick={disabled ? undefined : handleClick}
            sx={{ cursor: disabled ? undefined : 'pointer' }}
          >
            {label}
          </S.FilterSectionTitle>
          {!disabled && clearComponent}
          <S.ExpandButton
            data-testid="filter-section--expand"
            disabled={disabled || disableExpandCollapse}
            size="small"
            onClick={handleClick}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </S.ExpandButton>
        </S.FilterSectionTitleBar>
        <Collapse in={isExpanded} timeout="auto">
          <Box my={2}>{children}</Box>
        </Collapse>
      </S.FilterSection>
    )
  }
)

export type FilterSectionProps = Omit<
  BaseFilterSectionProps,
  'clearComponent' | 'filterKey' | 'isPopulated' | 'label'
> & {
  filter: FilterOption
  invisibleFilters?: FilterOption[]
  searchState: SearchState
  blockedByTagType?: TagFilterTarget
} & ControlledFilterSectionProps

export const FilterSection: React.FC<FilterSectionProps> = memo(function FilterSection({
  filter,
  invisibleFilters,
  searchState,
  blockedByTagType,
  ...props
}) {
  const isPopulated = (searchState.refinementList?.[(filter as any).attribute]?.length ?? 0) > 0
  // We need to convert the search state to a masters index compatible search state
  // so we convert it to the common filterset format, then convert it back to a
  // search state for the masters index
  const productSkufilters = useMemo(() => filtersFromSearchState(searchState), [searchState])

  const handleTagsOmittedTargets = useMemo(
    () => (filter.filterKey === 'tags' ? getTagTargetsFromFilterOptions(invisibleFilters) : []),
    [filter.filterKey, invisibleFilters]
  )

  const clearComponent = useMemo(() => {
    if (blockedByTagType) {
      return undefined
    }
    const props: Partial<ClearRefinementsProps> = {
      buttonProps
    }
    if (filter.type === 'refinement-list' || filter.type === 'hierarchical-menu' || filter.type === 'numeric-range') {
      props.attribute = filter.attribute
    } else if (filter.type === 'refinement-product-sku-list') {
      props.attribute = PRODUCT_SKUS_VARIANT_FIELDS
    } else if (filter.type === 'searchbox') {
      props.clearsQuery = true
      props.attribute = QUERY_ATTRIBUTE
    } else if (filter.type === 'toggle') {
      props.attribute = filter.attributes
    } else if (filter.type === 'switch') {
      props.attribute = [filter.attribute]
    }
    return <ClearRefinements {...props} />
  }, [blockedByTagType, filter])

  const filterCallback = useCallback(
    (item: string) => !item.endsWith(`${TAG_ALGOLIA_SEPARATOR}${blockedByTagType}`),
    [blockedByTagType]
  )

  return (
    <BaseFilterSection
      {...props}
      isPopulated={isPopulated}
      filterKey={filter.filterKey.toString()}
      label={filter.label}
      clearComponent={clearComponent}
    >
      {blockedByTagType ? (
        <BlockedByFilter
          filterCallback={filterCallback}
          textLinkPrefix={`You have selected a tag filter that applies to ${blockedByTagType}. To select specific ${blockedByTagType}, `}
          textLinkSuffix="."
          textLink={`unselect this tag filter for ${blockedByTagType}`}
          attribute={TagFilterOption.attribute}
        />
      ) : (
        <>
          {filter.type === 'refinement-list' &&
            (filter.filterKey === 'tags' ? (
              <TagsHierarchicalRefinement
                attribute={filter.attribute}
                {...refinementListProps}
                omitTargets={handleTagsOmittedTargets}
              />
            ) : (
              <RefinementList attribute={filter.attribute} {...refinementListProps} />
            ))}
          {filter.type === 'hierarchical-menu' && <HierarchicalMenu attributes={filter.attributes} />}
          {filter.type === 'refinement-product-sku-list' && (
            <FilterProductSku
              label="Select products..."
              attribute={filter.attributes[0]}
              filters={productSkufilters}
              hideArrow
              hideClearRefinements
              spacingProps={productSkuSpacingProps}
            />
          )}
          {filter.type === 'toggle' && (
            <FilterToggleGroup
              hideLabel
              hideClear
              attributes={filter.attributes}
              attributeLabels={filter.attributeLabels}
            />
          )}
          {filter.type === 'switch' && (
            <FilterSwitchGroup
              hideLabel
              hideClear
              attribute={filter.attribute}
              attributeFalseLabel={filter.attributeFalseLabel}
              attributeTrueLabel={filter.attributeTrueLabel}
            />
          )}
          {filter.type === 'searchbox' && <SearchBox margin="dense" />}
          {filter.type === 'numeric-range' && (
            <RefinementNumericRange
              attribute={filter.attribute}
              startLabel="Min"
              endLabel="Max"
              InputProps={numericRangeInputProps}
            />
          )}
        </>
      )}
    </BaseFilterSection>
  )
})
