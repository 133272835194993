import { palette } from '@client/styles/theme/palette'
import { createStyles, makeStyles } from '@mui/styles'

export const useSpeedDialTooltipStyles = makeStyles(() =>
  createStyles({
    staticTooltipLabel: {
      color: palette.lightest,
      background: palette.darkest,
      whiteSpace: 'nowrap',
      maxWidth: 'none'
    }
  })
)

export const useSpeedDialStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: theme.spacing(10)
    },
    fab: {
      color: palette.darkGray,
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      '&.Mui-disabled': {
        backgroundColor: 'transparent !important'
      }
    },
    actions: {
      paddingTop: `${theme.spacing(1)} !important`,
      paddingRight: `${theme.spacing(4)} !important`,
      marginTop: '0 !important'
    }
  })
)
