import { Box, Button as MuiButton, Typography } from '@mui/material'
import styled from 'styled-components'

export const EditableTitle = styled(Typography)`
  position: relative;
  min-width: ${({ contentEditable }) => (contentEditable ? '40px' : '0px')};
  word-break: break-word;
  outline: none !important;
  cursor: ${({ contentEditable }) => (contentEditable ? 'text' : 'inherit')};
`

export const InputBorder = styled(Box)<{ $hasError?: boolean; $isFocused?: boolean }>`
  background-color: ${({ theme, $hasError }) => ($hasError ? theme.palette.red : theme.palette.lightBlue)};
  bottom: 0;
  display: inline;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleX(${({ $isFocused }) => ($isFocused ? 1 : 0)});
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
`

export const Button = styled(MuiButton)`
  white-space: nowrap;
`
