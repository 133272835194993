import { usersLocalStorage } from '@client/helpers/usersLocalStorage'
import { Comparison, DraftComparison } from '@client/types/comparison'
import produce from 'immer'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface ComparisonStore {
  isInitialized: boolean
  draftComparison?: DraftComparison
  savedComparisons: Comparison[]
  setSavedComparisons: (comparisons: Comparison[]) => void
  saveDraft: (comparison: Comparison) => void
  upsertComparison: (comparison: DraftComparison) => void
  removeComparison: (id?: string) => void
}

export const useComparisonStore = create(
  devtools(
    persist<ComparisonStore, [], [], { draftComparison?: DraftComparison }>(
      (set) => ({
        isInitialized: false,
        draftComparison: undefined,
        savedComparisons: [],
        setSavedComparisons: (savedComparisons) => {
          set({ savedComparisons, isInitialized: true })
        },
        saveDraft: (comparison) => {
          set(
            produce((state) => {
              state.savedComparisons.unshift(comparison)
              state.draftComparison = undefined
            })
          )
        },
        upsertComparison: (comparison) => {
          set(
            produce((state) => {
              if (comparison.id) {
                const savedIndex = state.savedComparisons.findIndex((c: DraftComparison) => c.id === comparison.id)
                if (savedIndex !== -1) {
                  state.savedComparisons[savedIndex] = comparison
                } else {
                  state.savedComparisons.push(comparison)
                }
              } else {
                state.draftComparison = comparison
              }
            })
          )
        },
        removeComparison: (id) => {
          set(
            produce((state) => {
              if (id) {
                state.savedComparisons = state.savedComparisons.filter((c: DraftComparison) => c.id !== id)
              } else {
                state.draftComparison = undefined
              }
            })
          )
        }
      }),
      {
        name: '@hoodie:draft-comparison',
        getStorage: () => usersLocalStorage,
        partialize: ({ draftComparison }) => ({ draftComparison })
      }
    ),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: 'user-comparison'
    }
  )
)
