import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpLink } from '@client/components/help-link/HelpLink'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerToggleButton,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { SearchInput } from '@client/components/search-input/SearchInput'
import { upperCaseFirst } from '@client/helpers/strings'
import { useFavorites } from '@client/hooks/use-favorites'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { useFavoritesStore } from '@client/stores/use-favorites-store'
import { Box } from '@client/styles/theme/box'
import { FavoriteType, favoritesTypes } from '@client/types/favorites'
import { Close, FavoriteBorder } from '@mui/icons-material'
import { Alert, AlertTitle, Divider, Drawer, IconButton, Skeleton, ToggleButtonGroup, Typography } from '@mui/material'
import { singular } from 'pluralize'
import { Fragment, MouseEvent, useCallback, useMemo, useState } from 'react'
import { FavoriteListItem } from './components/favorite-list-item/FavoriteListItem'
import { FavoritesSortBy } from './components/favorites-sort-by/FavoritesSortBy'

interface FavoritesDrawerContentProps {
  onClose: () => void
}

const FavoritesDrawerContent: React.FC<FavoritesDrawerContentProps> = ({ onClose }) => {
  const [filterType, setFilterType] = useState<FavoriteType | undefined>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { isLoading, favorites, favoritesCount } = useFavorites(filterType)

  const pageFavoriteId = useFavoritesStore((state) => state.pageFavoriteId)

  const filteredFavorites = useMemo(() => {
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()
      return favorites.filter(({ name }) => name.toLowerCase().includes(lowerCaseSearchTerm))
    }
    return favorites
  }, [searchTerm, favorites])

  const handleFilterChange = useCallback((event: MouseEvent<HTMLElement>, filter?: FavoriteType) => {
    setFilterType(filter)
  }, [])

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            Favorites
            <Box display="flex" alignItems="center">
              <HelpLink helpLink="sales-enablement/favorites" tooltip="View help on favorites" />
              <IconButton edge="end" onClick={onClose} data-testid="close-drawer-button">
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <ToggleButtonGroup
          color="primary"
          value={filterType}
          exclusive
          onChange={handleFilterChange}
          size="small"
          sx={{ width: '100%', flexWrap: 'wrap' }}
        >
          {Object.entries(favoritesTypes).map(([type], index) => (
            <DrawerToggleButton
              key={type}
              value={type}
              data-testid={`favorites-drawer--toggle-${type}`}
              sx={{ minWidth: '33%', mt: index > 2 ? '-1px' : 0 }}
            >
              {upperCaseFirst(type)}
            </DrawerToggleButton>
          ))}
        </ToggleButtonGroup>
        <Box display="flex" pl={3} pr={2} my={2} gap={2}>
          <SearchInput searchTerm={searchTerm} onSearch={setSearchTerm} margin="none" />
          <FavoritesSortBy />
        </Box>
        {filteredFavorites.map((favorite) => (
          <ErrorBoundary key={favorite.favoriteId}>
            <FavoriteListItem favorite={favorite} onClick={onClose} isActive={favorite.favoriteId === pageFavoriteId} />
          </ErrorBoundary>
        ))}
        {isLoading &&
          [...Array(3).keys()].map((i) => (
            <Fragment key={i}>
              <Box py={2} px={4} data-testid="favorites-drawer--skeleton">
                <Skeleton variant="text" width={170} height={28} sx={{ mb: 1 }} />
                <Skeleton variant="text" width={50} height={20} />
              </Box>
              <Divider />
            </Fragment>
          ))}
        {!isLoading && favorites.length === 0 && !searchTerm && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            m={4}
            data-testid="favorites-drawer--no-favorites"
          >
            <Alert severity="info">
              <AlertTitle>
                {filterType ? `No ${filterType} favorited yet!` : 'Nothing has been favorited yet!'} You can favorite{' '}
                {filterType ? `a ${singular(filterType)}` : 'an item'} by clicking on the heart icon (
                <FavoriteBorder color="primary" sx={{ fontSize: '12px', top: '2px', position: 'relative' }} />) next to
                its name.
              </AlertTitle>
            </Alert>
          </Box>
        )}
        {favoritesCount > 0 && filteredFavorites.length === 0 && searchTerm && (
          <Typography p={4} fontStyle="italic" data-testid="favorites-drawer--no-matches" textAlign="center">
            No favorites found for <strong>{searchTerm}</strong>
          </Typography>
        )}
      </DrawerContent>
    </>
  )
}

export const FavoritesDrawer: React.FC = () => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  return (
    <Drawer
      open={drawer?.id === DrawerId.favorites}
      anchor="right"
      classes={drawerStyles}
      onClose={clearDrawer}
      data-testid="favorites-drawer"
    >
      <ErrorBoundary>
        <FavoritesDrawerContent onClose={clearDrawer} />
      </ErrorBoundary>
    </Drawer>
  )
}
