import { ReactNode } from 'react'

declare module '@mui/x-data-grid-pro' {
  interface NoRowsOverlayPropsOverrides {
    isError?: boolean
    searchTerm?: string
    label?: string
    component?: ReactNode
  }
  interface NoResultsOverlayPropsOverrides {
    label?: string
  }
}

const overrides = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow:
          '0px -3px 5px 1px rgba(0,0,0,0.4), 0px -6px 10px 0px rgba(0,0,0,0.16), 0px -5px 18px 0px rgba(0,0,0,0.12)'
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.MuiTableCell-sizeSmall': {
          padding: '3px 3px'
        }
      }
    }
  },
  MuiCard: {
    styleOverrides: {
      root: ({ square }: any) => ({
        borderRadius: square ? 0 : '6px',
        boxShadow: 'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px'
      })
    }
  },
  PrivateTabIndicator: {
    styleOverrides: {
      root: {
        height: '3px'
      }
    }
  },
  MuiBox: {
    styleOverrides: {
      root: {
        padding: '0',
        fontWeight: 'inherit'
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#4782da',
          fontWeight: 700
        }
      }
    }
  },
  MuiCardHeader: {
    styleOverrides: {
      action: {
        marginTop: '-4px',
        marginRight: '-4px'
      },
      defaultProps: {
        titleTypographyProps: { variant: 'h6' }
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: '300'
      }
    }
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: '64px'
      }
    }
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: '6px'
      }
    }
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: 'transparent',
        '& > *': {
          backgroundColor: 'transparent'
        }
      },
      switchHeader: {
        marginTop: '2px',
        marginBottom: '4px'
      }
    }
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`
      }
    }
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: '32px',
        height: '32px'
      }
    }
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400
        }
      },
      timeHeader: {
        '& h3': {
          fontSize: '3rem',
          fontWeight: 400
        }
      }
    }
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        '& h2': {
          fontSize: '3.75rem',
          fontWeight: 300
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '12px'
      },
      popper: {
        background: 'transparent'
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0
      }
    }
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        '& h4': {
          fontSize: '2.125rem',
          fontWeight: 400
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        fontWeight: 700,
        marginRight: '10px'
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        paper: {
          overflowY: 'inherit'
        }
      },
      paperScrollPaper: {
        maxHeight: 'calc(100% - 120px)'
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '& h2': {
          fontSize: '1.2rem',
          fontWeight: 700
        },
        '&+.MuiDialogContent-root': {
          padding: '8px 24px'
        }
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '24px'
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      formControl: {
        '&.select-input': {
          left: '15px',
          top: '-3px'
        }
      }
    }
  },
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        '&$selected.styled-list': {
          backgroundColor: theme.palette.primaryGreen,
          color: '#fff',
          fontWeight: 700,
          '&.Mui-checked': {
            color: 'red'
          },
          '&:hover': {
            backgroundColor: '#75d7a1'
          }
        }
      })
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%'
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        width: '100%'
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          width: 'auto'
        },
        '& .MuiTablePagination-select': {
          width: 'auto'
        }
      }
    }
  }
}

export default overrides
