import { useFeatureToggle } from '@client/hooks/use-feature-toggle'
import {
  FeedbackWidgetProps,
  FeedbackButton as InnerFeedbackButton,
  FeedbackWidget as InnerFeedbackWidget
} from './FeedbackWidget'

export const FeedbackButton: React.FC = () => {
  const { isEnabled, toggles } = useFeatureToggle()
  return isEnabled(toggles.feedback_widget) ? <InnerFeedbackButton /> : null
}

export const FeedbackWidget: React.FC<FeedbackWidgetProps> = (props) => {
  const { isEnabled, toggles } = useFeatureToggle()
  return isEnabled(toggles.feedback_widget) ? <InnerFeedbackWidget {...props} /> : null
}
