import orderBy from '@client/helpers/orderBy'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { usePermission } from '@client/hooks/use-permissions'
import { useHasura } from '@client/providers/hasura'
import { apiClient } from '@client/services/api/api-client'
import {
  DialogNotificationPreference,
  DraftComparisonNotificationPreference,
  DraftNotificationPreference,
  NotificationCategory,
  NotificationChannel,
  NotificationOptions,
  NotificationPreference
} from '@client/types/notification'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Permission } from '@lib/types/permission'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import * as yup from 'yup'

const { query: enqueueNotificationPreference } = apiClient.notificationPreferences.enqueueNotificationPreference

interface NotificationPreferenceContextData {
  getPreference: (notificationPreferenceId: string) => NotificationPreference | undefined
  preferences: Map<string, DialogNotificationPreference>
  settingsPreferencesKeys: Record<string, string>
  notificationOptions: NotificationOptions
  addPreference(preference: DraftNotificationPreference): DialogNotificationPreference
  getChannelsByPreferenceId(notificationPreferenceId: string): string[]
  getCategoryIdByCode(code: string): string | undefined
  getChannelIdByName(name: string): string | undefined
  discardDraftPreferences(): void
  validateDirty(filtersetId?: string, schema?: yup.ObjectSchema<any>): boolean
  validatePreference(preferenceKey: string, schema?: yup.ObjectSchema<any>): boolean
  preferencesForFiltersetId(filtersetId?: string): Map<string, DialogNotificationPreference>
  markDirty(key: string, preference: NotificationPreference): void
  saveDirty(filtersetId: string, lookForUnassigned?: boolean): Promise<NotificationPreference[]>
  saveNotificationPreference(preference: DraftNotificationPreference, key: string): Promise<NotificationPreference>
  deleteNotificationPreference(key: string): Promise<boolean>
  handleNotificationPreferenceNow(notificationPreferenceId: string): Promise<boolean>
  deleteAlertsByFiltersetID(filtersetId: string): Promise<boolean>
  deleteAlertsByComparisonID(comparisonId: string): Promise<boolean>
}

export const orderAndGroupByCategory = (categories: NotificationCategory[]) => {
  categories = orderBy(categories, ['parentId', 'name'])

  return categories
    .filter((category) => category.parentId === null)
    .reduce(
      (all: NotificationCategory[], parent: NotificationCategory) =>
        all.concat([parent, ...categories.filter((category) => category.parentId === parent.id)]),
      []
    )
}

const NotificationPreferenceContext = createContext<NotificationPreferenceContextData>(
  {} as NotificationPreferenceContextData
)

const canUpdateOrDelete = (preference: DraftNotificationPreference): boolean => !!preference.id

export const NotificationPreferenceProvider = ({ children }: { children: ReactNode }) => {
  const { hasuraClient } = useHasura()
  const { eventTracker } = useEventTracker({ category: TrackableCategory.notifications })
  const { hasPermission } = usePermission(Permission.NOTIFICATION_PREFERENCE_CREATE)
  const [preferences, setPreferences] = useState<Map<string, DialogNotificationPreference>>(new Map())
  const [notificationOptions, setNotificationOptions] = useState<NotificationOptions>({
    channels: [] as NotificationChannel[],
    categories: [] as NotificationCategory[],
    allCategories: [] as NotificationCategory[]
  })

  const settingsPreferencesKeys = useMemo(
    () => Object.fromEntries([...preferences].map(([key, preference]) => [preference.settings.id, key])),
    [preferences]
  )

  useEffect(() => {
    if (hasuraClient && hasPermission) {
      hasuraClient
        .notificationOptions()
        .then((options) => {
          return {
            channels: options.channels,
            categories: orderAndGroupByCategory(options.categories),
            allCategories: options.allCategories
          }
        })
        .then(setNotificationOptions)
      hasuraClient.notificationPreferences().then((preferences) => {
        setPreferences(
          new Map(
            preferences.map((settings) => {
              const key = uuid4()
              return [key, { key, isDirty: false, settings }]
            })
          )
        )
      })
    }
  }, [hasPermission, hasuraClient])

  const addPreference = useCallback((preference: DraftNotificationPreference) => {
    const key = uuid4()
    const dialogPref = { key, isDirty: true, settings: preference }
    setPreferences((map) => new Map([...map, [key, dialogPref]]))
    return dialogPref
  }, [])

  const discardDraftPreferences = useCallback(() => {
    setPreferences((map) => {
      return new Map([...map].filter(([, { settings }]) => canUpdateOrDelete(settings)))
    })
  }, [])

  const getPreference = useCallback(
    (notificationPreferenceId: string) => {
      return preferences.get(settingsPreferencesKeys[notificationPreferenceId])?.settings as NotificationPreference
    },
    [preferences, settingsPreferencesKeys]
  )

  const preferencesForFiltersetId = useCallback(
    (filtersetId?: string) =>
      new Map(
        [...preferences].filter(
          (entry) =>
            (filtersetId && entry[1].settings.filtersetId === filtersetId) ||
            (!filtersetId && !canUpdateOrDelete(entry[1].settings))
        )
      ) as Map<string, DialogNotificationPreference>,
    [preferences]
  )

  const preferencesForComparisonId = useCallback(
    (comparisonId: string) =>
      new Map([...preferences].filter((entry) => entry[1].settings.comparisonId === comparisonId)) as Map<
        string,
        DialogNotificationPreference<DraftComparisonNotificationPreference>
      >,
    [preferences]
  )

  const markDirty = useCallback((key: string, preference: NotificationPreference) => {
    setPreferences((map) => {
      const entry = map.get(key)

      if (!entry) {
        return map
      }

      entry.isDirty = true
      entry.settings = preference

      return new Map([...map.set(key, { ...entry })])
    })
  }, [])

  const validatePreference = useCallback(
    (preferenceKey: string, schema: yup.ObjectSchema<any> = baseSchema): boolean => {
      const preference = preferences.get(preferenceKey)
      if (!preference) {
        return false
      }
      const { isDirty, settings } = preference
      // validate preference usign a yup schema
      const errors: Record<string, string> = {}
      try {
        schema.validateSync(settings, { abortEarly: false })
      } catch (err: any) {
        err.inner.forEach((error: yup.ValidationError) => {
          if (error.path) {
            errors[error.path] = error.message
          }
        })
      }
      // set or reset errors
      setPreferences((map) => {
        return new Map([...map.set(preferenceKey, { key: preferenceKey, isDirty, settings, errors })])
      })

      if (Object.keys(errors).length) {
        return false
      }

      return true
    },
    [preferences]
  )

  const validateDirty = useCallback(
    (filtersetId?: string, schema: yup.ObjectSchema<any> = baseSchema): boolean => {
      return [...preferences]
        .filter(([, pref]) => pref.isDirty && pref.settings.filtersetId === filtersetId)
        .map(([key]) => validatePreference(key, schema))
        .every(Boolean)
    },
    [preferences, validatePreference]
  )

  const saveNotificationPreference = useCallback(
    async (preference: DraftNotificationPreference, key: string): Promise<NotificationPreference> => {
      if (!hasuraClient) {
        throw new Error('Hasura client not initialized')
      }
      const persistedPreference = canUpdateOrDelete(preference)
        ? await hasuraClient.updateNotificationPreference(preference as NotificationPreference)
        : await hasuraClient.insertNotificationPreference(preference)

      setPreferences((map) => new Map([...map.set(key, { key, isDirty: false, settings: { ...persistedPreference } })]))
      return persistedPreference
    },
    [hasuraClient, setPreferences]
  )

  const saveDirty = useCallback(
    (filtersetId: string, lookForUnassigned = false) => {
      const filtersetToLookFor = !lookForUnassigned ? filtersetId : undefined
      return Promise.all(
        [...preferences]
          .filter((entry) => entry[1].isDirty && entry[1].settings.filtersetId === filtersetToLookFor)
          .map(async (entry) => {
            const settings = entry[1].settings
            settings.filtersetId = filtersetId
            eventTracker({
              action: TrackableAction.notificationPreferenceSaved,
              dimension1: settings.schedule?.frequency,
              dimension2: `${settings.schedule?.time}`,
              dimension3: `${settings.schedule?.dow}`,
              dimension4: settings.channels?.join(', '),
              dimension5: settings.categories?.join(', ')
            })
            return await saveNotificationPreference(settings, entry[0])
          })
      )
    },
    [eventTracker, preferences, saveNotificationPreference]
  )

  const getChannelsByPreferenceId = useCallback(
    (notificationPreferenceId: string): string[] => {
      return (preferences
        .get(settingsPreferencesKeys[notificationPreferenceId])
        ?.settings?.channels?.map((channel) => notificationOptions.channels.find(({ id }) => id === channel)?.name)
        ?.filter(Boolean) ?? []) as string[]
    },
    [notificationOptions.channels, preferences, settingsPreferencesKeys]
  )

  const removeNotificationPreference = useCallback(
    (key: string) => {
      if (!preferences.has(key)) {
        return false
      }

      setPreferences((map) => {
        map.delete(key)
        return new Map([...map])
      })

      return true
    },
    [preferences]
  )

  const deleteNotificationPreference = useCallback(
    async (key: string): Promise<boolean> => {
      const preference = preferences.get(key)?.settings

      if (!preference) {
        throw Error('Missing or empty notification preference')
      }

      if (hasuraClient && canUpdateOrDelete(preference)) {
        await hasuraClient.deleteNotificationPreference(preference as NotificationPreference)
      }

      return removeNotificationPreference(key)
    },
    [hasuraClient, preferences, removeNotificationPreference]
  )

  const deleteAlertsByFiltersetID = useCallback(
    async (filtersetId: string) => {
      const resolved = await Promise.all(
        [...preferencesForFiltersetId(filtersetId)].map(
          async (entry): Promise<boolean> => await deleteNotificationPreference(entry[0])
        )
      )
      return resolved.reduce((deletedSuccessfully, currentlyDeleted) => deletedSuccessfully && currentlyDeleted, true)
    },
    [deleteNotificationPreference, preferencesForFiltersetId]
  )

  const deleteAlertsByComparisonID = useCallback(
    async (comparisonId: string) => {
      const resolved = await Promise.all(
        [...preferencesForComparisonId(comparisonId)].map(
          async (entry): Promise<boolean> => await deleteNotificationPreference(entry[0])
        )
      )
      return resolved.reduce((deletedSuccessfully, currentlyDeleted) => deletedSuccessfully && currentlyDeleted, true)
    },
    [deleteNotificationPreference, preferencesForComparisonId]
  )

  const handleNotificationPreferenceNow = useCallback(
    async (notificationPreferenceId: string) => {
      const result = await enqueueNotificationPreference({ notificationPreferenceId })
      eventTracker({
        action: TrackableAction.notificationPreferenceHandled,
        dimension1: notificationPreferenceId
      })
      return !!result.messageId
    },
    [eventTracker]
  )

  const getCategoryIdByCode = useCallback(
    (code: string): string | undefined => {
      return notificationOptions.allCategories.find((category) => category.code === code)?.id
    },
    [notificationOptions.allCategories]
  )

  const getChannelIdByName = useCallback(
    (name: string): string | undefined => {
      return notificationOptions.channels.find((channel) => channel.name === name)?.id
    },
    [notificationOptions.channels]
  )

  return (
    <NotificationPreferenceContext.Provider
      value={{
        getPreference,
        preferences,
        settingsPreferencesKeys,
        notificationOptions,
        getCategoryIdByCode,
        getChannelIdByName,
        getChannelsByPreferenceId,
        validatePreference,
        discardDraftPreferences,
        validateDirty,
        saveDirty,
        markDirty,
        addPreference,
        preferencesForFiltersetId,
        saveNotificationPreference,
        deleteNotificationPreference,
        deleteAlertsByFiltersetID,
        deleteAlertsByComparisonID,
        handleNotificationPreferenceNow
      }}
    >
      {children}
    </NotificationPreferenceContext.Provider>
  )
}

export const useNotificationPreferences = () => {
  const context = useContext(NotificationPreferenceContext)

  if (!context) {
    throw new Error('useNotificationPreferences must be used within an NotificationPreferenceProvider.')
  }

  return context
}

export const scheduleSchema = yup
  .object()
  .shape({
    frequency: yup.string().required('Required'),
    time: yup.string().required('Required'),
    dow: yup.string().when('frequency', {
      is: 'weekly',
      then: yup.string().required('Required')
    })
  })
  .required()
  .test('hasSchedule', 'Must have a valid schedule', (value: { frequency: string; time: string; dow: string }) => {
    return value?.frequency && value?.time && (value?.dow || value?.frequency !== 'weekly')
  })

export const baseSchema = yup.object().shape({
  categories: yup.array().of(yup.string()).min(1, 'Please select a category'),
  channels: yup.array().of(yup.string()).min(1, 'Please select a channel'),
  schedule: scheduleSchema
})

export const scheduleReportSchema = yup
  .object()
  .shape({
    data: yup.object().shape({
      dashboardId: yup.string().required('Invalid dashboard'),
      recipients: yup.array().of(yup.string()).min(1, 'Please include at least one recipient'),
      reportName: yup.string().required('Please enter a report name'),
      attachments: yup.array().of(yup.string()).min(1, 'Please select at least one attachment')
    })
  })
  .concat(baseSchema)
