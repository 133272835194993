export enum IntegrationType {
  LeafLink = 'Leaf Link',
  LeafTrade = 'Leaf Trade',
  LeafLogix = 'Leaf Logix'
}

export enum IntegrationStatus {
  Active = 'active',
  Inactive = 'inactive',
  Failing = 'failing'
}

export interface Integration {
  id: string
  name: IntegrationType
  description: string
  credentialsLink?: string
  configuration: {
    credentials?: string[]
    bucket?: string
  }
}

export interface IntegrationProfile {
  id: string
  label: string
  integration: Integration
  status: IntegrationStatus
  lastRun?: Omit<IntegrationProfileLog, 'id'>
  data?: {
    credentials?: Record<string, string>
    fileKeys?: [string]
  }
}

export interface IntegrationProfileEditableData {
  enabled?: boolean
  label?: string
  data?: Record<string, any>
}

export interface IntegrationProfileLog {
  id: string
  data?: Record<string, number> | null
  errorLog?: string | null
  successed: boolean
  date: string
}

export interface IntegrationProfileLogPagination {
  start: number
  limit: number
  length: number
  total: number
  logs: IntegrationProfileLog[]
}

export interface IntegrationResponse {
  integration_id: string
  integration_name: string
  integration_description: string
  integration_link?: string
  configuration: {
    credentials?: string[]
    bucket?: string
  }
}

export interface IntegrationProfileResponse {
  integration_profile_id: string
  label: string
  user_id: string
  integration_id: string
  enabled: boolean
  data: {
    credentials: Record<string, string>
  }
  last_log?: {
    data: Record<string, number>
    error_log: string
    successed: boolean
    date: string
  }
}
