import { isEmpty, isEqual, isNil, isObject, keys, union } from 'lodash'
import { ConditionalKeys } from 'type-fest'

/** Sorts the given array and returns a new array with the sorted elements.
 *  Note - this is a temporary replacement of Array.prototype.toSorted until it is supported in all browsers.
 */
export function toSorted<T>(arr: T[], compareFn?: (a: T, b: T) => number): T[] {
  return arr.slice().sort(compareFn)
}

/**
 * Sets a property which is contained in string (with dots), similar to lodash set
 * @param obj object to set value
 * @param prop propert array or string
 * @param value value to assign
 */
export function set(obj: Record<string, any>, prop: string | string[], value: any) {
  if (typeof prop === 'string') {
    prop = prop.split('.')
  }
  if (prop.length > 1) {
    const e = prop.shift()
    if (e) {
      set((obj[e] = Object.prototype.toString.call(obj[e]) === '[object Object]' ? obj[e] : {}), prop, value)
    }
  } else {
    obj[prop[0]] = value
  }
  return obj
}

export function splitArrayIntoChunks<T>(arr: T[], chunkSize: number) {
  const result: T[][] = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize))
  }
  return result
}

/**
 * Gets an object with the recursive differences from obj1 in comparison to obj2. Null and undefined values are threated as equal.
 * @param obj1 The object to compare
 * @param obj2 The object to compare against
 * @returns An object containing only the differences properties
 */
export const getObjectDifferences = (obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {}

  const objectKeys = union(keys(obj1), keys(obj2))

  for (const key of objectKeys) {
    const value1 = obj1[key]
    const value2 = obj2[key]

    // Ignore null or undefined values in both objects
    if (isNil(value1) && isNil(value2)) {
      continue
    }

    if (isObject(value1) && isObject(value2)) {
      const diff = getObjectDifferences(value1, value2)
      if (!isEmpty(diff)) {
        result[key] = diff
      }
    } else if (!isEqual(value1, value2)) {
      result[key] = value1
    }
  }
  return result
}

/**
 * Converts an array of objects to a map using the given key
 * @param listOfObjects The list of objects to convert
 * @param key The key to use as the map key
 */
export const convertToMap = <T extends Record<string, any>>(
  listOfObjects: T[] = [],
  key: ConditionalKeys<T, string | number>
): Record<string, T> => {
  return listOfObjects.reduce<Record<string, T>>((acc, obj) => {
    acc[obj[key]] = obj
    return acc
  }, {})
}
