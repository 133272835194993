import type { RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'
import { FilterProductSkuBaseProps, FilterProductSku as FilterProductSkuInner } from './FilterProductSku'

type FilterProductSkuInnerObjectIDProps = FilterProductSkuBaseProps & {
  refineCMID: (value: string[]) => any
} & RefinementListProvided

const FilterProductSkuObj = connectRefinementList(function FilterProductSkuInnerObjectID({
  filters,
  label,
  hideArrow,
  hideClearRefinements,
  spacingProps,
  refine,
  refineCMID
}: FilterProductSkuInnerObjectIDProps) {
  return (
    <FilterProductSkuInner
      filters={filters}
      label={label}
      refineCMID={refineCMID}
      refineObjectID={refine}
      hideArrow={hideArrow}
      hideClearRefinements={hideClearRefinements}
      spacingProps={spacingProps}
    />
  )
})

type FilterProductSkuInnerCMIDProps = FilterProductSkuBaseProps & RefinementListProvided

export const FilterProductSku = connectRefinementList(function FilterProductSkuInnerCMID({
  filters,
  label,
  hideArrow,
  hideClearRefinements,
  spacingProps,
  refine
}: FilterProductSkuInnerCMIDProps) {
  return (
    <FilterProductSkuObj
      filters={filters}
      label={label}
      refineCMID={refine}
      hideArrow={hideArrow}
      hideClearRefinements={hideClearRefinements}
      spacingProps={spacingProps}
      attribute="objectID"
    />
  )
})
