import { People, PeopleOutline } from '@mui/icons-material'
import { SvgIconProps, Tooltip } from '@mui/material'
import { memo } from 'react'

interface SharedIconProps extends SvgIconProps {
  isMine?: boolean
}

export const SharedIcon: React.FC<SharedIconProps> = memo(function SharedIcon({ isMine = false, ...iconProps }) {
  const Icon = isMine ? People : PeopleOutline
  return (
    <Tooltip title={isMine ? 'Shared with account' : 'Shared with me'}>
      <Icon {...iconProps} data-testid={`shared-with-${isMine ? 'account' : 'me'}`} />
    </Tooltip>
  )
})
