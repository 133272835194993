import { TrackableListItemButton } from '@client/components/Trackables'
import { withClickEventTracker } from '@client/hoc/withClickEventTracker'
import { drawerWidth } from '@client/styles/global'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Chip, IconButton, ListItemButton, ListItemText, List as MuiList, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { darken, rgba } from 'polished'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const useDrawerStyles = makeStyles(() =>
  createStyles({
    root: {
      width: drawerWidth,
      flexShrink: 0,
      borderRight: 0
    },
    paper: {
      width: drawerWidth,
      borderRight: 0
    }
  })
)

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    drawerHeader: {
      color: theme.sidebar.header.color,
      backgroundColor: theme.sidebar.header.background,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    }
  })
)

export const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgb(0 0 0 / 12%);
`

export const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`

export const BrandLink = styled(NavLink)`
  padding-right: ${(props) => props.theme.spacing(1)};
  color: ${(props) => props.theme.sidebar.header.color};
  text-decoration: none;
  svg path {
    fill: ${(props) => props.theme.sidebar.header.color};
  }
  &:hover {
    color: ${(props) => props.theme.palette.common.white};
    svg path {
      fill: ${(props) => props.theme.palette.common.white};
    }
  }
`
export const TrackableBrandLink = withClickEventTracker(BrandLink)

export const Brand = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(4)};
  margin-top: ${(props) => props.theme.spacing(1)};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`

export const CloseButton = styled(IconButton)`
  &:hover {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.header.background)};
  }
`

export type CategoryType = {
  activeClassName?: string
  button?: boolean
  onClick?: () => void
  to?: string
  href?: string
  target?: string
  component?: typeof NavLink | 'a'
}

export const Category = styled(ListItemButton).withConfig({
  shouldForwardProp: (prop) => !['activeClassName'].includes(prop)
})<CategoryType>`
  padding: ${(props) => props.theme.spacing(3, 7, 3, 10)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: ${(props) => props.theme.sidebar.color};
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0 0 0 / 8%);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

export const TrackableCategory = withClickEventTracker(Category)

export const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    padding: 0 ${(props) => props.theme.spacing(4)};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    color: ${(props) => props.theme.sidebar.color};
  }
`

export const CategoryIconLess = styled(ExpandLess)`
  /* stylelint-disable-next-line color-function-notation */
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

export const CategoryIconMore = styled(ExpandMore)`
  /* stylelint-disable-next-line color-function-notation */
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

export const Link = styled(TrackableListItemButton).withConfig({
  shouldForwardProp: (prop) => !['activeClassName'].includes(prop)
})<{
  activeClassName: string
  component: typeof NavLink
  to: string
}>`
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(19.5)};

  span {
    /* stylelint-disable-next-line color-function-notation */
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    /* stylelint-disable-next-line color-function-notation */
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) => darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`

export const LinkText = styled(ListItemText)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
`

export const LinkBadge = styled(Chip)`
  position: absolute;
  top: 8px;
  right: 28px;
  height: 20px;
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  background: ${(props) => props.theme.sidebar.badge.background};

  .MuiChip-label,
  .MuiChip-label:hover {
    padding-right: ${(props) => props.theme.spacing(2)};
    padding-left: ${(props) => props.theme.spacing(2)};
    color: ${(props) => props.theme.sidebar.badge.color};
    cursor: pointer;
  }
`

export const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`

export const SidebarSection = styled(Typography)`
  display: block;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(7)} ${theme.spacing(1)}`};
  font-weight: 900;
  color: ${(props) => props.theme.sidebar.color};
  opacity: 0.9;
`

export const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  border-right: 1px solid rgb(0 0 0 / 12%);
`
