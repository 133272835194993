import { TreeItem as MuiTreeItem } from '@mui/x-tree-view'
import styled from 'styled-components'

export const TreeItem = styled(MuiTreeItem)`
  /* Override the @media(hover) CSS that hides the background shading for selected
   items on mobile screens */
  &.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label,
  &.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
  &.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    font-weight: 600;
    background-color: rgb(47 124 246 / 8%) !important;
  }
`

export const TreeItemLabelText = styled.div`
  overflow: hidden;
  font-size: 0.85rem;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
`
