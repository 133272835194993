import { slugify } from '@client/helpers/strings'
import { useOnboardingTour } from '@client/hooks/use-onboarding-tour'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useCallback } from 'react'
import { CallBackProps, Props, STATUS } from 'react-joyride'
import { Onboarding as OnboardingDisplay } from './Onboarding'
import { OnboardingStep } from './components/OnboardingTooltip'

export type { OnboardingStep } from './components/OnboardingTooltip'

export const onboardingTourKey = (tourId: string) => `has-viewed-onboarding-tour-${slugify(tourId)}`

const mobileFloaterProps: Props['floaterProps'] = {
  styles: {
    floater: {
      width: '350px'
    }
  }
}

// These status values indicate that the tour should be considered 'viewed'
export const VIEWED_STATUSES: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

export interface OnboardingProps extends Props {
  tourId: string
  steps: OnboardingStep[]
  expiry?: Date
}

export const Onboarding: React.FC<OnboardingProps> = ({ tourId, expiry, callback, ...props }) => {
  const { isMobile } = useResponsiveState()
  const { isLoading, setViewed, hasBeenViewed, hasExpired } = useOnboardingTour({ tourId, expiry })

  // When the tour has been viewed, set the corresponding flag
  const handleCallback = useCallback(
    (data: CallBackProps) => {
      const { status } = data
      if (VIEWED_STATUSES.includes(status)) {
        setViewed()
      }
      callback && callback(data)
    },
    [callback, setViewed]
  )

  // Do not show the tour if the flags are loading or the tour has already been viewed or the
  // tour has expired
  if (isLoading || hasBeenViewed || hasExpired) {
    return null
  }

  return (
    <OnboardingDisplay floaterProps={isMobile ? mobileFloaterProps : undefined} {...props} callback={handleCallback} />
  )
}

export const waitForStepTarget = (
  step: OnboardingStep,
  cb: () => void,
  options?: { attempts?: number; delayMs?: number }
) => {
  let attemptsLeft = options?.attempts || 3
  const action = () => {
    const nextTargetEl = document.querySelector(step.target as string)
    if (nextTargetEl) {
      cb()
    } else if (attemptsLeft > 0) {
      attemptsLeft--
      setTimeout(action, options?.delayMs || 300)
    }
  }
  action()
}
