import { formatDayMonthDateLocalized } from '@client/helpers/dates'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { usePermission } from '@client/hooks/use-permissions'
import {
  Integration,
  IntegrationProfile,
  IntegrationProfileEditableData,
  IntegrationProfileLogPagination,
  IntegrationProfileResponse,
  IntegrationResponse,
  IntegrationStatus,
  IntegrationType
} from '@client/types/integrations'
import { Permission } from '@lib/types/permission'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'

export const parseIntegration = (data: IntegrationResponse): Integration => {
  return {
    id: data.integration_id,
    name: data.integration_name as IntegrationType,
    description: data.integration_description,
    credentialsLink: data.integration_link,
    configuration: data.configuration
  }
}

export const parseIntegrationProfile = (
  data: IntegrationProfileResponse,
  integration: Integration
): IntegrationProfile => {
  return {
    id: data.integration_profile_id,
    label: data.label,
    integration: integration,
    status: !data.enabled
      ? IntegrationStatus.Inactive
      : !data.last_log || data.last_log?.successed
      ? IntegrationStatus.Active
      : IntegrationStatus.Failing,
    lastRun: data.last_log
      ? {
          data: data.last_log.data,
          errorLog: data.last_log.error_log,
          successed: data.last_log.successed,
          date: formatDayMonthDateLocalized(data.last_log.date)
        }
      : undefined,
    data: data.data
  }
}

// Return a suitable default profile label for a given integration
// If there are existing profiles for the integration, it will return the integration name followed
// by the number of existing profiles + 1
export const defaultProfileLabel = (integration: Integration, profiles: IntegrationProfile[] = []): string => {
  const existingProfilesForIntegration = profiles?.filter((profile) => profile.integration.id === integration.id).length
  return existingProfilesForIntegration ? `${integration.name} ${existingProfilesForIntegration + 1}` : integration.name
}

export interface IntegrationContextInterface {
  integrations: Integration[] | undefined
  profiles: IntegrationProfile[] | undefined
  loading: boolean
  getProfiles: () => void
  getProfileLogs: (id: string, page?: number, pageSize?: number) => Promise<IntegrationProfileLogPagination>
  addProfile: (type: IntegrationType) => void
  saveProfile: (id: string, data: IntegrationProfileEditableData) => Promise<boolean>
  deleteProfile: (id: string) => Promise<boolean>
  testIntegration: (type: IntegrationType, credentials: Record<string, string>) => Promise<boolean>
}

const IntegrationContext = createContext<IntegrationContextInterface>({} as IntegrationContextInterface)

export const IntegrationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hasPermission } = usePermission(Permission.MODULE_ACCESS_SALES_ENABLEMENT)
  const [integrations, setIntegrations] = useState<Integration[]>()
  const [profiles, setProfiles] = useState<IntegrationProfile[]>()
  const { value: loading, setTrue: startLoading, setFalse: stopLoading } = useBooleanState(false)

  useEffect(() => {
    if (hasPermission) {
      // Temp mock:
      setIntegrations(
        Object.values(IntegrationType).map((type) => ({
          id: type,
          name: type,
          description: '',
          configuration: {
            credentials: ['api_key']
          }
        })) as Integration[]
      )
    }
  }, [hasPermission])

  const getProfileLogs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (profileId: string, page = 1, pageSize = 15): Promise<IntegrationProfileLogPagination> => {
      // Temp

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            start: page * pageSize,
            limit: pageSize,
            length: pageSize,
            total: 150,
            logs: [...Array(pageSize).keys()].map((i) => ({
              id: i + new Date().getTime().toString(),
              data:
                i > 0
                  ? {
                      products: Math.floor(Math.random() * 1000),
                      dispensaries: Math.floor(Math.random() * 80),
                      orders: Math.floor(Math.random() * 150)
                    }
                  : null,
              errorLog: i === 0 ? 'Error run' : null,
              successed: i > 0,
              date: formatDayMonthDateLocalized(`2022-10-20T${20 - i}:00:00`)
            }))
          })
        }, 500)
      })
    },
    []
  )

  const getProfiles = useCallback(() => {
    startLoading()
    // ....
    setProfiles([])
    stopLoading()
  }, [startLoading, stopLoading])

  const addProfile = useCallback(
    (type: IntegrationType) => {
      // Temp
      const integration = integrations?.find((integration) => integration.name === type)
      if (integration) {
        setProfiles([
          ...(profiles ?? []),
          {
            id: new Date().getTime().toString(),
            label: defaultProfileLabel(integration, profiles),
            integration: integration,
            status:
              integration.name === IntegrationType.LeafTrade ? IntegrationStatus.Active : IntegrationStatus.Inactive,
            data: integration.configuration.credentials
              ? {
                  credentials: Object.fromEntries(
                    integration.configuration.credentials.map((c) => [
                      c,
                      integration.name === IntegrationType.LeafTrade ? '****************6584' : ''
                    ])
                  )
                }
              : {},
            lastRun:
              integration.name === IntegrationType.LeafTrade
                ? {
                    data: {
                      products: Math.floor(Math.random() * 1000),
                      dispensaries: Math.floor(Math.random() * 80),
                      orders: Math.floor(Math.random() * 150)
                    },
                    errorLog: null,
                    successed: true,
                    date: formatDayMonthDateLocalized(`2022-10-20T20:00:00`)
                  }
                : undefined
          }
        ])
      }
    },
    [integrations, profiles]
  )

  const saveProfile = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (id: string, data: IntegrationProfileEditableData) => {
      return Promise.resolve(true)
    },
    []
  )

  const deleteProfile = useCallback(
    (id: string) => {
      // Temp
      setProfiles((profiles ?? []).filter((profile) => profile.id !== id))
      return Promise.resolve(true)
    },
    [profiles]
  )

  const testIntegration = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (id: string, credentials: Record<string, string>): Promise<boolean> => {
      // Temp
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true)
        }, 2000)
      })
    },
    []
  )

  return (
    <IntegrationContext.Provider
      value={{
        integrations,
        profiles,
        loading,
        getProfileLogs,
        getProfiles,
        addProfile,
        saveProfile,
        deleteProfile,
        testIntegration
      }}
    >
      {children}
    </IntegrationContext.Provider>
  )
}

export const useIntegration = () => {
  const context = useContext(IntegrationContext)

  if (!context) {
    throw new Error('useIntegration must be used within a IntegrationProvider.')
  }

  return context
}
