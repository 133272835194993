import { useDrawers } from '@client/hooks/use-drawers'
import { usePermission } from '@client/hooks/use-permissions'
import { Permission } from '@lib/types/permission'
import { SpacingProps } from '@mui/system'
import { memo } from 'react'
import { FavoritesDrawerButton as FavoritesDrawerButtonInner } from './FavoritesDrawerButton'

export const FavoritesDrawerButton: React.FC<SpacingProps> = memo(function FavoritesDrawerButton({ ...props }) {
  const { openFavoritesDrawer } = useDrawers()
  const { hasPermission } = usePermission(Permission.MODULE_ACCESS_SALES_ENABLEMENT)

  return hasPermission ? <FavoritesDrawerButtonInner {...props} onClick={openFavoritesDrawer} /> : null
})
