import { CircularProgress, Slide, SlideProps } from '@mui/material'
import deepmerge from 'deepmerge'
import { OptionsObject, SnackbarKey, SnackbarMessage, VariantType, useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'

function TransitionDown(props: SlideProps) {
  return <Slide {...props} direction="down" />
}

const defaultToastOptions: OptionsObject = {
  TransitionComponent: TransitionDown,
  autoHideDuration: 4000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
}

const progressAction = () => <CircularProgress size={18} color="inherit" sx={{ mr: 2 }} />

export const useToastNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueue = useCallback(
    (message: SnackbarMessage, variant: VariantType, options: OptionsObject) => {
      return enqueueSnackbar(message, deepmerge({ ...defaultToastOptions, variant }, options))
    },
    [enqueueSnackbar]
  )

  const toast = useMemo(
    () => ({
      success: (message: SnackbarMessage, options: OptionsObject = defaultToastOptions) => {
        return enqueue(message, 'success', options)
      },
      info: (message: SnackbarMessage, options: OptionsObject = defaultToastOptions) => {
        return enqueue(message, 'error', options)
      },
      warning: (message: SnackbarMessage, options: OptionsObject = defaultToastOptions) => {
        return enqueue(message, 'warning', options)
      },
      error: (message: SnackbarMessage, options: OptionsObject = defaultToastOptions) => {
        return enqueue(message, 'error', options)
      },
      default: (message: SnackbarMessage, options: OptionsObject = defaultToastOptions) => {
        return enqueue(message, 'default', options)
      },
      dismiss: (key?: SnackbarKey) => {
        return closeSnackbar(key)
      }
    }),
    [closeSnackbar, enqueue]
  )

  return { toast, progressAction }
}
