import { Box } from '@client/styles/theme/box'
import { Relationship } from '@client/types/relationships'
import { Typography } from '@mui/material'
import { memo } from 'react'

type Props = {
  relationship: Relationship
}

export const RelationshipDetailsView = memo(function RelationshipDetailsView({ relationship }: Props) {
  return (
    <Box data-testid="relationship-manager--details-view" p={4}>
      <Typography variant="h3">{relationship.name}</Typography>
    </Box>
  )
})
