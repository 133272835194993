import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

export const useActiveComparison = () => {
  const [comparisonId] = useQueryParam('comparisonId', StringParam, { enableBatching: true })
  const { id: currentComparisonId } = useParams()

  const activeComparisonId = useMemo(
    () => currentComparisonId || (comparisonId ?? undefined),
    [comparisonId, currentComparisonId]
  )

  return {
    activeComparisonId
  }
}
