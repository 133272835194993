import { useAuth0 } from '@auth0/auth0-react'
import { Sentry } from '@client/configs/sentry'
import { tracking } from '@client/configs/tracking'
import { localStorageUserKey } from '@client/helpers/usersLocalStorage'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { useCallback } from 'react'
import { useEventTracker } from './use-event-tracker'

export const useLogout = (redirectTo?: string | null) => {
  const { logout } = useAuth0()
  const { eventTracker } = useEventTracker({ category: TrackableCategory.account })

  const logoutUser = useCallback(() => {
    Sentry.setUser(null)
    eventTracker({ action: TrackableAction.loggedOut })
    tracking.setUser(undefined)
    localStorage.removeItem(localStorageUserKey)
    logout({ returnTo: redirectTo || window.location.origin })
  }, [eventTracker, logout, redirectTo])

  return { logout: logoutUser }
}
