import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { ButtonProps, Collapse } from '@mui/material'
import { FC, PropsWithChildren, memo } from 'react'
import { RequireExactlyOne } from 'type-fest'
import { useTimeout, useUpdateEffect } from 'usehooks-ts'
import * as S from './CollapsibleIconButton.style'

export type CollapsibleIconButtonProps = RequireExactlyOne<ButtonProps, 'startIcon' | 'endIcon'> & {
  'data-testid'?: string
  /** If set, the content will be initially expanded and will prevent it from being collapsed. */
  preventCollapse?: boolean
  /** If set, the content will be initially expanded and will collapse after the specified time in milliseconds. */
  autoCollapse?: number
}

export const CollapsibleIconButton: FC<PropsWithChildren<CollapsibleIconButtonProps>> = memo(
  function CollapsibleIconButton({ children, autoCollapse, preventCollapse = false, ...props }) {
    const { isMobile } = useResponsiveState()
    const {
      value: isExpanded,
      setTrue: expand,
      setFalse: collapse
    } = useBooleanState((preventCollapse || !!autoCollapse) && !!children)

    useTimeout(collapse, !preventCollapse ? autoCollapse ?? null : null)

    // If preventCollapse is set after the component is mounted, expand it
    useUpdateEffect(() => {
      if (preventCollapse) {
        expand()
      }
    }, [preventCollapse])

    return (
      <S.Button
        {...props}
        onMouseEnter={!isMobile && !!children ? expand : undefined}
        onMouseLeave={!isMobile && !preventCollapse && !!children ? collapse : undefined}
        $isOpen={isExpanded}
      >
        <Collapse orientation="horizontal" in={isExpanded}>
          {children}
        </Collapse>
      </S.Button>
    )
  }
)
