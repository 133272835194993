import { slugify } from '@client/helpers/strings'
import { MuiIcon } from '@client/styles/global'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { Typography } from '@client/styles/theme/typography'
import { Checkbox, CheckboxProps, FormControlLabel, SvgIconProps, Tooltip } from '@mui/material'
import HRN from 'human-readable-numbers'
import { memo, useMemo, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import * as S from './FilterCheckbox.style'

export type FilterCheckboxIcon = {
  icon: MuiIcon
  href?: string
  tooltip?: string
  color?: SvgIconProps['color']
}

interface FilterCheckboxProps extends Omit<CheckboxProps, 'onClick'> {
  value: string
  label: string
  subLabel?: string
  icons?: FilterCheckboxIcon[]
  count?: number
  onClick: (value: string, isChecked: boolean) => void
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = memo(function FilterCheckbox({
  label,
  subLabel,
  value,
  icons,
  count,
  onClick,
  ...rest
}) {
  const formControlLabelClasses = S.useFormControlLabelStyles()
  // Local state to keep track of the checked state to have an immediate visual feedback
  const [checked, setChecked] = useState(!!rest.checked)

  const slug = useMemo(() => slugify(label), [label])

  // Keep the local state in sync with the checked prop
  useUpdateEffect(() => {
    setChecked(!!rest.checked)
  }, [rest.checked])

  return (
    <FormControlLabel
      classes={formControlLabelClasses}
      control={
        <Checkbox
          sx={{ p: '0 9px' }}
          data-testid={`facet-checkbox-${slug}`}
          color="secondary"
          name={label}
          {...rest}
          checked={checked}
          onClick={() => {
            setChecked(!checked)
            onClick(value, !checked)
          }}
        />
      }
      label={
        <Box flexDirection="column" display="flex" width="100%">
          <Box alignItems="center" flexDirection="row" display="flex" gap={2}>
            <S.CheckboxLabelText data-testid={`facet-checkbox-label-${slug}`}>{label}</S.CheckboxLabelText>
            {count !== undefined && (
              <Typography variant="caption" color="primaryGreen">
                {HRN.toHumanString(count.toString())}
              </Typography>
            )}
            {!!icons?.length && (
              <S.IconsStack direction="row" gap={0.5}>
                {icons.map(({ icon: Icon, href, color, tooltip }, index) => (
                  <Tooltip title={tooltip ?? ''} arrow disableInteractive key={index}>
                    {href ? (
                      <S.ExternalLink
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid={`facet-checkbox-external-link-${slug}`}
                      >
                        <Icon color={color} />
                      </S.ExternalLink>
                    ) : (
                      <Icon color={color} sx={{ color: !color ? palette.mediumdark : undefined }} />
                    )}
                  </Tooltip>
                ))}
              </S.IconsStack>
            )}
          </Box>
          {subLabel && <S.CheckboxSubLabelText noWrap>{subLabel}</S.CheckboxSubLabelText>}
        </Box>
      }
    />
  )
})
