import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { FilterPillToggleBoolean } from '@client/components/algolia/filter-pill/filter-pill-toggle-boolean'
import { Box } from '@client/styles/theme/box'
import { ButtonGroup, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

interface FilterToggleGroupProps {
  label?: string
  attributes: string[]
  attributeLabels?: string[]
  multiple?: boolean
  hideLabel?: boolean
  hideClear?: boolean
  pl?: number
  onSelected?: (val: string | null | undefined) => void
}

export const FilterToggleGroup: React.FC<FilterToggleGroupProps> = ({
  attributes,
  attributeLabels,
  label,
  multiple,
  hideLabel,
  hideClear,
  pl = 0,
  onSelected
}) => {
  const [selected, setSelected] = useState<string | null>()

  useEffect(() => {
    onSelected?.(selected)
  }, [selected, onSelected])

  const handleClick = useCallback((isSelected: boolean, label: string) => {
    setSelected(isSelected ? label : null)
  }, [])

  const handleCleared = useCallback(
    (label: string) => {
      if (selected === label) {
        setSelected(null)
      }
    },
    [selected]
  )

  const handleInitialSelection = useCallback((label: string) => {
    setSelected(label)
  }, [])

  if (!attributes.length) {
    return null
  }

  return (
    <Box py={hideClear ? 0 : 2} pl={pl} alignItems="center" flexDirection="row" display="flex">
      {!hideLabel && (
        <Typography mr={4} data-testid="toggle-group-label">
          {label}
        </Typography>
      )}
      <ButtonGroup>
        {attributes.map((attribute, index) => (
          <FilterPillToggleBoolean
            key={attribute}
            label={attributeLabels?.[index] ?? attribute}
            attribute={attribute}
            value={true}
            onClick={!multiple ? handleClick : undefined}
            onCleared={!multiple ? handleCleared : undefined}
            selectedFilter={!multiple ? selected : undefined}
            onInitialSelection={!multiple ? handleInitialSelection : undefined}
          />
        ))}
      </ButtonGroup>
      {!hideClear && <ClearRefinements attribute={attributes} buttonProps={{ ml: 2 }} />}
    </Box>
  )
}
