import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material'
import styled from 'styled-components'

export const PopupMenu = styled(MuiMenu)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  ul {
    padding: 0;
  }
`

export const PopupMenuItem = styled(MuiMenuItem)`
  min-height: auto;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
`
