import { baseImageUrl, dispensaryImageMissing, getDefaultImage, imageMissing } from '@client/helpers/image/image'
import { DISPENSARY_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import { BordersProps, SpacingProps } from '@mui/system'
import React, { useMemo, useState } from 'react'

export const fallbackSrc = imageMissing

export interface CustomImageProps {
  category?: string
  src?: string | null
  alt?: string
  searchIndex?: string
  height?: string | number
  width?: string | number
  quality?: string | number
  style?: any
  backupSrc?: string
}

export const imageDomains = [
  'data-item-images.s3.us-east-2.amazonaws.com',
  'unifyd-canna-img.s3.us-east-2.amazonaws.com'
]
const OptimizedImage: React.FC<BordersProps & SpacingProps & CustomImageProps> = ({
  src = '',
  category = '',
  alt,
  searchIndex = VARIANT_INDEX,
  backupSrc = '',
  width = 640,
  quality = 65,
  ...rest
}) => {
  const [errImg, setErrImg] = useState('')
  const [errorCount, setErrorCount] = useState(src ? 0 : 1)

  const imgUrl = useMemo(() => {
    let fallbackSrc = ''
    if (backupSrc) {
      fallbackSrc = backupSrc
    } else {
      fallbackSrc = searchIndex === VARIANT_INDEX ? imageMissing : dispensaryImageMissing
    }
    let url = src
    if (imageDomains.some((domain: string) => src && src.includes('https://' + domain))) {
      url = `${baseImageUrl}/width=${width},quality=${quality}/${src}`
    }

    if (!errImg && src) {
      return url
    } else if (errorCount < 2 && searchIndex !== DISPENSARY_INDEX) {
      return getDefaultImage(category, width, quality)
    } else {
      return fallbackSrc
    }
  }, [src, errImg, category, searchIndex, backupSrc, errorCount, width, quality])

  return (
    <img
      src={imgUrl || ''}
      alt={alt}
      onError={() => {
        setErrorCount(errorCount + 1)
        setErrImg(getDefaultImage(category) || fallbackSrc)
      }}
      {...rest}
    />
  )
}
export default React.memo(OptimizedImage)
