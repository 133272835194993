import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  CardProps,
  Collapse,
  IconButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
  Typography
} from '@mui/material'
import { flexbox, spacing, SpacingProps } from '@mui/system'
import { memo, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import { typography } from './typography'

export const Card = styled(MuiCard)(spacing)

export const DebugableCard = styled(MuiCard)`
  ${spacing}
  position: relative;
  .debug-popover {
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover .debug-popover {
    opacity: 1;
  }
`

// This just re-instates the default MUI box-shadow for Card
export const BoxedCard = styled(MuiCard)`
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
`

export const FlexCardContent = styled(MuiCardContent)(flexbox)

export const ListItemCard = styled(Card).attrs(() => ({
  elevation: 1
}))`
  margin: ${(props) => props.theme.spacing(4)} 0;
  background-color: #000;
  border-radius: 12px;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const ListItemCardTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.size.s3};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  color: ${({ theme }) => theme.palette.navyBlue};
  text-decoration: none;
  a {
    color: ${({ theme }) => theme.palette.navyBlue};
    text-transform: uppercase;
  }
  &.subtitle {
    font-size: ${({ theme }) => theme.typography.size.s1};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    color: ${({ theme }) => theme.palette.darkGray};
    a {
      color: ${({ theme }) => theme.palette.darkGray};
    }
  }
`

export const OutlinedCard = styled(Card).attrs({ variant: 'outlined' })``

export const CardHeader = styled(MuiCardHeader).attrs({
  titleTypographyProps: { fontSize: typography.size.s3, fontWeight: 'bold' }
})``

export const CardContent = styled(MuiCardContent)`
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacing(4)} !important;
`

export const WholeSaleCardContent = styled(CardContent)`
  padding-bottom: ${({ theme }) => theme.spacing(1)} !important;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: ${({ theme }) => theme.spacing(0)} !important;
  }
`

export const CollapsibleCard: React.FC<CardProps & SpacingProps & { startCollapsed?: boolean; title: ReactNode }> =
  memo(function CollapsibleCard({ startCollapsed = false, title, ...props }) {
    const [isCollapsed, setIsCollapsed] = useState(startCollapsed)
    const toggleCollapsed = useCallback(() => setIsCollapsed((prev) => !prev), [])
    return (
      <Card {...props}>
        <CardHeader
          title={title}
          action={
            <IconButton onClick={toggleCollapsed} data-testid="toggle-collapse-button">
              {isCollapsed ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
          }
        />
        <Collapse in={!isCollapsed}>
          <CardContent>{props.children}</CardContent>
        </Collapse>
      </Card>
    )
  })
