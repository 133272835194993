import {
  IconButton,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Popover as MuiPopover
} from '@mui/material'
import { flexbox } from '@mui/system'
import styled from 'styled-components'

export const DialogTitle = styled(MuiDialogTitle).attrs({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})(flexbox)

export const CloseButton = styled(IconButton)`
  margin-left: ${(props) => props.theme.spacing(1)};
`

export const DialogContent = styled(MuiDialogContent)`
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const MobileDialogContent = styled(MuiDialogContent)`
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  .MuiPaper-root {
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`

export const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`
