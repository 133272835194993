import { slugify, toKebabCase } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { ArrowDownward, ArrowUpward, Sort } from '@mui/icons-material'
import { Button, ButtonGroup, ButtonGroupProps, Tooltip, Typography } from '@mui/material'
import { MouseEventHandler, useCallback, useMemo, useRef } from 'react'

export const SortAsc = 'asc'
export const SortDesc = 'desc'
export type SortOrder = typeof SortAsc | typeof SortDesc

export const reverseSortOrder = (sortOrder: SortOrder) => (sortOrder === SortAsc ? SortDesc : SortAsc)

export type SortOption<TSortField extends string = string> = {
  label: string
  value: TSortField
  disabled?: boolean
}

export interface SortByProps<TSortField extends string> extends ButtonGroupProps {
  sortField?: TSortField
  sortOrder: SortOrder
  options: SortOption<TSortField>[]
  gaCategory?: TrackableCategory
  gaAction?: TrackableAction
  onSortFieldChange: (value: SortOption<TSortField>['value']) => void
  onSortOrderChange: (order: SortOrder) => void
}

export const SortBy = <TSortField extends string = string>({
  sortField,
  sortOrder = SortAsc,
  options,
  gaCategory,
  gaAction,
  onSortFieldChange,
  onSortOrderChange,
  sx,
  ...rest
}: SortByProps<TSortField>) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const { value: isMenuOpen, setTrue: openMenu, setFalse: closeMenu } = useBooleanState(false)
  const { eventTracker } = useEventTracker()

  const selectedOption = useMemo(
    () => options.find(({ value: optionValue }) => optionValue === sortField),
    [sortField, options]
  )

  const trackChanges = useCallback(
    (sort: SortOrder, option?: string) => {
      if (gaCategory && gaAction && option) {
        eventTracker({
          category: gaCategory,
          action: gaAction,
          dimension1: toKebabCase(option),
          dimension2: sort
        })
      }
    },
    [eventTracker, gaAction, gaCategory]
  )

  const handleSortOrderChange: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    const newSortOrder = sortOrder === SortAsc ? SortDesc : SortAsc
    onSortOrderChange(newSortOrder)
    trackChanges(newSortOrder, selectedOption?.value)
  }, [onSortOrderChange, selectedOption?.value, sortOrder, trackChanges])

  const handleOptionClick: MouseEventHandler<HTMLLIElement> = useCallback(
    (ev) => {
      const { value } = ev.currentTarget.dataset
      onSortFieldChange(value as TSortField)
      trackChanges(sortOrder, value)
      closeMenu()
    },
    [closeMenu, onSortFieldChange, sortOrder, trackChanges]
  )

  const SortIcon = sortOrder === SortAsc ? ArrowUpward : ArrowDownward

  return (
    <>
      <ButtonGroup
        data-testid="sort-by-button-group"
        variant="outlined"
        size="small"
        sx={{ ...sx, minWidth: 0 }}
        {...rest}
      >
        <Tooltip title="Select sorting criteria...">
          <Button
            id="sort-by-field-button"
            data-testid="sort-by-field-button"
            ref={anchorRef}
            onClick={openMenu}
            startIcon={<Sort />}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
          >
            <Typography noWrap>{selectedOption?.label || 'Sort by...'}</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Toggle sort direction">
          <Button data-testid={`sort-by-order-button-${sortOrder}`} onClick={handleSortOrderChange}>
            <SortIcon fontSize={rest.size ?? undefined} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <PopupMenu
        id="sort-by-menu"
        anchorEl={anchorRef.current}
        onClose={closeMenu}
        open={isMenuOpen}
        MenuListProps={{
          'aria-labelledby': 'sort-by-field-button'
        }}
      >
        {options.map(({ label, value, disabled }) => (
          <PopupMenuItem
            data-testid={`sort-by-option-${slugify(value || 'unselected')}`}
            key={value}
            data-value={value}
            value={value}
            disabled={disabled}
            selected={value === sortField}
            onClick={handleOptionClick}
          >
            {label}
          </PopupMenuItem>
        ))}
      </PopupMenu>
    </>
  )
}
