import { ClearRefinements } from '@client/components/algolia/clear-refinements'
import { VirtualWidgets } from '@client/components/algolia/virtual-widgets/VirtualWidgets'
import { DialogTitle } from '@client/components/dialog/Dialog'
import { ProductSkuSelector } from '@client/components/product-sku/product-sku-selector/ProductSkuSelector'
import { GrowTransition } from '@client/components/transitions'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useAlgolia } from '@client/providers/algolia'
import { OpenSearchApiProvider } from '@client/providers/open-search-api'
import { VARIANT_INDEX } from '@client/services/algolia'
import { Box } from '@client/styles/theme/box'
import {
  MasterProductFilterOptions,
  PRODUCT_SKUS_VARIANT_FIELDS,
  ProductSkuFilterOption,
  keysFromShouldToFilters,
  searchStateFromFilters
} from '@client/types/filterset'
import { ProductSkuKey } from '@client/types/products'
import { Filters, keysFromFiltersToShould } from '@hoodie/hoodie-filters/lib/filterset'
import { Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from '@mui/material'
import { SpacingProps } from '@mui/system'
import { PropsWithChildren, memo, useCallback, useMemo } from 'react'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { useUpdateEffect } from 'usehooks-ts'
import * as S from './FilterProductSku.style'
import { CurrentProductsRefinement } from './components/current-products-refinement'
import { InvalidProduct } from './components/current-products-refinement/CurrentProductsRefinement'

const DISPLAY_PER_PAGE = 10
const DISPLAY_ON_LIST = 7
const MASTER_ATTRIBUTES_TO_RETRIEVE = ['NAME', 'IMG', 'ACTIVE_VARIANTS', 'CATEGORY_2', 'BRAND', 'CM_ID', 'objectId']

export type FilterProductSkuBaseProps = {
  label: string
  filters: Filters
  hideArrow?: boolean
  hideClearRefinements?: boolean
  spacingProps?: SpacingProps
}

type FilterProductSkuProps = FilterProductSkuBaseProps & {
  refineCMID?: (value: string[]) => any
  refineObjectID?: (value: string[]) => any
}

export const removeProductsFromFilters = (filters: Filters): Filters => {
  const normalizedFilters = keysFromShouldToFilters(filters, ProductSkuFilterOption.filterKey)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cmIds, menuIds, ...filterBy } = normalizedFilters.filterBy
  return { filterBy }
}

export const FilterProductSku: React.FC<FilterProductSkuProps> = memo(function FilterProductSku({
  label,
  filters,
  hideArrow,
  hideClearRefinements,
  spacingProps,
  refineCMID,
  refineObjectID
}) {
  const { isMobile } = useResponsiveState()
  const { algoliaSearchClients } = useAlgolia()
  const { value: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBooleanState(false)
  const {
    value: checkedSelection,
    toggleValue: toggleSelection,
    setTrue: displaySelection,
    setFalse: hideSelection
  } = useBooleanState(false)
  const classes = S.useStyles()

  const { currentSelectionFilters, currentRefinement, filtersWithoutProducts, total } = useMemo(() => {
    const normalizedFilters = keysFromShouldToFilters(filters, ProductSkuFilterOption.filterKey)
    const { cmIds, menuIds, ...filterBy } = normalizedFilters.filterBy
    const currentSelectionFilters = keysFromFiltersToShould(
      { filterBy: { cmIds, menuIds } },
      ProductSkuFilterOption.filterKey
    )
    const currentRefinement = searchStateFromFilters(currentSelectionFilters)
    const total = (cmIds?.length ?? 0) + (menuIds?.length ?? 0)
    const filtersWithoutProducts = { filterBy }
    return { currentSelectionFilters, currentRefinement, filtersWithoutProducts, total }
  }, [filters])

  const handleSelectMastered = useCallback(
    (products: string[]) => {
      refineCMID?.(products)
    },
    [refineCMID]
  )

  const handleSelectNonMastered = useCallback(
    (products: string[]) => {
      refineObjectID?.(products)
    },
    [refineObjectID]
  )

  const onRemove = useCallback(
    (value: string, key: ProductSkuKey) => {
      const ids = (currentRefinement?.refinementList?.[key] ?? []).filter((id) => id !== value)
      if (key === 'CM_ID') {
        refineCMID?.(ids)
      } else {
        refineObjectID?.(ids)
      }
    },
    [currentRefinement?.refinementList, refineCMID, refineObjectID]
  )

  const handleRemoveInvalidProducts = useCallback(
    async (invalidProducts: InvalidProduct[]) => {
      // Parse both invalid products ids list for mastered and non-mastered products
      const cmIds = invalidProducts.filter(({ key }) => key === 'CM_ID').map(({ id }) => id)
      const menuIds = invalidProducts.filter(({ key }) => key === 'objectID').map(({ id }) => id)
      // Refine the current selection removing the invalid products
      if (cmIds.length) {
        refineCMID?.((currentRefinement?.refinementList?.CM_ID ?? []).filter((id) => !cmIds.includes(id)))
        // HACK: If we need to update both fields, we need to wait for the refinement to be applied before refining the other attribute
        if (menuIds.length) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
        }
      }
      if (menuIds.length) {
        refineObjectID?.((currentRefinement?.refinementList?.objectID ?? []).filter((id) => !menuIds.includes(id)))
      }
    },
    [currentRefinement?.refinementList?.CM_ID, currentRefinement?.refinementList?.objectID, refineCMID, refineObjectID]
  )

  const renderContentWrapper = useCallback(
    ({ children }: PropsWithChildren) => {
      return (
        <DialogContent sx={{ p: '0 !important' }} dividers>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" sx={{ position: 'relative' }}>
            {children}
            <FormControlLabel
              label="Show current selection"
              control={
                <Checkbox
                  onChange={toggleSelection}
                  checked={checkedSelection}
                  color="success"
                  data-testid="hide-checkbox"
                />
              }
            />
          </Box>
        </DialogContent>
      )
    },
    [checkedSelection, toggleSelection]
  )

  const renderMasterFilterWrapper = useCallback(({ children }: PropsWithChildren) => {
    return <DialogActions sx={{ justifyContent: 'space-around', gap: 1, py: 5 }}>{children}</DialogActions>
  }, [])

  useUpdateEffect(() => {
    if (isDialogOpen) {
      hideSelection()
    }
  }, [isDialogOpen])

  return (
    <OpenSearchApiProvider>
      <S.ListItem disableGutters onClick={openDialog} data-testid="filter-list-item--product_sku" {...spacingProps}>
        <Box display="flex" alignItems="center" flexDirection="row" sx={{ position: 'relative' }}>
          <Typography>{label}</Typography>
          {!hideClearRefinements && <ClearRefinements attribute={PRODUCT_SKUS_VARIANT_FIELDS} />}
          <Box flex={1} minHeight={22} />
        </Box>
        <InstantSearch
          searchState={currentRefinement}
          searchClient={algoliaSearchClients[VARIANT_INDEX]}
          indexName={VARIANT_INDEX}
        >
          <Configure
            hitsPerPage={total ? DISPLAY_ON_LIST : 0}
            restrictSearchableAttributes={[]}
            attributesToRetrieve={MASTER_ATTRIBUTES_TO_RETRIEVE}
            distinct={1}
          />
          <VirtualWidgets invisibleFilters={MasterProductFilterOptions} index={VARIANT_INDEX} />
          <CurrentProductsRefinement
            hideArrow={hideArrow}
            onRemove={onRemove}
            onRemoveInvalid={handleRemoveInvalidProducts}
            onClickMore={displaySelection}
            total={total}
            maxToDisplay={DISPLAY_ON_LIST}
          />
        </InstantSearch>
      </S.ListItem>
      <Dialog
        classes={classes}
        maxWidth="xs"
        fullWidth
        fullScreen={isMobile}
        keepMounted={false}
        open={isDialogOpen}
        onClose={closeDialog}
        TransitionComponent={GrowTransition}
        data-testid="facet-dialog--product_sku"
      >
        <DialogTitle title={label} onClose={closeDialog} />
        <ProductSkuSelector
          filters={checkedSelection ? currentSelectionFilters : filtersWithoutProducts}
          hitsPerPage={checkedSelection && total === 0 ? 0 : DISPLAY_PER_PAGE}
          onSelectMastered={handleSelectMastered}
          onSelectNonMastered={refineObjectID ? handleSelectNonMastered : undefined}
          selectedMastered={currentRefinement?.refinementList?.CM_ID ?? []}
          selectedNonMastered={currentRefinement?.refinementList?.objectID ?? []}
          emptyListPlaceholderProps={{
            color: checkedSelection ? 'success' : undefined,
            message: checkedSelection ? "You don't have any products/SKUs selected at the moment." : undefined
          }}
          ContentWrapper={renderContentWrapper}
          MasterFilterWrapper={renderMasterFilterWrapper}
        />
      </Dialog>
    </OpenSearchApiProvider>
  )
})
