import { ALGOLIA_INDEX } from '@client/services/algolia'
import { filtersFromSearchState, getFilterKeyMapFromTagFilters, searchStateFromFilters } from '@client/types/filterset'
import { Filters, defaultFilters } from '@hoodie/hoodie-filters/lib/filterset'
import deepEqual from 'fast-deep-equal'
import { useCallback, useMemo, useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { useUpdateEffect } from 'usehooks-ts'

export const useSearchState = (filters = defaultFilters, index?: ALGOLIA_INDEX) => {
  const [initialFilters, setInitialFilters] = useState<Filters>(filters)
  const initialSearchState = useMemo(() => searchStateFromFilters(initialFilters, index), [initialFilters, index])
  const [searchState, setSearchState] = useState<SearchState>(initialSearchState)

  const { updatedFilters, blockedFilters } = useMemo(() => {
    const updatedFilters = filtersFromSearchState(searchState, index)
    return {
      updatedFilters,
      blockedFilters: getFilterKeyMapFromTagFilters(updatedFilters)
    }
  }, [searchState, index])

  const isDirty = useMemo(() => {
    return !deepEqual(updatedFilters, initialFilters)
  }, [initialFilters, updatedFilters])

  const resetSearchState = useCallback(
    (state: SearchState) => {
      const filtersFromSearch = filtersFromSearchState(state, index)
      // Only update searchState if it's different from the current one to not trigger a duplicated algolia search
      setSearchState((prev) => (!deepEqual(filtersFromSearchState(prev, index), filtersFromSearch) ? state : prev))
      setInitialFilters(filtersFromSearch)
    },
    [index]
  )

  useUpdateEffect(() => {
    const newSearchState = searchStateFromFilters(filters, index)
    resetSearchState(newSearchState)
  }, [resetSearchState, filters, index])

  return {
    searchState,
    isDirty,
    initialSearchState,
    blockedFilters,
    setSearchState,
    resetSearchState
  }
}
