import { AccessControl } from '@client/components/access-control/AccessControl'
import { HelpLink } from '@client/components/help-link/HelpLink'
import { NotificationAlert } from '@client/components/notification-alert'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import { draftNotificationPreference } from '@client/types/notification'
import { Permission } from '@lib/types/permission'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import React, { useCallback, useEffect } from 'react'

export interface NotificationAlertsProps {
  compact?: boolean
  filtersetId?: string
  busy?: boolean
}

export const NotificationAlerts: React.FC<NotificationAlertsProps> = ({
  filtersetId,
  busy = false,
  compact = false
}) => {
  const { preferencesForFiltersetId, addPreference, discardDraftPreferences } = useNotificationPreferences()

  const handleAddClick = useCallback(
    () => addPreference(draftNotificationPreference(filtersetId)),
    [addPreference, filtersetId]
  )

  const filteredPreferences = [...preferencesForFiltersetId(filtersetId)]

  // Discard unsaved preferences when component unmounts
  useEffect(() => {
    return () => discardDraftPreferences()
  }, [discardDraftPreferences])

  return (
    <Box mt={4}>
      <Typography fontWeight="600" variant="h5" gutterBottom>
        Alerts
        <HelpLink buttonProps={{ sx: { ml: 2 } }} helpLink="sales-enablement/alerts" tooltip="View help on alerts" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        Get notified of changes that affect your saved view.
      </Typography>
      <AccessControl
        permission={Permission.NOTIFICATION_PREFERENCE_CREATE}
        emailSubject="Notifications access request"
        variant="outlined"
      >
        <div style={busy ? { opacity: '.5', pointerEvents: 'none' } : {}}>
          {filteredPreferences?.map((entry) => (
            <NotificationAlert
              key={entry[0]}
              lookupKey={entry[0]}
              errors={entry[1].errors}
              isDirty={entry[1].isDirty}
              defaultPreference={entry[1].settings}
              compact={compact}
            />
          ))}
          <Button role="button" variant="contained" endIcon={<AddIcon />} onClick={handleAddClick}>
            Add new alert
          </Button>
        </div>
      </AccessControl>
    </Box>
  )
}
