import { SharedIcon } from '@client/components/SharedIcon/SharedIcon'
import { Lock, Public } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { memo } from 'react'

interface AccessIconProps extends SvgIconProps {
  hasActions?: boolean
  isMine?: boolean
  isPublic?: boolean
  isShared?: boolean
}

export const AccessIcon: React.FC<AccessIconProps> = memo(function AccessIcon({
  hasActions = false,
  isMine = false,
  isPublic = false,
  isShared = false,
  ...iconProps
}) {
  if (!isMine && hasActions) {
    return <Lock {...iconProps} data-testid="access-icon--locked" />
  } else if (isPublic) {
    return <Public {...iconProps} data-testid="access-icon--public" />
  } else if (isShared) {
    return <SharedIcon isMine={isMine} {...iconProps} />
  } else {
    return null
  }
})
