import { toHumanString } from '@client/helpers/strings'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { IconButton, InputAdornment, Stack, TextField, type IconButtonProps, type TextFieldProps } from '@mui/material'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { NumericFormat, type NumericFormatProps } from 'react-number-format'

export type NumericInputProps = Omit<TextFieldProps, 'onChange'> & {
  hideActionButtons?: boolean
  max?: number
  min?: number
  numericFormatProps?: NumericFormatProps
  onChange: (value?: number) => void
  step?: number
  defaultValue?: number
  value?: number
  dataTestId?: 'min-' | 'max-'
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, NumericFormatProps>(function NumericFormatCustom(
  props,
  ref
) {
  const { value, defaultValue, ...rest } = props

  return (
    <NumericFormat
      {...rest}
      defaultValue={defaultValue}
      value={value ?? ''} // We can't ever pass null to value because it breaks the shrink state of the label, so we pass empty string instead
      getInputRef={ref}
      thousandSeparator=","
      decimalSeparator="."
    />
  )
})

export const NumericInput = React.forwardRef<HTMLDivElement, NumericInputProps>(function NumericInput(props, ref) {
  const {
    disabled = false,
    InputProps,
    inputProps,
    hideActionButtons = false,
    max = Infinity,
    min = -Infinity,
    numericFormatProps: numericFormatPropsProp,
    onChange,
    size,
    step = 1,
    value,
    defaultValue,
    label,
    dataTestId = '',
    helperText: helperTextProp
  } = props

  const style = useMemo(() => {
    if (value === defaultValue) {
      return { color: 'grey' }
    }
    return
  }, [value, defaultValue])

  const error = useMemo(() => {
    if (value && min !== undefined && max !== undefined && (value < min || value > max)) {
      return true
    } else {
      return false
    }
  }, [value, min, max])

  const helperText = useMemo(() => {
    if (helperTextProp) {
      return helperTextProp
    } else if (label && defaultValue !== undefined) {
      return `${label}: ${toHumanString(defaultValue, true)}`
    } else if (defaultValue !== undefined) {
      return `${toHumanString(defaultValue, true)}`
    } else {
      return ''
    }
  }, [defaultValue, label, helperTextProp])

  const handleOnBlur = useCallback(() => {
    // reset to max or min if value is out of bounds
    if (error) {
      onChange(defaultValue)
    }
  }, [error, onChange, defaultValue])

  const increment = () => {
    // If we increment when the input is empty, we consider the previous value to be 0
    const newValue = (value !== undefined && !Number.isNaN(value) ? value : 0) + step

    if (newValue > max) {
      return
    }

    onChange(newValue)
  }

  const decrement = () => {
    // If we decrement when the input is empty, we consider the previous value to be 0
    const newValue = (value !== undefined && !Number.isNaN(value) ? value : 0) - step

    if (newValue < min) {
      return
    }

    onChange(newValue)
  }

  const numericFormatProps: NumericFormatProps = {
    // We set a default to avoid displaying floating point errors when using a decimal step
    decimalScale: 2,

    // Only add the min, max and step html attributes when the value isn't the default one
    max: max !== Infinity ? max : undefined,
    min: min !== -Infinity ? min : undefined,
    step: step !== 1 ? step : undefined,

    // Allow to increment with ArrowUp and decrement with ArrowDown
    onKeyDown: (event) => {
      if (event.key === 'ArrowUp') {
        increment()
      } else if (event.key === 'ArrowDown') {
        decrement()
      }
    },

    onValueChange: ({ floatValue }) => {
      // When incrementing or decrementing, the value prop is already up to date
      // so we make sure the value needs to be updated to prevent an unnecessary re-render
      if (floatValue === value) {
        return
      }

      onChange(floatValue ?? undefined)
    },
    value,

    ...numericFormatPropsProp
  }

  const commonAdornmentButtonProps: IconButtonProps = {
    edge: 'end',
    sx: { p: size !== 'small' ? '1px' : 0 }
  }

  return (
    <TextField
      ref={ref}
      error={error}
      label={label}
      onBlur={handleOnBlur}
      value={value ?? ''} // We can't ever pass null to value because it breaks the shrink state of the label, so we pass empty string instead
      disabled={disabled}
      size={size}
      helperText={helperText}
      InputProps={{
        ...InputProps,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumericFormatCustom as any,
        endAdornment: !hideActionButtons && (
          <InputAdornment position="end">
            <Stack>
              <IconButton
                data-testid={`${dataTestId}increment`}
                disabled={disabled || (value ?? 0) + step > max}
                onClick={increment}
                {...commonAdornmentButtonProps}
              >
                <KeyboardArrowUpIcon fontSize={size} />
              </IconButton>
              <IconButton
                data-testid={`${dataTestId}decrement`}
                disabled={disabled || (value ?? 0) - step < min}
                onClick={decrement}
                {...commonAdornmentButtonProps}
              >
                <KeyboardArrowDownIcon fontSize={size} />
              </IconButton>
            </Stack>
          </InputAdornment>
        )
      }}
      // @ts-expect-error The type should be React.ComponentProps<typeof inputComponent> but instead
      // it is hard-coded to InputBaseComponentProps
      inputProps={{
        ...inputProps,
        style,
        'data-testid': `${dataTestId}numeric-input`,
        ...numericFormatProps
      }}
    />
  )
})

// Taken from here:
// https://stackoverflow.com/questions/32054025/how-to-determine-thousands-separator-in-javascript#comments-77517574
