import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { Save, UnfoldLess, UnfoldMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Collapse,
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import { useIsMounted, useUpdateEffect } from 'usehooks-ts'

export type FormStep = {
  label: string
  required: boolean
  completed: boolean
  component: ReactNode
}

interface FormStepperProps {
  autoFocusInvalid?: boolean
  initialStep?: number
  steps: FormStep[]
  CustomButtons?: ReactNode
  sx?: SxProps<Theme>
  onSubmit?: () => Promise<void>
}

export const isStepValid = ({ required, completed }: FormStep) => !required || completed

export const FormStepper: React.FC<FormStepperProps> = memo(function FormStepper({
  autoFocusInvalid = false,
  initialStep = 0,
  steps,
  CustomButtons,
  sx,
  onSubmit
}) {
  const { value: busy, setTrue: setBusy, setFalse: setNotBusy } = useBooleanState(false)
  const { isMobile } = useResponsiveState()
  const isMounted = useIsMounted()

  const canExpand = useMemo(() => steps.every(isStepValid), [steps])
  const [isExpanded, setIsExpanded] = useState(canExpand)
  const [activeStep, setActiveStep] = useState(initialStep)

  // If autoFocusInvalid is active and the steps are expanded, collapse the filter and go to the first invalid step
  useUpdateEffect(() => {
    if (autoFocusInvalid && !canExpand && isExpanded) {
      const invalidStep = steps.findIndex((step) => !isStepValid(step))
      setActiveStep(Math.max(0, invalidStep))
      setIsExpanded(false)
    }
  }, [canExpand, isExpanded, steps])

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [])

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [])

  const canGoToStep = useCallback(
    (toStep: number) => {
      return steps.slice(0, toStep).every(isStepValid)
    },
    [steps]
  )

  const goToStep = useCallback(
    (toStep: number) => {
      canGoToStep(toStep) && setActiveStep(toStep)
    },
    [canGoToStep]
  )

  const handleSubmit = useCallback(async () => {
    setBusy()
    await onSubmit?.()
    if (isMounted()) {
      setNotBusy()
    }
  }, [onSubmit, setBusy, setNotBusy, isMounted])

  return (
    <Box display="flex" flexDirection="column" gap={4} p={{ xs: 4, md: 6 }} sx={sx}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ component, label, required, completed }, index) => (
          <Step key={label} expanded={isExpanded || undefined} completed={completed}>
            <StepLabel
              optional={
                !required ? (
                  <Typography variant="caption" fontStyle="italic">
                    Optional
                  </Typography>
                ) : undefined
              }
              sx={{
                cursor: !isExpanded && canGoToStep(index) ? 'pointer !important' : undefined
              }}
              onClick={!isExpanded ? () => goToStep(index) : undefined}
              data-testid={`form-stepper--step-label`}
            >
              {label}
            </StepLabel>
            <StepContent sx={isMobile ? { pr: 0, pl: 2 } : undefined}>
              <Box py={3} data-testid={`form-stepper--step-content-${index}`}>
                {component}
              </Box>
              <Collapse in={!isExpanded}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" gap={2}>
                  {index < steps.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={(required && !completed) || index === steps.length - 1}
                      data-testid="form-stepper--next-step"
                    >
                      Next step
                    </Button>
                  )}
                  {index > 0 && (
                    <Button onClick={handleBack} data-testid="form-stepper--prev-step">
                      Previous step
                    </Button>
                  )}
                </Box>
              </Collapse>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Box display="flex" justifyContent="space-between" gap={2}>
        {isMobile ? (
          <IconButton
            data-testid="form-stepper--toggle-button-mobile"
            onClick={() => setIsExpanded(!isExpanded)}
            disabled={!canExpand}
            color="primary"
          >
            {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
          </IconButton>
        ) : (
          <Button
            data-testid="form-stepper--toggle-button"
            onClick={() => setIsExpanded(!isExpanded)}
            variant="text"
            startIcon={isExpanded ? <UnfoldLess /> : <UnfoldMore />}
            size={isMobile ? 'small' : 'medium'}
            sx={{ whiteSpace: 'nowrap' }}
            disabled={!canExpand}
          >
            {isExpanded ? 'Collapse steps' : 'Expand all steps'}
          </Button>
        )}
        <Box display="flex" justifyContent="flex-end" width={{ xs: '100%', md: 'auto' }} gap={2}>
          {CustomButtons}
          {onSubmit && (
            <LoadingButton
              disabled={!canExpand}
              loading={busy}
              size={isMobile ? 'small' : 'medium'}
              data-testid="form-stepper--save-button"
              onClick={handleSubmit}
              variant="contained"
              startIcon={<Save />}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Save
            </LoadingButton>
          )}
        </Box>
      </Box>
      {isMobile && canExpand && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={1}
          mt={2}
          data-testid="form-stepper--expand-helper"
        >
          <Typography variant="caption" fontStyle="italic">
            Tap the
          </Typography>
          {isExpanded ? <UnfoldLess sx={{ fontSize: '12px' }} /> : <UnfoldMore sx={{ fontSize: '12px' }} />}
          <Typography variant="caption" fontStyle="italic">
            icon to {isExpanded ? 'collapse' : 'expand'} the steps
          </Typography>
        </Box>
      )}
    </Box>
  )
})
