import { useAuth0 } from '@auth0/auth0-react'
import { SharedIcon } from '@client/components/SharedIcon/SharedIcon'
import { DesktopTooltip } from '@client/components/desktop-tooltip/DesktopTooltip'
import { MenuItem, MenuItemText, MenuSection } from '@client/components/menu'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { ViewActiveIcon, ViewIcon } from '@client/styles/global'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { AutocompletePopper } from '@client/styles/theme/popper'
import { View } from '@hoodie/hoodie-filters/lib/filterset'
import { BoxProps, IconButton, IconButtonProps, Popover, TextField } from '@mui/material'
import Autocomplete, { AutocompleteChangeReason, AutocompleteCloseReason } from '@mui/material/Autocomplete'
import { Fragment, useCallback } from 'react'

export interface IconButtonViewSelectorProps extends Omit<IconButtonProps, 'onChange' | 'value'> {
  views: View[]
  value?: View
  popperWidth?: number
  label?: string
  onChange: (value?: View) => void
}

export const IconButtonViewSelector: React.FC<IconButtonViewSelectorProps> = ({
  value,
  onChange,
  popperWidth = 288,
  views,
  label = 'Apply filters from a saved view...',
  ...iconButtonProps
}) => {
  const { user } = useAuth0()
  const { menuAnchor, handleClick: openMenu, handleClose: closeMenu } = useMenuAnchor()

  const Icon = value ? ViewActiveIcon : ViewIcon

  const handleAutocompleteClose = useCallback(
    (event: React.SyntheticEvent<Element, Event>, reason: AutocompleteCloseReason) => {
      if (reason === 'escape') {
        closeMenu()
      }
    },
    [closeMenu]
  )

  const handleChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: View | null, reason: AutocompleteChangeReason) => {
      const keyboardEvent = event.type === 'keydown' ? (event as React.KeyboardEvent).key : null
      if (reason === 'removeOption' && (keyboardEvent === 'Backspace' || keyboardEvent === 'Delete')) {
        return
      }
      onChange(newValue ?? undefined)
      closeMenu()
    },
    [onChange, closeMenu]
  )

  return (
    <>
      <DesktopTooltip title={label}>
        <IconButton
          size="small"
          {...iconButtonProps}
          onClick={menuAnchor ? closeMenu : openMenu}
          data-testid="view-select"
        >
          <Icon fontSize="small" />
        </IconButton>
      </DesktopTooltip>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => closeMenu()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Autocomplete
          open
          value={value ?? null}
          onClose={handleAutocompleteClose}
          onChange={handleChange}
          disableCloseOnSelect
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          PopperComponent={({ disablePortal, anchorEl, open, ...props }) => (
            <AutocompletePopper {...(props as BoxProps)} sx={{ width: `${popperWidth}px !important` }} />
          )}
          noOptionsText={views.length ? 'No views found' : 'You have no saved views'}
          options={views}
          getOptionLabel={(view) => view.name}
          groupBy={(view) => (user?.sub === view.userId ? 'My saved views' : 'Shared with me')}
          renderGroup={({ group, key, children }) => (
            <Fragment key={key}>
              <MenuSection label={group} />
              {children}
            </Fragment>
          )}
          renderOption={(props, view, { selected }) => (
            <MenuItem
              {...props}
              key={view.id}
              data-value={view.id}
              style={selected ? { ...props.style, backgroundColor: palette.mediumlight } : undefined}
            >
              <MenuItemText
                title={view.name}
                sx={{ display: 'block' }}
                primary={
                  <>
                    {user?.sub !== view.userId && <SharedIcon />}
                    {view.name}
                  </>
                }
              />
            </MenuItem>
          )}
          sx={{ width: popperWidth }}
          renderInput={(params) => (
            <Box p={2}>
              <TextField
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder={label}
                variant="standard"
                autoFocus
              />
            </Box>
          )}
        />
      </Popover>
    </>
  )
}
