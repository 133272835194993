import { formatDayMonthDateClean } from '@client/helpers/dates'
import { FC, PropsWithChildren, memo } from 'react'
import * as S from './SnoozeBadge.style'

export const SnoozeBadge: FC<PropsWithChildren<{ disabledUntil: Date | undefined }>> = memo(function SnoozeBadge({
  disabledUntil,
  children
}) {
  const isSnoozed = disabledUntil && disabledUntil > new Date()
  return (
    <S.SnoozeBadge
      title={isSnoozed ? `Snoozed until ${formatDayMonthDateClean(disabledUntil)}` : undefined}
      badgeContent={isSnoozed ? 'zzz' : undefined}
    >
      {children}
    </S.SnoozeBadge>
  )
})
