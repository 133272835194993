import { UserSelectOption } from '@client/components/form/user-select/UserSelect'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useUser } from '@client/providers/user'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { useCallback, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'

type ShareTransferObject = {
  id: string
  name: string
  userId: string
  subscriptionId?: string
}

type TrackingShareTransfer = {
  category: TrackableCategory
  shareAction: TrackableAction
  unshareAction: TrackableAction
  transferAction: TrackableAction
}

type ShareTransferStatus = {
  shared: boolean
  unshared: boolean
  transferred: boolean
}

const defaultStatus: ShareTransferStatus = {
  shared: false,
  unshared: false,
  transferred: false
}

export const useShareTransfer = <TData extends ShareTransferObject>({
  data,
  onSave,
  label,
  tracking
}: {
  label: string
  data: TData
  onSave: (data: TData) => Promise<TData | null>
  tracking?: TrackingShareTransfer
}) => {
  const { account } = useUser()
  const { toast } = useToastNotification()
  const { eventTracker } = useEventTracker()

  const {
    value: isShared,
    toggleValue: toggleIsShared,
    setFalse: setUnshared,
    setTrue: setShared
  } = useBooleanState(!!data.subscriptionId)
  const [transferTarget, setTransferTarget] = useState<UserSelectOption>()

  const handleShareChange = useCallback(() => {
    toggleIsShared()
  }, [toggleIsShared])

  const handleTransferTargetChange = useCallback((value: UserSelectOption[] | UserSelectOption | null) => {
    setTransferTarget((value as UserSelectOption) || undefined)
  }, [])

  useUpdateEffect(() => {
    setTransferTarget(undefined)
    if (data.subscriptionId) {
      setShared()
    } else {
      setUnshared()
    }
  }, [data.subscriptionId, data.userId])

  const applyShareTransfer = useCallback(async (): Promise<ShareTransferStatus> => {
    if (!account) {
      return defaultStatus
    }
    const updatedData = {
      ...data,
      subscriptionId: isShared ? account.id : undefined,
      userId: transferTarget?.id || data.userId
    }
    const messages: string[] = []

    // Share/Unshare
    if (updatedData.subscriptionId && !data.subscriptionId) {
      messages.push(`${label} shared with ${account.name}`)
      if (tracking) {
        eventTracker({
          category: tracking.category,
          action: tracking.shareAction,
          dimension1: updatedData.id,
          dimension2: account.id
        })
      }
    } else if (!updatedData.subscriptionId && data.subscriptionId) {
      messages.push(`${label} no longer shared with ${account.name}`)
      if (tracking) {
        eventTracker({
          category: tracking.category,
          action: tracking.unshareAction,
          dimension1: updatedData.id,
          dimension2: account.id
        })
      }
    }
    // Transfer
    if (updatedData.userId !== data.userId) {
      messages.push(`${label} transferred to ${transferTarget?.name}`)
      if (tracking) {
        eventTracker({
          category: tracking.category,
          action: tracking.transferAction,
          dimension1: updatedData.id,
          dimension2: updatedData.userId
        })
      }
    }
    try {
      await onSave(updatedData)
      messages.forEach((message) => toast.success(message))
      return {
        shared: !!updatedData.subscriptionId && !data.subscriptionId,
        unshared: !updatedData.subscriptionId && !!data.subscriptionId,
        transferred: updatedData.userId !== data.userId
      }
    } catch (e) {
      toast.error('Something went wrong. Please try again.')
    }
    return defaultStatus
  }, [account, data, isShared, transferTarget?.id, transferTarget?.name, label, tracking, eventTracker, onSave, toast])

  return {
    isShared,
    transferTarget,
    applyShareTransfer,
    handleShareChange,
    handleTransferTargetChange
  }
}
