import { TrackableRefRouterLink } from '@client/components/Trackables'
import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { MenuItemIcon, MenuItemText } from '@client/components/menu'
import { NotificationSnooze, SnoozeBadge } from '@client/components/notification-snooze'
import { EventArgs } from '@client/configs/tracking'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { palette } from '@client/styles/theme/palette'
import { NotificationPreference } from '@client/types/notification'
import { HoodieTheme } from '@client/types/styles'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { ExpandLess, ExpandMore, MoreVert, Snooze } from '@mui/icons-material'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import { Box, Collapse, IconButton, List, ListItem, Skeleton, Typography, useTheme } from '@mui/material'
import { memo, useCallback, useEffect, useMemo } from 'react'
import * as S from './NotificationsDropdown.style'

export type Notification = {
  isRead: boolean
  timeAgo: string
  frequency: string
  title: string
  categories: string[]
  channels?: string[]
  description: string
  notification_id: string
  notificationPreferenceId?: string
  filtersetId?: string
  uri: string
}

export type NotificationProps = {
  notification: Notification
  preferencesKey?: string
  onMarkRead: (notification: Notification, closeDropdown?: boolean) => void
  onUnsubscribe: (preferencesKey: string) => Promise<void>
}

const gaEvent: EventArgs = { action: TrackableAction.notificationClicked, category: TrackableCategory.navigation }

export const NotificationSkeleton = memo(function NotificationSkeleton() {
  const theme = useTheme<HoodieTheme>()
  return (
    <ListItem divider sx={{ backgroundColor: theme.palette.lightest, pr: 0 }}>
      <Box flex={1}>
        <Box display="flex" gap={1} mb={1}>
          <Skeleton variant="rectangular" width={58} height={24} style={{ borderRadius: '5px' }} />
          <Skeleton variant="text" width={120} height={24} />
        </Box>
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="text" width={60} height={16} />
      </Box>
    </ListItem>
  )
})

export const Notification = memo(
  function Notification({ notification, preferencesKey, onMarkRead, onUnsubscribe }: NotificationProps) {
    const { uri, isRead, title, frequency, description, timeAgo } = notification
    const theme = useTheme<HoodieTheme>()
    const { getPreference } = useNotificationPreferences()
    const notificationPreference = useMemo(
      () => (notification.notificationPreferenceId ? getPreference(notification.notificationPreferenceId) : undefined),
      [getPreference, notification.notificationPreferenceId]
    )

    return (
      <ListItem
        divider
        component={TrackableRefRouterLink}
        to={uri}
        onClick={() => onMarkRead(notification, true)}
        sx={{ backgroundColor: isRead ? theme.palette.lightest : theme.palette.paleBlue, pr: 0 }}
        gaEvent={gaEvent}
        data-testid="notification-item"
      >
        <Box display="flex" width="100%" maxWidth="100%">
          <Box flex="1">
            <Title title={title} frequency={frequency} />
            <Description content={description} />
            <Time value={timeAgo} />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <NotificationActions
              notificationPreference={notificationPreference}
              notification={notification}
              onMarkRead={!isRead ? () => onMarkRead(notification) : undefined}
              onUnsubscribe={preferencesKey ? () => onUnsubscribe(preferencesKey) : undefined}
            />
          </Box>
        </Box>
      </ListItem>
    )
  },
  (prevProps, nextProps) =>
    prevProps.preferencesKey === nextProps.preferencesKey &&
    prevProps.notification.isRead === nextProps.notification.isRead
)

const Title = ({ title, frequency }: { title: string; frequency: string }) => (
  <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
    {frequency && <S.Chip size="small" label={frequency} aria-label="frequency-tag" role="listitem" />}
    <Typography variant="subtitle1" color="textPrimary" fontWeight="bold" lineHeight="20px">
      {title}
    </Typography>
  </Box>
)

const Description = ({ content }: { content: string }) => (
  <Typography variant="subtitle2" color="textPrimary">
    {content}
  </Typography>
)

const Time = ({ value }: { value: string }) => (
  <Typography variant="caption" color="mediumdark">
    {value || ''}
  </Typography>
)

const ConfirmDialogContent = ({ notification }: { notification: Notification }) => (
  <>
    <Typography>
      This will mean you will no longer receive <strong>{notification.frequency}</strong> notifications
      {notification.channels?.length ? ' via ' : ''}
      <strong>{notification.channels?.join(', ')?.replace(/, ([^,]*)$/, ' and $1')}</strong> about the following events
      for <strong>{notification.title}</strong>:
    </Typography>
    <List dense sx={{ listStyleType: 'disc', pl: 4, ml: 4 }} data-testid="notification-categories-list">
      {notification.categories.map((category) => (
        <ListItem key={category} sx={{ display: 'list-item', pl: 0 }}>
          {category}
        </ListItem>
      ))}
    </List>
  </>
)

type NotificationActionsProps = {
  notificationPreference?: NotificationPreference
  notification: Notification
  onMarkRead?: () => void
  onUnsubscribe?: () => Promise<void>
}

const NotificationActions = memo(function NotificationActions({
  notificationPreference,
  notification,
  onMarkRead,
  onUnsubscribe
}: NotificationActionsProps) {
  const { menuAnchor, handleClick, handleClose } = useMenuAnchor()
  const { value: snoozeExpanded, toggleValue: toggleSnoozeExpand } = useBooleanState(false)
  // delete with confirmation
  const { value: deleting, setTrue: startDelete, setFalse: endDelete } = useBooleanState(false)
  const { value: showConfirmation, setTrue: openConfirmation, setFalse: closeConfirmation } = useBooleanState(false)

  const markAsRead = useCallback(
    (event: any) => {
      onMarkRead?.()
      handleClose(event)
    },
    [handleClose, onMarkRead]
  )

  const unsubscribe = useCallback(() => {
    if (onUnsubscribe) {
      startDelete()
      onUnsubscribe().finally(() => {
        endDelete()
        handleClose()
      })
    }
  }, [handleClose, onUnsubscribe, startDelete, endDelete])

  // Close/reset snooze menu when closing the popup menu
  useEffect(() => {
    if (snoozeExpanded && !menuAnchor) {
      toggleSnoozeExpand()
    }
  }, [menuAnchor, snoozeExpanded, toggleSnoozeExpand])

  return (
    <>
      <IconButton
        aria-haspopup="true"
        aria-label="btn-more"
        onClick={handleClick}
        data-testid="notification-options-button"
      >
        <MoreVert />
      </IconButton>
      <PopupMenu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={(e) => handleClose(e as any)}
        onClick={(e) => e.stopPropagation()}
        data-testid="notification-options-menu"
      >
        <PopupMenuItem disabled={!onMarkRead} onClick={markAsRead} aria-label="menu-item-mark-as-read">
          <MenuItemIcon>
            <MarkEmailReadIcon fontSize="small" />
          </MenuItemIcon>
          <MenuItemText primary="Mark as read" />
        </PopupMenuItem>
        {notificationPreference && [
          <PopupMenuItem key="menu-snooze" onClick={toggleSnoozeExpand} aria-label="menu-item-snooze">
            <MenuItemIcon>
              <Snooze fontSize="small" color="snooze" />
            </MenuItemIcon>
            <MenuItemText
              primary={
                <SnoozeBadge disabledUntil={notificationPreference.disabledUntil}>Snooze this alert</SnoozeBadge>
              }
              primaryTypographyProps={{ overflow: 'visible !important' }}
            />
            {snoozeExpanded ? <ExpandLess /> : <ExpandMore />}
          </PopupMenuItem>,
          <Collapse key="menu-snooze-expand" in={snoozeExpanded}>
            <NotificationSnooze
              context="notifications-dropdown"
              notificationPreferenceId={notificationPreference.id as string}
              sx={{
                backgroundColor: palette.lighter,
                borderTop: `1px solid ${palette.light}`,
                borderBottom: `1px solid ${palette.light}`
              }}
            />
          </Collapse>
        ]}
        <PopupMenuItem disabled={!onUnsubscribe} onClick={openConfirmation} aria-label="menu-item-unsubscribe-alert">
          <MenuItemIcon>
            <NotificationsOffIcon color="error" fontSize="small" />
          </MenuItemIcon>
          <MenuItemText primary="Stop alerting on this" />
        </PopupMenuItem>
      </PopupMenu>
      {!!onUnsubscribe && (
        <ConfirmationDialog
          open={showConfirmation}
          title={`Delete the ${notification.frequency} alerts for ${notification.title}?`}
          confirmButtonText="Stop alerts"
          loading={deleting}
          loadingIndicator="Stopping alerts..."
          content={<ConfirmDialogContent notification={notification} />}
          onCancel={closeConfirmation}
          onConfirm={unsubscribe}
          onClick={(e) => e.stopPropagation()}
          danger
        />
      )}
    </>
  )
})
