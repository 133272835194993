import { PageLoader } from '@client/components/page-loader/PageLoader'
import React, { Suspense } from 'react'

// This function is a wrapper around React.lazy that adds a fallback loading spinner.
export const lazy = <T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>) => {
  const LazyComponent = React.lazy(factory)
  return function lazy(props: React.ComponentProps<T>) {
    return (
      <Suspense fallback={<PageLoader />}>
        <LazyComponent {...props} />
      </Suspense>
    )
  }
}
