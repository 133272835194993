import { SkuCoverageInput } from '@api/open-search/routes/_skuCoverage'
import { BrandOptions } from '@client/types/brands'
import { DispensariesOptions } from '@client/types/dispensary-stats'
import { EventsOptions } from '@client/types/event'
import { MasterProductsOptions, ProductGridOptions } from '@client/types/products'
import { StockFilterOption, VariantsOptions } from '@client/types/variants'
import { Filters } from '@hoodie/hoodie-filters/lib/filterset'
import { DataDisplay } from '@lib/types/modules'
import { IndexPrefix } from '@lib/types/open-search'
import { SetRequired } from 'type-fest'
import { apiClient } from './api/api-client'

export type ProductHistoryFilters = {
  masterId?: string
  menuId?: string
  variantId?: string
  numberOfRecords?: number
}

export type PageParams = {
  from: number
  pageSize: number
}

export interface OpenSearchApiServiceParams {
  token: string
  permissionsKey: string
  subscriptionKey: string
}

/* A class for interacting with the elastic OpenSearch API */
export class OpenSearchApiService {
  private normalizeFilterWithTags?: (filters?: Filters) => Omit<Filters, 'tags'> | undefined

  constructor(options?: { normalizeFilterWithTags?: (filters?: Filters) => Omit<Filters, 'tags'> | undefined }) {
    this.normalizeFilterWithTags = options?.normalizeFilterWithTags
  }

  private normalizeFilters(filters?: Filters): Omit<Filters, 'tags'> | undefined {
    if (!this.normalizeFilterWithTags) {
      return filters
    }
    return this.normalizeFilterWithTags(filters)
  }

  async openSearchLatestIndex(index: IndexPrefix) {
    return apiClient.openSearch.latestIndex.query({ variables: { index } })
  }

  async openSearchDispensariesPromotionsKpi(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.dispensariesPromotionsKpi.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensariesLowStockKpi(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.dispensariesLowStockKpi.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensariesOutOfStockKpi(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.dispensariesOutOfStockKpi.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensariesSalesProspectsKpi(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.dispensariesSalesProspectsKpi.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchSKUsNewlyStocked(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getSkusNewlyStocked.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchNewPromotions(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getNewPromotions.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchTopBrands(dates: Array<{ on: string }>, numberOfBrands: number, filterset?: Filters) {
    return apiClient.openSearch.getTopBrands.mutate({
      variables: { dates, numberOfBrands },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchPriceComparison(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getPriceComparison.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchPriceChange(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getPriceChange.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchNewSkus(date: { on: string }, interval: number, dayRange: number, filterset?: Filters) {
    return apiClient.openSearch.getNewSkus.mutate({
      variables: { date, interval, dayRange },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchTopDiscounts(
    dates: Array<{ on: string }>,
    numberOfDiscounts: number,
    greaterThanPrice: number,
    filterset?: Filters
  ) {
    return apiClient.openSearch.getTopDiscounts.mutate({
      variables: { dates, numberOfDiscounts, greaterThanPrice },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchSKUsOutOfStock(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getSkusOutOfStock.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchSKUsCount(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getSkuCount.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchCategoryCount(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getCategoryCount.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchPromotedItems(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getPromotedItems.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchProductStats(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getProductStats.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchBrandStats(dates: Array<{ on: string }>, options: { overrideBrand: string }, filterset?: Filters) {
    return apiClient.openSearch.getBrandStats.mutate({
      variables: { dates, ...options },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchBrandSalesStats(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getBrandSalesStats.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchBrandShare(
    dates: Array<{ on: string }>,
    filterset?: Filters,
    dataDisplay: DataDisplay = 'SE',
    competitiveOptions?: { fetchBrandSalesPercentiles?: boolean; numberOfBrands?: number }
  ) {
    return apiClient.openSearch.getBrandShareV2.mutate({
      variables: { dates, dataDisplay, ...competitiveOptions },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchTotalDistributionPoints(
    dates: Array<{ on: string }>,
    filterset?: Filters,
    dataDisplay: DataDisplay = 'SE'
  ) {
    return apiClient.openSearch.getTotalDistributionPoints.mutate({
      variables: { dates, dataDisplay },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchTotalSales(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getTotalSales.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchOutOfStockTrends(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getOosTrends.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchLargestBrandChange(
    dates: Array<{ on: string }>,
    filterset?: Filters,
    dataDisplay: DataDisplay = 'SE'
  ) {
    return apiClient.openSearch.getBrandLargestChange.mutate({
      variables: { dates, dataDisplay },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchSegments(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getSegments.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchProductCategories(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getProductCategories.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchShareOfCategory(dates: Array<{ on: string }>, filterset?: Filters, dataDisplay: DataDisplay = 'SE') {
    return apiClient.openSearch.getShareOfCategory.mutate({
      variables: { dates, dataDisplay },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchVariantsCount(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getVariantsCount.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchMasterProducts(dates: Array<{ on: string }>, options: MasterProductsOptions, filterset?: Filters) {
    return apiClient.openSearch.getMasterProducts.mutate({
      variables: { dates, ...options },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensarySkus(
    date: string,
    { sortOrder, sortField, ...options }: ProductGridOptions,
    filterset?: Filters
  ) {
    return apiClient.openSearch.getDispensarySKUs.mutate({
      variables: { date, sort: [{ field: sortField, order: sortOrder }], ...options },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchVariantsUOM(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getVariantsUOM.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchBrandList(dates: Array<{ on: string }>, options: BrandOptions, filterset?: Filters) {
    return apiClient.openSearch.getBrands.mutate({
      variables: { dates, ...options },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchVariants(dates: Array<{ on: string }>, options: VariantsOptions, filterset?: Filters) {
    const optionsToSend: VariantsOptions = {
      ...options,
      filters: {
        ...options.filters,
        stock: options.filters.stock === StockFilterOption.ANY ? undefined : options.filters.stock
      }
    }
    return apiClient.openSearch.getVariants.mutate({
      variables: { dates, ...optionsToSend },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchUnitQuantities(dates: Array<{ on: string }>, options: VariantsOptions, filterset?: Filters) {
    const optionsToSend: VariantsOptions = {
      ...options,
      filters: {
        ...options.filters,
        stock: options.filters.stock === StockFilterOption.ANY ? undefined : options.filters.stock
      }
    }
    return apiClient.openSearch.getUnitQuantities.mutate({
      variables: { dates, ...optionsToSend },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensaries(dates: Array<{ on: string }>, options: DispensariesOptions, filterset?: Filters) {
    return apiClient.openSearch.dispensaries.mutate({
      variables: { dates, ...options },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchEvents(options: EventsOptions, filterset?: Filters) {
    return apiClient.openSearch.getEvents.mutate({
      variables: {
        dates: options.dates,
        page: {
          from: options.from,
          pageSize: options.pageSize
        },
        sort: {
          field: options.sortField,
          order: options.sortOrder
        },
        filters: options.filters
      },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchProductHistory(dates: Array<{ on: string }>, filters?: ProductHistoryFilters) {
    return apiClient.openSearch.getProductHistory.mutate({ variables: { dates, ...filters } })
  }

  async openSearchBrandCategories(
    dates: Array<{ on: string }>,
    overrideBrand: string,
    filterset?: Filters,
    dataDisplay: DataDisplay = 'SE'
  ) {
    return apiClient.openSearch.getBrandShareOfCategory.mutate({
      variables: { dates, overrideBrand, dataDisplay },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchSkuCoverage(
    { dates, sort }: SetRequired<Partial<SkuCoverageInput['variables']>, 'dates'>,
    filterset?: Filters
  ) {
    return apiClient.openSearch.getSkuCoverage.mutate({
      variables: { dates, sort },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchBrandCoverage(
    dates: {
      on: string
    }[],
    filterset?: Filters
  ) {
    return apiClient.openSearch.getUniqueCount.mutate({
      variables: { dates, config: { groupBy: 'CATEGORY', countUnique: 'BRAND' } },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensaryShareOfCategory(dates: Array<{ on: string }>, filterset?: Filters) {
    return apiClient.openSearch.getDispensaryShareOfCategory.mutate({
      variables: { dates },
      filterset: this.normalizeFilters(filterset)
    })
  }

  async openSearchDispensaryCategoryAvgPrice(
    dates: Array<{ on: string }>,
    filterset?: Filters,
    dataDisplay: DataDisplay = 'SE'
  ) {
    return apiClient.openSearch.getDispensaryCategoryAvgPrice.mutate({
      variables: { dates, dataDisplay },
      filterset: this.normalizeFilters(filterset)
    })
  }
}
