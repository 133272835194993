import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { spacing } from '@mui/system'
import styled from 'styled-components'

export const useButtonStyles = makeStyles(() => ({
  label: {
    lineHeight: 1.5
  }
}))

export const ClearRefinementsButton = styled(Button)`
  ${spacing}
`
