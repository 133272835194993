import { usePermission } from '@client/hooks/use-permissions'
import { useWholesaleOrderCount } from '@client/hooks/wholesale/use-wholesale-data'
import { useWholesaleStore } from '@client/stores/use-wholesale-store'
import { Permission } from '@lib/types/permission'
import { FC, PropsWithChildren, useEffect } from 'react'

export const WholesaleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hasPermission } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)
  const simulatedData = useWholesaleStore((state) => state.simulatedData)
  const setHasWholesaleOrders = useWholesaleStore((state) => state.setHasWholesaleOrders)

  const { data: count } = useWholesaleOrderCount()

  useEffect(() => {
    if (!hasPermission) {
      setHasWholesaleOrders(false)
    } else if (simulatedData?.orders?.length) {
      setHasWholesaleOrders(true)
    } else {
      setHasWholesaleOrders(!!count)
    }
  }, [count, hasPermission, setHasWholesaleOrders, simulatedData])

  return <>{children}</>
}
