import { TAG_COLORS, TagWithItems } from '@client/types/tags'
import { Cancel, Lock } from '@mui/icons-material'
import { ChipProps, Tooltip, Zoom } from '@mui/material'
import { SyntheticEvent, memo, useCallback, useMemo } from 'react'
import * as S from './TagItem.style'

interface TagItemProps extends Omit<ChipProps, 'onDelete'> {
  tag: TagWithItems
  displayCount?: boolean
  removable?: boolean
  onDelete?: (e?: SyntheticEvent) => void
}

export const TagItem: React.FC<TagItemProps> = memo(function TagItem({
  tag,
  removable = false,
  displayCount = false,
  onDelete,
  size = 'small',
  ...chipProps
}) {
  const DeleteIcon = useMemo(
    () =>
      !removable ? (
        <Tooltip title="This tag can't be removed" arrow enterTouchDelay={0}>
          <Lock sx={{ cursor: 'not-allowed !important', fontSize: '14px !important' }} />
        </Tooltip>
      ) : (
        <Cancel />
      ),
    [removable]
  )

  const handleDelete = useCallback(
    (e?: SyntheticEvent) => {
      if (removable) {
        onDelete?.(e)
      }
    },
    [onDelete, removable]
  )

  return (
    <S.Chip
      {...chipProps}
      size={size}
      label={
        <>
          {tag.label}
          {displayCount && (
            <S.ItemCount $tagColor={tag.color ?? TAG_COLORS[0]} data-testid="tag-item--count">
              {tag.items?.length ?? 0}
            </S.ItemCount>
          )}
        </>
      }
      onDelete={onDelete ? handleDelete : undefined}
      deleteIcon={
        <Zoom in={!!onDelete} appear={false}>
          {DeleteIcon}
        </Zoom>
      }
      $tagColor={tag.color ?? TAG_COLORS[0]}
      data-testid="tag-item"
    />
  )
})
