import { palette } from '@client/styles/theme/palette'
import { rgbToHex } from '@mui/system'

export const chartColors = ['#F8CD46', '#622870', '#5BC6D0', '#21767B', '#362D79', '#2F7CF6', '#1D3663', '#EC4E3D']

export const getChartColor = (label: string, index: number) => {
  if (index < chartColors.length) {
    return chartColors[index]
  }
  return getBackgroundColor(label)
}

/**
 * Convert the given string to a color suitable as a background color
 * @param stringInput {string} the string to convert
 * @returns {string} the color generated for the given string
 */
export const getBackgroundColor = (stringInput: string) => {
  const stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`
}

export const getPercentageACVColor = () => getChartColor('percentageACV', 2)
export const getPointsOfDistributionColor = () => getChartColor('totalDistributionPoints', 1)
export const getSalesColor = () => getChartColor('sales', 3)
export const getStoresColor = () => getChartColor('stores', 5)
export const getAvgDaysOOSColor = () => getChartColor('averageDaysOutOfStock', 1)
export const getOutOfStockSkusColor = () => getChartColor('outOfStockSkus', 7)
export const getActiveUsersColor = () => getChartColor('activeUsers', 4)

export const compareColors = (...colors: string[]) => {
  const rgbColors = colors.map((color) => (color.startsWith('rgb') ? rgbToHex(color) : color).toLowerCase())
  return rgbColors.every((color) => color === rgbColors[0])
}

export const salesColors = {
  sales: getSalesColor(),
  benchmark: getChartColor('currentBrand', 2),
  filtered: palette.primaryGreen
}
