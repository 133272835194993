import { toHumanString } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { memo, useCallback } from 'react'

type Props = {
  disableSelectAll?: boolean
  disableSelectAllMessage?: string
  nbHits?: number
  sx?: SxProps<Theme>
  onSelectAll?: () => Promise<void>
  onClearSelection?: () => void
}

export const TabSelectorFooter = memo(function TabSelectorFooter({
  disableSelectAll,
  disableSelectAllMessage,
  nbHits,
  sx,
  onClearSelection,
  onSelectAll
}: Props) {
  const { isMobile } = useResponsiveState()
  const { setValue: setIsLoading, value: isLoading } = useBooleanState(false)
  const handleSelectAll = useCallback(() => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    onSelectAll?.().finally(() => {
      setIsLoading(false)
    })
  }, [isLoading, onSelectAll, setIsLoading])

  return onSelectAll || onClearSelection ? (
    <Stack direction="row" alignItems="center" gap={1} ml="auto" mt={2} sx={sx}>
      {!!onClearSelection && !isLoading && (
        <Button data-testid="infinite-hits--clear-selection-button" onClick={onClearSelection}>
          Clear selection
        </Button>
      )}
      {!!onSelectAll && (
        <Tooltip title={(!isMobile && disableSelectAll && disableSelectAllMessage) ?? ''} arrow placement="top-end">
          <span>
            <LoadingButton
              data-testid="infinite-hits--select-all-button"
              loading={isLoading}
              disabled={disableSelectAll}
              onClick={handleSelectAll}
            >
              Select all results
              {!isLoading && !disableSelectAll && !!nbHits && (
                <Typography fontSize={12} color="GrayText" ml={1}>
                  ({toHumanString(nbHits)})
                </Typography>
              )}
            </LoadingButton>
          </span>
        </Tooltip>
      )}
    </Stack>
  ) : null
})
