import { apiClient } from '@client/services/api/api-client'
import { TrackingEvent } from '@lib/types/tracking'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'

const track = apiClient.tracking.track.mutate

// Utility hook to keep track of the active tab on different pages.
// This is a slight hack: we use the `tab` query param on some pages and `selectedTab` on others.
const useTab = () => {
  // SE, Wholesale, Brand Detail, Dispensary Detail, Account Details, User Details
  const [tab] = useQueryParam('tab', StringParam)

  // Reports, Dashboards
  const [selectedTab] = useQueryParam('selectedTab', StringParam)

  const activeTab = useMemo(() => tab || selectedTab, [selectedTab, tab])

  return { activeTab }
}

export const PageTracker: React.FC = () => {
  const { pathname } = useLocation()
  const { activeTab } = useTab()

  useEffect(() => {
    const properties: Record<string, any> = { pathname }
    if (activeTab) {
      properties.activeTab = activeTab
    }
    track({
      eventName: TrackingEvent.pageView,
      properties
    })
  }, [pathname, activeTab])

  return null
}
