import { RefObject, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

interface Size {
  width: number
  height: number
}

// usehooks-ts has its own implementation of useElementSize, but it doesn't keep on track of
// changes on the content size, just on window resize. This implementation uses ResizeObserver
// to keep track of changes on the content size.
// https://github.com/juliencrn/usehooks-ts/issues/236
// Original useElementSize implementation: https://usehooks-ts.com/react-hook/use-element-size
export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [RefObject<T>, Size] {
  const ref = useRef<T>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  })

  useIsomorphicLayoutEffect(() => {
    const updateSize = (element: Element | null) => {
      const { width, height } = element?.getBoundingClientRect() ?? {
        width: 0,
        height: 0
      }
      setSize({ width, height })
    }

    updateSize(ref.current)

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0]
      if (entry) {
        updateSize(entry.target)
      }
    })

    ref.current && resizeObserver.observe(ref.current)
    return () => {
      ref.current && resizeObserver.unobserve(ref.current)
    }
  }, [ref.current])

  return [ref, size]
}
