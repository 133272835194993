import { getRandomInt } from './numbers'

export const firstNames = ['Cameron', 'Maya', 'Diego', 'Morgan', 'Kai', 'Isabella']

export const lastNames = ['Williams', 'Patel', 'Rodriguez', 'Turner', 'Davis', 'Lee']

// A list of all combinations of first and last names
export const names = firstNames.flatMap((firstName) => lastNames.map((lastName) => `${firstName} ${lastName}`))

export const getRandomName = () => names[getRandomInt(0, names.length - 1)]
