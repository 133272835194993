// This is a list of known permission codes.

export enum Permission {
  DENY = 'DENY', // This is a special permission code that is used to deny access to a user
  ACCOUNT_USER_VIEW = 'ACCOUNT_USER_VIEW',
  ACCOUNT_USER_CREATE = 'ACCOUNT_USER_CREATE',
  ACCOUNT_USER_EDIT = 'ACCOUNT_USER_EDIT',
  ACCOUNT_USER_DELETE = 'ACCOUNT_USER_DELETE',
  ACCOUNT_USER_SUSPEND = 'ACCOUNT_USER_SUSPEND',
  HOODIE_SUPER_ADMIN = 'HOODIE_SUPER_ADMIN',
  MODULE_ACCESS_WTB = 'MODULE_ACCESS_WTB',
  MODULE_ACCESS_PIM = 'MODULE_ACCESS_PIM',
  MODULE_ACCESS_SALES_ENABLEMENT = 'MODULE_ACCESS_SALES_ENABLEMENT',
  MODULE_ACCESS_COMPETITIVE_INTELLIGENCE = 'MODULE_ACCESS_COMPETITIVE_INTELLIGENCE',
  MODULE_ACCESS_DISPENSARY_ANALYTICS = 'MODULE_ACCESS_DISPENSARY_ANALYTICS',
  MODULE_ACCESS_INTEGRATIONS = 'MODULE_ACCESS_INTEGRATIONS',
  MODULE_ACCESS_WHOLESALE = 'MODULE_ACCESS_WHOLESALE',
  MODULE_ACCESS_MARKET_INTELLIGENCE = 'MODULE_ACCESS_MARKET_INTELLIGENCE',
  MARKET_MEASUREMENT_DATA_READ = 'MARKET_MEASUREMENT_DATA_READ',
  MASTER_PRODUCT_READ = 'MASTER_PRODUCT_READ',
  PRODUCT_DETAILS_READ = 'PRODUCT_DETAILS_READ',
  PRODUCT_EVENT_READ = 'PRODUCT_EVENT_READ',
  BRAND_DETAILS_READ = 'BRAND_DETAILS_READ',
  BRAND_ANALYTICS = 'BRAND_ANALYTICS',
  BRAND_LEADERBOARD_READ = 'BRAND_LEADERBOARD_READ',
  WTB_CONFIG_VIEW_SNIPPET = 'WTB_CONFIG_VIEW_SNIPPET',
  WTB_CONFIG_DISABLE = 'WTB_CONFIG_DISABLE',
  WTB_CONFIG_SUPER_ADMIN = 'WTB_CONFIG_SUPER_ADMIN',
  LIVE_UPDATE_EXECUTE = 'LIVE_UPDATE_EXECUTE',
  NOTIFICATION_PREFERENCE_CREATE = 'NOTIFICATION_PREFERENCE_CREATE',
  DA_NOTIFICATION_PREFERENCE_CREATE = 'DA_NOTIFICATION_PREFERENCE_CREATE',
  CUSTOM_TERRITORY_EDIT = 'CUSTOM_TERRITORY_EDIT',
  EXPORT_DATA = 'EXPORT_DATA',
  EXPORT_DATA_CUSTOMIZATION = 'EXPORT_DATA_CUSTOMIZATION',
  REPORTS_EXPORT = 'REPORTS_EXPORT',
  REPORTS_READ = 'REPORTS_READ',
  REPORTS_SCHEDULE = 'REPORTS_SCHEDULE',
  SIMULATE_WHOLESALE_DATA = 'SIMULATE_WHOLESALE_DATA',
  AUDIT_LOG_READ = 'AUDIT_LOG_READ',
  API_KEY_CREATE = 'API_KEY_CREATE',
  TAGS_BULK_EDIT = 'TAGS_BULK_EDIT',
  TAGS_DELETE = 'TAGS_DELETE',
  ASSORTMENTS_EDIT = 'ASSORTMENTS_EDIT',
  RELATIONSHIPS_EDIT = 'RELATIONSHIPS_EDIT',
  INTEGRATION_PROFILE_READ = 'INTEGRATION_PROFILE_READ',
  INTEGRATION_PROFILE_CREATE = 'INTEGRATION_PROFILE_CREATE',
  INTEGRATION_PROFILE_DELETE = 'INTEGRATION_PROFILE_DELETE',
  INTEGRATION_PROFILE_ENABLE_DISABLE = 'INTEGRATION_PROFILE_ENABLE_DISABLE',
  INTEGRATION_PROFILE_UPDATE = 'INTEGRATION_PROFILE_UPDATE',
  INTEGRATION_PROFILE_SUPER_ADMIN = 'INTEGRATION_PROFILE_SUPER_ADMIN'
}
