import create from 'zustand'
import { devtools } from 'zustand/middleware'

export interface SessionStateStore {
  allSessionData: Record<string, any>
  getSessionData: <T>(key: string) => T
  setSessionData: <T>(key: string, value: T) => void
  clearSessionData: (key: string) => void
  clearAllSessionData: () => void
}

// This is like useAppStateStore, but for session data that is not persisted
export const useSessionStateStore = create(
  devtools<SessionStateStore>(
    (set, get) => ({
      allSessionData: {},
      getSessionData: (key) => {
        return get().allSessionData[key]
      },
      setSessionData: (key, value) =>
        set((state) => {
          return { ...state, allSessionData: { ...state.allSessionData, [key]: value } }
        }),
      clearSessionData: (key) =>
        set((state) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [key]: _, ...allSessionData } = state.allSessionData
          return { allSessionData }
        }),
      clearAllSessionData: () =>
        set(() => {
          return { allSessionData: {} }
        })
    }),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: `@hoodie:session-state-store`
    }
  )
)

export const useSetSessionStateData = () => useSessionStateStore((state) => state.setSessionData)
export const useGetSessionStateData = () => useSessionStateStore((state) => state.getSessionData)
