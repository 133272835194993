import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box } from '@client/styles/theme/box'
import { Edit, SaveOutlined } from '@mui/icons-material'
import { BoxProps, Button, IconButton, TypographyProps } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import * as S from './EditableTitle.style'

export interface EditableTitleProps {
  title: string
  placeholder?: string
  allowEmpty?: boolean
  props?: TypographyProps
  BoxProps?: BoxProps
  variant?: 'complete' | 'compact'
  size?: 'small' | 'medium' | 'large'
  buttonLabel?: string
  onChange: (title: string) => void
}

export const EditableTitle: React.FC<EditableTitleProps> = ({
  title: initialTitle,
  placeholder = '',
  allowEmpty,
  props,
  BoxProps,
  size = 'small',
  variant = 'compact',
  buttonLabel = 'Save',
  onChange
}) => {
  const ref = useRef<HTMLParagraphElement>(null)
  const [title, setTitle] = useState(initialTitle)
  const { value: editMode, setTrue: enableEditMode, setFalse: disableEditMode } = useBooleanState(false)
  const { value: hasError, setTrue: showError, setFalse: hideError } = useBooleanState(false)

  const fontSize = useMemo(() => (size === 'medium' ? 18 : size === 'large' ? 24 : 14), [size])

  const handleSubmit = useCallback(
    (newTitle: string) => {
      if (newTitle.trim().length || allowEmpty) {
        setTitle(newTitle)
        disableEditMode()
      } else {
        showError()
      }
    },
    [allowEmpty, disableEditMode, setTitle, showError]
  )

  const onBlur = useCallback(() => {
    handleSubmit(ref.current?.textContent ?? '')
  }, [handleSubmit])

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (variant === 'compact') {
          ref.current?.removeEventListener('blur', onBlur)
        }
        disableEditMode()
      } else if (event.key === 'Enter') {
        event.preventDefault()
        handleSubmit(ref.current?.textContent ?? '')
      }
    },
    [disableEditMode, handleSubmit, onBlur, variant]
  )

  const onTextChange = useCallback(() => {
    if (ref.current?.textContent?.trim().length) {
      hideError()
    } else {
      showError()
    }
  }, [hideError, showError])

  useEffect(() => {
    disableEditMode()
  }, [disableEditMode, title])

  useUpdateEffect(() => {
    if (initialTitle !== title) {
      setTitle(initialTitle)
    }
  }, [initialTitle])

  useEffect(() => {
    if (ref.current) {
      if (editMode) {
        ref.current.focus()
        // Move the cursor to the end of the field
        document.getSelection()?.selectAllChildren(ref.current)
        document.getSelection()?.collapseToEnd()
        // Move the scroll to the end if it's scrollable
        if (ref.current.scrollWidth > ref.current.clientWidth) {
          ref.current.scrollLeft = ref.current.scrollWidth - ref.current.clientWidth
        }

        // Add listeners
        if (!allowEmpty) {
          ref.current.addEventListener('keyup', onTextChange)
        }
        if (variant === 'compact') {
          ref.current.addEventListener('blur', onBlur)
        }
        ref.current.addEventListener('keydown', onKeyDown)
      } else {
        ref.current.scrollLeft = 0
        if (!allowEmpty) {
          ref.current.removeEventListener('keyup', onTextChange)
        }
        if (variant === 'compact') {
          ref.current.removeEventListener('blur', onBlur)
        }
        ref.current.removeEventListener('keydown', onKeyDown)
        hideError()
      }
    }
  }, [onBlur, onKeyDown, hideError, editMode, allowEmpty, variant, onTextChange])

  useEffect(() => {
    if (ref.current && !editMode) {
      // If empty, show placeholder
      ref.current.textContent = title || placeholder
      if (title !== initialTitle) {
        onChange(title)
      }
    } else if (editMode && ref.current?.textContent === placeholder) {
      // If in edit mode and placeholder is shown, clear it
      ref.current.textContent = ''
    }
  }, [editMode, title, onChange, placeholder, initialTitle])

  return (
    <Box
      alignItems="flex-start"
      display="inline-grid"
      gridTemplateColumns="1fr auto"
      gap={variant === 'compact' ? 1 : 2}
      {...BoxProps}
      onClick={(e) => editMode && e.stopPropagation()}
    >
      <Box minWidth={0} position="relative">
        <S.EditableTitle
          {...props}
          contentEditable={editMode}
          ref={ref}
          suppressContentEditableWarning
          data-testid="editable-title"
        />
        <S.InputBorder $hasError={hasError} $isFocused={editMode} />
      </Box>
      {variant === 'compact' && (
        <IconButton onClick={enableEditMode} size={size} disabled={editMode} data-testid="editable-title-button">
          <Edit sx={{ fontSize }} />
        </IconButton>
      )}
      {variant === 'complete' && (
        <>
          {editMode ? (
            <Box display="flex" gap={1}>
              <Button
                onClick={onBlur}
                variant="contained"
                color="primary"
                size={size}
                data-testid="editable-title-save-button"
              >
                Save
              </Button>
              <Button onClick={disableEditMode} size={size} data-testid="editable-title-cancel-button">
                Cancel
              </Button>
            </Box>
          ) : (
            <S.Button
              onClick={(e) => {
                e.stopPropagation()
                enableEditMode()
              }}
              size={size}
              data-testid="editable-title-button"
              startIcon={<SaveOutlined />}
            >
              {buttonLabel}
            </S.Button>
          )}
        </>
      )}
    </Box>
  )
}
