import { initGraphQLTada } from 'gql.tada'
import type { introspection } from '../../../typings/graphql-env.d.ts'

export { readFragment } from 'gql.tada'
export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada'

export const graphql = initGraphQLTada<{
  introspection: introspection
  disableMasking: true
  scalars: {
    uuid: string
    timestamptz: string
    jsonb: any
  }
}>()
