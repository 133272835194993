import { useEventTracker } from '@client/hooks/use-event-tracker'
import { TrackableProps } from '@client/types/tracking'
import { CardActionAreaProps } from '@mui/material'
import { ComponentType, ForwardRefExoticComponent, PropsWithChildren, Ref, RefAttributes } from 'react'

export type WithLinkComponentProps = CardActionAreaProps<
  'button',
  { href: string; target?: string; LinkComponent: React.ElementType }
>

export type ClickEventTrackerBaseProps = {
  onClick?: (...args: any) => void
  innerRef?: Ref<HTMLAnchorElement | HTMLButtonElement>
} & TrackableProps

export function withClickEventTracker<TProps extends object>(
  Component:
    | ComponentType<PropsWithChildren<TProps>>
    | ForwardRefExoticComponent<TProps & RefAttributes<HTMLAnchorElement | HTMLButtonElement>>
) {
  return function WrappedWithClickTracking({
    gaEvent,
    gaLabel,
    onClick,
    innerRef,
    ...props
  }: TProps & ClickEventTrackerBaseProps) {
    const { handleTracking } = useEventTracker({ gaEvent, gaLabel, callback: onClick, isClickable: true })
    return <Component ref={innerRef} {...(props as TProps)} onClick={handleTracking} />
  }
}
