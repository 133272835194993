import { TempFilters, useSavedViews } from '@client/providers/saved-views'
import { ALGOLIA_INDEX } from '@client/services/algolia'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { Comparison } from '@client/types/comparison'
import { DraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { useCallback } from 'react'

type CustomFilterset = TempFilters & {
  index?: ALGOLIA_INDEX
  disableViews?: boolean
  onApply: (filterset: DraftFilterset) => void
}

export const useDrawers = () => {
  const setDrawer = useDrawerState((state) => state.setDrawer)
  const { currentFilterset, tempFilters } = useSavedViews()

  const openFiltersDrawer = useCallback(
    (customFilterset?: CustomFilterset, prevDrawer?: any) => {
      const state: Record<string, unknown> = { prevDrawer }
      if (customFilterset) {
        const { onApply, index, filterset, disableViews, ...tempFilters } = customFilterset
        state.currentFilterset = filterset
        state.tempFilters = tempFilters
        state.onApply = onApply
        state.index = index
        state.disableViews = disableViews
      } else {
        state.currentFilterset = currentFilterset
        state.tempFilters = tempFilters
      }
      setDrawer({ id: DrawerId.filters, state })
    },
    [currentFilterset, setDrawer, tempFilters]
  )

  const openViewsDrawer = useCallback(() => setDrawer({ id: DrawerId.views }), [setDrawer])

  const openComparisonsDrawer = useCallback(() => setDrawer({ id: DrawerId.comparisons }), [setDrawer])

  const openFavoritesDrawer = useCallback(() => setDrawer({ id: DrawerId.favorites }), [setDrawer])

  const openSaveViewDrawer = useCallback(
    (filtersetToSave: DraftFilterset, prevDrawer: any) => {
      setDrawer({ id: DrawerId.saveView, state: { filterset: filtersetToSave, prevDrawer } })
    },
    [setDrawer]
  )

  const openShareViewDrawer = useCallback(
    (filtersetToSave: DraftFilterset, prevDrawer: any) => {
      setDrawer({ id: DrawerId.shareView, state: { filterset: filtersetToSave, prevDrawer } })
    },
    [setDrawer]
  )

  const openShareComparisonDrawer = useCallback(
    (comparison: Comparison, prevDrawer: any) => {
      setDrawer({ id: DrawerId.shareComparison, state: { comparison, prevDrawer } })
    },
    [setDrawer]
  )

  return {
    openComparisonsDrawer,
    openFavoritesDrawer,
    openFiltersDrawer,
    openViewsDrawer,
    openShareViewDrawer,
    openShareComparisonDrawer,
    openSaveViewDrawer
  }
}
