import { DocumentTransform } from '@apollo/client'
import { GridFilterItem, GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DocumentNode, visit } from 'graphql'
import { z } from 'zod'
import { set } from './objects'

dayjs.extend(utc)

export const setOperationName = (query: DocumentNode, operationName: string) => {
  const transform = new DocumentTransform((doc) => {
    const newDoc = visit(doc, {
      OperationDefinition(node) {
        return {
          ...node,
          name: {
            kind: 'Name',
            value: operationName
          }
        }
      }
    })
    return newDoc
  })
  return transform.transformDocument(query)
}

export const addSearchTerm = (
  where: any,
  paths: string | string[],
  searchTerm?: string,
  caseSensitive = false,
  minChars = 1
) => {
  if (searchTerm && searchTerm.length >= minChars) {
    if (typeof paths === 'string') {
      set(where, `${paths}._${caseSensitive ? '' : 'i'}like`, `%${searchTerm}%`)
    } else {
      where._or = where._or || []
      paths.forEach((path) => {
        where._or.push(set({}, `${path}._${caseSensitive ? '' : 'i'}like`, `%${searchTerm}%`))
      })
    }
  }
  return where
}

type WhereClause = '_eq' | '_gte' | '_lte' | '_gt' | '_lt' | '_neq' | '_ilike' | '_like' | '_nilike' | '_is_null'

export const addWhereClause = (
  where: any,
  path: string,
  value: string | string[] | number | boolean,
  rule: WhereClause = '_eq'
) => {
  if (Array.isArray(value)) {
    set(where, `${path}._in`, value)
  } else {
    set(where, `${path}.${rule}`, value)
  }
  return where
}

export const addOrWhereClause = (where: Record<string, unknown>, path: string, rules: Record<string, unknown>[]) => {
  set(where, `${path}._or`, rules)
  return where
}

// A generic method for returning a Zod schema for a sort item.
// This structure also matches MUI-X's DataGrid sort model.
export const sortItemSchema = <T extends string>(fieldSchema: z.ZodType<T>) =>
  z.object({
    field: fieldSchema,
    sort: z.enum(['asc', 'desc', 'asc_nulls_first', 'asc_nulls_last', 'desc_nulls_first', 'desc_nulls_last'])
  })

export type HasuraConditionObject =
  | {
      _eq: string | number | boolean
    }
  | {
      _neq: string | number | boolean
    }
  | {
      _gt: string | number
    }
  | {
      _lt: string | number
    }
  | {
      _gte: string | number
    }
  | {
      _lte: string | number
    }
  | {
      _like: string
    }
  | {
      _ilike: string
    }
  | {
      _in: Array<string | number | boolean>
    }
  | {
      _is_null: boolean
    }

const numberOperators = ['=', '!=', '>', '<', '>=', '<=']
// const stringOperators = ['contains', 'equals', 'startsWith', 'endsWith']
const booleanOperators = ['is']
const dateOperators = ['is', 'not', 'after', 'before', 'onOrAfter', 'onOrBefore']

// TBD: Should this function live here or in a MUI-X helper file?
export const hasuraClauseFromMUIX = (item: GridFilterItem): HasuraConditionObject | undefined => {
  if (item.value === undefined) {
    switch (item.operator) {
      case 'isEmpty':
        return { _is_null: true }
      case 'isNotEmpty':
        return { _is_null: false }
      default:
        return undefined
    }
  }

  if (Array.isArray(item.value)) {
    // Value is an array
    if (item.operator === 'isAnyOf') {
      return { _in: item.value }
    }
    throw new Error(`Unexpected MUI-X operator: ${item.operator}`)
  }

  if (
    item.value instanceof Date ||
    (typeof item.value === 'string' && !isNaN(Date.parse(item.value)) && dateOperators.includes(item.operator))
  ) {
    // Value is a date or a string representation of a date
    const value = typeof item.value === 'string' ? item.value : item.value.toISOString()
    const startOfDay = dayjs(value).utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
    const endOfDay = dayjs(value).utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
    switch (item.operator) {
      case 'is':
        return { _gte: startOfDay, _lte: endOfDay }
      case 'not':
        return { _neq: value }
      case 'after':
        return { _gt: endOfDay }
      case 'before':
        return { _lt: startOfDay }
      case 'onOrAfter':
        return { _gte: startOfDay }
      case 'onOrBefore':
        return { _lte: endOfDay }
      default:
        throw new Error(`Unexpected MUI-X operator: ${item.operator}`)
    }
  }

  if (
    (typeof item.value === 'number' || (typeof item.value === 'string' && !isNaN(Number(item.value)))) &&
    numberOperators.includes(item.operator)
  ) {
    // Value is a number or a string representation of a number
    const value = Number(item.value)
    switch (item.operator) {
      case '=':
        return { _eq: value }
      case '!=':
        return { _neq: value }
      case '>':
        return { _gt: value }
      case '<':
        return { _lt: value }
      case '>=':
        return { _gte: value }
      case '<=':
        return { _lte: value }
      default:
        throw new Error(`Unexpected MUI-X operator: ${item.operator}`)
    }
  }

  if (
    (typeof item.value === 'boolean' ||
      (typeof item.value === 'string' && (item.value === 'true' || item.value === 'false'))) &&
    booleanOperators.includes(item.operator)
  ) {
    // Value is a boolean or a string representation of a boolean
    const value = item.value === true || item.value === 'true'
    if (item.operator === 'is') {
      return { _eq: value }
    }
    throw new Error(`Unexpected MUI-X operator: ${item.operator}`)
  }

  // Value is a string
  switch (item.operator) {
    case 'contains':
      return { _ilike: `%${item.value}%` }
    case 'equals':
      return { _eq: item.value }
    case 'startsWith':
      return { _ilike: `${item.value}%` }
    case 'endsWith':
      return { _ilike: `%${item.value}` }
    default:
      throw new Error(`Unexpected MUI-X operator: ${item.operator}`)
  }
}

export const addFiltersToWhereClause = (where: Record<string, unknown>, filters: GridFilterModel) => {
  const clauses: Array<{ key: string; clause: HasuraConditionObject }> = []

  filters.items.forEach((filter) => {
    const hasuraClause = hasuraClauseFromMUIX(filter)
    if (hasuraClause) {
      clauses.push({ key: filter.field, clause: hasuraClause })
    }
  })

  if (clauses.length) {
    const logicOperator = filters.logicOperator === GridLogicOperator.And ? '_and' : '_or'
    if (!where[logicOperator]) {
      where[logicOperator] = []
    }
    const options = where[logicOperator] as Array<{ [key: string]: HasuraConditionObject }>
    clauses.forEach(({ key, clause }) => {
      const item = set({}, key, clause)
      options.push(item)
    })
  }

  return where
}
