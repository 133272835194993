import { FeedbackWidget } from '@client/components/feedback-widget'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useAppStateStore } from '@client/stores/use-app-state-store'
import { Help } from '@mui/icons-material'
import { Collapse, Fade, Typography } from '@mui/material'
import { memo } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { HelpOverlay } from '../help-overlay/HelpOverlay'
import * as S from './HelpFloatingButton.style'

export const HelpFloatingButton: React.FC = memo(function HelpFloatingButton() {
  const { isMobile } = useResponsiveState()
  const { isMobile: isSmall } = useResponsiveState('lg')
  const { isMobile: isMedium } = useResponsiveState('xl')

  const { value: isOpen, setTrue: openOverlay, setFalse: closeOverlay } = useBooleanState(false)
  const { value: isFeedbackOpen, setTrue: openFeedback, setFalse: closeFeedback } = useBooleanState(false)
  const { value: isExpanded, setTrue: expandButton, setFalse: collapseButton } = useBooleanState(false)

  const hasWiderContent = useAppStateStore((state) => state.allAppData.WIDER_CONTENT)
  const displayAsFab = !isSmall && !isMedium && !hasWiderContent
  const canExpand = !isMobile && !isOpen

  const Button = (displayAsFab ? S.FabHelpButton : S.HelpButton) as React.ElementType
  const fontSize = isMobile ? 16 : isSmall ? 18 : displayAsFab ? 26 : 22

  useUpdateEffect(() => {
    if (isOpen) {
      collapseButton()
    }
  }, [isMobile, isOpen])

  return (
    <>
      <Fade in={!isOpen}>
        <S.HelpButtonWrapper
          onClick={openOverlay}
          onMouseEnter={canExpand ? expandButton : undefined}
          onMouseLeave={canExpand ? collapseButton : undefined}
          pr={displayAsFab ? 3 : undefined}
        >
          <Button data-testid="help-floating-button">
            <Collapse orientation="horizontal" in={isExpanded}>
              <Typography
                variant={isSmall ? 'caption' : 'body1'}
                noWrap
                px={displayAsFab ? undefined : 1}
                pr={displayAsFab ? 2 : undefined}
                data-testid="help-floating-button--text"
              >
                Help on this page
              </Typography>
            </Collapse>
            <Help sx={{ fontSize }} />
          </Button>
        </S.HelpButtonWrapper>
      </Fade>
      <FeedbackWidget isOpen={isFeedbackOpen} onClose={closeFeedback} />
      <HelpOverlay isOpen={isOpen} onClose={closeOverlay} onFeedbackClick={openFeedback} />
    </>
  )
})
