import { slugify } from '@client/helpers/strings'
import { ButtonProps } from '@mui/material'
import { SpacingProps } from '@mui/system'
import HRN from 'human-readable-numbers'
import { forwardRef, MouseEvent } from 'react'
import * as S from './FilterPill.style'

interface FilterPillProps extends SpacingProps, Omit<ButtonProps, 'onClick'> {
  label: string
  count?: number
  isActive: boolean
  onClick: (event?: MouseEvent<HTMLElement>) => void
  overrideColor?: string
}

export const FilterPill = forwardRef<HTMLButtonElement, FilterPillProps>(function FilterPill(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { overrideColor, label, count, onClick, isActive, ...rest },
  ref
) {
  return (
    <S.FilterPill
      sx={{
        ...rest.sx,
        backgroundColor: isActive ? 'success' : 'white'
      }}
      ref={ref}
      variant={isActive ? 'contained' : 'outlined'}
      color="success"
      size="small"
      onClick={(event) => onClick(event)}
      data-testid={`filter-pill-${slugify(label)}`}
      {...rest}
    >
      {label}
      {!!count && (
        <S.Chip label={HRN.toHumanString(count.toString())} variant="outlined" size="small" checked={isActive} />
      )}
    </S.FilterPill>
  )
})
export default FilterPill
