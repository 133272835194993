import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  SxProps,
  TextField,
  Theme
} from '@mui/material'
import { ReactNode, memo, useEffect, useMemo, useState } from 'react'
import * as S from './ConfirmationDialog.style'

export interface ConfirmationDialogProps extends Omit<DialogProps, 'title' | 'content'> {
  title: ReactNode
  content: ReactNode
  open: boolean
  loading?: boolean
  loadingIndicator?: ReactNode
  disableConfirmButton?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  onCancel?: () => void
  confirmInputText?: string
  confirmInputTextLabel?: string
  danger?: boolean
  alertMessage?: ReactNode
  alertSeverity?: AlertProps['severity']
  contentSx?: SxProps<Theme>
  leftSideActions?: ReactNode
  denseTitle?: boolean
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = memo(function RemoveUserDialog({
  title,
  content,
  open,
  loading,
  loadingIndicator,
  disableConfirmButton,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  onCancel,
  confirmInputText,
  confirmInputTextLabel,
  danger,
  alertMessage,
  alertSeverity = 'warning',
  contentSx,
  leftSideActions,
  denseTitle = false,
  ...rest
}) {
  const [inputVal, setInputVal] = useState('')
  const [inputText, setInputText] = useState('')
  const classes = S.useStyles()
  useEffect(() => {
    if (open) {
      setInputVal('')
      setInputText(confirmInputText || '')
    }
  }, [open, confirmInputText])

  const disableConfirm = useMemo(() => {
    return (
      disableConfirmButton ||
      (!!inputText && (inputText || '').toLowerCase() !== (inputVal || '').replace(/^"|"$/g, '').toLowerCase())
    )
  }, [disableConfirmButton, inputText, inputVal])

  return (
    <Dialog maxWidth={'md'} {...rest} open={open} aria-label="confirmation-dialog">
      <DialogTitle sx={denseTitle ? { padding: 0 } : undefined}>{title}</DialogTitle>
      <DialogContent sx={contentSx}>
        {alertMessage && (
          <Alert sx={{ mb: 4 }} severity={alertSeverity} data-testid="confirm-action--alert">
            {alertMessage}
          </Alert>
        )}
        {typeof content == 'string' ? <DialogContentText>{content}</DialogContentText> : content}
        {inputText && (
          <TextField
            label={confirmInputTextLabel || `Please enter "${inputText}" to confirm`}
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            style={{ minWidth: '300px', marginTop: '20px' }}
            inputProps={{ 'aria-label': 'dialog-confirm-input', 'data-testid': 'confirm-action--input' }}
          />
        )}
      </DialogContent>
      <DialogActions className={leftSideActions ? classes.actionsContainer : undefined}>
        {leftSideActions && <Box className={classes.leftSideContainer}>{leftSideActions}</Box>}
        {onCancel && (
          <Button variant="text" onClick={onCancel} disabled={loading} data-testid="confirmation-dialog--cancel-action">
            {cancelButtonText}
          </Button>
        )}
        <LoadingButton
          onClick={onConfirm}
          variant="contained"
          color={!danger ? 'primary' : 'error'}
          loading={loading}
          loadingIndicator={loadingIndicator}
          data-testid="confirm-action"
          role="button"
          disabled={disableConfirm}
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
})
