import { Button, ButtonProps, LinkProps, Typography } from '@mui/material'
import { Fragment, memo } from 'react'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { typography } from '@client/styles/theme/typography'

interface DetailsPlaceholderProps {
  text: string | React.ReactNode
  buttons?: Array<ButtonProps & { 'data-testid'?: string; target?: LinkProps['target'] }>
  Icon?: React.ElementType
}

export const DetailsPlaceholder: React.FC<DetailsPlaceholderProps> = memo(function TextBox({
  Icon,
  text,
  buttons,
  ...props
}) {
  const { isMobile } = useResponsiveState()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
      px={4}
      py={isMobile ? 10 : 20}
      data-testid="details-placeholder"
      {...props}
    >
      {Icon && <Icon fontSize="large" sx={{ color: palette.mediumdark }} />}
      <Box
        fontSize={typography.size.s2}
        color={palette.dark}
        textAlign="center"
        data-testid="details-placeholder--text"
      >
        {text}
      </Box>
      {buttons?.map((button, index) => (
        <Fragment key={index}>
          <Button key={index} size={isMobile ? 'small' : 'medium'} variant="contained" color="primary" {...button} />
          {index < buttons.length - 1 && (
            <Typography
              fontSize={typography.size.s2}
              color={palette.dark}
              textAlign="center"
              data-testid="details-placeholder--button-separator"
            >
              or
            </Typography>
          )}
        </Fragment>
      ))}
    </Box>
  )
})
