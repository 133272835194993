import { formatDayMonthDateClean } from '@client/helpers/dates'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { IconButton } from '@client/styles/theme/icon-button'
import { DraftNotificationPreference } from '@client/types/notification'
import { Snooze } from '@mui/icons-material'
import { Popover, PopoverProps, Tooltip, TooltipProps } from '@mui/material'
import { memo } from 'react'
import { NotificationSnooze, NotificationSnoozeProps } from './index'

export interface NotificationSnoozeButtonProps extends Pick<NotificationSnoozeProps, 'context'> {
  notificationPreference: DraftNotificationPreference
  popoverProps?: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>
  tooltipPlacement?: TooltipProps['placement']
  disabled?: boolean
}

export const NotificationSnoozeButton: React.FC<NotificationSnoozeButtonProps> = memo(
  function NotificationSnoozeButton({
    notificationPreference,
    context,
    popoverProps,
    tooltipPlacement = 'top',
    disabled
  }) {
    const { menuAnchor, handleClick, handleClose } = useMenuAnchor()
    const isSnoozed = notificationPreference.disabledUntil && notificationPreference.disabledUntil > new Date()
    return (
      <>
        <Tooltip
          placement={tooltipPlacement}
          arrow
          title={
            isSnoozed
              ? `Snoozed until ${formatDayMonthDateClean(notificationPreference.disabledUntil as Date)}`
              : 'Snooze this alert'
          }
        >
          <IconButton
            color="snooze"
            data-testid="notification-snooze-button"
            variant={isSnoozed ? 'contained' : undefined}
            disabled={disabled}
            size="small"
            onClick={handleClick}
          >
            <Snooze fontSize="small" />
          </IconButton>
        </Tooltip>
        <Popover
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...popoverProps}
          onClose={() => handleClose()}
        >
          <NotificationSnooze
            subtitle="Snooze this alert..."
            notificationPreferenceId={notificationPreference.id as string}
            context={context}
            onSnooze={() => handleClose()}
            onUnsnooze={() => handleClose()}
          />
        </Popover>
      </>
    )
  }
)
