import { EventArgs } from '@client/configs/tracking'
import type { RequireExactlyOne } from 'type-fest'

export type TrackableProps = RequireExactlyOne<{
  gaEvent?: EventArgs
  gaLabel?: TrackableLabel
}>

export enum TrackableAction {
  submit = 'submit',
  open = 'open',
  close = 'close',
  skip = 'skip',
  back = 'back',
  primary = 'primary',
  onboardingCta = 'onboarding-cta',
  clickOutboundLink = 'click-outbound-link',
  comparisonCreateClicked = 'comparison-create-clicked',
  comparisonCreated = 'comparison-created',
  comparisonDeleted = 'comparison-deleted',
  comparisonDocumentationClicked = 'comparison-documentation-clicked',
  comparisonEditClicked = 'comparison-edit-clicked',
  comparisonGuidedTourClicked = 'comparison-guided-tour-clicked',
  comparisonHelpClicked = 'comparison-help-clicked',
  comparisonItemClicked = 'comparison-item-clicked',
  comparisonReportClicked = 'comparison-report-clicked',
  comparisonSaved = 'comparison-saved',
  comparisonSelected = 'comparison-selected',
  comparisonShared = 'comparison-shared',
  comparisonTransferred = 'comparison-transferred',
  comparisonUnshared = 'comparison-unshared',
  dashboardKpiClicked = 'dashboard-kpi-clicked',
  dateRangeSelected = 'date-range-selected',
  dateRangeShortcutSelected = 'date-range-shortcut-selected',
  dispensariesKpiFilterSelected = 'dispensaries-kpi-filter-selected',
  dispensariesSortChanged = 'dispensaries-sort-changed',
  dispensaryCardClicked = 'dispensary-card-clicked',
  dispensaryDetailsTabSelected = 'dispensary-details-tab-selected',
  dispensaryTableRowClicked = 'dispensary-table-row-clicked',
  dispensaryKpiClicked = 'dispensary-kpi-clicked',
  dispensaryPopupClick = 'dispensary-popup-clicked',
  dispensaryTabSelected = 'dispensary-tab-selected',
  dispensaryViewSelected = 'dispensary-view-selected',
  dispensaryShowProducts = 'dispensary-show-products',
  dispensaryHideProducts = 'dispensary-hide-products',
  eventCardDispensaryLinkClicked = 'event-card-dispensary-link-clicked',
  eventCardProductLinkClicked = 'event-card-product-link-clicked',
  eventCodeFilterChanged = 'event-code-filter-changed',
  eventCodesFiltersCleared = 'event-codes-filters-cleared',
  eventSortChanged = 'event-sort-changed',
  favoriteClicked = 'favorite-clicked',
  globalFiltersActionApply = 'global-filters-action-apply',
  globalFiltersActionSaveAsNewView = 'global-filters-action-save-as-new-view',
  globalFiltersActionUpdateExistingView = 'global-filters-action-update-existing-view',
  globalFiltersCleared = 'global-filters-cleared',
  globalSearchByTerm = 'global-search-by-term',
  globalSearchDispensaryClicked = 'global-search-dispensary-clicked',
  globalSearchBrandClicked = 'global-search-brand-clicked',
  globalSearchMasterProductClicked = 'global-search-master-product-clicked',
  integrationCreated = 'integration-created',
  integrationCredentialsClicked = 'integration-credentials-clicked',
  integrationDeleted = 'integration-deleted',
  integrationDisabled = 'integration-disabled',
  integrationEnabled = 'integration-enabled',
  integrationInstructionClicked = 'integration-instruction-clicked',
  integrationLogsClicked = 'integration-logs-clicked',
  integrationSaved = 'integration-saved',
  integrationTested = 'integration-tested',
  landingDashboardsClicked = 'landing-dashboards-clicked',
  landingSalesEnablementClicked = 'landing-sales-enablement-clicked',
  landingUserGuideClicked = 'landing-user-guide-clicked',
  landingWhereToBuyClicked = 'landing-where-to-buy-clicked',
  landingDispensaryAnalyticsClicked = 'landing-menu-analytics-clicked',
  landingMarketIntelligenceClicked = 'landing-market-intelligence-clicked',
  landingWholesaleClicked = 'landing-wholesale-clicked',
  loggedOut = 'logged-out',
  masterProductsSortChanged = 'master-products-sort-changed',
  notificationClicked = 'notification-clicked',
  notificationDropdownOpened = 'notification-dropdown-opened',
  notificationMarkAllAsReadClicked = 'notification-mark-all-as-read-clicked',
  notificationPreferenceHandled = 'notification-preference-handled',
  notificationPreferenceSaved = 'notification-preference-saved',
  notificationPreferenceDeleted = 'notification-preference-deleted',
  notificationPreferenceSnoozed = 'notification-preference-snoozed',
  notificationPreferenceUnsnoozed = 'notification-preference-unsnoozed',
  notificationPreferenceUnsubscribed = 'notification-preference-unsubscribed',
  notificationReportsPreferenceSaved = 'notification-reports-preference-saved',
  notificationDispensaryAnalyticsPreferenceSaved = 'notification-menu-analytics-preference-saved',
  globalOptionsDropdownOpened = 'global-options-dropdown-opened',
  orderStatusFilterChanged = 'order-status-filter-changed',
  pageFetched = 'page-fetched',
  permissionRequest = 'permission-request',
  passwordChanged = 'password-changed',
  productViewSelected = 'product-view-selected',
  productFilterSelected = 'product-filter-selected',
  productGridItemClicked = 'product-grid-item-clicked',
  productGridItemSourceClicked = 'product-grid-item-source-clicked',
  productGridItemDispensaryClicked = 'product-grid-item-dispensary-clicked',
  productMasterListItemClicked = 'product-master-list-item-clicked',
  productMasterSalesItemClicked = 'product-master-sales-item-clicked',
  productMenuItemDispensaryLinkClicked = 'product-menu-item-dispensary-link-clicked',
  productMenuItemProductLinkClicked = 'product-menu-item-product-link-clicked',
  productSortChanged = 'product-sort-changed-sort-changed',
  productVariantClicked = 'product-variant-clicked',
  relationshipDetailsClicked = 'relationship-details-clicked',
  resetDashboard = 'reset-dashboard',
  salesEnablementTabSelected = 'sales-enablement-tab-selected',
  sidebarAccountsClicked = 'sidebar-accounts-clicked',
  sidebarBrandsClicked = 'sidebar-brands-clicked',
  sidebarDispensariesClicked = 'sidebar-dispensaries-clicked',
  sidebarEventsClicked = 'sidebar-events-clicked',
  sidebarIntegrationsClicked = 'sidebar-integrations-clicked',
  sidebarUsageMetricsClicked = 'sidebar-usage-metrics-clicked',
  sidebarActivityLogClicked = 'sidebar-activity-log-clicked',
  sidebarHomeClicked = 'sidebar-home-clicked',
  sidebarLogoClicked = 'sidebar-logo-clicked',
  sidebarProductsClicked = 'sidebar-products-clicked',
  sidebarReportsClicked = 'sidebar-reports-clicked',
  sidebarDAReportsClicked = 'sidebar-da-reports-clicked',
  sidebarBrandCatalogClicked = 'sidebar-brand-catalog-clicked',
  sidebarSalesEnablementClicked = 'sidebar-sales-enablement-clicked',
  sidebarTagManagerClicked = 'sidebar-tag-manager-clicked',
  sidebarRelationshipsClicked = 'sidebar-relationships-clicked',
  sidebarTeamMembersClicked = 'sidebar-team-members-clicked',
  sidebarTerritoryBuilderClicked = 'sidebar-territory-builder-clicked',
  sidebarUserGuideClicked = 'sidebar-user-guide-clicked',
  sidebarAllReportsClicked = 'sidebar-all-reports-clicked',
  sidebarWhereToBuyClicked = 'sidebar-where-to-buy-clicked',
  sidebarDispensaryAnalyticsClicked = 'sidebar-menu-analytics-clicked',
  sidebarAssortmentsClicked = 'sidebar-assortments-clicked',
  sidebarMarketIntelligenceClicked = 'sidebar-market-intelligence-clicked',
  sidebarMIReportsClicked = 'sidebar-mi-reports-clicked',
  sidebarMIAcvClicked = 'sidebar-mi-acv-clicked',
  sidebarWholesaleDashboardClicked = 'sidebar-wholesale-dashboard-clicked',
  sidebarWholesaleInventoryClicked = 'sidebar-wholesale-inventory-clicked',
  sidebarWholesaleOrdersClicked = 'sidebar-wholesale-orders-clicked',
  sidebarWholesaleCustomersClicked = 'sidebar-wholesale-customers-clicked',
  sidebarWholesaleReportsClicked = 'sidebar-wholesale-reports-clicked',
  signUpCompleted = 'sign-up-completed',
  tagCreated = 'tag-created',
  tagDeleted = 'tag-deleted',
  tagDetailsClicked = 'tag-details-clicked',
  tagExported = 'tag-exported',
  tagItemNavigated = 'tag-item-navigated',
  tagManagerFromEditorPopup = 'tag-manager-from-editor-popup',
  tagManagerFromFilters = 'tag-manager-from-filters',
  tagUpdated = 'tag-updated',
  viewAppliedToFilters = 'view-applied-to-filters',
  viewCreated = 'view-created',
  viewDeleted = 'view-deleted',
  viewSelected = 'view-selected',
  viewShared = 'view-shared',
  viewUnshared = 'view-unshared',
  viewTransferred = 'view-transferred',
  territoryCreated = 'territory-created',
  territoryUpdated = 'territory-updated',
  whatsNewButtonClicked = 'whats-new-button-clicked',
  liveUpdateRequest = 'live-update-request',
  brandListItemClicked = 'brand-list-item-clicked',
  brandSortChanged = 'brand-sort-changed',
  brandTabSelected = 'brand-tab-selected',
  sendNow = 'send-now',
  oosLeaderboardListItemClicked = 'oos-dispensary-list-item-clicked',
  oosLeaderboardViewAllClicked = 'oos-leaderboard-view-all-clicked',
  userProfileClicked = 'user-profile-clicked',
  export = 'export',
  favorited = 'favorited',
  unfavorited = 'unfavorited'
}

export enum TrackableCategory {
  comparisons = 'comparisons',
  navigation = 'navigation',
  pagination = 'pagination',
  account = 'account',
  customizableDashboard = 'customizable-dashboard',
  filters = 'filters',
  favorites = 'favorites',
  savedViews = 'saved-views',
  customTerritories = 'custom-territories',
  activity = 'activity',
  dispensaries = 'dispensaries',
  globalSearch = 'global-search',
  integrations = 'integrations',
  masterProducts = 'master-products',
  notifications = 'notifications',
  permissions = 'permissions',
  products = 'products',
  feedback = 'feedback',
  onboarding = 'onboarding',
  reports = 'reports',
  scheduledReports = 'scheduled-reports',
  auditLog = 'audit-log',
  brands = 'brands',
  tags = 'tags',
  relationships = 'relationships',
  wtbAnalytics = 'wtb-analytics'
}

export enum TrackableLabel {
  dispensaryCardCall = 'dispensary-card-call',
  dispensaryCardDirections = 'dispensary-card-directions',
  dispensaryCardEmail = 'dispensary-card-email',
  dispensaryCardWebsite = 'dispensary-card-website',
  dispensaryDetailsCall = 'dispensary-details-call',
  dispensaryDetailsDirections = 'dispensary-details-directions',
  dispensaryDetailsEmail = 'dispensary-details-email',
  dispensaryDetailsWebsite = 'dispensary-details-website'
}
