import { Relationship } from '@client/types/relationships'

// HOODIE has requested POS data from CURALEAF
export const posRelationship1: Relationship = {
  id: '0ae4d9e9-d566-4027-899d-804836ccbfe1',
  name: 'CURALEAF',
  fromAccount: {
    id: 'a1',
    name: 'CURALEAF'
  },
  toAccount: {
    id: 'a2',
    name: 'HOODIE'
  },
  direction: 'INBOUND',
  type: 'POS'
}

// HOODIE is sharing WHOLESALE data with TRULIEVE
export const wholesaleRelationship1: Relationship = {
  id: 'd8499f7b-6d88-45ab-8166-2c6932c9a8d3',
  name: 'TRULIEVE',
  fromAccount: {
    id: 'a2',
    name: 'HOODIE'
  },
  toAccount: {
    id: 'a3',
    name: 'TRULIEVE'
  },
  direction: 'OUTBOUND',
  type: 'WHOLESALE'
}

export const relationships = [posRelationship1, wholesaleRelationship1]
