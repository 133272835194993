import { Link, Stack, Typography, svgIconClasses } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import styled from 'styled-components'

export const CheckboxLabelText = styled.div`
  overflow: hidden;
  font-size: ${({ theme }) => theme.typography.size.s2};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CheckboxSubLabelText = styled(Typography)`
  color: ${({ theme }) => theme.palette.mediumdark};
  font-size: ${({ theme }) => theme.typography.size.xs3};
  margin-top: ${({ theme }) => theme.spacing(-0.75)};

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  }
`
export const ExternalLink = styled(Link)`
  color: ${({ theme, color }) => (!color ? theme.palette.mediumdark : undefined)};
`

export const IconsStack = styled(Stack)`
  margin-left: auto;
  .${svgIconClasses.root} {
    display: block;
    font-size: ${({ theme }) => theme.typography.size.s3};
  }
`

export const useFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    /* Stretch to full width of parent (left margin is -11px) */
    maxWidth: 'calc(100% + 11px)',
    marginRight: 0,
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  label: {
    minWidth: 0,
    flexGrow: 1
  }
}))
