import { FormStep, FormStepper } from '@client/components/form/FormStepper/FormStepper'
import { Relationship } from '@client/types/relationships'
import { Box } from '@mui/material'
import { memo } from 'react'

export const RelationshipManagerPageForm: React.FC<{ item: Relationship }> = memo(
  function RelationshipManagerPageForm() {
    const steps: FormStep[] = []
    return (
      <Box data-testid="relationship-manager--page-form">
        <FormStepper steps={steps} />
      </Box>
    )
  }
)
