import { DraggerWidth } from '@client/components/right-drawer/component/ResizableDrawer'
import { Badge, Box, Button, Collapse } from '@mui/material'
import styled from 'styled-components'

export const minSectionWidth = 280

export const TrailingBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    top: 11,
    right: -16
  }
}))

export const DrawerGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${minSectionWidth}px, 1fr));
  grid-auto-rows: min-content;
`

export const StickyCollapse = styled(Collapse).attrs({
  sx: { position: 'sticky', bottom: 0, left: 0, width: '100%', backgroundColor: 'white' }
})`
  z-index: 2;
`

export const ShowMoreAttributesButton = styled(Button)`
  margin-left: ${DraggerWidth};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(3)}`};
  gap: ${({ theme }) => theme.spacing(1)};
  width: calc(100% - ${DraggerWidth});
`
