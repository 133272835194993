import styled from 'styled-components'

export const ErrorDisplayWrapper = styled.div`
  display: flex;
  min-height: 100%;
`
export const ErrorDisplayMessageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  text-align: center;
`

export const ErrorDisplayHeader = styled.h1`
  font-size: ${({ theme }) => theme.typography.size.l3};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const ErrorDisplayMessage = styled.p`
  margin-bottom: 18px;
  font-size: ${({ theme }) => theme.typography.size.m3};
`
