import { Link, ListItemButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { spacing } from '@mui/system'
import styled from 'styled-components'

export const ListItem = styled(ListItemButton)`
  ${spacing}
  flex-direction: column;
  align-items: stretch;
  padding-left: ${({ px, pl, paddingLeft, paddingX, p, padding, theme }: any) =>
    theme.spacing(pl ?? paddingLeft ?? px ?? paddingX ?? p ?? padding ?? 2)};
  .arrow-icon {
    right: ${({ px, pr, paddingRight, paddingX, p, padding, theme }: any) =>
      theme.spacing(pr ?? paddingRight ?? px ?? paddingX ?? p ?? padding ?? 0)};
  }
`

export const useStyles = makeStyles(() => ({
  paper: {
    /* Leave enough room for displaying the top 10 results without
     * the dialog height having to be increased */
    minHeight: '566px'
  }
}))

export const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${({ theme }) => theme.spacing(2)};
  right: 0;
  width: 22px;
  height: 22px;
`

// A link which prevents the text content from wrapping
export const MoreLink = styled(Link)`
  ${spacing}
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
`
