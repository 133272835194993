import {
  DISTINCT_DATA_MATCH_QUERY_KEY,
  DISTINCT_PRODUCTS_MATCH_QUERY_KEY,
  DistinctComplementData,
  DistinctProductsProps,
  useDistinctData,
  useDistinctProducts
} from '@client/hooks/use-distinct-data'
import { FilterBy, Filters } from '@hoodie/hoodie-filters/lib/filterset'
import { ElasticMapping } from '@lib/types/open-search'
import { useQueryClient } from '@tanstack/react-query'
import { uniq } from 'lodash'
import { useCallback } from 'react'

type DistinctSelectorViewProps<D = keyof ElasticMapping, F = Array<keyof ElasticMapping>> = {
  cacheFilterBy: keyof FilterBy
  distinct: D
  fields: F
  filters?: Filters
  selected: string[]
  dataComplement?: DistinctComplementData
}

export const useDistinctSelectorView = <
  Distinct extends keyof ElasticMapping,
  F extends keyof ElasticMapping,
  Fields extends Exclude<F, Distinct>
>({
  cacheFilterBy,
  dataComplement,
  distinct,
  fields,
  filters,
  selected
}: DistinctSelectorViewProps<Distinct, Array<F>>) => {
  const queryClient = useQueryClient()

  const { queryInput, refetch: fetchAll } = useDistinctData<Distinct, F, Fields>(
    {
      filterset: filters,
      distinct,
      fields,
      dataComplement
    },
    { enabled: false }
  )

  type RefetchResponse = Awaited<ReturnType<typeof fetchAll>>['data']

  const getDistinctCacheKey = useCallback(
    (selected: string[]) => [
      DISTINCT_DATA_MATCH_QUERY_KEY,
      {
        filterset: {
          filterBy: {
            [cacheFilterBy]: selected
          }
        },
        variables: {
          ...queryInput.variables,
          size: selected.length
        }
      }
    ],
    [cacheFilterBy, queryInput.variables]
  )
  const getCurrentDistinctCache = useCallback(() => {
    return queryClient.getQueryData(getDistinctCacheKey(selected)) as RefetchResponse
  }, [getDistinctCacheKey, queryClient, selected])

  const updateDistinctCache = useCallback(
    (data: RefetchResponse, selected: string[]) => {
      return queryClient.setQueryData(getDistinctCacheKey(selected), () => {
        const prevState = getCurrentDistinctCache()

        // Filter what should stay on the cache and remove what's not selected anymore
        const previous = prevState?.filter((p) => selected.includes(p[distinct] as string)) || []

        // Only add items that weren't already on the list
        const addItems = (data || []).filter((item) => !previous.find((i) => i[distinct] === item[distinct]))

        return [...previous, ...addItems]
      })
    },
    [getCurrentDistinctCache, distinct, getDistinctCacheKey, queryClient]
  )

  const selectAll = useCallback(async () => {
    const { data: all } = await fetchAll()
    const allSelected = uniq([...selected, ...(all?.map((p) => p[distinct] as string) || [])] as string[])

    updateDistinctCache(all, allSelected)

    return { selected: allSelected }
  }, [distinct, fetchAll, selected, updateDistinctCache])

  return {
    getCurrentDistinctCache,
    getDistinctCacheKey,
    selectAll,
    updateDistinctCache
  }
}

type DistinctProductsSelectorViewProps = {
  filters?: Filters
  selectedMastered: string[]
  selectedNonMastered: string[]
} & Pick<DistinctProductsProps, 'dataComplement'>

export const useDistinctProductsSelectorView = ({
  dataComplement,
  filters,
  selectedMastered,
  selectedNonMastered
}: DistinctProductsSelectorViewProps) => {
  const queryClient = useQueryClient()

  const { refetch: fetchAll, queryInput } = useDistinctProducts(
    {
      dataComplement,
      fields: ['NAME'],
      filterset: filters
    },
    { enabled: false }
  )

  type RefetchResponse = Awaited<ReturnType<typeof fetchAll>>['data']

  const getDistinctCacheKey = useCallback(
    (selectedMastered: string[], selectedNonMastered: string[]) => [
      DISTINCT_PRODUCTS_MATCH_QUERY_KEY,
      {
        filterset: {
          filterBy: {
            cmIds: selectedMastered,
            menuIds: selectedNonMastered
          }
        },
        variables: {
          ...queryInput.variables,
          size: selectedMastered.length + selectedNonMastered.length
        }
      }
    ],
    [queryInput.variables]
  )

  const getCurrentDistinctCache = useCallback(() => {
    return queryClient.getQueryData(getDistinctCacheKey(selectedMastered, selectedNonMastered)) as RefetchResponse
  }, [getDistinctCacheKey, queryClient, selectedMastered, selectedNonMastered])

  const updateDistinctCache = useCallback(
    (data: RefetchResponse, selectedMastered: string[], selectedNonMastered: string[]) => {
      queryClient.setQueryData(getDistinctCacheKey(selectedMastered, selectedNonMastered), () => {
        const prevState = getCurrentDistinctCache()

        // Filter what should stay on the cache and remove what's not selected anymore
        const previous =
          prevState?.filter(
            (p) => (!!p.CM_ID && selectedMastered.includes(p.CM_ID)) || selectedNonMastered.includes(p.MENU_ID)
          ) || []

        // Only add items that weren't already on the list
        const addItems = (data || []).filter(
          (product) => !previous.find((p) => p.CM_ID === product.CM_ID && p.MENU_ID === product.MENU_ID)
        )

        return [...previous, ...addItems]
      })
    },
    [getCurrentDistinctCache, getDistinctCacheKey, queryClient]
  )

  const selectAll = useCallback(async () => {
    const { data: all } = await fetchAll()
    const allMastered = uniq([
      ...selectedMastered,
      ...(all?.filter((p) => !!p.CM_ID).map((p) => p.CM_ID) || [])
    ] as string[])
    const allNonMastered = uniq([
      ...selectedNonMastered,
      ...(all?.filter((p) => !p.CM_ID).map((p) => p.MENU_ID) || [])
    ] as string[])

    updateDistinctCache(all, allMastered, allNonMastered)

    return { selectedMastered: allMastered, selectedNonMastered: allNonMastered }
  }, [fetchAll, selectedMastered, selectedNonMastered, updateDistinctCache])

  return {
    getCurrentDistinctCache,
    getDistinctCacheKey,
    selectAll,
    updateDistinctCache
  }
}
