import { Chip, Typography } from '@mui/material'
import OptimizedImage from '@client/components/optimized-image/OptimizedImage'
import styled from 'styled-components'

export const searchImageDim = 42

export const Image = styled(OptimizedImage)`
  border-radius: 5px;
  width: ${searchImageDim}px;
  height: ${searchImageDim}px;
  min-width: ${searchImageDim}px;
  min-height: ${searchImageDim}px;
  object-fit: cover;
`

export const SecondaryText = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`

export const DiscountChip = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`
