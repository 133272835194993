import { ButtonProps, IconButton as MuiIconButton, lighten } from '@mui/material'
import styled, { CSSObject } from 'styled-components'

type IconButtonVariant = Exclude<ButtonProps['variant'], 'text'> | 'outlined-reverse'

export const IconButton = styled(MuiIconButton)<{
  variant?: IconButtonVariant
}>(({ theme, variant, color, disabled }) => {
  const overrides: CSSObject = {}
  overrides.borderRadius = '50%'

  const colorAsVariant = color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color
  if (variant === 'contained') {
    if (disabled) {
      overrides['&:disabled'] = {
        backgroundColor: theme.palette.action.disabled
      }
    }
    overrides[':hover'] = {
      backgroundColor: lighten(theme.palette[colorAsVariant].main, 0.2)
    }
    overrides.backgroundColor = theme.palette[colorAsVariant].main
    overrides.color = theme.palette[colorAsVariant].contrastText
  }
  if (variant === 'outlined') {
    overrides.outline = `1px solid ${disabled ? theme.palette.action.disabled : theme.palette[colorAsVariant].main}`
    overrides.outlineOffset = '-1px'
    overrides.color = theme.palette[colorAsVariant].main
  }
  if (variant === 'outlined-reverse') {
    overrides.backgroundColor = theme.palette[colorAsVariant].main
    overrides.outline = `1px solid ${theme.palette[colorAsVariant].contrastText}`
    overrides.outlineOffset = '-1px'
    overrides.color = theme.palette[colorAsVariant].contrastText
  }

  return {
    ...overrides
  }
})
