import { indexAttributes } from '@client/components/global-search/types'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useAlgolia } from '@client/providers/algolia'
import {
  BRAND_INDEX,
  BrandHit,
  DISPENSARY_INDEX,
  DispensaryHit,
  ProductHit,
  SearchOptions,
  VARIANT_INDEX
} from '@client/services/algolia'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { useEffect, useState } from 'react'
import { SearchResults as InnerSearchResults } from './SearchResults'

const baseParams: SearchOptions = {
  filters: ``,
  distinct: 1,
  clickAnalytics: true,
  hitsPerPage: 5
}

const productParams = {
  ...baseParams,
  distinct: 1,
  attributesToRetrieve: [
    ...Object.values(indexAttributes[VARIANT_INDEX]),
    'CM_ID',
    'DISPENSARY_COUNT',
    'BRAND',
    'MASTERED_STATUS'
  ]
}

const dispensaryParams = {
  ...baseParams,
  attributesToRetrieve: [...Object.values(indexAttributes[DISPENSARY_INDEX]), 'CITY', 'STATE']
}

const brandParams = {
  ...baseParams,
  attributesToRetrieve: [...Object.values(indexAttributes[BRAND_INDEX]), 'ACTIVE_VARIANTS']
}

export interface SearchResultsProps {
  searchTerm: string
}

export const SearchResults: React.FC<SearchResultsProps> = ({ searchTerm }) => {
  const [productsSuggestions, setProductsSuggestions] = useState<ProductHit[]>()
  const [dispensariesSuggestions, setDispensariesSuggestions] = useState<DispensaryHit[]>()
  const [brandSuggestions, setBrandSuggestions] = useState<BrandHit[]>()
  const { algoliaSearchClients } = useAlgolia()
  const { eventTracker } = useEventTracker({ category: TrackableCategory.globalSearch })

  useEffect(() => {
    if (!algoliaSearchClients) {
      return
    }

    if (!searchTerm) {
      setProductsSuggestions(undefined)
      setDispensariesSuggestions(undefined)
      setBrandSuggestions(undefined)
      return
    }

    algoliaSearchClients[VARIANT_INDEX].search<ProductHit>([
      {
        indexName: VARIANT_INDEX,
        query: searchTerm,
        params: productParams
      }
    ]).then(({ results }) => {
      setProductsSuggestions(results[0].hits)
    })

    algoliaSearchClients[DISPENSARY_INDEX].search<DispensaryHit>([
      {
        indexName: DISPENSARY_INDEX,
        query: searchTerm,
        params: dispensaryParams
      }
    ]).then(({ results }) => {
      setDispensariesSuggestions(results[0].hits)
    })

    algoliaSearchClients[BRAND_INDEX].search<BrandHit>([
      {
        indexName: BRAND_INDEX,
        query: searchTerm,
        params: brandParams
      }
    ]).then(({ results }) => {
      setBrandSuggestions(results[0].hits)
    })

    eventTracker({
      action: TrackableAction.globalSearchByTerm,
      dimension1: searchTerm
    })
  }, [algoliaSearchClients, eventTracker, searchTerm])

  return (
    <InnerSearchResults
      products={productsSuggestions}
      dispensaries={dispensariesSuggestions}
      brands={brandSuggestions}
    />
  )
}
