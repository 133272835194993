import { Button, Chip as MuiChip } from '@mui/material'
import { spacing } from '@mui/system'
import styled from 'styled-components'

export const FilterPill = styled(Button)`
  ${spacing}
  min-width: max-content;
  font-weight: ${({ theme }) => theme.typography.weight.bold} !important;
  white-space: nowrap;
  border-color: ${(props) => props.theme.palette.primaryGreen} !important;
`

export const Chip = styled(MuiChip)<{ checked: boolean }>`
  height: auto;
  margin-right: 0;
  margin-left: ${({ theme }) => theme.spacing(2)};
  color: ${({ checked, theme }) => (checked ? '#fff' : theme.palette.primaryGreen)};
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? 'rgba(0, 0, 0, 0.26)' : '#fff')};
  border-color: ${({ checked, theme }) => (checked ? 'transparent' : theme.palette.primaryGreen)};
  transition: border-color 0.2s, color 0.2s, background-color 0.2s;
  .MuiChip-label {
    padding: 0 ${({ theme }) => theme.spacing(1)};
    font-size: 80%;
  }
`
