import { UserData } from '@client/services/hasura'

export const getUserInitials = (userData: UserData) => {
  const { fname, lname } = userData
  return `${fname[0] ?? ''}${lname[0] ?? ''}`.trim() || null
}

export const getUserFullName = (userData: UserData) => {
  return `${userData.fname ?? ''} ${userData.lname ?? ''}`.trim()
}
