import { FilterPill } from '@client/components/algolia/filter-pill/filter-pill/FilterPill'
import { ButtonGroup } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import { memo, useCallback, useEffect, useMemo } from 'react'
import type { RefinementListProvided } from 'react-instantsearch-core'

export type FilterPillSwitchBooleanProps = RefinementListProvided & {
  onCleared?: () => void
  onClick?: (value: boolean | null) => void
  options: [[string, false], [string, true]]
}

export type FilterPillSwitchBooleanButtonProps = Pick<FilterPillSwitchBooleanProps, 'currentRefinement'> & {
  count?: number
  label: string
  onClick: (value: boolean) => void
  value: boolean
}

const FilterPillSwitchBooleanButton = memo(function FilterPillSwitchBooleanButton({
  count,
  currentRefinement,
  label,
  onClick,
  value
}: FilterPillSwitchBooleanButtonProps) {
  const isActive = useMemo(() => currentRefinement.includes(value.toString()), [currentRefinement, value])

  return (
    <FilterPill
      key={label}
      onClick={() => onClick(value)}
      isActive={isActive}
      label={label}
      count={count}
      className="MuiToggleButtonGroup-grouped"
    />
  )
})

export const FilterPillSwitchBoolean = memo(function FilterPillSwitchBoolean({
  currentRefinement,
  items,
  onCleared,
  onClick,
  options,
  refine
}: FilterPillSwitchBooleanProps) {
  const refinement = useMemo(() => currentRefinement.map(String), [currentRefinement])
  const transformedItems = useMemo(
    () => items.map((item) => ({ ...item, label: item.label === 'true' ? options[1][0] : options[0][0] })),
    [items, options]
  )

  const toggleOption = useCallback(
    (bool: boolean) => {
      const value = bool.toString()
      let newValue: Array<string> = []

      if (!refinement.includes(value.toString())) {
        newValue = [value.toString()]
      }

      refine(newValue)
      onClick?.(newValue.length ? bool : null)
    },
    [onClick, refine, refinement]
  )

  useEffect(() => {
    if (onCleared && !refinement.length) {
      onCleared()
    }
  }, [currentRefinement, onCleared, refinement.length])

  return (
    <ButtonGroup>
      {options.map(([label, value]) => (
        <FilterPillSwitchBooleanButton
          key={label}
          count={transformedItems.find((i) => i.label === label)?.count}
          currentRefinement={refinement}
          label={label}
          onClick={toggleOption}
          value={value}
        />
      ))}
    </ButtonGroup>
  )
},
deepEqual)
