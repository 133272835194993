import { daysOfWeek } from '@client/helpers/dates'
import { useIsDirty } from '@client/hooks/use-is-dirty'
import { Box } from '@client/styles/theme/box'
import { DOW, NotificationSchedule as ScheduleType, TIME } from '@client/types/notification'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'usehooks-ts'

export interface ScheduleProps {
  compact?: boolean
  errors?: Record<string, string>
  defaultSchedule?: ScheduleType
  onScheduleChange?: (schedule: ScheduleType) => void
  size?: 'small' | 'medium'
  containerSx?: SxProps<Theme>
  hideHelperText?: boolean
}

export const Schedule: React.FC<ScheduleProps> = ({
  compact,
  defaultSchedule,
  onScheduleChange,
  errors = {},
  size = 'medium',
  containerSx,
  hideHelperText
}) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const {
    isDirty: frequencyIsDirty,
    reset: resetFrequency,
    setValue: setFrequency,
    value: frequency
  } = useIsDirty(defaultSchedule?.frequency ?? '')
  const { isDirty: dowIsDirty, reset: resetDOW, setValue: setDOW, value: dow } = useIsDirty(defaultSchedule?.dow ?? '')
  const {
    isDirty: timeIsDirty,
    reset: resetTime,
    setValue: setTime,
    value: time
  } = useIsDirty(defaultSchedule?.time ?? '')

  const isDirty = useMemo(
    () => frequencyIsDirty || dowIsDirty || timeIsDirty,
    [frequencyIsDirty, dowIsDirty, timeIsDirty]
  )

  useUpdateEffect(() => {
    resetFrequency(defaultSchedule?.frequency ?? '')
    resetDOW(defaultSchedule?.dow ?? '')
    resetTime(defaultSchedule?.time ?? '')
  }, [defaultSchedule])

  useUpdateEffect(() => {
    const updatedSchedule: ScheduleType = {
      frequency,
      time,
      timezone
    }

    if (frequency === 'weekly') {
      updatedSchedule['dow'] = dow
    }

    isDirty && onScheduleChange && onScheduleChange(updatedSchedule)
  }, [frequency, dow, time, timezone, isDirty])

  // define state handlers
  const handleFrequencyChange = useCallback(
    (event: SelectChangeEvent<SelectOption | string>) => setFrequency(event.target.value as any),
    [setFrequency]
  )
  const handleDOWChange = useCallback(
    (event: SelectChangeEvent<SelectOption | DOW | string>) => setDOW(event.target.value as any),
    [setDOW]
  )
  const handleTimeChange = useCallback(
    (event: SelectChangeEvent<SelectOption | TIME | string>) => setTime(event.target.value as any),
    [setTime]
  )

  // define utility callbacks
  const unScheduled = !frequency?.length
  const scheduledWeekly = frequency === 'weekly'

  return (
    <Box
      display="grid"
      gap={compact ? 0 : 2}
      gridTemplateColumns={compact ? '1fr' : unScheduled || scheduledWeekly ? '6fr 8fr 5fr' : '1fr 1fr'}
      justifyContent="center"
      alignItems="center"
      sx={containerSx}
      data-testid="schedule-picker"
    >
      <FormControl error={!!errors?.frequency} size={size}>
        <InputLabel id={`freq_label`}>Frequency</InputLabel>
        <Select
          size={size}
          name="frequency"
          value={frequency}
          onChange={handleFrequencyChange}
          labelId={`freq_label`}
          input={<OutlinedInput label="Frequency" />}
        >
          {frequencies.map((f) => (
            <MenuItem key={f.value as string} value={f.value as string}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
        {!hideHelperText && <FormHelperText>{errors?.frequency ? errors.frequency : ' '}</FormHelperText>}
      </FormControl>
      {(unScheduled || scheduledWeekly) && (
        <FormControl error={!!errors?.dow} size={size}>
          <InputLabel id={`dow_label`}>on (day of week)</InputLabel>
          <Select
            size={size}
            name="dow"
            value={dow}
            onChange={handleDOWChange}
            labelId={`dow_label`}
            input={<OutlinedInput label="on (day of week)" />}
            disabled={unScheduled}
          >
            {days.map((d) => (
              <MenuItem key={d.value as number} value={d.value as number}>
                {d.name}
              </MenuItem>
            ))}
          </Select>
          {!hideHelperText && <FormHelperText>{errors?.dow ? errors.dow : ' '}</FormHelperText>}
        </FormControl>
      )}
      <FormControl error={!!errors?.time} size={size}>
        <InputLabel id={`time_label`}>at (time)</InputLabel>
        <Select
          size={size}
          name="time"
          value={time}
          onChange={handleTimeChange}
          labelId={`time_label`}
          input={<OutlinedInput label="at (time)" />}
        >
          {times.map((t) => (
            <MenuItem key={t.value as number} value={t.value as number}>
              {t.name}
            </MenuItem>
          ))}
        </Select>
        {!hideHelperText && <FormHelperText>{errors?.time ? errors.time : ' '}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

type SelectOption = {
  name: string
  value: string | number
}

const frequencies: SelectOption[] = [
  { value: 'daily', name: 'Daily' },
  { value: 'weekly', name: 'Weekly' }
]

const days: SelectOption[] = daysOfWeek.map((name, index) => ({ value: index, name }))

const times: SelectOption[] = [
  { value: 0, name: '12am' },
  { value: 1, name: '1am' },
  { value: 2, name: '2am' },
  { value: 3, name: '3am' },
  { value: 4, name: '4am' },
  { value: 5, name: '5am' },
  { value: 6, name: '6am' },
  { value: 7, name: '7am' },
  { value: 8, name: '8am' },
  { value: 9, name: '9am' },
  { value: 10, name: '10am' },
  { value: 11, name: '11am' },
  { value: 12, name: '12pm' },
  { value: 13, name: '1pm' },
  { value: 14, name: '2pm' },
  { value: 15, name: '3pm' },
  { value: 16, name: '4pm' },
  { value: 17, name: '5pm' },
  { value: 18, name: '6pm' },
  { value: 19, name: '7pm' },
  { value: 20, name: '8pm' },
  { value: 21, name: '9pm' },
  { value: 22, name: '10pm' },
  { value: 23, name: '11pm' }
]
