import { TrackableButton } from '@client/components/Trackables'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { EventArgs } from '@client/configs/tracking'
import { useActiveComparison } from '@client/hooks/use-active-comparison'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { Box } from '@client/styles/theme/box'
import { COMPARISON_DRAFT_URL_KEY, COMPARISON_NEW_URL_KEY, DraftComparison } from '@client/types/comparison'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Close } from '@mui/icons-material'
import { Alert, AlertTitle, Drawer, IconButton, Link } from '@mui/material'
import { ComparisonListItem } from './components/comparison-list-item/ComparisonListItem'

const gaEvent: EventArgs = {
  action: TrackableAction.comparisonCreateClicked,
  category: TrackableCategory.navigation
}

export interface ComparisonDrawerProps {
  comparisons: DraftComparison[]
}

interface ComparisonDrawerContentProps extends ComparisonDrawerProps {
  onClose: () => void
}

const ComparisonDrawerContent: React.FC<ComparisonDrawerContentProps> = ({ onClose, comparisons }) => {
  const { activeComparisonId } = useActiveComparison()

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            Comparisons
            <IconButton edge="end" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        {comparisons.map((comparison) => (
          <ErrorBoundary key={comparison.id ?? 'draft-comparison'}>
            <ComparisonListItem
              comparison={comparison}
              onClick={onClose}
              isActive={activeComparisonId === (comparison.id ?? COMPARISON_DRAFT_URL_KEY)}
            />
          </ErrorBoundary>
        ))}
        {comparisons.length === 0 && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            m={4}
            data-testid="comparison-drawer--no-comparisons"
          >
            <Alert severity="info">
              <AlertTitle>No comparisons saved!</AlertTitle>
              Create a new comparison by clicking the button on the bottom of the drawer.
            </Alert>
          </Box>
        )}
      </DrawerContent>
      <DrawerFooter>
        <TrackableButton
          LinkComponent={Link}
          variant="contained"
          color="primary"
          gaEvent={gaEvent}
          onClick={onClose}
          href={`#/dispensary-analytics/${COMPARISON_NEW_URL_KEY}`}
          data-testid="create-new-comparison"
        >
          Create new comparison
        </TrackableButton>
      </DrawerFooter>
    </>
  )
}

export const ComparisonDrawer: React.FC<ComparisonDrawerProps> = (props) => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  return (
    <Drawer open={drawer?.id === DrawerId.comparisons} anchor="right" classes={drawerStyles} onClose={clearDrawer}>
      <ErrorBoundary>
        <ComparisonDrawerContent {...props} onClose={clearDrawer} />
      </ErrorBoundary>
    </Drawer>
  )
}
