import { useAuth0 } from '@auth0/auth0-react'
import { MenuItem, MenuItemText, MenuSection } from '@client/components/menu'
import { slugify } from '@client/helpers/strings'
import { useIsSuperAdmin } from '@client/hooks/use-permissions'
import { useSubscriptionUsers, useUsers } from '@client/hooks/use-users'
import { UsersSortField } from '@client/types/user'
import { userDisplay } from '@lib/helpers/user'
import { Autocomplete, Chip, CircularProgress, InputProps, SxProps, TextField, Theme, Typography } from '@mui/material'
import { Fragment, memo, useCallback, useMemo } from 'react'

const NO_GROUP = '(UNASSIGNED)'

export const filterUserOptions = (
  value: UserSelectOption[],
  options: UserSelectOption[],
  params: { inputValue: string }
) => {
  const filtered = options.filter((option) => {
    if (params.inputValue === '') {
      return true
    }
    if (value.find((v) => v.email === option.email)) {
      return false
    }
    if (option.email.toLowerCase().includes(params.inputValue.toLowerCase())) {
      return true
    }
    if (option.name && option.name.toLowerCase().includes(params.inputValue.toLowerCase())) {
      return true
    }
    if (option.subscription?.name && option.subscription.name.toLowerCase().includes(params.inputValue.toLowerCase())) {
      return true
    }
    return false
  })
  return filtered
}

export type UserSelectOption = {
  id: string
  name: string
  email: string
  isDeleted?: boolean
  subscription?: {
    name: string
    id: string
  }
}

export interface UserSelectProps {
  groupBySubscription?: boolean
  label?: string
  multiple?: boolean
  loading?: boolean
  size?: InputProps['size']
  value?: UserSelectOption | UserSelectOption[] | string | string[] | null
  options: UserSelectOption[]
  onChange: (value: UserSelectOption | UserSelectOption[] | null) => void
  error?: { message: string }
  sx?: SxProps<Theme>
}

export const UserSelect: React.FC<UserSelectProps> = memo(function UserSelect({
  groupBySubscription = false,
  label = 'Select user...',
  multiple = false,
  loading = false,
  size,
  value,
  options,
  error,
  onChange,
  sx
}) {
  const handleChange = useCallback(
    (event: any, newValue: UserSelectOption | UserSelectOption[] | null) => {
      onChange(newValue)
    },
    [onChange]
  )

  const innerValue: UserSelectOption | UserSelectOption[] | null = useMemo(() => {
    if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'string') {
      return value.map((v) => options.find((o) => o.id === v)) as UserSelectOption[]
    }
    if (typeof value === 'string') {
      return options.find((o) => o.id === value) || null
    }
    if (typeof value === 'object' && value !== null) {
      return value as UserSelectOption[]
    }
    return multiple ? [] : null
  }, [value, options, multiple])

  // Allow searching by name, email or subscription name.
  const getFilterOptions = useCallback(
    (options: UserSelectOption[], params: any) => {
      const arrayValue = Array.isArray(innerValue) ? innerValue : innerValue ? [innerValue] : []
      return filterUserOptions(arrayValue, options, params)
    },
    [innerValue]
  )

  const getOptionLabel = useCallback(
    (option: UserSelectOption) => {
      const suffix = groupBySubscription && option.subscription ? ` (${option.subscription?.name})` : ''
      return `${option.name || option.email}${suffix}`
    },
    [groupBySubscription]
  )

  const sortedOptions = useMemo(() => {
    if (groupBySubscription) {
      return options.sort((a, b) => {
        if (a.subscription?.name && b.subscription?.name) {
          return a.subscription.name.localeCompare(b.subscription.name)
        }
        if (a.subscription?.name) {
          return -1
        }
        if (b.subscription?.name) {
          return 1
        }
        return 0
      })
    }
    return options
  }, [groupBySubscription, options])

  return (
    <Autocomplete
      data-testid="user-select"
      sx={sx}
      size={size}
      autoComplete
      groupBy={groupBySubscription ? (option) => option.subscription?.name || NO_GROUP : undefined}
      multiple={multiple}
      loading={loading}
      filterSelectedOptions={multiple}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={sortedOptions}
      filterOptions={getFilterOptions}
      getOptionLabel={getOptionLabel}
      value={innerValue}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index })
          return <Chip label={getOptionLabel(option)} {...rest} size="small" key={key} />
        })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? <CircularProgress size={16} color="inherit" /> : params.InputProps.endAdornment,
            sx: { pr: loading ? '16px !important' : undefined }
          }}
          helperText={error?.message}
          variant="outlined"
          label={label}
          error={!!error}
        />
      )}
      renderGroup={(params) => (
        <Fragment key={params.group || NO_GROUP}>
          <MenuSection
            label={params.group || NO_GROUP}
            data-testid={`user-select-group-${slugify(params.group || NO_GROUP)}`}
          />
          {params.children}
        </Fragment>
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          value={option.id}
          key={`${option.id}-${option.subscription?.id || 'unasigned'}`}
          data-testid={`user-select-option-${option.id}`}
        >
          <MenuItemText
            title={`${option.name || option.email}${option.isDeleted ? ' (deleted)' : ''}`}
            sx={{ display: 'block' }}
            primary={
              <>
                {option.name || option.email}
                {option.isDeleted && (
                  <Typography variant="caption" color="error">
                    {' '}
                    (deleted)
                  </Typography>
                )}
              </>
            }
            secondary={option.name ? option.email : undefined}
          />
        </MenuItem>
      )}
    />
  )
})

export interface SubscriptionUserSelectProps extends Omit<UserSelectProps, 'options'> {
  excludeSuspended?: boolean
  excludeSelf?: boolean
  excludeDeleted?: boolean
  subscriptionId?: string
}

export const SubscriptionUserSelect: React.FC<SubscriptionUserSelectProps> = memo(function SubscriptionUserSelect({
  excludeDeleted = true,
  excludeSuspended = true,
  excludeSelf = false,
  subscriptionId = '',
  ...props
}) {
  const { data: users, isFetching } = useSubscriptionUsers({
    excludeSuspended,
    excludeDeleted,
    subscriptionId: subscriptionId
  })
  const { user } = useAuth0()
  const options: UserSelectOption[] = useMemo(() => {
    const mappedUsers =
      users?.map((u) => ({
        id: u.id,
        name: userDisplay(u),
        email: u.email,
        isDeleted: !excludeDeleted && u.isDeleted
      })) || []
    return excludeSelf ? mappedUsers.filter((u) => u.id !== user?.sub) : mappedUsers
  }, [excludeDeleted, excludeSelf, user?.sub, users])
  return <UserSelect {...props} loading={isFetching} options={options} />
})

export interface AllUserSelectProps extends Omit<UserSelectProps, 'options'> {
  excludeSuspended?: boolean
  excludeSelf?: boolean
  excludeDeleted?: boolean
}

export const AllUsersSelect: React.FC<AllUserSelectProps> = memo(function AllUsersSelect({
  excludeDeleted = true,
  excludeSuspended = true,
  excludeSelf = false,
  ...props
}) {
  const { isSuperAdmin } = useIsSuperAdmin()
  if (!isSuperAdmin) {
    throw new Error('Only super admins can use this component')
  }
  // TODO: this is a bit hacky. We should maybe only fetch users after the user has started searching.
  // TODO: this hook needs better sorting options (e.g. sort by first name and then last name)
  const { data: users, isFetching } = useUsers({
    from: 0,
    pageSize: 10000,
    sortField: UsersSortField.LAST_NAME,
    sortOrder: 'asc',
    excludeSuspended,
    excludeDeleted
  })
  const { user } = useAuth0()
  const options: UserSelectOption[] = useMemo(() => {
    const mappedUsers =
      users?.map(({ user, accounts }) => ({
        id: user.id,
        name: userDisplay(user),
        email: user.email,
        isDeleted: !excludeDeleted && user.isDeleted,
        subscription: accounts?.length > 0 ? accounts[0].account : undefined
      })) || []
    return excludeSelf ? mappedUsers.filter((u) => u.id !== user?.sub) : mappedUsers
  }, [excludeDeleted, excludeSelf, user?.sub, users])
  return <UserSelect {...props} loading={isFetching} options={options} groupBySubscription />
})
