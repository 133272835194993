import { useAuth0 } from '@auth0/auth0-react'
import { PageLoader } from '@client/components/page-loader/PageLoader'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { EXPIRED_SESSION_ROUTE } from '@client/types/routes'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import PublicRoutes, { publicRoutes } from './Public'

export default function Routes() {
  const { isLoading, isAuthenticated, error } = useAuth0()
  const navigate = useNavigateRef()
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (error) {
      navigate.current('/access-denied', { state: { error } })
    } else if (isLoading === false && isAuthenticated === false && !publicRoutes.includes(pathname)) {
      const redirectTo = `${pathname}${search}`
      if (redirectTo === '/sign-in' || redirectTo === '/') {
        navigate.current('/sign-in')
      } else {
        navigate.current(`/sign-in?redirectTo=${encodeURIComponent(redirectTo)}`)
      }
    }
    // Need to prevent infinite loop - so don't depend on pathname
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, error, navigate])

  if (isLoading) {
    return <PageLoader />
  }

  return isAuthenticated && pathname !== EXPIRED_SESSION_ROUTE ? <AuthenticatedRoutes /> : <PublicRoutes />
}
