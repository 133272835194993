import React, { RefObject, useEffect, useRef, useState } from 'react'

const defaultOptions: IntersectionObserverInit = {}

/**
 * A React hook that uses IntersectionObserver to return the IntersectionObserverEntry
 * associated with the given reference.
 *
 * Usage:
 * const myRef = useRef<HTMLElement>(null)
 * const observerEntry = useIntersection(myRef)
 *
 * @param ref - the React ref to the element to observe
 * @param options - options passed to the IntersectionObserver
 * @returns - the IntersectionObserverEntry (empty object if not observed yet)
 */
export const useIntersection = (
  ref: RefObject<HTMLElement>,
  options: IntersectionObserverInit = defaultOptions
): IntersectionObserverEntry => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>({} as IntersectionObserverEntry)
  const observer: React.MutableRefObject<null | IntersectionObserver> = useRef(null)

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }

    if (globalThis.IntersectionObserver) {
      observer.current = new IntersectionObserver(([entry]) => setEntry(entry), options)

      const { current: currentObserver } = observer
      if (ref.current) {
        currentObserver.observe(ref.current)
      }

      return () => currentObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, options])

  return entry
}
