import { DetailsPlaceholder } from '@client/components/DetailsPlaceholder/DetailsPlaceholder'
import { urls } from '@client/helpers/urls'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { RelationshipsIcon } from '@client/styles/global'
import { Add } from '@mui/icons-material'
import { memo, useCallback } from 'react'

export const RelationshipManagerPageLanding: React.FC = memo(function RelationshipManagerPageLanding() {
  const navigate = useNavigateRef()
  const { isMobile } = useResponsiveState()

  const handleNewRelationshipClick = useCallback(() => {
    navigate.current(urls.relationshipManagerNewRelationshipPage())
  }, [navigate])

  return (
    <DetailsPlaceholder
      data-testid="relationship-manager--landing-view"
      Icon={RelationshipsIcon}
      text={
        isMobile ? (
          <>
            No relationships to display.
            <br />
            Click the button below to create your first relationship.
          </>
        ) : (
          <>
            Select a relationship from the list on the left to edit it
            <br />
            or start creating new relationship.
          </>
        )
      }
      buttons={[
        {
          children: 'Create new relationship',
          onClick: handleNewRelationshipClick,
          startIcon: <Add />,
          variant: 'outlined'
        }
      ]}
    />
  )
})
