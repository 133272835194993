import { Card } from '@client/styles/theme/card'
import { ErrorOutline } from '@mui/icons-material'
import { Box, CardContent, CardProps, IconButton, IconButtonProps, Typography } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps
} from 'react-error-boundary'
import { InfoPopover } from '../info_popover/InfoPopover'

interface BaseErrorBoundaryProps {
  variant?: 'complete' | 'compact'
  cardProps?: CardProps
  iconButtonProps?: IconButtonProps
}

type ErrorFallbackProps = FallbackProps & BaseErrorBoundaryProps

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ variant = 'complete', error, cardProps, iconButtonProps }) => {
  // If we encounter a ChunkLoadError, we need to reload the page
  useEffect(() => {
    if (error.name === 'ChunkLoadError') {
      console.warn('App was updated. Reloading page...')
      window.location.reload()
    }
  }, [error])

  if (error.name === 'ChunkLoadError') {
    return null
  }

  const isCompact = variant === 'compact'

  const errorContent = (
    <Box display="flex" flexDirection="column" alignItems="center" gap={isCompact ? 1 : 2} p={isCompact ? 3 : 0}>
      <Box display="flex" alignItems="center" gap={1}>
        <ErrorOutline fontSize={isCompact ? 'medium' : 'large'} color="error" />
        <Typography variant={isCompact ? 'h5' : 'h4'}>Oops!</Typography>
      </Box>
      <Typography variant="body1">{error.message || 'Something went wrong'}</Typography>
    </Box>
  )

  return isCompact ? (
    <InfoPopover
      Button={
        <IconButton {...iconButtonProps}>
          <ErrorOutline color="error" />
        </IconButton>
      }
    >
      {errorContent}
    </InfoPopover>
  ) : (
    <Card mt={4} {...cardProps}>
      <CardContent>{errorContent}</CardContent>
    </Card>
  )
}

interface ErrorBoundaryProps extends BaseErrorBoundaryProps {
  resetKeys?: Array<string> | string | object
  fallbackRender?: ReactErrorBoundaryProps['fallbackRender']
  children: ReactNode
}

export const ErrorBoundary = ({ resetKeys, fallbackRender, children, ...rest }: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      fallbackRender={fallbackRender || ((props) => <ErrorFallback {...props} {...rest} />)}
      resetKeys={[resetKeys]}
    >
      {children}
    </ReactErrorBoundary>
  )
}
