import { CircularProgress } from '@mui/material'
import React from 'react'
import * as S from './Loader.style'

export const Loader: React.FC<{ minHeight?: string | number }> = React.memo(function Loader({ minHeight }) {
  return (
    <S.LoadingWrapper data-testid="loading-wrapper" minHeight={minHeight}>
      <CircularProgress data-testid="loading-icon" color="secondary" />
    </S.LoadingWrapper>
  )
})
