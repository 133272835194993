import { SortOrder } from '@client/components/sort-by/SortBy'

export interface QtyOptionsProps {
  QUANTITY?: number | null
  UNIT?: string | null
}

export interface ProductStatsProps {
  inventoryCount?: number
  promosCount?: number
  menusCount?: number
}

export enum VariantSortField {
  DAYS_UNTIL_STOCK_OUT = 'DAYS_UNTIL_STOCK_OUT',
  ACTUAL_STOCK = 'ACTUAL_STOCK',
  ACTUAL_PRICE = 'ACTUAL_PRICE',
  ORIGINAL_PRICE = 'ORIGINAL_PRICE',
  DISCOUNTED_PRICE = 'DISCOUNTED_PRICE',
  FIRST_SEEN_AT = 'FIRST_SEEN_AT',
  LAST_SEEN_AT = 'LAST_SEEN_AT'
}

export const variantSortOptions: { label: string; value: VariantSortField }[] = [
  // {
  //   label: 'Days Until Stock Out',
  //   value: VariantSortField.DAYS_UNTIL_STOCK_OUT
  // },
  // {
  //   label: 'Inventory',
  //   value: VariantSortField.ACTUAL_STOCK
  // },
  // {
  //   label: 'Retail Price',
  //   value: VariantSortField.ORIGINAL_PRICE
  // },
  // {
  //   label: 'Discounted Price',
  //   value: VariantSortField.DISCOUNTED_PRICE
  // },
  {
    label: 'Price',
    value: VariantSortField.ACTUAL_PRICE
  },
  {
    label: 'First Seen At',
    value: VariantSortField.FIRST_SEEN_AT
  },
  {
    label: 'Last Seen At',
    value: VariantSortField.LAST_SEEN_AT
  }
]

export enum StockFilterOption {
  // ANY is used to *explicitly* specify that we want to show variants regardless of stock status
  // We need this option because the default stock filter behavior is to filter for ALL_AVAILABLE
  ANY = 'ANY',
  ALL_AVAILABLE = 'ALL_AVAILABLE',
  NEWLY_STOCKED = 'NEWLY_STOCKED',
  // IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  LOW_STOCK = 'LOW_STOCK'
}

export const StockFilterOptions: { label: string; value: StockFilterOption }[] = [
  {
    label: 'All available',
    value: StockFilterOption.ALL_AVAILABLE
  },
  {
    label: 'New',
    value: StockFilterOption.NEWLY_STOCKED
  },
  //leaving this here if we want to add this option back in
  // {
  //   label: 'In stock',
  //   value: StockFilterOption.IN_STOCK
  // },
  {
    label: 'Low stock',
    value: StockFilterOption.LOW_STOCK
  },
  {
    label: 'Out of stock',
    value: StockFilterOption.OUT_OF_STOCK
  }
]

export type UnitQuantities = {
  unit: string
  quantities: Array<{
    quantity: number | null
    count: number
  }>
}

export type SelectedUnitQuantities = Record<string, string[]>

export type VariantsOptions = {
  sortField: VariantSortField
  sortOrder: SortOrder
  from: number
  pageSize: number
  filters: {
    stock?: StockFilterOption
    onPromotion?: boolean
    med?: boolean
    rec?: boolean
    units?: SelectedUnitQuantities
  }
}

export type VariantListItem = {
  menuId: string
}
