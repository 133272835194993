import { green, grey } from '@mui/material/colors'
import { palette } from '../palette'

type MainContrastTextType = {
  main: string
  contrastText: string
  dark?: string
}
type ColorBgType = {
  color: string
  background: string
}
type CustomPalette = {
  primary: MainContrastTextType
  secondary: MainContrastTextType
  snooze: MainContrastTextType
  wholesale: MainContrastTextType
  help: MainContrastTextType
  toggle: MainContrastTextType
  import: MainContrastTextType
}

export type VariantType = {
  name: string
  palette: CustomPalette
  header: ColorBgType & {
    search: {
      color: string
    }
    indicator: {
      background: string
    }
  }
  footer: ColorBgType
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string
      }
    }
    footer: ColorBgType & {
      online: {
        background: string
      }
    }
    badge: ColorBgType
  }
}

// Module Augmentation to expand the Theme
declare module '@mui/material/styles' {
  interface Theme extends VariantType {}
  // allow configuration using `createTheme`
  interface ThemeOptions extends Partial<VariantType> {}

  // define custom colors: https://material-ui.com/customization/palette/
  interface Palette {
    palette: CustomPalette
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    toggle: true
    import: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
    import: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    snooze: true
    wholesale: true
    help: true
  }
}

const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#2F7CF6',
  800: '#2f65cb',
  900: '#2052c2 '
}

const defaultVariant = {
  name: 'DEFAULT',
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      contrastText: '#FFF',
      green: '#51bc81'
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF',
      deepTinRust: '#21767b',
      seafoamGreen: '#5bc6d0',
      purpleBlue: '#362d79',
      purple: '#622870'
    },
    snooze: palette.snooze,
    import: palette.import,
    toggle: palette.toggle,
    wholesale: palette.wholesale,
    help: palette.help,
    background: {
      default: '#F7F9FC',
      paper: '#FFF'
    }
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800]
    },
    indicator: {
      background: customBlue[600]
    }
  },
  footer: {
    color: grey[500],
    background: '#FFF'
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      background: '#233044',
      brand: {
        color: customBlue[500]
      }
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: customBlue[500]
    }
  }
}

const variants: Array<VariantType> = [defaultVariant]

export default variants
