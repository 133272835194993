import { ListItemIcon, ListItemText, Menu as MuiMenu, MenuItem as MuiMenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import styled, { css } from 'styled-components'

type Props = { variant?: 'primary' | 'danger' }

//had to split declaration because compiler complains otherwise
const BaseMenuItem = styled(MuiMenuItem).withConfig<Props>({
  shouldForwardProp: (prop) => prop !== 'variant'
})`
  padding-right: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  ${(props) =>
    props.variant == 'danger' &&
    css`
      color: ${({ theme }) => theme.palette.red};
      svg {
        fill: ${({ theme }) => theme.palette.red};
      }
    `}
`

export const MenuItem = styled(BaseMenuItem).attrs(() => ({
  dense: true
}))``

export const DangerMenuItem = styled(BaseMenuItem).attrs(() => ({ variant: 'danger', dense: true }))``

const StyledMenu = styled(MuiMenu).attrs(() => ({
  MenuListProps: {
    disablePadding: true
  }
}))``

export const Menu = withStyles(() => ({
  paper: {
    boxShadow: '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12)'
  }
}))(StyledMenu)

export const MenuItemIcon = withStyles(() => ({
  root: {
    minWidth: '30px !important',
    '& > img': {
      width: '24px'
    }
  }
}))(ListItemIcon)

export const MenuItemText = withStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0
  },
  primary: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))(ListItemText)

export const SectionTypography = styled(Typography)`
  font-weight: 600;
  text-transform: uppercase;
`

export const MenuSectionItem = styled(MenuItem)`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  span {
    font-weight: 600;
  }
`

const TRANSPARENT = 'rgba(0,0,0,0)'
export const useNestedMenuItemStyles = makeStyles((theme) => ({
  root: (props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    backgroundColor: props.open ? theme.palette.action.hover : TRANSPARENT
  })
}))
