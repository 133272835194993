import { useResponsiveState } from '@client/hooks/use-responsive-state'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Box, IconButton, IconButtonProps, Popover, PopoverProps } from '@mui/material'
import { FC, PropsWithChildren, cloneElement, useCallback, useMemo, useRef } from 'react'
import { useBoolean } from 'usehooks-ts'

type InfoAnchorProps = Pick<IconButtonProps, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> & { 'data-testid': string }

interface InfoPopoverProps {
  fontSize?: React.ComponentProps<typeof InfoIcon>['fontSize']
  position?: 'top' | 'bottom' | 'left' | 'right'
  on?: 'hover' | 'click'
  Icon?: React.FC<any>
  color?: string
  autoDismissOnHover?: boolean
  Button?: React.ReactElement<InfoAnchorProps>
}

export const getAnchors = (
  position?: InfoPopoverProps['position']
): Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'> => {
  switch (position) {
    case 'top':
      return {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        transformOrigin: { vertical: 'bottom', horizontal: 'center' }
      }
    case 'left':
      return {
        anchorOrigin: { vertical: 'center', horizontal: 'left' },
        transformOrigin: { vertical: 'center', horizontal: 'right' }
      }
    case 'right':
      return {
        anchorOrigin: { vertical: 'center', horizontal: 'right' },
        transformOrigin: { vertical: 'center', horizontal: 'left' }
      }
    case 'bottom':
    default:
      return {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: { vertical: 'top', horizontal: 'center' }
      }
  }
}

export const InfoPopover: FC<PropsWithChildren<InfoPopoverProps>> = ({
  fontSize,
  position = 'bottom',
  children,
  on = 'hover',
  Icon = InfoIcon,
  Button,
  color,
  autoDismissOnHover = true
}) => {
  const { anchorOrigin, transformOrigin } = useMemo(() => getAnchors(position), [position])
  const { isMobile: isTablet } = useResponsiveState('md')
  const menuAnchor = useRef<HTMLButtonElement | null>(null)
  const { value: isOpen, setFalse: handleClose, setTrue: openPopover } = useBoolean(false)

  const closePopup = useCallback(() => {
    handleClose()
  }, [handleClose])

  const buttonProps: InfoAnchorProps = useMemo(
    () => ({
      ref: menuAnchor,
      'data-testid': 'popup-button',
      onClick: isTablet || on === 'click' ? openPopover : undefined,
      onMouseEnter: on === 'hover' ? openPopover : undefined,
      onMouseLeave: on === 'hover' ? closePopup : undefined
    }),
    [closePopup, isTablet, menuAnchor, on, openPopover]
  )

  const preventHoverDismiss = on === 'hover' && !autoDismissOnHover

  return (
    <>
      {Button ? (
        cloneElement(Button, buttonProps)
      ) : (
        <IconButton {...buttonProps}>
          <Icon fontSize={fontSize || 'small'} htmlColor={color} />
        </IconButton>
      )}
      <Popover
        sx={on === 'hover' ? { pointerEvents: 'none' } : undefined}
        anchorEl={menuAnchor.current}
        open={isOpen}
        data-testid="popup-content"
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={on === 'click' ? closePopup : undefined}
      >
        <Box
          p={2}
          onMouseLeave={preventHoverDismiss ? closePopup : undefined}
          onMouseEnter={preventHoverDismiss ? openPopover : undefined}
          sx={preventHoverDismiss ? { pointerEvents: 'auto' } : undefined}
          data-testid="popup-content-box"
        >
          {children}
        </Box>
      </Popover>
    </>
  )
}
