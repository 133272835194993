import { Chip as MuiChip, MenuItem as MuiMenuItem } from '@mui/material'
import styled from 'styled-components'

export const MenuItem = styled(MuiMenuItem)`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
`

export const Chip = styled(MuiChip)`
  height: auto;
  margin-right: 0;
  margin-left: ${({ theme }) => theme.spacing(2)};
  color: ${(props) => props.theme.palette.seafoamGreen};
  background-color: #fff;
  border-color: ${(props) => props.theme.palette.seafoamGreen};
  .MuiChip-label {
    padding: 0 ${({ theme }) => theme.spacing(1)};
    font-size: 80%;
  }
`
