import { mergeArrays } from '@client/helpers/lists'
import { isEmptyObject, isPlainObject } from '@client/helpers/objects'
import { ALGOLIA_INDEX, BRAND_INDEX, DISPENSARY_INDEX, VARIANT_INDEX } from '@client/types/algolia'
import { ElasticFields } from '@hoodie/hoodie-filters/lib/elastic'
import {
  ALL_FILTERS,
  ALL_PRODUCT_FILTERS,
  ConcentratetypeFilterOption,
  DraftFilterset,
  FilterBy,
  FilterFlagKey,
  FilterKey,
  FilterKeyType,
  FilterKeys,
  FilterOption,
  Filters,
  FlavorFilterOption,
  InfusedPreRollFilterOption,
  InvalidFilterData,
  InvalidFiltersMap,
  KEYWORD_SEARCH_FILTER,
  LOCATION_FILTER_KEYS,
  MED_REC_FILTER,
  NumericFilter,
  PackSizeFilterOption,
  ProductFilters,
  ProductSkuFilterOption,
  Should,
  TAG_FILTER_TARGETS,
  TagFilter,
  TagFilterOption,
  TagFilterTarget,
  View,
  defaultFilters,
  emptyDraftFilterset,
  filterTagTargetToFilterKey,
  flagLabels,
  getDefaultFilters,
  keysFromFiltersToShould,
  mergeTagFilters,
  normalizeFilters,
  productSkuKeys
} from '@hoodie/hoodie-filters/lib/filterset'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import clone from 'fast-clone'
import { singular } from 'pluralize'
import { SearchState } from 'react-instantsearch-core'

// Deprecated - We should keep this here for not breaking the opened PRs
// Please import from @hoodie/hoodie-filters directly, and use View instead of Filterset
export * from '@hoodie/hoodie-filters/lib/filterset'
export type Filterset = View
// End Deprecated

export type FiltersWith<TSpecialFilter extends Record<string, string | string[] | boolean>> = Filters & {
  filterBy: FilterBy & TSpecialFilter
}
// Alternative filters that can be used direct in use-algolia-list using FiltersWith
export type LicenseNumbersFilterBy = { licenseNumbers?: string[] }
export type ProductNamesFilterBy = { products?: string[] }

// This should only be used for mapping the filter keys to the database fields
export type FilterKeyWithAlternativeKey = FilterKey | keyof LicenseNumbersFilterBy | keyof ProductNamesFilterBy

export type InvalidFiltersWithDataMap = InvalidFiltersMap<
  InvalidFilterData & {
    messages?: string[]
    components?: string[]
  }
>

export const PRODUCT_DETAIL_FILTERS = [
  TagFilterOption,
  ProductFilters.actualPrice,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const BRAND_DETAIL_FILTERS = [
  TagFilterOption,
  ProductFilters.actualPrice,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.banner,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  ProductSkuFilterOption,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const DISPENSARY_DETAIL_FILTERS = [
  TagFilterOption,
  ProductFilters.actualPrice,
  ProductFilters.brand,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  MED_REC_FILTER,
  ProductFilters.strain,
  ProductSkuFilterOption,
  KEYWORD_SEARCH_FILTER,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption
]

export const ALL_PRODUCT_FILTERS_WITHOUT_DISPENSARIES = ALL_PRODUCT_FILTERS.filter(
  (filter) => filter.filterKey !== 'dispensaries'
)

export const VIRTUAL_PRODUCT_FILTERS = ALL_PRODUCT_FILTERS

export const INVISIBLE_PRODUCT_SKU_FILTERS = [
  ProductFilters.brand,
  ProductFilters.state,
  ProductFilters.city,
  ProductFilters.dispensary,
  ProductFilters.categories,
  ProductFilters.segments,
  ProductFilters.subsegments,
  ProductFilters.productPresentation,
  ProductFilters.strain,
  InfusedPreRollFilterOption,
  ConcentratetypeFilterOption,
  FlavorFilterOption,
  PackSizeFilterOption,
  ProductFilters.actualPrice,
  KEYWORD_SEARCH_FILTER
]

export const MASTERED_STATUS_ATTRIBUTE = 'MASTERED_STATUS'
export type MasteredStatusValue = 'mastered' | 'un-mastered'
export type MasteredStatusWithEmptyValue = MasteredStatusValue | ''

export type TagFilterWithLabel = TagFilter & {
  tagLabel: string
  typesCount: [number, ...number[]]
}

/**
 * A filterset defined in terms of it's GraphQL fields
 */
export type FiltersetGraphQL = {
  filterset_id: string
  filterset_name: string
  userId: string | null
  subscriptionId: string | null
  tags: Array<{ tag: Tag }>
  filters: Filters
}

/** Get a filterset that includes the dispensary ID and the product-related filters of the given filterset
 */
export const getFiltersetForDispensary = (dispensaryId: string, filterset: DraftFilterset): DraftFilterset => {
  const nonLocationFilterBy = Object.fromEntries(
    Object.entries(filterset.filters.filterBy).filter(([key]) => !LOCATION_FILTER_KEYS.includes(key as FilterKey))
  ) as FilterBy
  return {
    filters: {
      ...getDefaultFilters(),
      filterBy: {
        ...nonLocationFilterBy,
        dispensaryIds: [dispensaryId]
      },
      should: filterset.filters.should,
      keywordSearch: filterset.filters.keywordSearch
    }
  }
}

export type FilterMap = Record<ALGOLIA_INDEX | 'elastic', string>

export const DatabaseFilterMap: Record<FilterKeyWithAlternativeKey, FilterMap> = {
  brands: {
    elastic: ElasticFields.brands,
    [VARIANT_INDEX]: 'BRAND',
    [DISPENSARY_INDEX]: 'BRANDS',
    [BRAND_INDEX]: 'BRAND_NAME'
  },
  states: {
    elastic: ElasticFields.states,
    [VARIANT_INDEX]: 'D_STATE',
    [DISPENSARY_INDEX]: 'STATE',
    [BRAND_INDEX]: 'STATES'
  },
  countryCodes: {
    elastic: ElasticFields.countryCodes,
    [VARIANT_INDEX]: 'D_COUNTRY',
    [DISPENSARY_INDEX]: 'COUNTRY_CODE'
  },
  cities: {
    elastic: ElasticFields.cities,
    [VARIANT_INDEX]: 'D_CITY',
    [DISPENSARY_INDEX]: 'CITY'
  },
  dispensaries: {
    elastic: ElasticFields.dispensaries,
    [VARIANT_INDEX]: 'MASTER_D_NAME',
    [DISPENSARY_INDEX]: 'DISPENSARY_NAME'
  },
  banners: {
    elastic: ElasticFields.banners,
    [VARIANT_INDEX]: 'D_BANNER',
    [DISPENSARY_INDEX]: 'BANNER'
  },
  categories: {
    elastic: ElasticFields.categories,
    [VARIANT_INDEX]: 'CATEGORY_0',
    [DISPENSARY_INDEX]: 'CATEGORY_0'
  },
  segments: {
    elastic: ElasticFields.segments,
    [VARIANT_INDEX]: 'CATEGORY_1',
    [DISPENSARY_INDEX]: 'CATEGORY_1'
  },
  subsegments: {
    elastic: ElasticFields.subsegments,
    [VARIANT_INDEX]: 'CATEGORY_2',
    [DISPENSARY_INDEX]: 'CATEGORY_2'
  },
  dispensaryIds: {
    elastic: ElasticFields.dispensaryIds,
    [VARIANT_INDEX]: 'MASTER_D_ID',
    [DISPENSARY_INDEX]: 'DISPENSARY_ID'
  },
  unit: {
    elastic: ElasticFields.unit,
    [VARIANT_INDEX]: 'VARIANTS.UNIT'
  },
  quantity: {
    elastic: ElasticFields.quantity,
    [VARIANT_INDEX]: 'VARIANTS.QUANTITY'
  },
  cmIds: {
    elastic: ElasticFields.cmIds,
    [VARIANT_INDEX]: 'CM_ID',
    [DISPENSARY_INDEX]: ''
  },
  menuIds: {
    elastic: ElasticFields.menuIds,
    [VARIANT_INDEX]: 'objectID',
    [DISPENSARY_INDEX]: ''
  },
  medical: {
    elastic: ElasticFields.medical,
    [VARIANT_INDEX]: 'VARIANTS.IS_MEDICAL',
    [DISPENSARY_INDEX]: 'MEDICAL'
  },
  recreational: {
    elastic: ElasticFields.recreational,
    [VARIANT_INDEX]: 'VARIANTS.IS_RECREATIONAL',
    [DISPENSARY_INDEX]: 'RECREATIONAL'
  },
  infusedPreRoll: {
    elastic: ElasticFields.infusedPreRoll,
    [VARIANT_INDEX]: 'INFUSED'
  },
  concentrateTypes: {
    elastic: ElasticFields.concentrateTypes,
    [VARIANT_INDEX]: 'CONCENTRATE_TYPE_AO'
  },
  flavors: {
    elastic: ElasticFields.flavors,
    [VARIANT_INDEX]: 'FLAVOR'
  },
  packSizes: {
    elastic: ElasticFields.packSizes,
    [VARIANT_INDEX]: 'PACK_SIZE'
  },
  delivery: {
    elastic: ElasticFields.delivery,
    [VARIANT_INDEX]: 'D_DELIVERY_ENABLED'
  },
  licenseNumbers: {
    [DISPENSARY_INDEX]: 'LICENSE_NUMBER'
  },
  products: {
    elastic: 'NAME',
    [VARIANT_INDEX]: 'NAME'
  },
  productPresentations: {
    elastic: ElasticFields.productPresentations,
    [VARIANT_INDEX]: 'VARIANTS.PRODUCT_PRESENTATION'
  },
  actualPrice: {
    elastic: ElasticFields.actualPrice,
    [VARIANT_INDEX]: 'VARIANTS.ACTUAL_PRICE'
  },
  strains: {
    elastic: ElasticFields.strains,
    [VARIANT_INDEX]: 'STRAIN'
  }
}

export const InverseDatabaseFilterMap = Object.entries(DatabaseFilterMap).reduce<
  Record<ALGOLIA_INDEX | 'elastic', Record<string, FilterKeyWithAlternativeKey>>
>((acc, filter) => {
  const filterKey = filter[0] as FilterKeyWithAlternativeKey
  Object.entries(filter[1]).forEach(([algoliaIndex, value]) => {
    if (algoliaIndex) {
      acc[algoliaIndex] = {
        ...acc[algoliaIndex],
        [value]: filterKey
      }
    }
  })
  return acc
}, {})

export const PRODUCT_SKUS_VARIANT_FIELDS = [
  DatabaseFilterMap.cmIds[VARIANT_INDEX],
  DatabaseFilterMap.menuIds[VARIANT_INDEX]
] as string[]

export enum JoinRule {
  OR = ' OR ',
  AND = ' AND '
}

export const defaultAlgoliaDispensaryFilters = ['NOT DELIVERY:"Delivery Only"']
export const elasticNotDeliveryOnly = {
  delivery: 'Delivery Only' as FilterBy['delivery']
}

export const TAG_ALGOLIA_SEPARATOR = '|'

const flagKeys = Object.keys(flagLabels)

export const normalizeFilterset = (filterset?: DraftFilterset): DraftFilterset => {
  // First move the product keys from should to the top level filters then merge with the default filterset to ensure all fields are presented
  const normalizedFilterset = filterset
    ? mergeFiltersets(emptyDraftFilterset(), {
        ...filterset,
        filters: normalizeFilters(filterset.filters)
      })
    : emptyDraftFilterset()
  return normalizedFilterset
}

// Wrapper for searchStateFromFilters
export const evaluateSearchState = (filterset?: DraftFilterset): SearchState => {
  return searchStateFromFilters(filterset?.filters || defaultFilters, VARIANT_INDEX)
}

// Converts the search state to a filterset and then applies just the visible filters before returning the result.
export const extractFilters = (searchState: SearchState, visibleFilters: FilterOption[]): Filters => {
  let filters = filtersFromSearchState(searchState, VARIANT_INDEX)
  filters = filterByFilterOptions(filters, visibleFilters)
  // Move the product keys from the top level filters to should
  filters = keysFromFiltersToShould(filters, productSkuKeys)
  return filters
}

export const searchStateFromFilters = (
  filtersToConvert: Filters,
  index: ALGOLIA_INDEX = VARIANT_INDEX
): SearchState => {
  const searchState: SearchState = {}
  // First normalize the filters:
  const filters = normalizeFilters(filtersToConvert)

  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof filters.filterBy[filterId] === 'boolean') {
      const filter = findFilterByAttribute(value[index])
      if (filter?.type === 'switch') {
        if (!searchState.refinementList) {
          searchState.refinementList = {}
        }
        searchState.refinementList[value[index] as string] = [filters.filterBy[filterId]!.toString()]
      } else if (value[index]) {
        if (searchState.toggle === undefined) {
          searchState.toggle = {}
        }
        searchState.toggle[value[index] as string] = filters.filterBy[filterId] as boolean
      }
    } else if (filters.filterBy[filterId] && value[index] && isPlainObject(filters.filterBy[filterId])) {
      if (!searchState.range) {
        searchState.range = {}
      }
      searchState.range[value[index] as string] = filters.filterBy[filterId] as { min: number; max: number }
    } else if (
      filters.filterBy[filterId] &&
      (filters.filterBy[filterId] as string | string[])?.length &&
      typeof value[index] === 'string'
    ) {
      if (!searchState.refinementList) {
        searchState.refinementList = {}
      }
      searchState.refinementList[value[index] as string] = filters.filterBy[filterId] as string[]
    }
  }

  if (filters.tags?.length) {
    if (!searchState.refinementList) {
      searchState.refinementList = {}
    }
    searchState.refinementList['TAG'] = filters.tags
      .map((tag) => tag.types.map((tagType) => `${tag.tagId}${TAG_ALGOLIA_SEPARATOR}${tagType}`))
      .flat()
  }

  if (filters.keywordSearch) {
    searchState.query = filters.keywordSearch
  }
  return searchState
}

export const tagFiltersFromSearchStateTags = (tags: string[]): TagFilter[] => {
  return tags.reduce<TagFilter[]>((acc, tag) => {
    const [tagId, type] = tag.split(TAG_ALGOLIA_SEPARATOR)
    const existingTag = acc.find((t) => t.tagId === tagId)
    if (existingTag) {
      existingTag.types.push(type as TagFilterTarget)
    } else {
      acc.push({
        tagId,
        types: [type as TagFilterTarget]
      })
    }
    return acc
  }, [])
}

export const filtersFromSearchState = (searchState: SearchState, index: ALGOLIA_INDEX = VARIANT_INDEX): Filters => {
  const filters = getDefaultFilters()

  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof value[index] === 'string') {
      const refinementListKey = value[index] as string
      if (searchState.refinementList?.[refinementListKey] && searchState.refinementList?.[refinementListKey].length) {
        const filter = findFilterByAttribute(refinementListKey)
        if (filter?.type === 'switch') {
          filters.filterBy[filterId] = (searchState.refinementList[refinementListKey][0] === 'true') as any
        } else {
          filters.filterBy[filterId] = searchState.refinementList[refinementListKey] as any
        }
      } else if (searchState.toggle?.[refinementListKey]) {
        filters.filterBy[filterId] = searchState.toggle[refinementListKey] as any
      } else if (searchState.range?.[refinementListKey]) {
        // range refinementListKey needs to exist and be set to undefined
        // or else previous range filter will persist
        if (isEmptyObject(searchState.range[refinementListKey])) {
          filters.filterBy[filterId] = undefined
        } else {
          filters.filterBy[filterId] = searchState.range[refinementListKey] as any
        }
      }
    }
  }

  if (searchState.refinementList?.['TAG']) {
    filters.tags = tagFiltersFromSearchStateTags(searchState.refinementList['TAG'])
  }
  if (searchState.query) {
    filters.keywordSearch = searchState.query
  }
  return filters
}

export const activeFilterCount = (filtersToCount: Filters, filterOptions: FilterOption[]): number => {
  // First normalize the filters:
  const filters = normalizeFilters(filtersToCount)
  let count = 0
  const filterKeys = filterOptions.map((fo) => fo.filterKey).flat()
  if (filters.keywordSearch && filterKeys.includes(KEYWORD_SEARCH_FILTER.filterKey)) {
    count++
  }
  if (filters.tags?.length && filterKeys.includes(TagFilterOption.filterKey)) {
    count += filters.tags.reduce((acc, tag) => acc + tag.types.length, 0)
  }

  const refinementFilterKeys = filterOptions
    .filter((fo) => ['refinement-list', 'refinement-product-sku-list'].includes(fo.type))
    .flatMap((fo) => fo.filterKey) as FilterKeys
  refinementFilterKeys.forEach((facet) => {
    if ((filters.filterBy[facet] as string | string[])?.length !== undefined) {
      count += (filters.filterBy[facet] as string | string[])?.length || 0
    }
  })

  const numericFilterKeys = filterOptions
    .filter((fo) => ['numeric-range'].includes(fo.type))
    .flatMap((fo) => fo.filterKey) as FilterKeys
  numericFilterKeys.forEach((facet) => {
    const rangeFilter = filters.filterBy[facet] as NumericFilter
    if (rangeFilter?.min || rangeFilter?.max) {
      count += 1
    }
  })

  const toggleFilterKeys: FilterFlagKey[] = filterOptions
    .filter((fo) => ['toggle', 'switch'].includes(fo.type))
    .flatMap((fo) => fo.filterKey) as FilterFlagKey[]
  count += toggleFilterKeys.filter(
    (a) => filterKeys.includes(a as FilterFlagKey) && Object.keys(filters.filterBy).includes(a)
  ).length

  return count
}

export const activeFilters = (filtersToCheck: Filters, filterOptions: FilterOption[]): FilterOption[] => {
  // First normalize the filters:
  const filters = normalizeFilters(filtersToCheck)
  const activeFilters: FilterOption[] = []
  for (const filterOption of filterOptions) {
    const filterKey = filterOption.filterKey
    if (filterKey === 'keywordSearch' && filters.keywordSearch) {
      activeFilters.push(filterOption)
    } else if (filterKey === 'tags' && filters.tags?.length) {
      activeFilters.push(filterOption)
    } else if (typeof filterKey === 'string') {
      const filterValue = filters.filterBy[filterKey as FilterKey]
      if (isPlainObject(filterValue)) {
        // for numeric range filters
        activeFilters.push(filterOption)
      } else if (
        typeof filterValue === 'boolean' ||
        ((filterValue as string | string[])?.length !== undefined && (filterValue as string | string[]).length > 0)
      ) {
        activeFilters.push(filterOption)
      }
    } else if (Array.isArray(filterKey)) {
      const filterValues = filterKey.map((key) => filters.filterBy[key]) as (string | boolean | string[] | undefined)[]
      if (
        filterValues.some((value) => typeof value === 'boolean' || (value?.length !== undefined && value.length > 0))
      ) {
        activeFilters.push(filterOption)
      }
    }
  }
  return activeFilters
}

//Adds a static filter e.g. (CM_ID, objectID, dispensaryId) and removes conflicting filters from filter options
export const filterByStaticFilter = (
  searchState: SearchState,
  filterName: string,
  filterValue: string,
  removeFacets: any,
  index: ALGOLIA_INDEX = VARIANT_INDEX
): SearchState => {
  const modifiedSearchState = clone(searchState)
  if (!modifiedSearchState.refinementList) {
    modifiedSearchState.refinementList = {}
  }
  const refinementListKey = DatabaseFilterMap[filterName as FilterKey][index] as string
  if (!modifiedSearchState.refinementList[refinementListKey]) {
    modifiedSearchState.refinementList[refinementListKey] = []
  }
  if (!modifiedSearchState.refinementList[refinementListKey].includes(filterValue)) {
    modifiedSearchState.refinementList[refinementListKey].push(filterValue)
  }

  // Remove all other existing dispensary-related filters as they could conflict with the
  // static filter
  const dispensaryFacets: Array<FilterKey> = removeFacets
  dispensaryFacets.forEach((facet) => {
    const refinementListKey = DatabaseFilterMap[facet][index] as string
    if (modifiedSearchState.refinementList?.[refinementListKey]) {
      delete modifiedSearchState.refinementList[refinementListKey]
    }
  })

  return modifiedSearchState
}

interface RefinementFilter {
  attribute: string
  values?: string[]
  index?: 'products' | 'dispensaries'
}

export const CATEGORY_SEPARATOR = ' > '

export const filtersToFiltersString = (
  filters: Filters,
  index: ALGOLIA_INDEX = VARIANT_INDEX,
  joinRule: JoinRule = JoinRule.AND
) => {
  const filterStrings = []
  const databaseKeys = Object.entries(DatabaseFilterMap).filter(
    ([, value]) => typeof value[index] === 'string' && value[index]
  )

  const refinementFilters: RefinementFilter[] = databaseKeys.map(([key, value]) => ({
    attribute: value[index] as string,
    values: filters.filterBy[key as FilterKey] as string[]
  }))

  filterStrings.push(...refinementFilters.map((f) => facetListToFilterString(f)).filter(Boolean))

  if (filters.should) {
    filterStrings.push(...shouldToFilterStrings(filters.should, index))
  }

  if (Object.keys(filters.mustNot || {}).length) {
    const mustNotFilters: RefinementFilter[] = databaseKeys.map(([key, value]) => ({
      attribute: value[index] as string,
      values: filters.mustNot?.[key as FilterKey] as string[]
    }))
    filterStrings.push(
      ...mustNotFilters
        .map((f) => facetListToFilterString(f, JoinRule.AND, (attribute, value) => `NOT ${attribute}:"${value}"`))
        .filter(Boolean)
    )
  }

  return filterStrings.join(joinRule)
}

export const shouldToFilterStrings = (shouldList: Should[], index: ALGOLIA_INDEX): string[] => {
  const filterStrings: string[] = []

  shouldList.forEach((shouldItem) => {
    if (shouldItem.filterBy) {
      if (!shouldItem.minimumShouldMatch || shouldItem.minimumShouldMatch < 2) {
        const filters: Filters = { ...defaultFilters, filterBy: shouldItem.filterBy }
        const shouldString = filtersToFiltersString(filters, index, JoinRule.OR)
        if (shouldString) {
          filterStrings.push(`(${shouldString})`)
        }
      } else {
        // Complex logic for grouping items accordingly with minimumShouldMatch:
        // Ex: filterBy: { unit: ['mg'], quantity: ['1','2'] } should return:
        // ((unit='mg' AND quantity='1') OR (unit='mg' AND quantity='2'))

        // Get the valid keys with non empty values for the given algolia index
        const databaseKeys = Object.entries(DatabaseFilterMap).filter(
          ([key, value]) =>
            typeof value[index] === 'string' &&
            value[index] &&
            (typeof shouldItem.filterBy?.[key as FilterKey] === 'boolean' ||
              (shouldItem.filterBy?.[key as FilterKey] as string[])?.length)
        )

        // Format values to [[unit='mg'], [quantity='1', quantity='2']]
        const filters: string[][] = databaseKeys.map(([key, value]) => {
          if (typeof shouldItem?.filterBy?.[key as FilterKey] === 'boolean') {
            return [`${value[index]}:"${shouldItem?.filterBy?.[key as FilterKey]}"`]
          } else {
            return (shouldItem?.filterBy?.[key as FilterKey] as string[]).map((item) => `${value[index]}:"${item}"`)
          }
        })
        // Iterate through the items creating all possible unique combination containing the minimumShouldMatch fields
        const uniqueCombinations: string[] = []
        for (let i = 0; i <= filters.length - shouldItem.minimumShouldMatch; i++) {
          // Creates a list that will hold all the combinations for the current field
          let fieldCombinations: string[][] = [...filters[i].map((item) => [item])]
          for (let j = 1; j < (shouldItem.minimumShouldMatch ?? 1); j++) {
            // Create a temporary list to not mutate fieldCombinations while iterating it
            const tempList: string[][] = []
            for (let k = i + j; k < filters.length; k++) {
              filters[k].forEach((item) => {
                fieldCombinations.forEach((leftItems) => {
                  // Prevent duplicated values from being inserted in the combination
                  if (!leftItems.includes(item)) {
                    tempList.push([...leftItems, item])
                  }
                })
              })
            }
            // Update the combinations list with the tempList
            fieldCombinations = tempList
          }
          // Join the inner fields with AND logic and push them to the combinations list
          uniqueCombinations.push(...fieldCombinations.map((rules) => `(${rules.join(JoinRule.AND)})`))
        }
        // Join all the combinations with OR in case there're valid combinations
        if (uniqueCombinations.length) {
          filterStrings.push(`(${uniqueCombinations.join(JoinRule.OR)})`)
        }
      }
    }
    // Recursively call should logic
    if (shouldItem.should?.length) {
      const joinLogic = !shouldItem.minimumShouldMatch || shouldItem.minimumShouldMatch < 2 ? JoinRule.OR : JoinRule.AND
      const shouldStringList = shouldToFilterStrings(shouldItem.should, index)
      if (shouldStringList.length) {
        filterStrings.push(`(${shouldStringList.join(joinLogic)})`)
      }
    }
  })

  return filterStrings
}

export const categoryToFilterStrings = (hierrarchicalCategory: string): string[] => {
  const [category, segment, subSegment] = hierrarchicalCategory.split(CATEGORY_SEPARATOR)
  const filterStrings = [`CATEGORY_0:"${category}"`]
  if (segment) {
    filterStrings.push(`CATEGORY_1:"${category}${CATEGORY_SEPARATOR}${segment}"`)
  }
  if (subSegment) {
    filterStrings.push(`CATEGORY_2:"${category}${CATEGORY_SEPARATOR}${segment}${CATEGORY_SEPARATOR}${subSegment}"`)
  }
  return filterStrings
}

const facetListToFilterString = (
  { attribute, values }: RefinementFilter,
  joinRule: JoinRule = JoinRule.OR,
  getFacetValue?: (attribute: string, value: string) => string
) => {
  return values && values.length
    ? `(${values
        .filter((v) => v)
        .map((facetValue) => getFacetValue?.(attribute, facetValue) || `${attribute}:"${facetValue}"`)
        .join(`${joinRule}`)})`
    : typeof values === 'boolean'
    ? `${attribute}:${values}`
    : ''
}

export const mergeFilterByKey = (
  filterKey: FilterKey,
  valueA?: FilterBy[keyof FilterBy],
  valueB?: FilterBy[keyof FilterBy]
): FilterBy[keyof FilterBy] => {
  if (valueA === undefined && valueB === undefined) {
    return undefined
  }
  if (!flagKeys.includes(filterKey) && (Array.isArray(valueA) || Array.isArray(valueB))) {
    return mergeArrays(valueA as string[], valueB as string[]) as any
  } else if (isPlainObject(valueA) || isPlainObject(valueB)) {
    return {
      ...((valueA ?? {}) as NumericFilter),
      ...((valueB ?? {}) as NumericFilter)
    } as any
  } else {
    const flagAIsSet = typeof valueA === 'boolean'
    const flagBIsSet = typeof valueB === 'boolean'
    if (flagAIsSet && flagBIsSet && valueA !== valueB) {
      throw new Error(`Cannot merge two filtersets if both define different ${flagLabels[filterKey as FilterFlagKey]}`)
    }
    if (flagAIsSet || flagBIsSet) {
      return (valueA ?? valueB) as any
    }
  }
}

export const mergeFiltersets = (filterset1?: DraftFilterset, filterset2?: DraftFilterset): DraftFilterset => {
  if (!filterset1) {
    return filterset2 || emptyDraftFilterset()
  }
  if (!filterset2) {
    return filterset1 || emptyDraftFilterset()
  }
  if (filterset1.id && filterset2.id) {
    throw new Error('Cannot merge two filtersets if both have IDs')
  }
  if (filterset1.filters.keywordSearch && filterset2.filters.keywordSearch) {
    throw new Error('Cannot merge two filtersets if both define a keyword search')
  }

  if (filterset1.filters.filterBy.actualPrice && filterset2.filters.filterBy.actualPrice) {
    throw new Error('Cannot merge two filtersets if both define a price filter')
  }
  // Normalize the filters and check if at least one of the filtersets has a should
  const hasShould = !!filterset1.filters.should?.length || !!filterset2.filters.should?.length
  const normalizedFilterset1 = {
    ...filterset1,
    filters: normalizeFilters(filterset1.filters)
  }
  const normalizedFilterset2 = {
    ...filterset2,
    filters: normalizeFilters(filterset2.filters)
  }

  const mergedFilterset: DraftFilterset = clone(normalizedFilterset1)

  for (const [key, value] of Object.entries(DatabaseFilterMap)) {
    const filterId = key as FilterKey
    if (typeof value[VARIANT_INDEX] === 'string') {
      const valueA = normalizedFilterset1.filters.filterBy[filterId]
      const valueB = normalizedFilterset2.filters.filterBy[filterId]
      const mergedValues = mergeFilterByKey(filterId, valueA, valueB) as any
      if (mergedValues !== undefined) {
        mergedFilterset.filters.filterBy[filterId] = mergedValues
      }
    }
  }

  if (normalizedFilterset1.filters.tags?.length || normalizedFilterset2.filters.tags?.length) {
    mergedFilterset.filters.tags = mergeTagFilters(
      normalizedFilterset1.filters.tags ?? [],
      normalizedFilterset2.filters.tags ?? []
    )
  }

  mergedFilterset.filters.keywordSearch =
    normalizedFilterset1.filters.keywordSearch || normalizedFilterset2.filters.keywordSearch || ''

  // If at least one of the filtersets had a should, move the product keys to the should
  if (hasShould) {
    return {
      ...mergedFilterset,
      filters: keysFromFiltersToShould(mergedFilterset.filters, productSkuKeys)
    }
  }

  return mergedFilterset
}

export const getFlagFilters = (filters: FilterBy, byValue?: boolean) => {
  return Object.entries(filters)
    .filter(([, value]) => typeof value === 'boolean' && (byValue === undefined || value === byValue))
    .map(([key]) => key)
}

export const applyFilterOverrides = (
  filterset: DraftFilterset,
  overrides?: DraftFilterset,
  overrideUndefinedFlags = false
): DraftFilterset => {
  if (!overrides) {
    return filterset
  }
  const newFilterset = clone(filterset)
  for (const [key, value] of Object.entries(overrides.filters.filterBy)) {
    // was losing numeric-range here
    if (
      Array.isArray(value) ||
      typeof value === 'boolean' ||
      typeof value === 'string' ||
      isPlainObject(value) ||
      isPlainObject(filterset.filters.filterBy[key as FilterKey])
    ) {
      newFilterset.filters.filterBy[key as FilterKey] = value as any
    }
  }
  if (
    typeof overrides.filters.keywordSearch === 'string' &&
    (overrides.filters.keywordSearch.length || overrideUndefinedFlags)
  ) {
    newFilterset.filters.keywordSearch = overrides.filters.keywordSearch
  }

  // Override the tags if they are defined in the overrides
  if (overrides.filters.tags?.length) {
    newFilterset.filters.tags = overrides.filters.tags
  }

  // Override the "should logic" if it is defined in the overrides
  if (overrides.filters.should !== undefined) {
    newFilterset.filters.should = overrides.filters.should
  }

  // If true, make sure to remove flag filters that are undefined on overrides (med/rec)
  if (overrideUndefinedFlags) {
    flagKeys.forEach((key) => {
      if (typeof overrides.filters.filterBy[key as FilterKey] !== 'boolean') {
        delete newFilterset.filters.filterBy[key as FilterKey]
      }
    })
  }
  return newFilterset
}

export const findFilterByAttribute = (attribute: string | undefined) => {
  return ALL_FILTERS.find((f) => {
    if ('attributes' in f && typeof attribute === 'string') {
      return f.attributes.includes(attribute)
    }
    if ('attribute' in f) {
      return f.attribute === attribute
    }
    return false
  })
}

export const getAttributeValue = (
  value: string,
  attribute: string,
  currentRefinement?: string,
  filter?: FilterOption
) => {
  // Product/Sku filters use the current refinement value
  if (attribute === 'CM_ID' || attribute === 'objectID') {
    return currentRefinement as string
  }
  // Special case for keyword search filters
  if (value.startsWith('query: ')) {
    return value.replace('query: ', '')
  }
  // Hierarchical filters use the last part of the label
  if (['CATEGORY_0', 'CATEGORY_1', 'CATEGORY_2'].includes(attribute as string)) {
    return value.split(' > ').pop() as string
  }
  // This is a switch filter
  const filterData = filter || findFilterByAttribute(attribute)
  if (filterData?.type === 'switch') {
    return value === 'true' ? filterData.attributeTrueLabel : filterData.attributeFalseLabel
  }

  return value
}

export const getAttributeLabel = (attribute: string | undefined, options?: { plural: boolean }) => {
  const filter = findFilterByAttribute(attribute)
  const label = filter?.tagLabel || filter?.label || attribute?.toString() || 'Search'
  return options?.plural ? label : singular(label)
}

export const filterByFilterOptions = (filters: Filters, filterOptions: FilterOption[]): Filters => {
  const newFilters: Filters = {
    filterBy: {},

    keywordSearch: ''
  }
  for (const filterOption of filterOptions) {
    if (filterOption.filterKey === 'keywordSearch') {
      newFilters.keywordSearch = filters.keywordSearch
    } else if (filterOption.filterKey === 'tags') {
      newFilters.tags = filters.tags
    } else {
      const filterKeys = Array.isArray(filterOption.filterKey) ? filterOption.filterKey : [filterOption.filterKey]
      filterKeys.forEach((filterKey) => {
        if (filterKey in filters.filterBy) {
          newFilters.filterBy[filterKey] = filters.filterBy[filterKey] as any
        }
      })
    }
  }
  return newFilters
}

export const truncateSegmentedFilterStr = (str: string, separator = CATEGORY_SEPARATOR, length = 7) => {
  const parts = str.split(separator)
  if (parts.length <= 2) {
    return str
  }
  for (let i = 1; i < parts.length - 1; i++) {
    parts[i] = parts[i].length > length ? `${parts[i].slice(0, length).trimEnd()}...` : parts[i]
  }
  return parts.join(separator)
}

/**
 * Convert the attribute and attributtes from the given FilterOption list according to the index fields
 * @param filterOptions A list of FilterOption
 * @param index The index to convert
 * @returns A list of FilterOption with the attributes converted
 */
export const getIndexFilterOptions = (filterOptions: FilterOption[], index: ALGOLIA_INDEX | 'elastic') => {
  // By default the attributes are based on the VARIANT_INDEX, so we don't need to convert it
  if (index === VARIANT_INDEX) {
    return filterOptions
  }
  return filterOptions.reduce((acc, option) => {
    if (option.filterKey === 'keywordSearch') {
      acc.push(option)
    } else if (option.filterKey === 'tags') {
      acc.push(option)
    } else {
      const keys = [option.filterKey].flat().map((key: FilterKey) => DatabaseFilterMap[key][index])
      if ((option as any).attribute && keys[0]) {
        acc.push({ ...option, attribute: keys[0] } as FilterOption)
      }
      if ((option as any).attributes && keys.every(Boolean)) {
        acc.push({ ...option, attributes: keys } as FilterOption)
      }
    }
    return acc
  }, [] as FilterOption[])
}

/**
 * Get the attributes from the given FilterOption list according to the index fields
 * @param filterOptions A list of FilterOption
 * @param [index] If provided will convert the attributes according to the index fields
 * @returns A list of attributes
 */
export const getFilterAttributes = (filterOptions: FilterOption[], index?: ALGOLIA_INDEX | 'elastic') => {
  const convertedFilters = index ? getIndexFilterOptions(filterOptions, index) : filterOptions
  return convertedFilters.reduce((acc, option) => {
    if ((option as any).attribute) {
      acc.push((option as any).attribute)
    } else if ((option as any).attributes) {
      acc.push(...(option as any).attributes)
    } else if ((option as any).filterKey === 'keywordSearch') {
      acc.push('query')
    }
    return acc
  }, [] as string[])
}

/**
 * Apply the default algolia dispensary filters to the given filters
 * @param filters A filter string or Filters object to apply the default algolia dispensary filters
 * @returns A string with the algolia dispensary filters applied
 */
export const applyDefaultAlgoliaDispensaryFilters = (filters?: string | Filters) => {
  if (filters !== undefined && typeof filters !== 'string') {
    filters = filtersToFiltersString(filters, DISPENSARY_INDEX)
  }
  const filterList = [...defaultAlgoliaDispensaryFilters]
  if (filters) {
    // Check if the filters are already wrapped in parenthesis
    filterList.push(filters.startsWith('(') ? filters : `(${filters})`)
  }
  return filterList.join(JoinRule.AND)
}

export const FORCE_NO_RESULTS_FILTER = 'force-no-results'

export const intersectFiltersList = (filters: Filters, list: string[], listKey: FilterKeyType<string[]>) => {
  if (!list.length) {
    return filters
  }
  // Create a new filters object to avoid mutating the original
  const newFilters = clone(filters)
  const originalList = newFilters.filterBy[listKey]
  // If there are no current filters, return all the new items for the given key
  if (!originalList?.length) {
    newFilters.filterBy[listKey] = list
  } else {
    // Otherwise, return the current filters that are also in the given list of items
    newFilters.filterBy[listKey] = originalList.filter((filter) => list.includes(filter))
    // If there are no filters overlapping, return a filter that will force no results
    if (!newFilters.filterBy[listKey]?.length) {
      newFilters.filterBy[listKey] = [FORCE_NO_RESULTS_FILTER]
    }
  }
  return newFilters
}

export const getBlockingTagTarget = (
  filterKey: FilterOption['filterKey'],
  blockedFilters?: Partial<Record<FilterKey, TagFilterTarget>>
): TagFilterTarget | undefined => {
  if (!blockedFilters) {
    return undefined
  }
  if (typeof filterKey === 'string') {
    return blockedFilters[filterKey as FilterKey]
  }
  return filterKey.map((key) => blockedFilters[key as FilterKey]).filter(Boolean)?.[0]
}

/**
 * Given a list of FilterOptions, return the list of tag targets present in the filter options
 */
export const getTagTargetsFromFilterOptions = (filterOptions?: FilterOption[]) => {
  if (!filterOptions?.length) {
    return []
  }
  const filterKeys = filterOptions.reduce<string[]>((acc, { filterKey }) => {
    return Array.isArray(filterKey) ? [...acc, ...filterKey] : [...acc, filterKey]
  }, [])
  return TAG_FILTER_TARGETS.filter((target) =>
    filterTagTargetToFilterKey[target].some((key) => filterKeys.includes(key))
  )
}

export const getAlgoliaInvalidFilterKeys = (options: {
  attribute: string
  value: string
  index: ALGOLIA_INDEX
  invalidFilters?: InvalidFiltersMap
}): FilterKey[] => {
  const { attribute, value, index, invalidFilters } = options
  // If there are no invalid filters, the attribute is valid
  if (!invalidFilters) {
    return []
  }
  // Handle tag filters
  if (attribute === TagFilterOption.attribute && value) {
    const [, tagType] = value.split(TAG_ALGOLIA_SEPARATOR)
    return filterTagTargetToFilterKey[tagType as TagFilterTarget].some((invalidKey) => !!invalidFilters[invalidKey])
      ? filterTagTargetToFilterKey[tagType as TagFilterTarget]
      : []
  }
  // Handle database filters
  const filterKey = InverseDatabaseFilterMap[index][attribute] as FilterKey
  if (filterKey) {
    const invalidData = invalidFilters[filterKey]
    if (invalidData?.invalidateKey) {
      return [filterKey]
    }
    if (value && invalidData?.invalidateValues) {
      if (Array.isArray(invalidData.invalidateValues)) {
        return invalidData.invalidateValues.includes(value) ? [filterKey] : []
      }
      if (`${invalidData.invalidateValues}` === value) {
        return [filterKey]
      }
    }
  }
  return []
}
