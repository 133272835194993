import { ContentCopy } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { memo, useCallback, useState } from 'react'
import { palette } from '@client/styles/theme/palette'

export interface CopyToClipboardProps {
  text: string
  size?: 'small' | 'medium' | 'large'
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = memo(function CopyToClipboard({ text, size }) {
  const [tooltipText, setTooltipText] = useState('Copy to clipboard')

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText('Copied!')
      setTimeout(() => setTooltipText('Copy to clipboard'), 2000)
    })
  }, [text])

  return (
    <Tooltip title={tooltipText}>
      <IconButton onClick={onCopy} size={size} sx={{ color: palette.medium }} data-testid="copy-to-clipboard">
        <ContentCopy fontSize={size} />
      </IconButton>
    </Tooltip>
  )
})
