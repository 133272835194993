import { Typography as MuiTypography } from '@mui/material'
import { typography as typographySystem } from '@mui/system'
import styled from 'styled-components'

export const Typography = styled(MuiTypography)(typographySystem)

export interface Typography {
  type: {
    primary: string
  }
  weight: {
    extraThin: string
    thin: string
    regular: string
    semiBold: string
    bold: string
  }
  size: {
    xs1: number
    xs2: number
    xs3: number
    s1: number
    s2: number
    s3: number
    m1: number
    m2: number
    m3: number
    l1: number
    l2: number
    l3: number
    size_64: number
  }
  button: {
    textTransform: any //TextTransform | undefined & string throws ts error
  }
}
// Module Augmentation to expand the Theme
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends Typography {}
}

export const fontSizePx = {
  xs1: 8,
  xs2: 10,
  xs3: 11,
  s1: 12,
  s2: 14,
  s3: 16,
  m1: 20,
  m2: 22,
  m3: 24,
  l1: 30,
  l2: 32,
  l3: 34,
  size_64: 64
}

function rem(pxValue: number) {
  return `${pxValue / 16}rem`
}

export const convertPxToRemUnit = Object.fromEntries(
  Object.entries(fontSizePx).map(([k, v]: [unknown, number]) => {
    return [k, rem(v)]
  })
)

export const typography: Typography = {
  type: {
    primary: '"Nunito Sans", Arial, sans-serif;'
  },
  weight: {
    extraThin: '200',
    thin: '300',
    regular: '400',
    semiBold: '600',
    bold: '700'
  },
  size: convertPxToRemUnit,
  button: {
    textTransform: 'none'
  }
}
