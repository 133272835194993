import { DraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { useMemo } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { ALGOLIA_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import {
  FilterOption,
  filtersFromSearchState,
  filtersToFiltersString,
  getFilterAttributes,
  getIndexFilterOptions
} from '@client/types/filterset'
import { useNumActiveFilters } from './use-num-active-filters'

interface UseInstantSearchVariablesArgs {
  searchState: SearchState
  visibleFilters: FilterOption[]
  preFilterset?: DraftFilterset
  index?: ALGOLIA_INDEX
}

export const useInstantSearchVariables = ({
  visibleFilters,
  preFilterset,
  searchState,
  index = VARIANT_INDEX
}: UseInstantSearchVariablesArgs) => {
  const filters = useMemo(() => filtersFromSearchState(searchState, index), [index, searchState])
  const numActiveFilters = useNumActiveFilters(filters, visibleFilters)

  const preFiltersString = useMemo(() => {
    return preFilterset ? filtersToFiltersString(preFilterset.filters, index) : ''
  }, [preFilterset, index])

  const displayFilters = useMemo(() => getIndexFilterOptions(visibleFilters, index), [index, visibleFilters])
  const filterAttributes = useMemo(() => getFilterAttributes(displayFilters), [displayFilters])

  return {
    displayFilters,
    filterAttributes,
    filters,
    numActiveFilters,
    preFiltersString
  }
}
