import { DebugPopover, DebugPopoverProps } from '@client/components/debug-popover/DebugPopover'
import { EventArgs } from '@client/configs/tracking'
import { urls } from '@client/helpers/urls'
import { getUserFullName, getUserInitials } from '@client/helpers/user'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useLogout } from '@client/hooks/use-logout'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { UserProfileTour } from '@client/pages/user-profile/components/user-profile-tour/UserProfileTour'
import { useUser } from '@client/providers/user'
import { UserData } from '@client/services/hasura'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { AccountSummary } from '@client/types/account'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { AccountBox } from '@mui/icons-material'
import { Divider, IconButton, IconButtonProps, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import React, { memo, useCallback, useMemo, useRef } from 'react'
import { LogOut } from 'react-feather'
import { TrackablePopupMenuItem } from '../Trackables'
import * as S from './UserAvatar.style'

export interface UserAvatarProps extends Omit<IconButtonProps, 'onClick'> {
  userData?: UserData
  account?: AccountSummary
}

export const UserAvatar: React.FC<UserAvatarProps> = memo(function UserAvatar({ account, userData, size, ...rest }) {
  const { userToken } = useUser()
  const { logout } = useLogout()
  const navigate = useNavigateRef()

  const initials = useMemo(() => (userData ? getUserInitials(userData) : null), [userData])
  const name = useMemo(() => (userData ? getUserFullName(userData) : ''), [userData])

  const avatarClasses = S.useAvatarClasses({ initials })
  const avatarStyles = S.useAvatarStyles()

  const menuAnchor = useRef<HTMLButtonElement>(null)
  const { toggleValue: toggleMenu, value: isMenuOpen, setTrue: openMenu, setFalse: closeMenu } = useBooleanState(false)

  const userProfileEvent: EventArgs = useMemo(
    () => ({
      category: TrackableCategory.navigation,
      action: TrackableAction.userProfileClicked,
      dimension1: userData?.email
    }),
    [userData?.email]
  )

  const debugPopoverProps: DebugPopoverProps = useMemo(() => {
    const summaryData = {
      userId: userData?.userId,
      email: userData?.email,
      jwt: userToken
    }
    return {
      title: 'User',
      summaryData,
      detailedData: {
        ...summaryData,
        ...userData
      }
    }
  }, [userData, userToken])

  const navigateToUserProfile = useCallback(() => {
    navigate.current(urls.userProfile())
    closeMenu()
  }, [closeMenu, navigate])

  return (
    <>
      <IconButton
        data-testid="user-menu--button"
        id="user-avatar--dropdown-trigger"
        {...rest}
        onClick={toggleMenu}
        size={size || 'medium'}
        ref={menuAnchor}
      >
        <S.Avatar
          src={userData?.iconImageUrl || undefined}
          alt={name}
          classes={avatarClasses}
          className={avatarStyles[size || 'medium']}
          data-testid="avatar"
        >
          {initials}
        </S.Avatar>
      </IconButton>

      <UserProfileTour closeMenu={closeMenu} openMenu={openMenu} />

      <PopupMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={menuAnchor.current}
        onClose={() => closeMenu()}
        open={isMenuOpen}
      >
        <ListItem data-testid="menu-item--user-details">
          <ListItemIcon sx={{ pr: 2, minWidth: 'auto' }}>
            <S.Avatar
              src={userData?.iconImageUrl || undefined}
              alt={name}
              classes={avatarClasses}
              className={avatarStyles['small']}
            >
              {initials}
            </S.Avatar>
          </ListItemIcon>
          <ListItemText
            primary={name || 'Unknown user'}
            secondary={account?.name}
            primaryTypographyProps={{ fontSize: 14, lineHeight: '16px' }}
            secondaryTypographyProps={{ fontSize: 10 }}
            sx={{ pr: 5 }}
          />
          <DebugPopover {...debugPopoverProps} sx={{ ml: 2 }} />
        </ListItem>

        <Divider />

        <TrackablePopupMenuItem
          gaEvent={userProfileEvent}
          data-testid="menu-item--user-profile"
          onClick={navigateToUserProfile}
          id="user-avatar--dropdown--profile"
        >
          <ActionIcon>
            <AccountBox />
          </ActionIcon>
          <ListItemText primary="Your Profile" />
        </TrackablePopupMenuItem>

        <PopupMenuItem data-testid="sign-out-avatar" onClick={logout}>
          <ActionIcon>
            <LogOut size={18} />
          </ActionIcon>
          <ListItemText primary="Sign out" />
        </PopupMenuItem>
      </PopupMenu>
    </>
  )
})

const ActionIcon = styled(ListItemIcon)`
  min-width: 32px !important;
  padding-right: 8px;

  & > * {
    margin: 0 auto;
  }
`
