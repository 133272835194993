import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface SidebarState {
  isOpen: boolean | undefined
  openSidebar: () => void
  closeSidebar: () => void
}

export const useSidebar = create(
  devtools<SidebarState>((set) => ({
    isOpen: undefined,
    openSidebar: () => set({ isOpen: true }, false, 'openSidebar'),
    closeSidebar: () => set({ isOpen: false }, false, 'closeSidebar')
  }))
)
