export interface Breakpoints {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1440
}
