import deepEqual from 'fast-deep-equal'

/**
 * Creates a shallow clone of object excluding the specified properties
 *
 * Equivalent of lodash.omit
 */
export const omit = (obj: Record<string, unknown>, props: string[]) => {
  obj = { ...obj }
  props.forEach((prop) => delete obj[prop])
  return obj
}

// should be equivalent to lodash isEmpty
export function isEmptyObject(obj: Record<string, unknown>) {
  return Object.values(obj).every((value: unknown) => {
    if (Array.isArray(value)) {
      return value.length === 0
    }
    return !value
  })
}

export function getValueByPath<TValue = unknown>(item: Record<string, any>, path: string) {
  const keys = path.split('.')
  const pathSeen: string[] = []
  let acc: any = item
  for (const i in keys) {
    const key = keys[i]
    pathSeen.push(key)
    if (!acc) {
      return undefined
    }
    // If the key ends with [${num}], search for the array index
    const match = key.match(/\[(\d+)\]$/)
    if (match) {
      acc = acc[key.replace(/\[(\d+)\]$/, '')][match[1]]
      continue
    }
    // If it's an array without index, return an array of values instead, breaking the loop
    if (key.endsWith('[]') && Array.isArray(acc[key.substring(0, key.length - 2)])) {
      return acc[key.substring(0, key.length - 2)].map((childItem: any) => {
        if (typeof childItem === 'object') {
          return getValueByPath(childItem, path.substring(pathSeen.join('.').length + 1, path.length))
        }
        return childItem
      })
    }
    // Continue the loop with the next key
    acc = acc[key]
  }
  return acc as TValue
}

export const getUniqueKeysFromObjects = <T extends Record<string, unknown>>(...objects: Partial<T>[]) => {
  return [...new Set(objects.flatMap((obj) => Object.keys(obj)))]
}

/**
 * Partial comparison of properties of two objects
 * @param mainObject
 * @param subObject
 * @returns true if the main object contains all the properties of the sub object, otherwise false
 */
export function contains(mainObject: Record<string, any>, subObject: Record<string, any>) {
  return Object.keys(subObject).every((key) => deepEqual(mainObject[key], subObject[key]))
}

export const isPlainObject = (variable: any) =>
  variable !== null && typeof variable === 'object' && !Array.isArray(variable)
