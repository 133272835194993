import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { ComparisonDrawer } from './drawers/comparison'
import { FavoritesDrawer } from './drawers/favorites/FavoritesDrawer'
import { FiltersDrawer } from './drawers/filters/FiltersDrawer'
import { SaveViewDrawer } from './drawers/save-view/SaveViewDrawer'
import { ShareComparisonDrawer } from './drawers/share-comparison/ShareComparisonDrawer'
import { ShareViewDrawer } from './drawers/share-view/ShareViewDrawer'
import { ViewsDrawer } from './drawers/views'

export const RightDrawer: React.FC<any> = () => {
  return (
    <ErrorBoundary>
      <ComparisonDrawer />
      <FavoritesDrawer />
      <FiltersDrawer />
      <ViewsDrawer />
      <SaveViewDrawer />
      <ShareViewDrawer />
      <ShareComparisonDrawer />
    </ErrorBoundary>
  )
}
