export const imageMissing = '/assets/img/missing.jpg'

export const dispensaryImageMissing = '/assets/img/dispensary-cover.jpg'

export const baseImageUrl = `https://askhoodie.com/cdn-cgi/image`

export const baseS3ImageUrl = `https://data-item-images.s3.us-east-2.amazonaws.com`

// image cache widths, must be in sync with askhoodie
// as we are charged for img caching/optimization in cloudflare
export const IMG_WIDTH = {
  product: { xs: 56, sm: 180, lg: 400 },
  dispLogo: { xs: 56, sm: 120 },
  dispCover: { lg: 450 },
  brand: { sm: 120, lg: 400 }
}

export const getDefaultImage = (category2?: string, width: number | string = 640, quality: number | string = 65) => {
  category2 = category2 || ''
  if (!category2) {
    return ''
  }
  const catLevels = category2.split(' > ')
  let imageName = category2.replace(/ > /g, '-')

  if (catLevels[0] === catLevels[1]) {
    imageName = `${catLevels[0]}-N-N`
  } else if (catLevels[1] === catLevels[2]) {
    imageName = `${catLevels[0]}-${catLevels[1]}-N`
  }
  return `${baseImageUrl}/width=${width},quality=${quality}/${baseS3ImageUrl}/plh/${encodeURIComponent(imageName)}.jpg`
}
