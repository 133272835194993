import { Sentry } from '@client/configs/sentry'
import { useHasura } from '@client/providers/hasura'
import { UserDataKeys } from '@client/services/hasura'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

const queryKey = 'user-flags'

type FlagMap = Record<string, boolean>

export const useUserFlags = () => {
  const { hasuraClient } = useHasura()
  const queryClient = useQueryClient()

  const query = useQuery([queryKey], () => {
    return hasuraClient?.getUserDataByKey<FlagMap>(UserDataKeys.UserFlags, 'GetUserFlags')
  })

  const mutation = useMutation(
    async (newFlags: FlagMap) => {
      return hasuraClient?.setUserDataByKey(UserDataKeys.UserFlags, newFlags, 'SetUserFlags')
    },
    {
      onSuccess: (updatedFlags) => {
        queryClient.setQueryData([queryKey], updatedFlags)
      },
      onError: (error) => {
        Sentry.captureException(error)
      }
    }
  )

  const flags = useMemo(() => {
    return query.data
  }, [query])

  const setFlag = useCallback(
    (flag: string, value = true) => {
      const newFlags = {
        ...flags,
        [flag]: value
      }
      mutation.mutate(newFlags)
    },
    [flags, mutation]
  )

  const setFlags = useCallback(
    (flagsToSet: { [key: string]: boolean }) => {
      const newFlags = {
        ...flags,
        ...flagsToSet
      }
      mutation.mutate(newFlags)
    },
    [flags, mutation]
  )

  const isFlagSet = useCallback(
    (flag: string) => {
      return flags && Boolean(flags[flag])
    },
    [flags]
  )

  return {
    isMutating: mutation.isLoading,
    isLoading: query.isLoading,
    error: query.error,
    flags,
    isFlagSet,
    setFlag,
    setFlags
  }
}
