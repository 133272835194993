import { Box } from '@client/styles/theme/box'
import { Button as MuiButton } from '@mui/material'
import styled from 'styled-components'

export const BoxRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Button = styled(MuiButton)`
  min-width: 50px;
  white-space: nowrap;
`
