import { Box } from '@client/styles/theme/box'
import { ConnectRangeExposed, ConnectRangeProvided } from '@client/types/connectors'
import deepEqual from 'fast-deep-equal'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { NumericInput, NumericInputProps } from '../NumericInput/NumericInput'

export type MinMax = {
  min?: number
  max?: number
}

export interface RefinementNumericRangeProps
  extends Omit<NumericInputProps, 'onChange' | 'min' | 'max'>,
    ConnectRangeExposed,
    ConnectRangeProvided {
  startLabel?: string
  endLabel?: string
}

export const RefinementNumericRange: React.FC<RefinementNumericRangeProps> = memo(function RefinementNumericRange(
  props
) {
  const {
    startLabel,
    endLabel,
    min,
    max,
    currentRefinement,
    refine,
    hideActionButtons,
    numericFormatProps,
    step,
    dataTestId,
    InputProps,
    inputProps
  } = props

  const numericInputProps = useMemo(
    () => ({
      hideActionButtons,
      numericFormatProps,
      step,
      dataTestId,
      InputProps,
      inputProps
    }),
    [hideActionButtons, numericFormatProps, step, dataTestId, InputProps, inputProps]
  )

  const [range, setRange] = useState<MinMax>({
    min: undefined,
    max: undefined
  })

  const debouncedRefine = useDebouncedCallback(refine, 700)

  //make range in sync with currentRefinement on facet update and initial load
  useEffect(() => {
    const updatedMin = currentRefinement.min !== min ? currentRefinement.min : undefined
    const updatedMax = currentRefinement.max !== max ? currentRefinement.max : undefined
    setRange((prevProps) => {
      if (updatedMin !== prevProps.min || updatedMax !== prevProps.max) {
        return {
          min: updatedMin,
          max: updatedMax
        }
      }
      return prevProps
    })
  }, [currentRefinement.min, currentRefinement.max, min, max])

  useEffect(() => {
    if (min !== undefined && max !== undefined) {
      const { min: currentMin, max: currentMax } = currentRefinement
      // Adjust current refinement if out of updated bounds on facet change
      if (currentMin < min || currentMax > max) {
        const minMax = {
          min: Math.max(currentMin, min),
          max: Math.min(currentMax, max)
        }
        debouncedRefine(minMax)
      }
    }
  }, [min, max, currentRefinement, debouncedRefine])

  const handleSetMin = useCallback(
    (value?: number) => {
      setRange((prevRange) => ({ ...prevRange, min: value }))
      if (value === undefined || (value && value >= min && value <= (currentRefinement.max ?? max))) {
        debouncedRefine({ min: value, max: currentRefinement.max })
      }
    },
    [currentRefinement.max, max, min, debouncedRefine]
  )

  const handleSetMax = useCallback(
    (value?: number) => {
      setRange((prevRange) => ({ ...prevRange, max: value }))
      if (value === undefined || (value && value <= max && value >= (currentRefinement.min ?? min))) {
        debouncedRefine({ min: currentRefinement.min, max: value })
      }
    },
    [currentRefinement.min, max, min, debouncedRefine]
  )

  return (
    <Box display="flex" alignItems="center" maxWidth={300} pt={2}>
      <NumericInput
        {...numericInputProps}
        label={startLabel}
        dataTestId={'min-'}
        onChange={handleSetMin}
        defaultValue={min}
        min={min}
        max={currentRefinement.max ?? max}
        value={range.min}
      />
      <Box mx={2}>to</Box>
      <NumericInput
        {...numericInputProps}
        label={endLabel}
        dataTestId={'max-'}
        onChange={handleSetMax}
        defaultValue={max}
        min={currentRefinement.min ?? min}
        max={max}
        value={range.max}
      />
    </Box>
  )
},
deepEqual)
