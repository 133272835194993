import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  DocumentNode,
  fromPromise,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { HasuraService } from '@client/services/hasura'
import { EXPIRED_SESSION_ROUTE } from '@client/types/routes'
import { sharedEnv } from '@lib/env'
import { createClient } from 'graphql-ws'
import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'

const webSocketURL = (uri: string) => uri.replace(/^(https?|wss?)/i, 'wss')

function isSubscription(query: DocumentNode) {
  const definition = getMainDefinition(query)
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
}

export function prepareApolloClient(
  token: string | null,
  freshToken: () => Promise<string | null>,
  expiredTokenCallback: () => void
) {
  const httpLink = new HttpLink({ uri: sharedEnv.REACT_APP_HASURA_URI })
  const wsLink = new GraphQLWsLink(
    createClient({
      url: webSocketURL(sharedEnv.REACT_APP_HASURA_URI ?? ''),
      connectionParams: async () => {
        const token = await freshToken()
        return {
          headers: {
            authorization: `Bearer ${token}`
          },
          reconnect: true
        }
      }
    })
  )

  let jwtToken = token
  let isRefreshing = false
  let pendingRequests: Array<() => void> = []

  const setJwtToken = (newToken: string | null) => {
    jwtToken = newToken
  }

  const setIsRefreshing = (value: boolean) => {
    isRefreshing = value
  }

  const addPendingRequest = (pendingRequest: () => void) => {
    pendingRequests.push(pendingRequest)
  }

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
  }

  // Error Link to handle GraphQL errors
  // https://stackoverflow.com/a/63386965
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions?.code === 'invalid-jwt') {
          if (!isRefreshing) {
            setIsRefreshing(true)
            // Refresh the JWT token
            return fromPromise(
              freshToken()
                .then((newToken) => {
                  setJwtToken(newToken)
                })
                .catch(() => {
                  // If the refresh token fails, we should sign out
                  resolvePendingRequests()
                  setIsRefreshing(false)
                  setJwtToken(null)
                  expiredTokenCallback()
                  return forward(operation)
                })
            ).flatMap(() => {
              // Resolve all pending requests after the JWT token has been refreshed
              resolvePendingRequests()
              setIsRefreshing(false)
              return forward(operation)
            })
          } else {
            // If refreshing, we should store the request and wait for the new token before continuing
            return fromPromise(
              new Promise((resolve: any) => {
                addPendingRequest(() => resolve())
              })
            ).flatMap(() => {
              return forward(operation)
            })
          }
        }
      }
    }
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`
      }
    }))

    return forward(operation)
  })

  const splitLink = split(({ query }) => isSubscription(query), wsLink, concat(authMiddleware, httpLink))

  try {
    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, splitLink]),
      cache: new InMemoryCache({
        dataIdFromObject: ({ __typename, id, ...rest }) => {
          return rest[__typename + '_id'] || (id as any)
        }
      })
    })

    if (token && token.length > 0) {
      return client
    } else {
      return null
    }
  } catch (error) {
    console.error(error)
  }
}

export interface HasuraContextInterface {
  apolloClientReady: boolean
  hasuraClient: HasuraService | null
  updateApolloClient: (userToken: string) => void
}

const HasuraContext = createContext<HasuraContextInterface>({
  apolloClientReady: false,
  hasuraClient: null,
  updateApolloClient: () => {}
})

const HasuraProvider: FC<PropsWithChildren> = ({ children }) => {
  const [once, setOnce] = useState<boolean>(false)
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(null)
  const [hasuraClient, setHasuraClient] = useState<HasuraService | null>(null)
  const navigate = useNavigateRef()

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const expiredTokenCallback = useCallback(() => {
    navigate.current(EXPIRED_SESSION_ROUTE)
  }, [navigate])

  const refreshToken = useCallback(async () => {
    await getAccessTokenSilently()
    const idToken = await getIdTokenClaims()
    if (!idToken || idToken.__raw.length === 0) {
      return null
    }
    return idToken.__raw
  }, [getAccessTokenSilently, getIdTokenClaims])

  useEffect(() => {
    if (!once) {
      getIdTokenClaims()
        .then((idToken) => {
          if (idToken && idToken.__raw.length > 0) {
            const apolloClient = prepareApolloClient(idToken.__raw, refreshToken, expiredTokenCallback)

            if (apolloClient) {
              setApolloClient(apolloClient)
              setOnce(true)
            }
          }
        })
        .catch(console.error)
    }
  })

  useEffect(() => {
    if (apolloClient) {
      setHasuraClient(new HasuraService({ apolloClient: apolloClient as ApolloClient<any> }))
    }
  }, [apolloClient])

  const updateApolloClient = useCallback(
    (userToken: string) => {
      const newApolloClient = prepareApolloClient(userToken, refreshToken, expiredTokenCallback)
      if (newApolloClient) {
        setApolloClient(newApolloClient)
      }
    },
    [refreshToken, expiredTokenCallback]
  )

  return (
    <HasuraContext.Provider
      value={{
        updateApolloClient,
        apolloClientReady: hasuraClient !== null,
        hasuraClient
      }}
    >
      {hasuraClient && apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : children}
    </HasuraContext.Provider>
  )
}

function useHasura() {
  const context = useContext(HasuraContext)

  if (!context) {
    throw new Error('useHasura must be used within an HasuraProvider.')
  }

  return context
}

export { HasuraContext, HasuraProvider, useHasura }
