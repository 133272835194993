import { RefObject } from 'react'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export const APP_BAR_LEFT_BUTTONS_REF = 'APP_BAR_LEFT_BUTTONS_REF'

export interface RefsStore {
  refs: Map<string, RefObject<HTMLElement>>
  setRef: (key: string, ref: RefObject<HTMLElement>) => void
  removeRef: (key: string) => void
}

export const useRefsStore = create(
  devtools<RefsStore>(
    (set) => ({
      refs: new Map(),
      setRef: (key, ref) =>
        set(
          (state) => {
            const newData = new Map(state.refs)
            newData.set(key, ref)
            return { ...state, refs: newData }
          },
          false,
          'setRef'
        ),
      removeRef: (key) => {
        set(
          (state) => {
            const newData = new Map(state.refs)
            newData.delete(key)
            return { ...state, refs: newData }
          },
          false,
          'removeRef'
        )
      }
    }),
    {
      name: `@hoodie:refs-store`
    }
  )
)
