import { useHelpLinksStore } from '@client/stores/use-help-links-store'
import { HelpLinkData } from '@client/types/help'
import { useEffectOnce } from 'usehooks-ts'

export const useHelpLink = (linkData?: HelpLinkData) => {
  const addLink = useHelpLinksStore((state) => state.addLink)
  const removeLink = useHelpLinksStore((state) => state.removeLink)

  useEffectOnce(() => {
    if (linkData) {
      // Add the link to the store when the component mounts
      addLink(linkData)
      // Remove the link from the store when the component unmounts
      return () => removeLink(linkData.tooltip)
    }
  })
}
