import { SearchInput } from '@client/components/search-input/SearchInput'
import { useElementSize } from '@client/hooks/use-element-size'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { palette } from '@client/styles/theme/palette'
import { ChevronLeft } from '@mui/icons-material'
import { Button, ButtonProps, List, Slide, Typography } from '@mui/material'
import { ComponentType, ReactNode, memo, useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'usehooks-ts'
import { ErrorBoundary } from '../error-boundary/ErrorBoundary'

const BREAKPOINT = 'lg'

export type ListDetailViewProps<
  TItem extends Record<string, any>,
  TListItemProps extends Record<string, any>,
  TDetailViewProps extends Record<string, any>
> = {
  onItemSelect: (item?: TItem) => void
  onSearch?: (item: TItem, searchTerm: string) => boolean
  onCustomSearch?: (item: TItem) => boolean
  headerActions?: Array<ButtonProps & { 'data-testid'?: string }>
  detailProps?: TDetailViewProps
  desktopBackButton?: boolean
  itemProps?: TListItemProps
  items: TItem[]
  item?: TItem
  listHeight?: 'auto' | 'detailsHeight'
  listWidth?: number
  minHeight?: number
  mobileMaxHeight?: number
  title?: ReactNode
  noItemsMessage?: string
  DetailViewComponent: ComponentType<TDetailViewProps & { item: TItem }>
  ListItemComponent: ComponentType<
    TListItemProps & { item: TItem; onClick: () => void; selected: boolean | ((item: TItem) => boolean) }
  >
  CustomSearchComponent?: ReactNode
  FooterComponent?: ReactNode
  PlaceholderComponent?: ReactNode
}

const UnmemoedListDetailView = <
  TItem extends Record<string, any>,
  TListItemProps extends Record<string, any>,
  TDetailViewProps extends Record<string, any>
>({
  onItemSelect,
  onSearch,
  onCustomSearch,
  item,
  headerActions,
  detailProps,
  desktopBackButton = false,
  itemProps,
  items,
  listHeight = 'auto',
  listWidth = 200,
  minHeight,
  mobileMaxHeight,
  title,
  noItemsMessage,
  DetailViewComponent,
  CustomSearchComponent,
  FooterComponent,
  ListItemComponent,
  PlaceholderComponent
}: ListDetailViewProps<TItem, TListItemProps, TDetailViewProps>) => {
  const { isMobile } = useResponsiveState(BREAKPOINT)

  const [contentRef, { height: contentHeight }] = useElementSize()

  const [currentItem, setCurrentItem] = useState<TItem | undefined>(item)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useUpdateEffect(() => {
    setCurrentItem(item)
  }, [item])

  const handleBack = useCallback(() => {
    setCurrentItem(undefined)
    onItemSelect(undefined)
  }, [onItemSelect])

  const handleItemClick = useCallback(
    (item: TItem) => {
      setCurrentItem(item)
      onItemSelect(item)
      itemProps?.onClick?.(item)
    },
    [onItemSelect, itemProps]
  )

  const displaySearchBox = (!!onSearch && (items.length || searchTerm)) || (!!onCustomSearch && CustomSearchComponent)

  const filteredItems = useMemo(() => {
    let filtered = items
    if (onSearch && searchTerm) {
      filtered = filtered.filter((item) => onSearch(item, searchTerm))
    }
    if (onCustomSearch) {
      filtered = filtered.filter((item) => onCustomSearch(item))
    }
    return filtered
  }, [items, onCustomSearch, onSearch, searchTerm])

  return (
    <Box
      display="flex"
      overflow="hidden"
      alignItems={listHeight === 'detailsHeight' ? 'flex-start' : 'stretch'}
      position="relative"
    >
      <Slide direction="right" in={!isMobile || !currentItem} appear={isMobile}>
        <Box
          display="flex"
          flexDirection="column"
          flexShrink={0}
          flexGrow={{ xs: 1, [BREAKPOINT]: 0 }}
          width={{ xs: '100%', [BREAKPOINT]: listWidth }}
          height={{ [BREAKPOINT]: listHeight === 'detailsHeight' ? contentHeight : undefined }}
          minHeight={minHeight}
          maxHeight={mobileMaxHeight}
          borderRight={1}
          borderColor={palette.noMenu}
          data-testid="list-detail-view--left-bar"
          sx={
            isMobile && currentItem
              ? {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 0
                }
              : undefined
          }
        >
          {typeof title === 'string' ? (
            <Box
              py={2}
              pl={4}
              pr={headerActions?.length ? 2 : 4}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={palette.lighter}
              borderBottom={1}
              borderColor={palette.noMenu}
            >
              <Typography variant="h6" fontWeight="bold" data-testid="list-detail-view--title">
                {title}
              </Typography>
              {!!headerActions?.length && (
                <Box display="flex" alignItems="center" gap={1}>
                  {headerActions.map((actionProps, index) => (
                    <Button key={index} data-testid={`list-detail-view--btn-${index}`} {...actionProps} />
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            title
          )}
          {displaySearchBox && (
            <Box display="flex" flexWrap="wrap" p={2}>
              {!!onSearch && (
                <SearchInput
                  searchTerm={searchTerm}
                  onSearch={setSearchTerm}
                  margin="none"
                  sx={{ width: 'auto', minWidth: 180, flexGrow: 1 }}
                  debounceMs={50}
                />
              )}
              {CustomSearchComponent}
            </Box>
          )}
          <Box flexGrow={1} overflow="auto">
            <List sx={{ py: 0 }} data-testid="list-detail-view--items-list">
              {filteredItems.map((item, index) => (
                <ErrorBoundary key={`${item.id}-${index}`}>
                  <ListItemComponent
                    item={item}
                    {...(itemProps as TListItemProps)}
                    onClick={() => handleItemClick(item)}
                    selected={item.id === currentItem?.id}
                  />
                </ErrorBoundary>
              ))}
            </List>
            {items.length > 0 && filteredItems.length === 0 && (
              <Typography p={4} pt={2} fontStyle="italic" data-testid="list-detail-view--no-matches" textAlign="center">
                No matches found
                {searchTerm && (
                  <>
                    {' '}
                    for <strong>{searchTerm}</strong>
                  </>
                )}
              </Typography>
            )}
            {!items.length &&
              !searchTerm &&
              (isMobile && PlaceholderComponent ? (
                PlaceholderComponent
              ) : (
                <Typography p={4} fontStyle="italic" data-testid="list-detail-view--no-items" textAlign="center">
                  {noItemsMessage ?? 'No items to display'}
                </Typography>
              ))}
          </Box>
          {FooterComponent}
        </Box>
      </Slide>
      <Slide direction="left" in={!isMobile || !!currentItem} appear={isMobile}>
        <Box
          flexGrow={1}
          width="100%"
          ref={contentRef}
          sx={{
            overflow: 'hidden',
            ...(isMobile ? { position: 'relative', zIndex: 2, bgcolor: palette.lightest } : {})
          }}
          data-testid="list-detail-view--right-side"
        >
          {(isMobile || desktopBackButton) && !!currentItem && (
            <Button
              onClick={handleBack}
              size="small"
              startIcon={<ChevronLeft fontSize="small" />}
              sx={{ mt: 2, ml: 2 }}
              data-testid="list-detail-view--back-button"
            >
              Back
            </Button>
          )}
          <ErrorBoundary>
            {currentItem ? (
              <DetailViewComponent item={currentItem} {...(detailProps as TDetailViewProps)} />
            ) : !isMobile ? (
              PlaceholderComponent
            ) : null}
          </ErrorBoundary>
        </Box>
      </Slide>
    </Box>
  )
}

export const ListDetailView = memo(UnmemoedListDetailView) as typeof UnmemoedListDetailView
