import { ConfirmationDialog } from '@client/components/confirmation-dialog/ConfirmationDialog'
import { Schedule } from '@client/components/schedule'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import {
  DraftNotificationPreference,
  NotificationPreference,
  NotificationSchedule,
  draftNotificationPreference
} from '@client/types/notification'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationSnoozeButton } from '../notification-snooze/NotificationSnoozeButton'

export interface NotificationAlertProps {
  compact?: boolean
  isDirty: boolean
  lookupKey: string
  errors?: Record<string, string>
  defaultPreference?: DraftNotificationPreference
}

export const NotificationAlert: React.FC<NotificationAlertProps> = ({
  compact,
  isDirty,
  lookupKey,
  errors,
  defaultPreference
}) => {
  const { notificationOptions, markDirty, validatePreference, deleteNotificationPreference } =
    useNotificationPreferences()
  const { eventTracker } = useEventTracker()
  const { toast } = useToastNotification()

  const isDraftPreference = useMemo(() => !defaultPreference?.id, [defaultPreference?.id])

  // load options
  const availableChannels = useMemo(
    () =>
      notificationOptions.channels.map((channel) => ({
        value: channel.id,
        label: channel.name
      })),
    [notificationOptions.channels]
  )
  const availableCategories = useMemo(
    () =>
      notificationOptions.categories.map((category) => ({
        selectable: !!category.parentId,
        value: category.id,
        label: category.name
      })),
    [notificationOptions.categories]
  )

  // delete with confirmation
  const { value: deleting, setTrue: startDelete, setFalse: endDelete } = useBooleanState(false)
  const { value: showConfirmation, setTrue: openConfirmation, setFalse: closeConfirmation } = useBooleanState(false)
  const deleteAlert = useCallback(() => {
    startDelete()

    deleteNotificationPreference(lookupKey)
      .then((deleted) => {
        if (isDraftPreference) {
          return
        }
        if (deleted) {
          toast.success('Alert deleted')
          eventTracker({
            action: TrackableAction.notificationPreferenceDeleted,
            category: TrackableCategory.notifications,
            dimension1: 'from-edit-filterset-dialog'
          })
        } else {
          toast.error('Could not delete alert')
          endDelete()
          closeConfirmation()
        }
      })
      .catch(() => {
        endDelete()
        closeConfirmation()
      })
  }, [
    startDelete,
    deleteNotificationPreference,
    lookupKey,
    eventTracker,
    endDelete,
    closeConfirmation,
    toast,
    isDraftPreference
  ])

  const { value: newChange, setTrue: startChange, setFalse: endChange } = useBooleanState(false)
  const [preference, setPreference] = useState<DraftNotificationPreference>(
    defaultPreference ?? draftNotificationPreference()
  )

  // Update the disabledUntil value in the local preference object
  // when it gets updated after snoozing/unsnoozing
  useEffect(() => {
    setPreference((pref) => ({
      ...pref,
      disabledUntil: defaultPreference?.disabledUntil
    }))
  }, [defaultPreference?.disabledUntil])

  // define state handlers
  const handleScheduleChange = useCallback(
    (schedule: NotificationSchedule) => {
      setPreference((p) => ({ ...p, schedule }))
      startChange()
    },
    [startChange]
  )
  const handleChannelsChange = useCallback(
    (
      event: SelectChangeEvent<
        | {
            name?: string | undefined
            value: string[]
          }
        | string[]
      >
    ) => {
      setPreference((p) => ({ ...p, channels: event.target.value as any }))
      startChange()
    },
    [startChange]
  )
  const handleCategoriesChange = useCallback(
    (
      event: SelectChangeEvent<
        | {
            name?: string | undefined
            value: unknown
          }
        | string[]
      >
    ) => {
      setPreference((p) => ({ ...p, categories: event.target.value as any }))
      startChange()
    },
    [startChange]
  )

  const hasErrors = Object.keys(errors ?? {}).length > 0
  useEffect(() => {
    if (newChange) {
      markDirty(lookupKey, preference as NotificationPreference)

      if (hasErrors) {
        validatePreference(lookupKey)
      }
    }

    endChange()
  }, [preference, newChange, endChange, markDirty, lookupKey, hasErrors, validatePreference])

  return (
    <Box my={2} border={2} borderRadius={5} borderColor="#fafafa" py={2} px={4}>
      <Box mb={2} width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Typography variant="caption" flex={1}>
          Notify Me
          {isDirty && <SaveRequired />}
        </Typography>
        <Box display="flex" gap={2} flexDirection="row" justifyContent="end" alignItems="center">
          {preference.id ? (
            <NotificationSnoozeButton
              notificationPreference={preference as NotificationPreference}
              context="preference-settings"
            />
          ) : null}
          <IconButton
            role="button"
            aria-label="Delete Alert"
            onClick={isDraftPreference ? deleteAlert : openConfirmation}
            disabled={deleting}
            size="small"
            edge="end"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Schedule
        compact={compact}
        onScheduleChange={handleScheduleChange}
        defaultSchedule={preference.schedule}
        errors={{
          frequency: errors?.frequency ?? errors?.['schedule.frequency'] ?? '',
          dow: errors?.dow ?? errors?.['schedule.dow'] ?? '',
          time: errors?.time ?? errors?.['schedule.time'] ?? ''
        }}
      />
      <Box display="flex" flexDirection={compact ? 'column' : 'row'} justifyContent="center" alignItems="center">
        <Box mr={compact ? 0 : 2} width="100%" maxWidth={compact ? undefined : '50%'}>
          <FormControl error={!!errors?.channels}>
            <InputLabel id={`via_label`}>Via</InputLabel>
            <Select
              name="channels"
              multiple
              value={preference.channels}
              onChange={handleChannelsChange}
              labelId={`via-label`}
              input={<OutlinedInput label="Via" />}
            >
              {availableChannels.map((channel) => (
                <MenuItem key={channel.value} value={channel.value}>
                  {channel.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.channels ? errors.channels : ' '}</FormHelperText>
          </FormControl>
        </Box>
        <Box ml={compact ? 0 : 2} width="100%" maxWidth={compact ? undefined : '50%'}>
          <FormControl error={!!errors?.categories}>
            <InputLabel id={`about_label`}>About</InputLabel>
            <Select
              name="categories"
              multiple
              value={preference.categories}
              onChange={handleCategoriesChange}
              labelId={`about_label`}
              input={<OutlinedInput label="About" />}
            >
              {availableCategories.map((category) => (
                <MenuItem key={category.value} value={category.value} disabled={!category.selectable}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.categories ? errors.categories : ' '}</FormHelperText>
          </FormControl>
        </Box>
      </Box>
      <ConfirmationDialog
        open={showConfirmation}
        title="Delete Alert"
        confirmButtonText="Delete"
        loading={deleting}
        loadingIndicator="Deleting..."
        content={<span>Are you sure? This cannot be undone!</span>}
        onCancel={closeConfirmation}
        onConfirm={deleteAlert}
      />
    </Box>
  )
}

const SaveRequired = () => (
  <small style={{ color: '#d32f2f' }}>
    <em> - (requires save)</em>
  </small>
)
