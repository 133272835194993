import { Auth0Provider } from '@auth0/auth0-react'
import { snackbarIcons } from '@client/configs/snackbar'
import { useHandleCloseEventErrors } from '@client/hooks/use-handle-close-event-errors'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { WholesaleProvider } from '@client/providers/wholesale-provider'
import { trpc, trpcClient } from '@client/services/api/api-client'
import createTheme from '@client/styles/theme/MUI'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material'
import { StylesProvider } from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import { FC, PropsWithChildren } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { AlgoliaProvider } from './algolia'
import { DebugProvider } from './debug-provider'
import { HasuraProvider } from './hasura'
import { IntegrationProvider } from './integration-provider'
import { MapProvider } from './map'
import { NotificationPreferenceProvider } from './notification-preferences'
import { NotificationProvider } from './notifications'
import { ReportsProvider } from './reports-provider'
import { SavedViewsProvider } from './saved-views'
import { TagsProvider } from './tags-provider'
import { TrackingProvider } from './tracking-provider'
import { UserProvider } from './user'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_DISABLE_REACT_QUERY_DEV_TOOLS } = process.env

const enableReactQueryDevTools = REACT_APP_DISABLE_REACT_QUERY_DEV_TOOLS !== 'true'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      retry: process.env.NODE_ENV === 'production' ? 3 : false
    }
  }
})

export const PublicProviders: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useResponsiveState('sm')

  useHandleCloseEventErrors()

  return (
    <SentryErrorBoundary fallback={() => <h1>An error has occurred</h1>}>
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={createTheme}>
            <ThemeProvider theme={createTheme}>
              <SnackbarProvider maxSnack={3} dense={isMobile} iconVariant={snackbarIcons}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <trpc.Provider client={trpcClient} queryClient={queryClient}>
                    <QueryClientProvider client={queryClient}>
                      {enableReactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />}
                      <Auth0Provider
                        domain={String(REACT_APP_AUTH0_DOMAIN)}
                        clientId={String(REACT_APP_AUTH0_CLIENT_ID)}
                        redirectUri={window.location.origin}
                        scope="profile"
                        cacheLocation="localstorage"
                        useRefreshTokens={true}
                      >
                        <Router>
                          <TrackingProvider>
                            <QueryParamProvider adapter={ReactRouter6Adapter}>{children}</QueryParamProvider>
                          </TrackingProvider>
                        </Router>
                      </Auth0Provider>
                    </QueryClientProvider>
                  </trpc.Provider>
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </SentryErrorBoundary>
  )
}

export const AuthenticatedProviders: FC<PropsWithChildren> = ({ children }) => (
  <DebugProvider>
    <HasuraProvider>
      <UserProvider>
        <TagsProvider>
          <AlgoliaProvider>
            <SavedViewsProvider>
              <NotificationPreferenceProvider>
                <NotificationProvider>
                  <MapProvider>
                    <ReportsProvider>
                      <IntegrationProvider>
                        <WholesaleProvider>{children}</WholesaleProvider>
                      </IntegrationProvider>
                    </ReportsProvider>
                  </MapProvider>
                </NotificationProvider>
              </NotificationPreferenceProvider>
            </SavedViewsProvider>
          </AlgoliaProvider>
        </TagsProvider>
      </UserProvider>
    </HasuraProvider>
  </DebugProvider>
)
