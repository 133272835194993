import { ReturningResults } from '@lib/types/graphql'
import { SortOrder } from '@lib/types/orderBy'
import { SetOptional } from 'type-fest'
import { AccountSummary } from './account'
import { DraftFilterset } from './filterset'
import { CountAggregate } from './graphql'
import { RoleItemResponse } from './role'

export enum UsersSortField {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CREATION_DATE = 'createdAt',
  LATEST_LOGIN = 'latestLogin'
}

export type Auth0User = {
  created_at: string
  email: string
  email_verified: boolean
  identities: {
    connection: string
    user_id: string
    provider: string
    isSocial: boolean
  }[]
  name: string
  nickname: string
  picture: string
  updated_at: string
  user_id: string
  user_metadata: {
    finished_sales_rep_setup?: boolean
    firstLogin: boolean
    invitation_link: string
  }
}

export type UsersOptions = {
  sortField: UsersSortField
  sortOrder: SortOrder
  from: number
  pageSize: number
  search?: string
  account?: AccountSummary
  roleId?: string
  excludeSuspended?: boolean
  excludeDeleted?: boolean
}

export const usersSortOptions: { label: string; value: UsersSortField }[] = [
  {
    label: 'Creation date',
    value: UsersSortField.CREATION_DATE
  },
  {
    label: 'First name',
    value: UsersSortField.FIRST_NAME
  },
  {
    label: 'Last name',
    value: UsersSortField.LAST_NAME
  },
  {
    label: 'Latest login',
    value: UsersSortField.LATEST_LOGIN
  }
]

export type HasuraUserSubscriptionRole = {
  role: RoleItemResponse
  subscription: {
    subscription_id: string
    subscription_name: string
  }
}

export type HasuraUserFilterset = {
  subscription_id: string
  filterset_id: string
  filterset: DraftFilterset
}

export type DeleteFiltersetResponse = {
  delete_filterset_by_pk: {
    filterset_id: string
  }
  delete_user_subscription_filterset: ReturningResults<{ filterset_id: string }>
}

export type HasuraUser = {
  user_id: string
  fname: string
  lname: string
  email: string
  suspended_at: string | null
  deleted_at?: string | null
  latest_login: string | null
  user_subscription_filtersets?: HasuraUserFilterset[]
  user_subscription_roles: HasuraUserSubscriptionRole[]
}

export interface UsersResponse {
  user: HasuraUser[]
  user_aggregate: CountAggregate
}

export interface User {
  id: string
  firstName?: string
  lastName?: string
  email: string
  isSuspended: boolean
  isDeleted?: boolean
  latestLogin: Date | null
}

export type DraftUser = SetOptional<User, 'id'>

export const emptyDraftUser = (): DraftUser => ({
  firstName: '',
  lastName: '',
  email: '',
  isSuspended: false,
  latestLogin: null
})

export interface UserByPkResponse {
  user_by_pk: HasuraUser
}

export interface UpdateUserResponse {
  update_user_by_pk: {
    user_id: string
  }
}

export interface UpdateRolesResponse {
  insert_user_subscription_role: ReturningResults<{ user_id: string }>
}

export interface InsertUserSubscriptionFiltersetResponse {
  insert_user_subscription_filterset_one: {
    filterset_id: string
    user_id: string
  }
}

export interface DeleteRolesResponse {
  delete_user_subscription_role: {
    user_id: string
  }
}

export interface HasuraUserRole {
  subscription_id: string
  user_id: string
  role_id: string
}
