import {
  NotificationCategoryResult,
  NotificationChannelResult,
  NotificationOptionsResult,
  NotificationPreferenceResult
} from '@client/services/hasura'
import { SetRequired } from 'type-fest'
import { DraftFilterset } from './filterset'
import { Attachment, VisualAttachment } from './scheduled-reports'

export const SCHEDULED_REPORT_CODE = 'SCHEDULED_REPORT'
export const DISPENSARY_ANALYTICS_KPIS_GROUP = 'DISPENSARY_ANALYTICS_KPIS'
export const REPORTS_GROUP = 'REPORTS'
export const SAVED_VIEWS_GROUP = 'SAVED_VIEWS'
const GROUPS_TO_DISPLAY = [SAVED_VIEWS_GROUP]

export function toPreferencesFromResults(preferences: NotificationPreferenceResult[]): NotificationPreference[] {
  return preferences.map((preference) => toPreferenceFromResult(preference))
}

export function toPreferenceFromResult(preference: NotificationPreferenceResult): NotificationPreference {
  return {
    id: preference.notification_preference_id,
    filtersetId: preference.filterset_id || undefined,
    comparisonId: preference.comparison_id || undefined,
    isEnabled: preference.is_enabled,
    disabledUntil: preference.disabled_until ? new Date(preference.disabled_until) : undefined,
    schedule: preference.schedule,
    data: preference.data,
    channels: preference.notification_preference_notification_channels?.map(
      ({ notification_channel_id }) => notification_channel_id
    ),
    categories: preference.notification_preference_notification_categories?.map(
      ({ notification_category_id }) => notification_category_id
    )
  }
}

export function toOptionsFromResult(options: NotificationOptionsResult): NotificationOptions {
  const allCategories = options.notification_category.map((category) => toCategoryFromResult(category))
  return {
    channels: options.notification_channel.map((channel) => toChannelFromResult(channel)),
    categories: allCategories.filter((category) => category.group && GROUPS_TO_DISPLAY.includes(category.group)),
    allCategories
  }
}

export function toChannelFromResult(channel: NotificationChannelResult): NotificationChannel {
  return {
    id: channel.notification_channel_id,
    name: channel.notification_channel_name,
    description: channel.notification_channel_description
  }
}

export function toCategoryFromResult(category: NotificationCategoryResult): NotificationCategory {
  return {
    parentId: category.parent_notification_category_id,
    id: category.notification_category_id,
    code: category.category_code,
    name: category.category_name,
    description: category.description,
    group: category.group
  }
}

export function preparePreferenceRequest(preference: DraftNotificationPreference): NotificationPreferenceRequest {
  if (!preference.schedule) {
    throw Error('Notification preference must define a schedule.')
  }
  if (
    !preference.filtersetId &&
    !preference.comparisonId &&
    !preference.data?.dashboardId &&
    !preference.data?.dispensaryId
  ) {
    throw Error('Missing required property.')
  }

  const request: NotificationPreferenceRequest = {
    filterset_id: preference.filtersetId,
    comparison_id: preference.comparisonId,
    schedule: preference.schedule,
    disabled_until: preference.disabledUntil?.toISOString() ?? null,
    data: preference.data,
    notification_preference_notification_channels: prepareChannelRequest(preference.channels),
    notification_preference_notification_categories: prepareCategoryRequest(preference.categories)
  }
  if (preference.id) {
    request.notification_preference_id = preference.id
  }

  return request
}

export function prepareChannelRequest(channels: string[]): NotificationChannelRequestCollection {
  return {
    data: channels.map((channel_id) => ({ notification_channel_id: channel_id }))
  }
}

export function prepareCategoryRequest(categories: string[]): NotificationCategoryRequestCollection {
  return {
    data: categories.map((category_id) => ({ notification_category_id: category_id }))
  }
}

export interface DialogNotificationPreference<T extends DraftNotificationPreference = DraftNotificationPreference> {
  key: string
  isDirty: boolean
  errors?: Record<string, string>
  settings: T
}

type NPData = Record<string, unknown> | undefined

export interface DraftNotificationPreference<TData = NPData> {
  id?: string
  filtersetId?: string
  comparisonId?: string
  isEnabled?: boolean
  disabledUntil?: Date
  schedule: NotificationSchedule
  channels: string[]
  categories: string[]
  data: TData
}

export interface NotificationPreference<TData = NPData> extends SetRequired<DraftNotificationPreference<TData>, 'id'> {
  schedule: SetRequired<NotificationSchedule, 'frequency' | 'time'>
}

export type ViewNotificationPreference<TData = NPData> = SetRequired<NotificationPreference<TData>, 'filtersetId'>

export interface DraftViewNotificationPreference<TData = NPData>
  extends SetRequired<DraftNotificationPreference<TData>, 'filtersetId'> {}

export type ReportsNotificationPreferenceData = {
  dashboardId: string
  reportName?: string
  recipients?: Array<{ name: string; email: string }>
  message?: string
  dashboardAttachment?: Attachment
  visualAttachments?: VisualAttachment[]
  filterset?: DraftFilterset
}

export interface ReportsNotificationPreference extends NotificationPreference<ReportsNotificationPreferenceData> {}

export interface DraftReportsNotificationPreference
  extends DraftNotificationPreference<ReportsNotificationPreferenceData> {}

export type ComparisonNotificationPreferenceData = {
  item?: string
  [key: string]: unknown
}

export type DispensaryOrderReminderPreferenceData = {
  dispensaryId: string
  subscriptionId: string
  reminderDelayDays: number
  lastActioned?: string
}

export interface DispensaryOrderReminderNotificationPreference
  extends NotificationPreference<DispensaryOrderReminderPreferenceData> {}

export interface DraftDispensaryOrderReminderNotificationPreference
  extends DraftNotificationPreference<DispensaryOrderReminderPreferenceData> {}

export interface ComparisonNotificationPreference
  extends SetRequired<NotificationPreference<ComparisonNotificationPreferenceData>, 'comparisonId'> {}

export interface DraftComparisonNotificationPreference
  extends SetRequired<DraftNotificationPreference<ComparisonNotificationPreferenceData>, 'comparisonId'> {}

export const draftNotificationPreference = (filtersetId?: string): DraftNotificationPreference => ({
  id: undefined,
  filtersetId: filtersetId,
  isEnabled: undefined,
  schedule: draftSchedule(),
  disabledUntil: undefined,
  channels: [],
  categories: [],
  data: undefined
})

export interface NotificationChannel {
  id: string
  name: string
  description: string | null
}

export type NotificationCategory = {
  parentId: string | null
  id: string
  name: string
  code: string
  group: string | null
  description: string | null
}

export interface NotificationOptions {
  channels: NotificationChannel[]
  categories: NotificationCategory[]
  allCategories: NotificationCategory[]
}

export interface NotificationPreferenceRequest {
  notification_preference_id?: string
  filterset_id?: string
  comparison_id?: string
  schedule: NotificationSchedule
  disabled_until: string | null
  data?: Record<string, unknown>
  notification_preference_notification_channels: NotificationChannelRequestCollection
  notification_preference_notification_categories: NotificationCategoryRequestCollection
}

export type NotificationChannelRequest = {
  notification_channel_id: string
}
export interface NotificationChannelRequestCollection {
  data: NotificationChannelRequest[]
}

export interface NotificationCategoryRequest {
  notification_category_id: string
}
export interface NotificationCategoryRequestCollection {
  data: NotificationCategoryRequest[]
}

export type DOW = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type TIME =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23

export type DAILY = 'daily'
export type WEEKLY = 'weekly'
export type Frequency = DAILY | WEEKLY

export function draftSchedule(): NotificationSchedule {
  return {
    frequency: '',
    dow: '',
    time: '',
    timezone: ''
  }
}

export type NotificationSchedule = DailySchedule | WeekelySchedule
export type DailySchedule = {
  frequency?: DAILY | ''
  dow?: DOW | ''
  time?: TIME | ''
  timezone?: string
}
export type WeekelySchedule = {
  frequency?: WEEKLY | ''
  dow?: DOW | ''
  time?: TIME | ''
  timezone?: string
}
