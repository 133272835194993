import { FilterCheckbox } from '@client/components/FilterCheckbox/FilterCheckbox'
import { SearchInput } from '@client/components/search-input/SearchInput'
import { facetToValue, valueToFacet } from '@client/helpers/algolia/algolia-helpers'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box } from '@client/styles/theme/box'
import { CATEGORY_SEPARATOR } from '@client/types/filterset'
import { Button, FormGroup, Typography } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import type { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { useUpdateEffect } from 'usehooks-ts'

export type RefinementListProps = RefinementListExposed & {
  disableSelection?: boolean
  autoFocusSearch?: boolean
  searchDebounceMs?: number
  // This is useful for when the refinement list is inside a popover that needs to recalculate its size when the list changes
  triggerResizeOnChange?: boolean
}

type RefinementListCompleteProps = RefinementListProvided & RefinementListProps

export const RefinementList: React.FC<any> = memo<any>(function RefinementList({
  limit,
  items: inputItems,
  showMore,
  searchable,
  autoFocusSearch,
  searchDebounceMs = 500,
  disableSelection,
  refine,
  searchForItems,
  currentRefinement,
  triggerResizeOnChange = false
}: RefinementListCompleteProps) {
  const items = useMemo(() => {
    const selected = currentRefinement.map((item: string) => facetToValue(item))
    return inputItems.map((item) => {
      return { ...item, isRefined: item.isRefined || selected.includes(item.label) }
    })
  }, [inputItems, currentRefinement])

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItem, setSelectedItem] = useState<[string, boolean]>()
  const { value: showingMore, toggleValue: toggleShowMoreItems } = useBooleanState(false)
  const maxItems = useMemo(() => limit || 10, [limit])
  const itemsToDisplay = useMemo(() => (showingMore ? items : items.slice(0, maxItems)), [showingMore, items, maxItems])

  const excludeSelected = useMemo(() => {
    return !!currentRefinement[0]?.startsWith('-')
  }, [currentRefinement])

  useUpdateEffect(() => {
    if (selectedItem) {
      const newValue = selectedItem[1]
        ? [...currentRefinement, valueToFacet(selectedItem[0], excludeSelected)]
        : currentRefinement.filter((item: string) => facetToValue(item) !== facetToValue(selectedItem[0]))
      refine(newValue)
      setSelectedItem(undefined)
      if (searchable && autoFocusSearch) {
        inputRef.current?.focus()
      }
    }
  }, [selectedItem, excludeSelected])

  useUpdateEffect(() => {
    if (triggerResizeOnChange) {
      window.dispatchEvent(new CustomEvent('resize'))
    }
  }, [itemsToDisplay])

  const onSearch = useCallback(
    (newSearchTerm: string) => {
      setSearchTerm(newSearchTerm)
      searchForItems(newSearchTerm)
    },
    [searchForItems]
  )

  const onClickFacet = useCallback((value: string, checked: boolean) => {
    setSelectedItem([value, checked])
    setSearchTerm('')
  }, [])

  return (
    <div>
      {searchable && (
        <SearchInput
          searchTerm={searchTerm}
          onSearch={onSearch}
          debounceMs={searchDebounceMs}
          margin="dense"
          inputRef={inputRef}
          autoFocus={autoFocusSearch}
        />
      )}
      <FormGroup>
        {itemsToDisplay.length ? (
          itemsToDisplay.map((item) => {
            const parts = item.label.split(CATEGORY_SEPARATOR)
            return (
              <FilterCheckbox
                key={item.label}
                checked={item.isRefined}
                label={parts[parts.length - 1]}
                value={item.label}
                subLabel={parts.length > 1 ? parts.slice(0, -1).join(CATEGORY_SEPARATOR) : undefined}
                count={item.count}
                disabled={!!disableSelection && !item.isRefined}
                onClick={onClickFacet}
              />
            )
          })
        ) : (
          <Typography color="textSecondary" p={2} fontStyle="italic">
            (No options)
          </Typography>
        )}
      </FormGroup>
      {showMore && items.length > maxItems && (
        <Box mt={2}>
          <Button variant="outlined" size="small" aria-label="show-more-facets" onClick={toggleShowMoreItems}>
            Show {showingMore ? 'less' : 'more'}
          </Button>
        </Box>
      )}
    </div>
  )
},
deepEqual)
