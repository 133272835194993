import { useAuth0 } from '@auth0/auth0-react'
import { useSavedViews } from '@client/providers/saved-views'
import { View } from '@hoodie/hoodie-filters/lib/filterset'
import { memo } from 'react'
import {
  IconButtonViewSelectorProps,
  IconButtonViewSelector as InnerIconButtonViewSelector
} from './IconButtonViewSelector'

// Sort views by mine first, then by name
export const sortViews = (views: View[], userId?: string) => {
  return views.sort((a, b) => {
    if (a.userId === userId && b.userId !== userId) {
      return -1
    }
    if (a.userId !== userId && b.userId === userId) {
      return 1
    }
    return a.name.localeCompare(b.name)
  })
}

export const IconButtonViewSelector: React.FC<Omit<IconButtonViewSelectorProps, 'views'>> = memo(
  function IconButtonViewSelector(props) {
    const { savedViews } = useSavedViews()
    const { user } = useAuth0()
    const sortedViews = sortViews(savedViews, user?.sub)
    return <InnerIconButtonViewSelector views={sortedViews} {...props} />
  }
)
