import { useReportsDashboards } from '@client/hooks/logi/use-reports-dashboards'
import { usePermissions } from '@client/hooks/use-permissions'
import { useDebug } from '@client/providers/debug-provider'
import { LogiDashboardWithTags } from '@client/types/logi-composer'
import { ModuleId } from '@lib/types/modules'
import { Permission } from '@lib/types/permission'
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useEffectOnce, useIsMounted } from 'usehooks-ts'

type StartVisData = Record<string, any>
type WidgetMap = Record<string, StartVisData>

export interface ReportsContextInterface {
  hasError: boolean
  isDashboardExportable: (dashboard: LogiDashboardWithTags) => boolean
  getWidgetStartVisData: (visualId: string) => StartVisData | undefined
  reports: LogiDashboardWithTags[] | undefined
}

const ReportsContext = createContext<ReportsContextInterface>({
  hasError: false,
  isDashboardExportable: () => false,
  getWidgetStartVisData: () => undefined,
  reports: undefined
})

export const ReportsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data: reports, error } = useReportsDashboards()
  const [widgetStartVisData, setWidgetStartVisData] = useState<WidgetMap>({})
  const isMounted = useIsMounted()

  // Override the websocket send method to intercept all START_VIS messages
  useEffectOnce(() => {
    const originalSend = WebSocket.prototype.send
    const wsSend = originalSend.apply.bind(originalSend)
    WebSocket.prototype.send = function (data: any) {
      if (typeof data === 'string') {
        try {
          const parsedData = JSON.parse(data)
          const visualId = parsedData.auditInfo?.visualId
          if (parsedData.type === 'START_VIS') {
            if (isMounted()) {
              setWidgetStartVisData((vis) => {
                // Update the corresponding widget config with this new START_VIS message
                return {
                  ...vis,
                  [visualId]: parsedData
                }
              })
            }
          }
        } catch (e) {
          // Do nothing - it's not a valid JSON message
        }
      }
      // eslint-disable-next-line prefer-rest-params
      return wsSend(this, arguments as any)
    }
    return () => {
      // Reset the websocket send method when unmounting
      WebSocket.prototype.send = originalSend
    }
  })

  const isDashboardExportable = useCallback(
    (dashboard: LogiDashboardWithTags) => {
      return dashboard.widgets
        .filter((widget) => widget.visualId)
        .every((widget) => widgetStartVisData[widget.visualId as string])
    },
    [widgetStartVisData]
  )

  const getWidgetStartVisData = useCallback(
    (visualId: string): StartVisData | undefined => {
      return widgetStartVisData[visualId]
    },
    [widgetStartVisData]
  )

  return (
    <ReportsContext.Provider value={{ reports, isDashboardExportable, getWidgetStartVisData, hasError: !!error }}>
      {children}
    </ReportsContext.Provider>
  )
}

export const useReports = (moduleId?: ModuleId) => {
  const context = useContext(ReportsContext)
  const { permissions } = usePermissions()
  const { isDebugEnabled } = useDebug()

  if (!context) {
    throw new Error('useReports must be used within a ReportsProvider.')
  }

  const list = useMemo(() => {
    return context.reports?.filter((report) => {
      // If the report is marked as being excluded from the gallery, don't show it
      if (report.hoodieMetadata?.excludeFromGallery) {
        return false
      }
      // If it's not in debug mode and it's not tagged as a production report, don't show it
      if (!isDebugEnabled && !report.tags?.find((t) => t.label === 'env:production')) {
        return false
      }
      // If this is a module-specific view, only show reports that are tagged with that module
      if (moduleId) {
        return reportInModule(report, moduleId)
      }
      // If the report is not tagged with a module, it is available to all users
      if (!report.tags?.find((t) => t.label.startsWith('module:'))) {
        return true
      }

      if (
        Object.entries(modulePermissions).find(([moduleId, permission]) => {
          // If the report is tagged with a module and the user has that module permission, it is available
          return reportInModule(report, moduleId as ModuleId) && (permissions as string[]).includes(permission)
        })
      ) {
        return true
      }
      return false
    })
  }, [context.reports, isDebugEnabled, moduleId, permissions])

  return { ...context, reports: list }
}

export const modulePermissions: Record<ModuleId, Permission> = {
  SE: Permission.MODULE_ACCESS_SALES_ENABLEMENT,
  DA: Permission.MODULE_ACCESS_DISPENSARY_ANALYTICS,
  MI: Permission.MODULE_ACCESS_MARKET_INTELLIGENCE,
  WS: Permission.MODULE_ACCESS_WHOLESALE
} as const

export const reportInModule = (report: LogiDashboardWithTags, moduleId: ModuleId) => {
  return report.tags?.find((t) => t.label === `module:${moduleId}`)
}
