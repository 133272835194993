import { Onboarding, OnboardingStep, waitForStepTarget } from '@client/components/onboarding'
import { urls } from '@client/helpers/urls'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useTheme } from '@mui/material'
import { memo, useCallback, useMemo, useState } from 'react'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'

type Props = {
  closeMenu: () => void
  openMenu: () => void
}

export const UserProfileTour = memo(function UserProfileTour({ closeMenu, openMenu }: Props) {
  const { transitions } = useTheme()
  const [state, setState] = useState({ stepIndex: 0, run: true })
  const navigate = useNavigateRef()

  const tourId = 'user-profile-v1'
  const steps = useMemo(
    () =>
      [
        {
          title: 'New Profile Page!',
          content:
            'You can now access and update your profile, including email address and password, from the user menu.',
          target: '[id="user-avatar--dropdown-trigger"]',
          placement: 'bottom-end',
          disableBeacon: true,
          ctaProps: {
            label: 'Open User Menu',
            onClick: openMenu
          }
        },
        {
          title: 'New Profile Page!',
          content:
            'This will take you to your profile page, where you can update your personal details, email address, password and more.',
          target: '[id="user-avatar--dropdown--profile"]',
          placement: 'bottom-end',
          disableBeacon: true,
          ctaProps: {
            label: 'Open profile page',
            onClick: () => {
              closeMenu()
              navigate.current(urls.userProfile())
            }
          }
        }
      ] as OnboardingStep[],
    [closeMenu, navigate, openMenu]
  )

  const handleNextStep = useCallback(
    (data: CallBackProps) => {
      const { action, index, status, type } = data

      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
        setState({ run: false, stepIndex: 0 })
      } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        // Move to the next step but not run it
        setState((prevState) => ({ ...prevState, run: false, stepIndex: nextStepIndex }))
        // If the next step is inside the options popup, we need to click the options button to show the content
        if (nextStepIndex === 1) {
          openMenu()

          waitForStepTarget(
            steps[nextStepIndex],
            () => {
              setState((prevState) => ({ ...prevState, run: true }))
            },
            { attempts: 10, delayMs: transitions.duration.standard }
          )
        } else {
          // Otherwise, just run the next step
          setState((prevState) => ({ ...prevState, run: true }))
        }
      }
    },
    [openMenu, steps, transitions.duration.standard]
  )

  return <Onboarding scrollToFirstStep tourId={tourId} callback={handleNextStep} continuous steps={steps} {...state} />
})
