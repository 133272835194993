import { SearchInput } from '@client/components/search-input/SearchInput'
import { TextFieldProps } from '@mui/material'
import { memo } from 'react'
import { SearchBoxExposed, SearchBoxProvided } from 'react-instantsearch-core'

export interface SearchBoxProps extends SearchBoxProvided, SearchBoxExposed {
  disabled?: boolean
  margin?: TextFieldProps['margin']
}

export const SearchBox: React.FC<SearchBoxProps> = memo<SearchBoxProps>(function SearchBox({
  currentRefinement,
  disabled,
  refine,
  margin = 'normal'
}) {
  return (
    <SearchInput
      disabled={disabled}
      searchTerm={currentRefinement}
      onSearch={refine}
      debounceMs={500}
      margin={margin}
      data-testid="search-box"
    />
  )
})
