import { MultipleQueriesQuery } from '@algolia/client-search'
import { convertTagsToFacets } from '@client/helpers/algolia/algolia-helpers'
import { TagTargetTagsMap } from '@client/types/tags'
import { TagFilterOption } from '@hoodie/hoodie-filters/lib/filterset'
import algoliasearch, { SearchClient } from 'algoliasearch'

const algoliaApp: any = process.env.REACT_APP_ALGOLIA_APP_ID
const algoliaSearchKey: any = process.env.REACT_APP_ALGOLIA_SEARCH_KEY

export const getSearchClient = (options?: {
  secureApiKey?: string
  tags?: TagTargetTagsMap
  facetsToGroup?: string[]
  suppressWarning?: boolean
}) => {
  const { secureApiKey, tags, facetsToGroup, suppressWarning = false } = options || {}
  // TEMP: if no secure API key is provided, default to using the base search key
  // Once all users have a suscription filterset set, we should require that
  // secureApiKey is set.
  if (!suppressWarning && !secureApiKey && process.env.NODE_ENV !== 'test') {
    console.warn('Algolia: no secure API key for this user. Using base search key instead.')
  }
  let searchClient = algoliasearch(algoliaApp, secureApiKey || algoliaSearchKey)
  if (tags) {
    searchClient = replaceTagsFromSearchClient(searchClient, tags)
  }
  if (facetsToGroup) {
    return groupFacetsSearchClient(searchClient, facetsToGroup)
  }
  return searchClient
}

/**
 * Overwrite algolia search client to replace the tags by its items
 * Example: Given a facetsToGroup: [TAG], the following facetFilters: [[TAG:tag-id|brand], [TAG:tag-id|dispensary]] will be converted into [[BRAND:1906],[DISPENSARY:NAME]]
 * @param algoliaSearchClient The algolia search client
 * @returns The algolia search client that replaces the tags by its items
 */
export const replaceTagsFromSearchClient = (
  algoliaSearchClient: SearchClient,
  tags: TagTargetTagsMap
): SearchClient => ({
  ...algoliaSearchClient,
  search: (queries: Readonly<MultipleQueriesQuery[]>, requestOptions?: any) => {
    const tagAttribute = TagFilterOption.attribute
    // Iterate through all algolia queries
    const newQueries: Readonly<MultipleQueriesQuery[]> = queries.map((query: MultipleQueriesQuery) => {
      // In case we don't have an array of facetFilters we can skip it by returning it
      if (!query?.params?.facetFilters?.length || !Array.isArray(query.params.facetFilters[0])) {
        return query
      }
      const index = query.indexName
      // Remove the tagAttribute from the facets
      let facets =
        query.params.facets && Array.isArray(query.params.facets)
          ? query.params.facets.filter((facet) => facet !== tagAttribute)
          : query.params.facets
      const facetFilters =
        typeof query.params.facetFilters === 'string'
          ? query.params.facetFilters
          : ((query.params.facetFilters as Array<string | string[]>).reduce((acc, facet) => {
              // Handle the specific filters
              if ((typeof facet === 'string' ? facet : facet[0]).startsWith(`${tagAttribute}:`)) {
                const { facets: facetsToAdd, facetFilters } = convertTagsToFacets({
                  facet,
                  tags,
                  index,
                  attribute: tagAttribute
                })
                if (query.params?.facets && typeof query.params.facets !== 'string') {
                  facets = Array.from(new Set([...(facets || []), ...facetsToAdd]))
                }
                facetFilters.forEach((values) => {
                  acc.push(values)
                })
                // Otherwise just push it
              } else {
                acc.push(facet)
              }
              return acc
            }, [] as Array<string | string[]>) as Readonly<string[]> | Readonly<Readonly<string[]>[]>)
      return { ...query, params: { ...query.params, facetFilters, facets } }
    })
    // Perform the algolia regular search with the new query
    return algoliaSearchClient.search(newQueries, requestOptions)
  }
})

/**
 * Overwrite algolia search client to group facetFilters of the given facetsToGroup, so algolia use OR instead AND
 * Example: Given a facetsToGroup: [CM_ID, objectID], the following facetFilters: [[BRAND:1906], [CM_ID:XX], [objectID:XX]] will be converted into [[BRAND:1906], [CM_ID:XX, objectID:XX]]
 * @param algoliaSearchClient The algolia search client
 * @param facetsToGroup a list of facets that should be grouped
 * @returns The algolia search client that groups the given facetsToGroup
 */
export const groupFacetsSearchClient = (algoliaSearchClient: SearchClient, facetsToGroup: string[]): SearchClient => ({
  ...algoliaSearchClient,
  search: (queries: Readonly<MultipleQueriesQuery[]>, requestOptions?: any) => {
    // There's no point to handle te queries if it does not have enough fields to group
    if (facetsToGroup.length < 2) {
      return algoliaSearchClient.search(queries, requestOptions)
    }
    // Iterate through all algolia queries
    const newQueries: Readonly<MultipleQueriesQuery[]> = queries.map((query: MultipleQueriesQuery) => {
      // In case we don't have an array of facetFilters we can skip it by returning it
      if (!query?.params?.facetFilters?.length || !Array.isArray(query.params.facetFilters[0])) {
        return query
      }
      let groupIndex: number
      // Group all the facetFilters that are presented on facetsToGroup
      const facetFilters =
        typeof query.params.facetFilters === 'string'
          ? query.params.facetFilters
          : ((query.params.facetFilters as Array<string | string[]>).reduce((acc, facet) => {
              const isString = typeof facet === 'string'
              // Handle the specific filters
              if (facetsToGroup.some((field) => (isString ? facet : facet[0]).startsWith(field))) {
                if (groupIndex !== undefined) {
                  const group = acc[groupIndex] as string[]
                  if (isString) {
                    group.push(facet)
                  } else {
                    group.push(...facet)
                  }
                } else {
                  groupIndex = acc.length
                  acc.push(facet)
                }
                // Otherwise just push it
              } else {
                acc.push(facet)
              }
              return acc
            }, [] as Array<string | string[]>) as Readonly<string[]> | Readonly<Readonly<string[]>[]>)
      return { ...query, params: { ...query.params, facetFilters } }
    })
    // Perform the algolia regular search with the new query
    return algoliaSearchClient.search(newQueries, requestOptions)
  }
})

/**
 * @deprecated Please re-import directly from @client/types/algolia
 */
export * from '@client/types/algolia'
