import { apiClient } from '@client/services/api/api-client'
import { useGetSessionStateData, useSetSessionStateData } from '@client/stores/use-session-state-store'
import { TrackingEvent } from '@lib/types/tracking'
import { useCallback } from 'react'

const track = apiClient.tracking.track.mutate

export const useSessionTracker = () => {
  const getSessionData = useGetSessionStateData()
  const setSessionData = useSetSessionStateData()

  const trackOnce = useCallback(
    ({ eventName, key, properties }: { eventName: TrackingEvent; key?: string; properties?: Record<string, any> }) => {
      const sessionKey = key ?? `tracked-${eventName}`
      const alreadyTracked = getSessionData<boolean>(sessionKey)
      if (!alreadyTracked) {
        setSessionData(sessionKey, true)
        track({
          eventName,
          properties
        })
        return true
      }
      return false
    },
    [getSessionData, setSessionData]
  )

  const hasTracked = useCallback(
    (key: string) => {
      return getSessionData<boolean>(key)
    },
    [getSessionData]
  )

  return { trackOnce, hasTracked }
}
