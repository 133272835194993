import { FilterCheckbox, FilterCheckboxIcon } from '@client/components/FilterCheckbox/FilterCheckbox'
import { SearchInput } from '@client/components/search-input/SearchInput'
import { Box } from '@client/styles/theme/box'
import { Button, FormGroup, Typography } from '@mui/material'
import { Fragment, ReactNode, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useBoolean } from 'usehooks-ts'

type TreeOptionStatus = 'checked' | 'unchecked' | 'indeterminate'

export type TreeOption = {
  value: string
  label: string
  count?: number
  icons?: FilterCheckboxIcon[]
  status?: TreeOptionStatus
  children?: TreeOption[]
}

export const initializeTree = ({ options, values }: { options: TreeOption[]; values: string[] }): TreeOption[] => {
  return options.map((option) => {
    const children = option.children?.length ? initializeTree({ options: option.children, values }) : undefined
    const isChecked = !option.children?.length && values.includes(option.value)
    let status: TreeOptionStatus = isChecked ? 'checked' : 'unchecked'
    if (!isChecked && children?.length) {
      const childrenStatus = children.reduce(
        (acc, child) => {
          if (child.status === 'checked') {
            acc.checked++
          } else if (child.status === 'unchecked') {
            acc.unchecked++
          }
          return acc
        },
        { checked: 0, unchecked: 0 }
      )
      status =
        childrenStatus.checked === children.length
          ? 'checked'
          : childrenStatus.unchecked === children.length
          ? 'unchecked'
          : 'indeterminate'
    }
    return {
      ...option,
      status,
      children
    }
  })
}

export const filterTree = ({
  options,
  searchTerm,
  searchUpToLevel,
  level = 0
}: {
  options: TreeOption[]
  searchTerm: string
  searchUpToLevel?: number
  level?: number
}): TreeOption[] => {
  if (!searchTerm) {
    return options
  }
  if (level === 0) {
    searchTerm = searchTerm.toLowerCase()
  }
  return options.reduce<TreeOption[]>((acc, option) => {
    const children = option.children
      ? searchUpToLevel === undefined || level < searchUpToLevel
        ? filterTree({
            options: option.children,
            searchTerm,
            searchUpToLevel,
            level: level + 1
          })
        : option.children
      : undefined
    const isMatch = option.label.toLowerCase().includes(searchTerm)
    const shouldInclude = isMatch || ((searchUpToLevel === undefined || level < searchUpToLevel) && children?.length)
    if (shouldInclude) {
      acc.push({
        ...option,
        children
      })
    }
    return acc
  }, [])
}

export const getTreeItemValues = ({ value, children }: Pick<TreeOption, 'value' | 'children'>): string[] => {
  if (!children?.length) {
    return [value]
  }
  return children.reduce<string[]>((acc, option) => {
    if (option.children?.length) {
      return [...acc, ...getTreeItemValues(option)]
    } else {
      return [...acc, option.value]
    }
  }, [])
}

const HierarchicalRefinementItem = ({
  value,
  status,
  label,
  count,
  icons,
  children,
  disableSelection,
  onChange
}: TreeOption & {
  onChange: (values: string[], checked: boolean) => void
  disableSelection?: boolean
}) => {
  const handleOnChange = useCallback(
    (label: string, isChecked: boolean) => {
      onChange(
        getTreeItemValues({
          value,
          children
        }),
        disableSelection ? false : isChecked
      )
    },
    [onChange, value, children, disableSelection]
  )
  return (
    <Fragment>
      <FilterCheckbox
        checked={status === 'checked'}
        indeterminate={status === 'indeterminate'}
        label={label}
        value={label}
        count={count}
        disabled={status === 'unchecked' && disableSelection}
        icons={icons}
        onClick={handleOnChange}
      />
      {children && (
        <Box pl={6} py={1}>
          <FormGroup>
            {children.map((item) => (
              <HierarchicalRefinementItem key={item.value} {...item} onChange={onChange} />
            ))}
          </FormGroup>
        </Box>
      )}
    </Fragment>
  )
}

export interface HierarchicalRefinementProps {
  currentRefinement: string[]
  attribute: string
  options: TreeOption[]
  autoFocusSearch?: boolean
  disableSelection?: boolean
  limit?: number
  showMore?: boolean
  searchable?: boolean
  searchUpToLevel?: number
  searchDebounceMs?: number
  NoOptionsComponent?: ReactNode
  onChange: (attribute: string, values: string[]) => void
}

export const HierarchicalRefinement: React.FC<HierarchicalRefinementProps> = memo(function HierarchicalRefinement({
  limit = 4,
  showMore,
  searchable,
  autoFocusSearch,
  searchDebounceMs = 500,
  searchUpToLevel,
  currentRefinement,
  disableSelection,
  attribute,
  options,
  NoOptionsComponent,
  onChange
}) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const { value: showingMore, toggle: toggleShowMoreItems } = useBoolean(false)

  const items = useMemo(() => {
    const filteredOptions = filterTree({ options, searchTerm, searchUpToLevel })
    return initializeTree({ options: filteredOptions, values: currentRefinement })
  }, [options, currentRefinement, searchTerm, searchUpToLevel])

  const itemsToDisplay = useMemo(() => (showingMore ? items : items.slice(0, limit)), [showingMore, items, limit])

  const handleRefine = useCallback(
    (values: string[], checked: boolean) => {
      const newValues = checked
        ? [...new Set([...currentRefinement, ...values])]
        : currentRefinement.filter((v) => !values.includes(v))
      onChange(attribute, newValues)
      if (checked && searchable) {
        autoFocusSearch && inputRef.current?.focus()
        searchTerm && setSearchTerm('')
      }
    },
    [currentRefinement, onChange, attribute, searchable, autoFocusSearch, searchTerm]
  )

  const handleSearch = useCallback((newSearchTerm: string) => {
    setSearchTerm(newSearchTerm)
  }, [])

  return (
    <Box>
      {searchable && (items.length || searchTerm) && (
        <SearchInput
          searchTerm={searchTerm}
          onSearch={handleSearch}
          debounceMs={searchDebounceMs}
          margin="dense"
          inputRef={inputRef}
          autoFocus={autoFocusSearch}
        />
      )}
      <FormGroup>
        {items.length ? (
          itemsToDisplay.map((item) => (
            <HierarchicalRefinementItem
              key={item.value}
              {...item}
              onChange={handleRefine}
              disableSelection={disableSelection}
            />
          ))
        ) : (
          <Typography color="textSecondary" p={2} fontStyle="italic" component={Box}>
            {NoOptionsComponent || 'No options available'}
          </Typography>
        )}
      </FormGroup>
      {showMore && items.length > limit && (
        <Box mt={2}>
          <Button variant="outlined" size="small" aria-label="show-more-facets" onClick={toggleShowMoreItems}>
            Show {showingMore ? 'less' : 'more'}
          </Button>
        </Box>
      )}
    </Box>
  )
})
