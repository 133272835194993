import { TrackableButton } from '@client/components/Trackables'
import { BackButton } from '@client/components/back-button/BackButton'
import { Loader } from '@client/components/loader/Loader'
import { EventArgs } from '@client/configs/tracking'
import { usePermission } from '@client/hooks/use-permissions'
import { AccessControlIcon, ColorProp, Layout } from '@client/styles/global'
import { Box } from '@client/styles/theme/box'
import { Card } from '@client/styles/theme/card'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Permission } from '@lib/types/permission'
import { CardContent, CardProps, Tooltip, Typography } from '@mui/material'
import { SpacingProps } from '@mui/system'
import { FC, PropsWithChildren, ReactNode } from 'react'

export interface AccessControlProps extends CardProps, SpacingProps {
  permission: Permission | Permission[]
  hideAltContent?: boolean
  icon?: ReactNode
  title?: string
  message?: string
  ctaText?: string
  hideCta?: boolean
  emailSubject: string
  children: ReactNode
}

type AccessControlNoticeProps = Omit<AccessControlProps, 'children' | 'hideAltContent'>

type AccessControlIndicatorProps = {
  permission: Permission | Permission[]
  color?: ColorProp
}

export const AccessControlIndicator: React.FC<AccessControlIndicatorProps> = ({ permission, color }) => {
  const { hasPermission } = usePermission(permission)
  return hasPermission ? null : (
    <Tooltip arrow title="Paid feature - not part of your subscription">
      <AccessControlIcon color={color || 'success'} data-testid="access-control-indicator" />
    </Tooltip>
  )
}

export const AccessControlNotice: React.FC<AccessControlNoticeProps> = ({
  icon = <AccessControlIcon color="success" />,
  title = 'Paid feature',
  message = "This feature isn't included with your account. Contact Hoodie Analytics to request access.",
  ctaText = 'I want access!',
  hideCta = false,
  permission,
  emailSubject,
  ...rest
}) => {
  const mailToHref = `mailto:support@hoodieanalytics.com?subject=${encodeURIComponent(emailSubject)}`
  const gaEvent: EventArgs = {
    category: TrackableCategory.permissions,
    action: TrackableAction.permissionRequest,
    dimension1: [permission]
      .flat()
      .filter((p) => p !== Permission.HOODIE_SUPER_ADMIN) // Remove super admin permission from the event
      .join(' or ') // This is very unlikely to happen, but just in case
  }
  return (
    <Card {...rest}>
      <CardContent>
        <Box display="flex" flexDirection="row">
          {icon}
          <Box ml={2}>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography gutterBottom variant="body1">
              {message}
            </Typography>
            {!hideCta && (
              <TrackableButton
                target="_blank"
                data-testid="access-request-btn"
                color="primary"
                href={mailToHref}
                gaEvent={gaEvent}
              >
                {ctaText}
              </TrackableButton>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export const AccessControl: FC<PropsWithChildren<AccessControlProps>> = ({
  permission,
  hideAltContent,
  children,
  ...rest
}) => {
  const { isLoading, hasPermission } = usePermission(permission)
  if (isLoading && !hideAltContent) {
    return <Loader minHeight="100px" />
  }
  return hasPermission ? (
    <>{children}</>
  ) : hideAltContent ? null : (
    <AccessControlNotice permission={permission} {...rest} />
  )
}

export interface PageAccessControlProps extends AccessControlProps {
  pageTitle: string
}

export const PageAccessControl: FC<PropsWithChildren<PageAccessControlProps>> = ({
  pageTitle,
  permission,
  children,
  ...rest
}) => {
  const { isLoading, hasPermission } = usePermission(permission)
  if (isLoading) {
    return <Loader minHeight="100vh" />
  }
  return hasPermission ? (
    <>{children}</>
  ) : (
    <Layout>
      <BackButton mb={2} />
      <Typography variant="h3" gutterBottom data-testid="access-control-page-title">
        {pageTitle}
      </Typography>
      <AccessControlNotice permission={permission} {...rest} />
    </Layout>
  )
}
