import { toggles } from '@client/hooks/use-feature-toggle'
import { DataDisplay } from '@lib/types/modules'
import { ReactElement, ReactNode } from 'react'
import { SetRequired } from 'type-fest'
import { DraftFilterset } from './filterset'

export type HistogramBucket<TKey extends string | number = number> = {
  key: TKey
  doc_count: number
}

export type BasePercentageChartData = {
  value: number
  percentage: number | null
  total: number
}

export type PercentageChartData = BasePercentageChartData & {
  date: string
}

export type BrandShareChartData = PercentageChartData & {
  averageRetailPrice: number | null
  promotionPercentage: number | null
  totalSales: number | null
}

export type LargestBrandChangeChartLabelItem<T extends BasePercentageChartData> = {
  dataIndex: number
  dataset: {
    data: T[]
    label: string
  }
}

export type ChartLabelItem<T> = {
  dataIndex: number
  dataset: {
    type?: string
    data: T[]
    label: string
  }
}

export enum ChartType {
  LargestBrandChange = 'largest-brand-change',
  ShareOfCategory = 'share-of-category',
  TotalDistributionPoints = 'total-distribution-points',
  TotalSalesAndUnits = 'total-sales-and-units',
  OosLeaderboard = 'oos-leaderboard',
  OutOfStockTrends = 'out-of-stock-trends'
}

export enum ChartOptionId {
  DeleteChart = 'delete-chart',
  DuplicateChart = 'duplicate-chart',
  OpenSettings = 'open-settings',
  ExportImage = 'export-image',
  ExportCsv = 'export-csv',
  ExportXlsx = 'export-xlsx'
}

export type ChartOption = {
  id: ChartOptionId
  icon: ReactElement
  title: ReactNode
  onClick?: () => void
}

export type ChartSettingType = 'TIME_MEASURE' | 'FILTERS' | 'DATA_DISPLAY'

export type MeasureUnit = 'days' | 'weeks' | 'months'

export type MeasureQuerySettings = { measure: MeasureUnit; measureMaxSize: number; measureInterval: number }

export type MeasureSettingsConfig = { measure?: MeasureUnit; measureSize?: number }

export interface ChartSettingsState<TDataDisplay extends DataDisplay = DataDisplay> {
  id: string
  title: string
  type: ChartType
  measure?: MeasureUnit
  measureSize?: number
  customFilters?: DraftFilterset
  customFiltersEnabled?: boolean
  dataDisplay?: TDataDisplay
  miSettings?: MeasureSettingsConfig
  featureFlag?: toggles
}

export const chartSettingsTypes: Record<ChartType, ChartSettingType[]> = {
  [ChartType.OutOfStockTrends]: ['TIME_MEASURE', 'FILTERS'],
  [ChartType.TotalDistributionPoints]: ['TIME_MEASURE', 'DATA_DISPLAY', 'FILTERS'],
  [ChartType.TotalSalesAndUnits]: ['TIME_MEASURE', 'FILTERS'],
  [ChartType.ShareOfCategory]: ['TIME_MEASURE', 'DATA_DISPLAY', 'FILTERS'],
  [ChartType.LargestBrandChange]: ['TIME_MEASURE', 'DATA_DISPLAY', 'FILTERS'],
  [ChartType.OosLeaderboard]: ['FILTERS']
}

export const defaultChartMeasure: Record<MeasureUnit, { measure: MeasureUnit; measureSize: number }> = {
  days: {
    measure: 'days',
    measureSize: 7
  },
  weeks: {
    measure: 'weeks',
    measureSize: 8
  },
  months: {
    measure: 'months',
    measureSize: 6
  }
}

export const timeMeasureQueryMapping: Record<MeasureUnit, MeasureQuerySettings> = {
  days: {
    // 7 days 1 day interval
    measure: 'days',
    measureMaxSize: 7,
    measureInterval: 1
  },
  weeks: {
    // 8 weeks 7 day interval
    measure: 'weeks',
    measureMaxSize: 8,
    measureInterval: 7
  },
  months: {
    // 12 months 1 month interval
    measure: 'months',
    measureMaxSize: 12,
    measureInterval: 1
  }
}

export type WithTimeMeasure<T extends ChartSettingsState> = SetRequired<T, 'measure' | 'measureSize'>
