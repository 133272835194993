import { Box } from '@client/styles/theme/box'
import { Circle, ErrorOutline } from '@mui/icons-material'
import { Divider, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { memo } from 'react'

type InvalidFiltersInfoProps =
  | {
      messages?: string[]
      isGlobal: true
      components?: string[]
    }
  | {
      messages?: string[]
      isGlobal?: false
      components?: never
    }

export const InvalidFiltersInfo: React.FC<InvalidFiltersInfoProps> = memo(function InvalidFiltersInfo({
  isGlobal = false,
  messages = [],
  components = []
}) {
  return (
    <Box maxWidth={300} data-testid="invalid-filters-info" py={3}>
      <Stack direction="row" gap={2} sx={{ px: 3 }}>
        <ErrorOutline fontSize="small" />
        <Typography fontWeight={600}>
          {isGlobal ? 'Filter with restrictions' : 'Some filters may contain restrictions'}
        </Typography>
      </Stack>
      <Divider sx={{ my: 3 }} />
      <Box px={3}>
        {messages.map((message) => (
          <Typography key={message} data-testid="invalid-filters-info--message">
            {message}
          </Typography>
        ))}
        <Typography
          variant="body2"
          my={1}
          fontStyle="italic"
          color="text.secondary"
          data-testid="invalid-filters-info--subtext"
        >
          {isGlobal
            ? components.length
              ? 'This filter will not be applied to:'
              : 'This filter will not be applied in this page'
            : 'Invalid filters are not being applied to this widget'}
        </Typography>
        {components.map((component) => (
          <ListItem sx={{ py: 0, pl: 1, my: 0 }} key={component} data-testid="invalid-filters-info--component">
            <Circle sx={{ fontSize: 5, mr: 1 }} />
            <ListItemText sx={{ my: 0 }} primary={component} />
          </ListItem>
        ))}
      </Box>
    </Box>
  )
})
