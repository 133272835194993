import { AccessControl } from '@client/components/access-control/AccessControl'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { ErrorDisplay } from '@client/components/ErrorDisplay/ErrorDisplay'
import { ListDetailView } from '@client/components/ListDetailView/ListDetailView'
import { PageLoader } from '@client/components/page-loader/PageLoader'
import { ListDetailToggleButtonGroup } from '@client/components/toggle-group/ToggleGroup.style'
import { urls } from '@client/helpers/urls'
import { useRelationships } from '@client/hooks/relationships/use-relationships'
import { useHelpLink } from '@client/hooks/use-help-link'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { Layout, RelationshipsIcon } from '@client/styles/global'
import {
  editRelationshipsPermissions,
  emptyDraftRelationship,
  NEW_URL_KEY,
  Relationship,
  RelationshipType,
  RelationshipTypeLabels
} from '@client/types/relationships'
import { Add } from '@mui/icons-material'
import { Box, Card, Link, ToggleButton, Typography } from '@mui/material'
import { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RelationshipDetailsView } from './components/relationship-details-view/RelationshipDetailsView'
import {
  RelationshipListItem,
  RelationshipListItemProps
} from './components/relationship-list-item/RelationshipListItem'
import { RelationshipManagerPageForm } from './components/relationship-manager-page-form/RelationshipManagerPageForm'
import { RelationshipManagerPageLanding } from './components/relationship-manager-page-landing/RelationshipManagerPageLanding'

const RelationshipManagerDetails: React.FC<{ item: Relationship }> = memo(function RelationshipManagerDetails({
  item
}) {
  return <RelationshipDetailsView relationship={item} />
})

const options: Array<{ id: RelationshipType; label: string }> = Object.entries(RelationshipTypeLabels).map(
  ([id, label]) => ({
    id: id as RelationshipType,
    label
  })
)

const CustomRelationshipFilter: React.FC<{ onChange: (type?: RelationshipType) => void; value?: RelationshipType }> =
  memo(function CustomRelationshipFilter({ onChange, value }) {
    return (
      <ListDetailToggleButtonGroup
        color="primary"
        size="small"
        exclusive
        value={value}
        onChange={(event: MouseEvent<HTMLElement>, type?: RelationshipType) => onChange(type)}
      >
        {options.map(({ id, label }) => (
          <ToggleButton key={id} value={id} data-testid={`custom-relationship-filter--type-${id}`}>
            {label}
          </ToggleButton>
        ))}
      </ListDetailToggleButtonGroup>
    )
  })

interface RelationshipManagerPageProps {
  details?: boolean
}

const RelationshipManagerPage: React.FC<RelationshipManagerPageProps> = memo(function RelationshipManagerPage({
  details = false
}) {
  const navigate = useNavigateRef()
  const { id: relationshipId } = useParams()
  const { isFetching, relationships, deleteRelationship, getRelationshipById } = useRelationships()
  const [filteredType, setFilteredType] = useState<RelationshipType | undefined>(undefined)
  const isDraft = relationshipId === NEW_URL_KEY

  const currentRelationship = useMemo(
    () => (isDraft ? emptyDraftRelationship() : relationshipId ? getRelationshipById(relationshipId) : undefined),
    [getRelationshipById, isDraft, relationshipId]
  )

  const invalidRelationship = !isDraft && !currentRelationship && relationshipId

  const currentView = useMemo(() => {
    if (details) {
      return RelationshipManagerDetails
    } else {
      return RelationshipManagerPageForm
    }
  }, [details])

  const handleNewRelationshipClick = useCallback(() => {
    navigate.current(`/settings/relationships/${NEW_URL_KEY}`)
  }, [navigate])

  const handleItemSelect = useCallback(
    (relationship?: Relationship) => {
      navigate.current(urls.relationshipManagerPage(relationship?.id))
    },
    [navigate]
  )

  const handleOpenDetails = useCallback(
    (relationship: Relationship) => {
      navigate.current(urls.relationshipManagerPage(`${relationship.id}/details`))
    },
    [navigate]
  )

  const handleIsActive = useCallback(
    (relationship: Relationship) => relationship.id === relationshipId,
    [relationshipId]
  )

  const handleDelete = useCallback(
    async (relationship: Relationship) => {
      const deleted = await deleteRelationship(relationship.id)
      if (deleted && currentRelationship?.id === relationship.id) {
        navigate.current(urls.relationshipManagerPage())
      }
      return deleted
    },
    [currentRelationship, deleteRelationship, navigate]
  )

  const handleOnSearch = useCallback((relationship: Relationship, searchTerm: string) => {
    const lcSearchTerm = searchTerm.toLowerCase()
    return relationship.name?.toLowerCase().includes(lcSearchTerm)
  }, [])

  const handleFilterByType = useCallback(
    (relationship: Relationship) => !filteredType || relationship.type === filteredType,
    [filteredType]
  )

  useHelpLink({
    tooltip: 'Relationship Manager',
    helpLink: 'settings/relationships',
    order: 0,
    icon: RelationshipsIcon
  })

  if (isFetching) {
    return <PageLoader />
  }

  if (invalidRelationship) {
    return (
      <ErrorDisplay message="The relationship you're trying to load does not exist">
        <Link href={urls.relationshipManagerPage(undefined, '#/')} underline="hover" color="inherit">
          Go back to Relationship Manager initial page
        </Link>
      </ErrorDisplay>
    )
  }

  return (
    <Layout maxWidth={1200}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Typography data-testid="page-header" variant="h3">
          Manage Relationships
        </Typography>
      </Box>
      <Card>
        <ErrorBoundary>
          <AccessControl
            permission={editRelationshipsPermissions}
            emailSubject="I'm interested in the Relationship Manager"
          >
            <ListDetailView
              desktopBackButton
              key={currentRelationship?.id || NEW_URL_KEY}
              items={relationships}
              item={currentRelationship as Relationship}
              itemProps={
                {
                  onOpenDetails: handleOpenDetails,
                  onDelete: handleDelete,
                  isActive: handleIsActive
                } as Omit<RelationshipListItemProps, 'item' | 'onClick'>
              }
              onItemSelect={handleItemSelect}
              onSearch={handleOnSearch}
              onCustomSearch={handleFilterByType}
              CustomSearchComponent={<CustomRelationshipFilter value={filteredType} onChange={setFilteredType} />}
              ListItemComponent={RelationshipListItem}
              DetailViewComponent={currentView}
              PlaceholderComponent={<RelationshipManagerPageLanding />}
              listWidth={280}
              title="Relationships"
              noItemsMessage="No relationships to display."
              headerActions={[
                {
                  onClick: handleNewRelationshipClick,
                  children: 'New relationship',
                  endIcon: <Add />,
                  size: 'small',
                  variant: 'contained'
                }
              ]}
            />
          </AccessControl>
        </ErrorBoundary>
      </Card>
    </Layout>
  )
})

export default RelationshipManagerPage
