import { isProductionEnv, sharedEnv } from '@lib/env'
import { version } from '@lib/version'
import { captureException, captureMessage, init, setUser } from '@sentry/react'

export const Sentry = {
  init: () => {
    if (!isProductionEnv() && sharedEnv.REACT_APP_DEBUG_SENTRY) {
      console.warn('Sentry debugging is enabled')
    }

    return init({
      dsn: sharedEnv.REACT_APP_SENTRY_DSN,
      release: `v${version}`,
      environment: sharedEnv.REACT_APP_ENVIRONMENT,
      enabled: isProductionEnv() || sharedEnv.REACT_APP_DEBUG_SENTRY,
      attachStacktrace: true
    })
  },

  captureMessage,
  captureException,
  setUser
}
