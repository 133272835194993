import { DispensaryHit, ProductHit } from '@client/services/algolia'
import {
  WholesaleCustomer,
  WholesaleInventoryItem,
  WholesaleOrder,
  WholesaleOrderStatus,
  WholesaleSimulatedData
} from '@client/types/wholesale'
import { GridFilterModel } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import { getRandomName } from './dummy-data'
import { getRandomInt } from './numbers'

export const DEFAULT_SIMULATED_COUNT = 100

// Sometimes the column names in the grid don't match the GraphQL schema field names
// This function handles mapping the filter model to the correct field names
export const applyFilterMapping = (filterModel: GridFilterModel, mapping: Record<string, string>) => {
  return {
    ...filterModel,
    items: filterModel.items.map((item) => {
      const field = mapping[item.field] ?? item.field
      return { ...item, field }
    })
  }
}

export function simulateWholesaleCustomers(
  dispensaries: DispensaryHit[] = [],
  requiredSimulatedCount = DEFAULT_SIMULATED_COUNT
): WholesaleCustomer[] {
  const data: WholesaleCustomer[] = []
  for (let i = 0; i < requiredSimulatedCount; i++) {
    const dispensary = dispensaries[i]
    data.push({
      id: `customer-${i}`,
      name: dispensary?.DISPENSARY_NAME ?? `Customer ${i}`,
      dispensaryId: dispensary?.objectID,
      locations: [
        {
          id: `location-${i}`,
          addr1: dispensary?.STREET_ADDRESS ?? `1234 Main St`,
          addr2: dispensary ? '' : `Suite ${i}`,
          city: dispensary?.CITY ?? `Denver`,
          state: dispensary?.STATE ?? `CO`,
          postalCode: dispensary?.POSTAL_CODE ?? `80202`,
          name: dispensary?.DISPENSARY_NAME ?? `location ${i}`,
          country: dispensary?.COUNTRY_CODE ?? `US`,
          rawData: {}
        }
      ],
      orderCount: getRandomInt(0, 100),
      rawData: {}
    })
  }
  return data
}
export function simulateWholesaleInventory(
  products: ProductHit[] = [],
  requiredSimulatedCount = DEFAULT_SIMULATED_COUNT
): WholesaleInventoryItem[] {
  const data: WholesaleInventoryItem[] = []
  for (let i = 0; i < requiredSimulatedCount; i++) {
    const product = products[i]
    data.push({
      id: `product-${i}`,
      cmId: product?.CM_ID ?? undefined,
      quantity: getRandomInt(0, 10000),
      quantityAvailable: getRandomInt(0, 10000),
      quantityReserved: getRandomInt(0, 1000),
      region: product?.D_STATE ?? `CO`,
      rawData: {},
      product: {
        name: product?.NAME ?? `Product ${i}`,
        cmId: product?.CM_ID ?? undefined,
        rawData: {}
      }
    })
  }
  return data
}

export function simulateWholesaleOrders(
  customers: WholesaleCustomer[],
  inventory: WholesaleInventoryItem[],
  requiredSimulatedCount = 10
): WholesaleOrder[] {
  const data: WholesaleOrder[] = []

  const status: WholesaleOrderStatus[] = ['Accepted', 'Backorder', 'Complete', 'Fulfilled', 'Shipped', 'Submitted']
  customers.forEach((customer, customerIndex) => {
    let createdAtBase = 0
    for (let i = 0; i < requiredSimulatedCount; i++) {
      createdAtBase += getRandomInt(1, 5)
      const from = getRandomInt(0, inventory.length - 1)
      const to = from + getRandomInt(1, 15)
      const orderItems = inventory.slice(from, to).map((item, index) => {
        const unitSize = 1
        const unitPrice = getRandomInt(5, 150)
        const unitQuantity = getRandomInt(1, 20) * 4
        const caseSize = unitSize * 4
        const caseQuantity = unitQuantity / caseSize
        return {
          id: `item-${customerIndex}-${i}-${index}`,
          casePrice: unitPrice * caseSize,
          caseQuantity,
          caseSize,
          extractedAt: '2022-10-02T15:32:59',
          totalPrice: unitPrice * unitQuantity,
          uom: 'Unit',
          unitPrice,
          unitQuantity,
          unitSize,
          rawData: {},
          product: { name: item.product.name, cmId: item.product.cmId, category: 'Flower' }
        }
      })

      data.push({
        id: `order-${customerIndex}-${i}`,
        createdAt: dayjs()
          .subtract(createdAtBase * 7, 'day')
          .toISOString(),
        shipDate:
          i > 2 && !!getRandomInt(0, 3)
            ? dayjs()
                .subtract(createdAtBase * 5, 'day')
                .toISOString()
            : null,
        unitQty: orderItems.reduce((acc, item) => acc + item.unitQuantity ?? 0, 0) || getRandomInt(10, 100),
        orderTotal: orderItems.reduce((acc, item) => acc + item.totalPrice, 0) || getRandomInt(100, 10000),
        orderStatus: status[getRandomInt(0, status.length)] ?? null,
        shippingAmount: orderItems.reduce((acc, item) => acc + item.unitQuantity, 0) || getRandomInt(10, 100),
        region: customer?.locations[0]?.state ?? `CO`,
        discountType: '%',
        discountAmount: !getRandomInt(0, 3) ? getRandomInt(0, 20) : null,
        salesRepName: getRandomName(),
        client: customer?.name ?? `Customer ${customerIndex}`,
        rawData: {},
        customer: {
          id: `customer-${customerIndex}`,
          name: customer?.name ?? `Customer ${customerIndex}`,
          dispensaryId: customer?.dispensaryId ?? null
        },
        orderItems
      })
    }
  })
  return data
}

export function simulateWholesaleData(
  {
    dispensaries,
    products
  }: {
    dispensaries: DispensaryHit[]
    products: ProductHit[]
  },
  requiredSimulatedCount = DEFAULT_SIMULATED_COUNT
): WholesaleSimulatedData {
  const customers = simulateWholesaleCustomers(dispensaries, requiredSimulatedCount)
  const inventory = simulateWholesaleInventory(products, requiredSimulatedCount)
  const orders = simulateWholesaleOrders(customers, inventory, 10)
  return {
    customers,
    inventory,
    orders
  }
}

export function simulateKey(column: string, simulatedDataKey: keyof WholesaleSimulatedData) {
  if (simulatedDataKey === 'customers') {
    switch (column) {
      case 'customer_name':
        return 'name'
    }
  }
  if (simulatedDataKey === 'inventory') {
    switch (column) {
      case 'product.product_name':
        return 'product.name'
      case 'quantity_available':
        return 'quantityAvailable'
      case 'quantity_reserved':
        return 'quantityReserved'
    }
  }
  if (simulatedDataKey === 'orders') {
    switch (column) {
      case 'created_at':
        return 'createdAt'
      case 'ship_date':
        return 'shipDate'
      case 'customer.customer_name':
        return 'customer.name'
      case 'order_total':
        return 'orderTotal'
      case 'sales_rep_name':
        return 'salesRepName'
    }
  }
  return column
}
