import { useAuth0 } from '@auth0/auth0-react'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { usePermission } from '@client/hooks/use-permissions'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useHasura } from '@client/providers/hasura'
import { apiClient } from '@client/services/api/api-client'
import { FilterOption, applyFilterOverrides } from '@client/types/filterset'
import {
  DraftFilterset,
  DraftView,
  View,
  emptyDraftFilterset,
  migrateCategoryToCategories
} from '@hoodie/hoodie-filters/lib/filterset'
import { Permission } from '@lib/types/permission'
import * as Sentry from '@sentry/browser'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

const { mutate: purgeNotificationPreferencesForFilterset } = apiClient.notificationPreferences.purgeOrphans

const { query: getFilterset } = apiClient.filtersets.getFilterset

export interface TempFilters {
  filterset: DraftFilterset
  visibleFilters: FilterOption[]
  preFilterset?: DraftFilterset
  invisibleFilters?: FilterOption[]
}

interface SavedViewsContextData {
  hasFetchedViews: boolean
  savedViews: View[]
  insertSavedView(newSavedView: DraftView): Promise<View | null>
  updateSavedView(updatedSavedView: View): Promise<View | null>
  deleteSavedView(savedViewId: string): Promise<boolean>
  fetchSavedViews(): Promise<View[]>
  tempFilters?: TempFilters
  setTempFilters: (tempFilters?: TempFilters) => void
  globalFilterset: DraftFilterset
  currentFilterset: DraftFilterset
  setGlobalFilterset: (filterset: DraftFilterset) => void
}

const SavedViewsContext = createContext<SavedViewsContextData>({} as SavedViewsContextData)

const CURRENT_VIEW_KEY = (userId?: string) => `@hoodie:${userId}:current-filterset`

const persistGlobalFilterset = (globalFilterset: DraftFilterset, user?: string) => {
  window.localStorage?.setItem(CURRENT_VIEW_KEY(user), JSON.stringify(globalFilterset))
}

const rehydrateGlobalFilterset = (user?: string) => {
  const filtersetString = window.localStorage?.getItem(CURRENT_VIEW_KEY(user))
  try {
    const rehydratedFilterset: DraftFilterset & { filters: { filterBy: { category?: string } } } = filtersetString
      ? (JSON.parse(filtersetString) as DraftFilterset)
      : emptyDraftFilterset()
    // Legacy migration: Convert category to categories
    migrateCategoryToCategories(rehydratedFilterset.filters.filterBy)
    return rehydratedFilterset as DraftFilterset
  } catch (err) {
    Sentry.captureException(err)
    return emptyDraftFilterset()
  }
}

type SavedViewsProviderProps = {
  filterPrefix?: string
}

const SavedViewsProvider: FC<PropsWithChildren<SavedViewsProviderProps>> = ({ children, filterPrefix = '' }) => {
  const { user } = useAuth0()
  const filterSetName = filterPrefix + user?.name
  const { value: hasFetchedViews, setTrue: setHasFetchedViews } = useBooleanState(false)
  const [savedViews, setSavedViews] = useState<View[]>([])
  const [tempFilters, setTempFilters] = useState<TempFilters>()
  const [globalFilterset, setGlobalFilterset] = useState<DraftFilterset>(rehydrateGlobalFilterset(filterSetName))
  const { hasuraClient } = useHasura()

  const { hasPermission: hasSEPermission } = usePermission(Permission.MODULE_ACCESS_SALES_ENABLEMENT)
  const [filtersetIdUrlParam, setFiltersetIdUrlParam] = useQueryParam('filtersetId', StringParam, {
    enableBatching: true
  })
  const { toast } = useToastNotification()

  // Set the current filterset from the URL param
  useEffect(() => {
    if (hasFetchedViews && filtersetIdUrlParam) {
      const filterset = savedViews.find((savedView) => savedView.id === filtersetIdUrlParam)
      if (filterset) {
        setGlobalFilterset(filterset)
        setFiltersetIdUrlParam(undefined, 'replaceIn')
      } else {
        getFilterset({ filtersetId: filtersetIdUrlParam })
          .then((filterset) => {
            setGlobalFilterset(filterset)
          })
          .catch(() => {
            toast.error(`Could not find saved view with id ${filtersetIdUrlParam}`)
          })
          .finally(() => {
            setFiltersetIdUrlParam(undefined, 'replaceIn')
          })
      }
    }
  }, [hasFetchedViews, filtersetIdUrlParam, savedViews, setFiltersetIdUrlParam, toast])

  const insertSavedView = useCallback(
    async (newSavedView: DraftView & { subscriptionId: View['subscriptionId'] }) => {
      if (hasuraClient) {
        try {
          const newSavedViewRecord = await hasuraClient.insertFilterset(newSavedView)
          setSavedViews([...savedViews, newSavedViewRecord])
          return newSavedViewRecord
        } catch (err) {
          return null
        }
      }
      return null
    },
    [hasuraClient, savedViews]
  )

  const updateSavedView = useCallback(
    async (updatedSavedView: View) => {
      if (hasuraClient) {
        try {
          const updatedIndex = savedViews.findIndex((view) => view.id === updatedSavedView.id)
          // If the view is being unshared or transferred (and not shared), purge any now-orphaned
          // notification preferences associated with this view. In the case of transferring a view,
          // for security this has to be done before updating the view (while the authenticated user
          // still owns the view)
          const isUnshared = savedViews[updatedIndex].subscriptionId && !updatedSavedView.subscriptionId
          const isTransferred = updatedSavedView.userId !== user?.sub && !updatedSavedView.subscriptionId
          if (isUnshared || isTransferred) {
            await purgeNotificationPreferencesForFilterset({
              filtersetId: updatedSavedView.id,
              newFiltersetOwnerId: updatedSavedView.userId
            })
          }
          await hasuraClient.updateFilterset(updatedSavedView)
          if (updatedIndex === -1) {
            // Maybe this view was deleted (race-condition)
            return updatedSavedView
          }
          if (updatedSavedView.userId !== user?.sub && !updatedSavedView.subscriptionId) {
            // This view was transferred to another user so remove it from the list
            // If it's also the current view, we should clear the current filterset
            if (updatedSavedView.id === globalFilterset.id) {
              setGlobalFilterset(emptyDraftFilterset())
            }
            setSavedViews(savedViews.filter(({ id }) => id !== updatedSavedView.id))
            return updatedSavedView
          }
          const updatedSavedViews = [
            ...savedViews.slice(0, updatedIndex),
            updatedSavedView,
            ...savedViews.slice(updatedIndex + 1)
          ]
          setSavedViews(updatedSavedViews)
          if (updatedSavedView.id === globalFilterset.id) {
            setGlobalFilterset(updatedSavedView)
          }
          return updatedSavedView
        } catch (err) {
          return null
        }
      }
      return null
    },
    [hasuraClient, savedViews, user?.sub, globalFilterset.id]
  )

  const deleteSavedView = useCallback(
    async (savedViewId: string) => {
      if (hasuraClient) {
        try {
          await hasuraClient.deleteFilterset(savedViewId)
          setSavedViews(savedViews.filter(({ id }) => id !== savedViewId))
          if (savedViewId === globalFilterset.id) {
            setGlobalFilterset(emptyDraftFilterset())
          }
          return true
        } catch (err) {
          return false
        }
      }
      return false
    },
    [hasuraClient, globalFilterset.id, savedViews, setSavedViews, setGlobalFilterset]
  )

  const fetchSavedViews = useCallback(async () => {
    if (hasuraClient && hasSEPermission) {
      try {
        const savedViewRecords = await hasuraClient.listFiltersets()
        setSavedViews(savedViewRecords)
        // If the rehydrated global filterset has an ID and is not present in the list of saved views,
        // reset it now.
        if (globalFilterset.id && !savedViewRecords.find((v) => v.id === globalFilterset.id)) {
          setGlobalFilterset(emptyDraftFilterset())
        }
        setHasFetchedViews()
        return savedViewRecords
      } catch (err) {
        toast.error('Failed to fetch saved views')
        setHasFetchedViews()
        return []
      }
    } else {
      return []
    }
  }, [hasuraClient, hasSEPermission, globalFilterset.id, setHasFetchedViews, toast])

  useEffect(() => {
    if (savedViews.length <= 0 && hasuraClient) {
      fetchSavedViews()
    }
  }, [savedViews.length, hasuraClient, fetchSavedViews])

  useEffect(() => {
    persistGlobalFilterset(globalFilterset, filterSetName)
  }, [globalFilterset, filterSetName])

  const currentFilterset = useMemo(() => {
    return applyFilterOverrides(globalFilterset, tempFilters?.filterset)
  }, [globalFilterset, tempFilters?.filterset])

  return (
    <SavedViewsContext.Provider
      value={{
        hasFetchedViews,
        savedViews,
        insertSavedView,
        updateSavedView,
        deleteSavedView,
        fetchSavedViews,
        tempFilters,
        setTempFilters,
        globalFilterset,
        setGlobalFilterset,
        currentFilterset
      }}
    >
      {children}
    </SavedViewsContext.Provider>
  )
}

function useSavedViews() {
  const context = useContext(SavedViewsContext)

  if (!context) {
    throw new Error('useSavedViews must be used within an SavedViewsProvider.')
  }

  return context
}

export { SavedViewsContext, SavedViewsProvider, useSavedViews }
