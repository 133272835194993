import { palette } from '@client/styles/theme/palette'
import { Dayjs } from 'dayjs'

export type Location = {
  id: string
  name: string
  addr1: string
  addr2: string
  city: string
  state: string
  postalCode: string
  country: string | null
  rawData?: Record<string, any>
}

export type WholesaleCustomerItemResponse = {
  id: string
  name: string
  dispensaryId: string | null
  rawData?: Record<string, any>
  locations: Array<{
    location: Location
  }>
  orderCount: {
    aggregate: {
      count: number
    }
  }
}

export type WholesaleCustomer = Omit<WholesaleCustomerItemResponse, 'locations' | 'orderCount'> & {
  locations: Location[]
  orderCount: number
}

export type WholesaleCustomersResponse = {
  customers: WholesaleCustomerItemResponse[]
  customersAgg: {
    aggregate: {
      count: number
    }
  }
}

export type WholesaleInventoryItemResponse = {
  id: string
  cmId?: string // Not available yet - but this could be where we store the mapped product ID
  quantity: number
  quantityAvailable: number
  quantityReserved: number
  region?: string
  rawData?: Record<string, any>
  product: {
    name: string
    cmId?: string
    rawData?: Record<string, any>
  }
}

export type WholesaleInventoryResponse = {
  inventory: WholesaleInventoryItemResponse[]
  inventoryAgg: {
    aggregate: {
      count: number
    }
  }
}

export type WholesaleInventoryItem = WholesaleInventoryItemResponse

export type OrderItem = {
  id: string
  totalPrice: number | null
  uom: string
  casePrice: number | null
  caseQuantity: number
  caseSize: number
  unitPrice: number | null
  unitQuantity: number
  unitSize: number
  extractedAt: string
  rawData?: Record<string, any>
}

export type WholesaleOrderResponse = {
  id: string
  createdAt: string
  shipDate: string | null
  orderTotal: number | null
  orderStatus: WholesaleOrderStatus | null
  shippingAmount: number
  region: string
  discountType: string
  discountAmount: number | null
  client: string
  salesRepName: string | null
  rawData?: Record<string, any>
  customer: {
    id: string
    name: string
    dispensaryId: string | null
  }
  orderItems: Array<OrderItem & { product: { name: string; cmId?: string | null; category: string | null } }>
}

export type WholesaleOrdersResponse = {
  orders: WholesaleOrderResponse[]
  orderCount: {
    aggregate: {
      count: number
    }
  }
}

export type WholesaleOrder = WholesaleOrderResponse & {
  unitQty: number
}

export type WholesaleLastOrder = Omit<WholesaleOrder, 'orderItems'>

export type WholesaleOrderItemSummary = {
  unitQuantity: number | null
  orderTotal?: number | null
  order: {
    createdAt: string
    orderStatus: WholesaleOrderStatus | null
    shipDate: string | null
  }
}

export type WholesaleDispensariesLastOrderResponse = {
  orders: Array<WholesaleLastOrder>
}

export type WholesaleInventorySummary = {
  quantity: number
  quantityAvailable: number
  quantityReserved: number
}

export type WholesaleOrdersByCmidsResponse = {
  product: Array<{
    orderItems: Array<WholesaleOrderItemSummary>
    inventories: Array<WholesaleInventorySummary>
    cmid: string
  }>
}

export type WholesaleDispensariesByOrdersFiltersResponse = {
  customers: Array<{ dispensaryId: string }>
}

export type WholesaleOrderCountResponse = {
  orderCount: {
    aggregate: {
      count: number
    }
  }
}

export type WholesaleProductStatus = { latestOrder: WholesaleOrderItemSummary; inventory: WholesaleInventorySummary }

///////////////////////////////////////////////////////////
// SORT OPTIONS
export enum CustomerSortField {
  customerName = 'customer_name'
}

export enum InventorySortField {
  productName = 'product.product_name',
  quantity = 'quantity',
  quantityAvailable = 'quantity_available',
  quantityReserved = 'quantity_reserved',
  region = 'region'
}

export enum OrderSortField {
  createdAt = 'created_at',
  shipDate = 'ship_date',
  orderTotal = 'order_total',
  region = 'region',
  customer = 'customer.customer_name',
  salesRepName = 'sales_rep_name'
}
///////////////////////////////////////////////////////////

// FILTER FIELD MAPPING

export const customerFilterFieldMapping: Record<string, string> = {
  city: 'customer_locations.location.city',
  state: 'customer_locations.location.state',
  postalCode: 'customer_locations.location.postal_code'
}

export const ordersFilterFieldMapping: Record<string, string> = {}

export const inventoryFilterFieldMapping: Record<string, string> = {}

///////////////////////////////////////////////////////////

// TEMP: These status options will be tidied up and consolidated. But for now we accept both Leaflink and Leaftrade statuses
type LLWholesaleOrderStatus = 'Accepted' | 'Backorder' | 'Complete' | 'Fulfilled' | 'Shipped' | 'Submitted'
type LTWholesaleOrderStatus =
  | 'approved'
  | 'cancelled'
  | 'new'
  | 'packaged'
  | 'complete_seller'
  | 'shipped'
  | 'complete'
  | 'revised'
export type WholesaleOrderStatus = LLWholesaleOrderStatus | LTWholesaleOrderStatus

export const orderStatusColor = (status?: WholesaleOrderStatus): string => {
  switch (status) {
    case 'Complete':
    case 'Fulfilled':
    case 'Shipped':
    case 'shipped':
    case 'complete':
    case 'complete_seller':
      return palette.success.main
    case 'Submitted':
    case 'approved':
      return palette.orange
    case 'cancelled':
      return palette.red
    case 'new':
    case 'Accepted':
    case 'Backorder':
    case 'packaged':
    case 'revised':
      return palette.mediumdark
    default:
      return palette.mediumdark
  }
}

export type GroupByProduct = 'category' | 'name'

export type WholesaleSimulatedData = {
  orders?: WholesaleOrder[]
  customers?: WholesaleCustomer[]
  inventory?: WholesaleInventoryItem[]
}

export type WholesaleHoodieOrderStatus =
  | 'New'
  | 'Submitted'
  | 'Packaged'
  | 'Complete Seller'
  | 'Revised'
  | 'Backorder'
  | 'Approved'
  | 'Shipped'
  | 'Complete'

export const wholesaleOrderStatusMapping = new Map<WholesaleHoodieOrderStatus, WholesaleOrderStatus[]>([
  ['New', ['new']],
  ['Submitted', ['Submitted']],
  ['Packaged', ['packaged']],
  ['Complete Seller', ['complete_seller']],
  ['Revised', ['revised']],
  ['Backorder', ['Backorder']],
  ['Approved', ['Accepted', 'approved']],
  ['Shipped', ['Shipped', 'shipped']],
  ['Complete', ['Fulfilled', 'Complete', 'complete']]
])

export const getHoodieOrderStatus = (status: WholesaleOrderStatus): WholesaleHoodieOrderStatus => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hoodieStatus = [...wholesaleOrderStatusMapping.entries()].find(([_, statuses]) => statuses.includes(status))
  return hoodieStatus?.[0] ?? 'New'
}

export type WholesaleOrdersFilters = {
  orderStatus?: string[]
  startDate?: Dayjs
  endDate?: Dayjs
}
