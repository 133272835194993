import { hasValidGeolocation } from '@client/helpers/algolia/dispensaries'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { DispensaryHit } from '@client/services/algolia'
import { Point } from '@lib/types/utils'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import { ViewState } from 'react-map-gl'
import { useDebouncedCallback } from 'use-debounce'
import WebMercatorViewport from 'viewport-mercator-project'

// Corresponds to zoom level 14
const MIN_VIEWPORT_WIDTH = 0.005
const MIN_VIEWPORT_HEIGHT = 0.01

export const FALLBACK_BOUNDARIES: [Point, Point] = [
  [-151.1695984, 17.9772828],
  [-53.1140768, 61.6901923]
]

export const MAP_DEFAULTS = {
  width: 924,
  height: 440
}

export const getMinOrMax = (dispensaries: DispensaryHit[], minOrMax: 'max' | 'min', latOrLng: 'lat' | 'lng') => {
  return Math[minOrMax](...dispensaries.map((dispensary) => dispensary._geoloc?.[latOrLng] ?? 0))
}

export const getBounds = (dispensaries: DispensaryHit[]): [Point, Point] => {
  const cleanDispensaries = dispensaries.filter(hasValidGeolocation)
  // Center the map on the US if there are no valid dispensaries
  if (!cleanDispensaries.length) {
    return FALLBACK_BOUNDARIES
  }
  const minLatitude = getMinOrMax(cleanDispensaries, 'min', 'lat')
  const maxLatitude = getMinOrMax(cleanDispensaries, 'max', 'lat')
  const minLongitude = getMinOrMax(cleanDispensaries, 'min', 'lng')
  const maxLongitude = getMinOrMax(cleanDispensaries, 'max', 'lng')

  const southWest: Point = [minLongitude, minLatitude]
  const northEast: Point = [maxLongitude, maxLatitude]

  return [southWest, northEast]
}

// Prevent bounding box being smaller than the specified dimensions
export const ensureMinBounds = (
  bounds: [Point, Point],
  minWidth = MIN_VIEWPORT_WIDTH,
  minHeight = MIN_VIEWPORT_HEIGHT
): [Point, Point] => {
  let [[minLng, minLat], [maxLng, maxLat]] = bounds
  const width = maxLng - minLng
  const height = maxLat - minLat

  if (width < minWidth) {
    const diff = minWidth - width
    const halfDiff = diff / 2
    minLng -= halfDiff
    maxLng += halfDiff
  }

  if (height < minHeight) {
    const diff = minHeight - height
    const halfDiff = diff / 2
    minLat -= halfDiff
    maxLat += halfDiff
  }

  return [
    [minLng, minLat],
    [maxLng, maxLat]
  ]
}

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current || []
}

interface CalculateViewPortProps {
  width: number
  height: number
  dispensaries?: DispensaryHit[]
}

interface MapContextData {
  viewport: { width: number; height: number }
  viewState: ViewState | undefined
  setViewState: (nextViewState: ViewState) => void
  mapInteracted: boolean
  setMapNotInteracted: () => void
  setMapInteracted: () => void
  calculateViewPort: (props: CalculateViewPortProps) => void
  mapContainerRef: React.RefObject<HTMLDivElement>
  filteredDispensaryHits: DispensaryHit[]
  setFilteredDispensaryHits: (dispensaries: DispensaryHit[]) => void
  previousFilteredHits: DispensaryHit[]
  productFilterRange: number[]
  displayMarkers: Record<'carried' | 'other' | 'noMenu' | 'kpi', boolean>
  onSetDisplayMarker: (label: string) => void
  setProductFilterRange: (range: number[]) => void
  onProductFilterRangeChange: (event: Event, range: number[]) => void
  debouncedDispensaryFilter: (dispensaries: DispensaryHit[]) => void
}

const MapContext = createContext<MapContextData>({} as MapContextData)

const MapProvider: FC<PropsWithChildren> = ({ children }) => {
  //TODO these states are potentially better suited in a separate context, also still doesn't fix persisting state on page change
  const [filteredDispensaryHits, setFilteredDispensaryHits] = useState<DispensaryHit[]>([])
  const [productFilterRange, setProductFilterRange] = useState<number[]>([0, 0])
  const [displayMarkers, setDisplayMarker] = useState<Record<'carried' | 'other' | 'noMenu' | 'kpi', boolean>>({
    kpi: true,
    carried: true,
    other: true,
    noMenu: true
  })

  const onSetDisplayMarker = (label: string) => {
    setDisplayMarker({ ...displayMarkers, [label]: !displayMarkers[label as keyof typeof displayMarkers] })
  }
  const debouncedDispensaryFilter = useDebouncedCallback(setFilteredDispensaryHits, 200)
  const previousFilteredHits = usePrevious(filteredDispensaryHits)
  const onProductFilterRangeChange = (_event: Event, newValue: number[]) => {
    setProductFilterRange(newValue)
  }
  // END TODO

  const [viewport, setViewport] = useState({
    width: MAP_DEFAULTS.width,
    height: MAP_DEFAULTS.height
  })

  const [viewState, setViewState] = useState<ViewState | undefined>(undefined)

  const { value: mapInteracted, setTrue: setMapInteracted, setFalse: setMapNotInteracted } = useBooleanState(false)

  const mapContainerRef = useRef(null)

  const calculateViewPort = ({ width, height, dispensaries = [] }: CalculateViewPortProps) => {
    if (width && height) {
      setViewport({ width, height })

      if (dispensaries.length) {
        const origDispensariesBounds = getBounds(dispensaries)
        const dispensariesBounds = ensureMinBounds(origDispensariesBounds, MIN_VIEWPORT_WIDTH, MIN_VIEWPORT_HEIGHT)
        setViewState((viewstate) => {
          const nextViewport = new WebMercatorViewport({
            ...((viewstate ?? {}) as WebMercatorViewport),
            width,
            height
          }).fitBounds(dispensariesBounds as [[number, number], [number, number]], {
            padding: 50
          })
          return {
            latitude: nextViewport.latitude,
            longitude: nextViewport.longitude,
            zoom: nextViewport.zoom,
            pitch: nextViewport.pitch,
            bearing: nextViewport.bearing,
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }
          }
        })
      }
    }
  }

  return (
    <MapContext.Provider
      value={{
        viewport,
        viewState,
        calculateViewPort,
        mapContainerRef,
        mapInteracted,
        setMapNotInteracted,
        setMapInteracted,
        setViewState,
        filteredDispensaryHits,
        debouncedDispensaryFilter,
        previousFilteredHits,
        productFilterRange,
        onProductFilterRangeChange,
        displayMarkers,
        onSetDisplayMarker,
        setFilteredDispensaryHits,
        setProductFilterRange
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

function useMap() {
  const context = useContext(MapContext)

  if (!context) {
    throw new Error('useMap must be used within an MapProvider.')
  }

  return context
}

export { MapContext, MapProvider, useMap }
