import { QueryKey } from '@tanstack/react-query'

/**
 * Checks if the query should be invalidated based on the subscriptionId
 * @param queryKey The list of parameters passed to the query
 * @param [subscriptionId] The subscriptionId to check
 * @returns A boolean indicating if the query should be invalidated
 */
export const shouldInvalidateSubscriptionQuery = (queryKey: QueryKey, subscriptionId?: string): boolean => {
  // If the query is an admin graphql query and not a mutation
  if (queryKey[0] === '/graphql/query' && (queryKey[1] as string).startsWith('query')) {
    // Invalidate if it's a GetSubscriptions query
    if ((queryKey[1] as string).startsWith('query GetSubscriptions(')) {
      return true
    }
    // Invalidate if it's explicitly querying for the subscriptionId
    if (
      subscriptionId &&
      typeof queryKey[2] === 'object' &&
      ((queryKey[2] as Record<string, any>)?.where?.subscription_id?._eq === subscriptionId ||
        (queryKey[2] as Record<string, any>)?.subscription_id)
    ) {
      return true
    }
  }
  // Invalidate the activity log
  if (queryKey[0] === 'get-audit-log-entries') {
    return true
  }
  return false
}

export const shouldInvalidateUserQuery = (queryKey: QueryKey, userId?: string): boolean => {
  // If the query is an admin graphql query and not a mutation
  if (queryKey[0] === '/graphql/query' && (queryKey[1] as string).startsWith('query')) {
    // Invalidate if it's a GetSubscriptions query
    if ((queryKey[1] as string).startsWith('query GetUsers(')) {
      return true
    }
    // Invalidate if it's explicitly querying for the userId
    if (
      userId &&
      typeof queryKey[2] === 'object' &&
      ((queryKey[2] as Record<string, any>)?.where?.user_id?._eq === userId ||
        (queryKey[2] as Record<string, any>)?.user_id)
    ) {
      return true
    }
  }
  // Invalidate the activity log
  if (queryKey[0] === 'get-audit-log-entries') {
    return true
  }
  return false
}
