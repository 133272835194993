import { TableCell } from '@mui/material'
import styled from 'styled-components'

export const TableHeaderCell = styled(TableCell)`
  font-weight: 800;
`

export const TableValueCell = styled(TableCell)`
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`
