import { useAuth0 } from '@auth0/auth0-react'
import {
  CollapsibleHorizontalList,
  DEFAULT_HIGHLIGHTED_MAX_COUNT,
  ExposedCollapsibleHorizontalListProps
} from '@client/components/CollapsibleHorizontalList/CollapsibleHorizontalList'
import { AccessIcon } from '@client/components/access-icon/AccessIcon'
import { TagItem } from '@client/components/tags/TagItem/TagItem'
import { withVisibleTags } from '@client/components/tags/withVisibleTags'
import { palette } from '@client/styles/theme/palette'
import { typography } from '@client/styles/theme/typography'
import { TagWithItems } from '@client/types/tags'
import pluralize from 'pluralize'
import { memo, useCallback, useMemo } from 'react'

export interface TagListProps extends ExposedCollapsibleHorizontalListProps {
  tags: TagWithItems[]
  inline?: boolean
  readonly?: boolean
  onClick?: (tag: TagWithItems) => void
  onDelete?: (tag: TagWithItems) => void
}

const VisibleTagList: React.FC<TagListProps> = ({
  higlightedMaxCount = DEFAULT_HIGHLIGHTED_MAX_COUNT,
  readonly = false,
  inline = false,
  tags,
  onClick,
  onDelete,
  ...rest
}) => {
  const { user } = useAuth0()

  const showMoreText = useMemo(() => {
    const toDisplay = tags.length - higlightedMaxCount
    return `+${toDisplay} ${pluralize('tag', toDisplay)}`
  }, [higlightedMaxCount, tags.length])

  const renderTag = useCallback(
    (tag: TagWithItems, index?: number) => {
      const isMine = !!user && tag.userId === user?.sub
      const isShared = !!tag.items?.[0].subscriptionId
      const allowActions = !readonly && isMine

      return (
        <TagItem
          icon={
            <AccessIcon
              hasActions={!!onClick}
              isMine={isMine}
              isPublic={!tag.userId}
              isShared={isShared}
              sx={{ fill: palette.lightest, fontSize: `${typography.size.s1} !important` }}
            />
          }
          tag={tag}
          key={index}
          removable={allowActions && !!onDelete}
          onClick={allowActions && onClick ? () => onClick(tag) : undefined}
          onDelete={allowActions && onDelete ? () => onDelete(tag) : undefined}
        />
      )
    },
    [onClick, onDelete, readonly, user]
  )

  return (
    <CollapsibleHorizontalList
      higlightedMaxCount={higlightedMaxCount}
      {...rest}
      gap={1}
      flexWrap={inline ? 'nowrap' : 'wrap'}
      items={tags}
      renderItem={renderTag}
      moreButtonLabel={showMoreText}
      moreLessButtonProps={{ size: 'small', sx: { height: 20 } }}
    />
  )
}

export const TagList: React.FC<TagListProps> = memo(withVisibleTags(VisibleTagList))
