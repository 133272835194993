import { User } from '@auth0/auth0-react'
import { featureToggleMap, toggles } from '@client/hooks/use-feature-toggle'
import { version } from '@lib/version'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'

const logRocketAppId = process.env.REACT_APP_LOGROCKET_APP_ID
if (!logRocketAppId) {
  throw new Error('REACT_APP_LOGROCKET_APP_ID is not set')
}

export const shouldMonitor = (isMonitoringEnabled: boolean, userEmail?: string): boolean => {
  if (!isMonitoringEnabled) {
    return false
  }

  // Set the environment variable 'REACT_APP_DEBUG_MONITORING' to true to enable debugging LogRocket
  const debugMonitoring = process.env.REACT_APP_DEBUG_MONITORING === 'true'
  if (debugMonitoring) {
    return true
  }

  // The exclude list is a comman-separated list of regex patterns.
  // If the user's email matches any of the patterns, they will not be monitored.
  const monitorExcludeList = process.env.REACT_APP_MONITOR_EXCLUDE_LIST
  if (!monitorExcludeList || !userEmail) {
    return true
  }
  const excludeList = monitorExcludeList.split(',')
  const notExcludedByEmail = !excludeList.some((pattern) => new RegExp(pattern, 'i').test(userEmail))
  return notExcludedByEmail
}

export const getTraitsForUser = (user: User, roles: string[] | null): Record<string, string> => {
  const traits: Record<string, string> = {}
  if (user.email) {
    traits.email = user.email
  }
  if (user.name) {
    traits.name = user.name
  }
  if (roles) {
    traits.roles = roles.join(',')
  }
  return traits
}

export class Monitoring {
  private isEnabled = false
  private _isInitialized = false
  private isMonitoringFeatureEnabled: boolean

  constructor() {
    this.isMonitoringFeatureEnabled = !!featureToggleMap[toggles.ux_monitoring]
  }

  get sessionURL(): string | null {
    return this._isInitialized ? LogRocket.sessionURL : null
  }

  get isInitialized(): boolean {
    return this._isInitialized
  }

  init(user: User, roles: string[] | null) {
    if (this._isInitialized) {
      return
    }
    this.isEnabled = shouldMonitor(this.isMonitoringFeatureEnabled, user.email)

    if (this.isEnabled) {
      LogRocket.init(logRocketAppId as string, {
        release: `v${version}`,
        network: {
          requestSanitizer: (request: any) => {
            // Ignore image requests
            if (request.url.toLowerCase().indexOf('_next/image?') !== -1) {
              // ignore the request response pair
              return null
            }

            // otherwise log the request normally
            return request
          }
        }
      })

      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('logRocketSessionURL', sessionURL)
        })
      })

      LogRocket.identify(user.sub as string, getTraitsForUser(user, roles))
      this._isInitialized = true
    }
  }
}

export const monitor = new Monitoring()
