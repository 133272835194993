import { getVisibleTags } from '@client/types/tags'
import { Tag } from '@hoodie/hoodie-filters/lib/tags'
import { ComponentType, FC, PropsWithChildren } from 'react'

export interface WithVisibleTagsProps<T extends Tag> {
  tags: T[]
}

export function withVisibleTags<T extends Tag, P extends WithVisibleTagsProps<T>>(
  WrappedComponent: ComponentType<PropsWithChildren<P>>
): FC<PropsWithChildren<P>> {
  const WithVisibleTags: FC<PropsWithChildren<P>> = (props) => {
    const { tags, ...rest } = props
    const visibleTags = getVisibleTags(tags)
    return <WrappedComponent {...(rest as any)} tags={visibleTags} />
  }
  WithVisibleTags.displayName = `withVisibleTags(${WrappedComponent.displayName || WrappedComponent.name})`
  return WithVisibleTags
}
