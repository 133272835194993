import { deepmerge } from '@mui/utils'
import Joyride, { CallBackProps, Props } from 'react-joyride'
import { OnboardingStep, OnboardingTooltip } from './components/OnboardingTooltip'

const defaultStyles: Props['styles'] = {
  options: {
    zIndex: 2000
  }
}

const defaultFloaterProps: Props['floaterProps'] = {
  disableAnimation: true,
  styles: {
    wrapper: {
      zIndex: 2000
    }
  }
}

interface OnboardingCallBackProps extends CallBackProps {
  step: OnboardingStep
}

interface OnboardingProps extends Props {
  callback?: (data: OnboardingCallBackProps) => void
  steps: OnboardingStep[]
}

export const Onboarding: React.FC<OnboardingProps> = (props) => {
  return (
    <Joyride
      {...props}
      debug={process.env.NODE_ENV === 'development'}
      disableOverlayClose
      tooltipComponent={OnboardingTooltip}
      floaterProps={deepmerge(props.floaterProps || {}, defaultFloaterProps)}
      styles={deepmerge(props.styles || {}, defaultStyles)}
    />
  )
}
