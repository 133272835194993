import { getValueByPath } from '@client/helpers/objects'
import { applyFilterMapping } from '@client/helpers/wholesale'
import { useGraphqlQuery } from '@client/hooks/use-admin-graphql'
import { useIsSuperAdmin, usePermission } from '@client/hooks/use-permissions'
import { useDebug } from '@client/providers/debug-provider'
import { useUser } from '@client/providers/user'
import { useWholesaleStore } from '@client/stores/use-wholesale-store'
import {
  CustomerSortField,
  InventorySortField,
  OrderSortField,
  WholesaleCustomer,
  WholesaleCustomerItemResponse,
  WholesaleCustomersResponse,
  WholesaleDispensariesLastOrderResponse,
  WholesaleInventoryResponse,
  WholesaleLastOrder,
  WholesaleOrder,
  WholesaleOrderCountResponse,
  WholesaleOrdersByCmidsResponse,
  WholesaleOrdersResponse,
  WholesaleProductStatus,
  WholesaleSimulatedData,
  customerFilterFieldMapping,
  inventoryFilterFieldMapping,
  ordersFilterFieldMapping
} from '@client/types/wholesale'
import { graphql } from '@lib/clients/graphql'
import { addFiltersToWhereClause, addSearchTerm, addWhereClause } from '@lib/helpers/graphql'
import { set } from '@lib/helpers/objects'
import { SortOrder } from '@lib/types/orderBy'
import { Permission } from '@lib/types/permission'
import { GridFilterModel } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import deepEqual from 'fast-deep-equal'
import { useEffect, useMemo, useState } from 'react'

export type WholesaleOptions<TSortField> = {
  pageSize: number
  from: number
  orderByField: TSortField
  orderByDirection: SortOrder
  searchTerm: string
  subscriptionId?: string
  dispensaryId?: string
  cmId?: string
  filters?: GridFilterModel
}

const fragments = {
  customer: graphql(`
    fragment CustomerRecord on wholesale_customer {
      id: customer_id
      name: customer_name
      dispensaryId: dispensary_id
    }
  `),
  location: graphql(`
    fragment LocationRecord on business_location {
      id: location_id
      name: location_name
      addr1: address_line_1
      addr2: address_line_2
      city
      state
      postalCode: postal_code
      country
    }
  `),
  inventory: graphql(`
    fragment InventoryRecord on wholesale_inventory {
      id: inventory_id
      quantity
      quantityAvailable: quantity_available
      quantityReserved: quantity_reserved
      region
    }
  `),
  product: graphql(`
    fragment ProductRecord on wholesale_product {
      name: product_name
      cmId: cm_id
      category
    }
  `),
  order: graphql(`
    fragment OrderRecord on wholesale_wholesale_order {
      id: order_id
      orderTotal: order_total
      orderStatus: order_status
      shippingAmount: shipping_amount
      shipDate: ship_date
      region
      discountType: discount_type
      discountAmount: discount_amount
      createdAt: created_at
      salesRepName: sales_rep_name
      client
    }
  `),
  orderItem: graphql(`
    fragment OrderItemRecord on wholesale_order_item {
      id: order_item_id
      casePrice: case_price
      caseQuantity: case_quantity
      caseSize: case_size
      extractedAt: extracted_at
      totalPrice: total_price
      uom: unit_of_measure
      unitPrice: unit_price
      unitQuantity: unit_quantity
      unitSize: unit_size
    }
  `)
}

const queries = {
  dispensaryLastOrder: graphql(
    `
      query GetLatestOrderForDispensary($subscriptionId: uuid!, $dispensaryId: String!) {
        orders: wholesale_wholesale_order(
          where: { subscription_id: { _eq: $subscriptionId }, customer: { dispensary_id: { _eq: $dispensaryId } } }
          limit: 1
          order_by: { created_at: desc_nulls_last }
        ) {
          ...OrderRecord
        }
      }
    `,
    [fragments.order]
  ),
  dispensariesLastOrder: graphql(
    `
      query GetWholesaleOrders($where: wholesale_wholesale_order_bool_exp) {
        orders: wholesale_wholesale_order(
          where: $where
          order_by: [{ customer_id: desc }, { created_at: desc }]
          distinct_on: customer_id
        ) {
          ...OrderRecord
          customer {
            ...CustomerRecord
          }
        }
      }
    `,
    [fragments.order, fragments.customer]
  ),
  ordersByCmids: graphql(`
    query GetOrdersByCMIDs(
      $productsWhere: wholesale_product_bool_exp!
      $orderItemsWhere: wholesale_order_item_bool_exp!
    ) {
      product: wholesale_product(distinct_on: cm_id, where: $productsWhere) {
        orderItems: order_items(
          order_by: { order: { created_at: desc_nulls_last } }
          limit: 1
          where: $orderItemsWhere
        ) {
          unitQuantity: unit_quantity
          order {
            createdAt: created_at
            orderStatus: order_status
            shipDate: ship_date
          }
        }
        inventories {
          inventoryId: inventory_id
          quantity
          quantityAvailable: quantity_available
          quantityReserved: quantity_reserved
        }
        cmid: cm_id
      }
    }
  `),
  customers: (includeRawData: boolean) =>
    graphql(
      `
    query GetWholesaleCustomers(
      $pageSize: Int!
      $from: Int!
      $orderBy: [wholesale_customer_order_by!]
      $where: wholesale_customer_bool_exp!
    ) {
      customers: wholesale_customer(limit: $pageSize, offset: $from, order_by: $orderBy, where: $where) {
        ...CustomerRecord
        ${includeRawData ? 'rawData: raw_data' : ''}
        locations: customer_locations {
          location {
            ...LocationRecord
            ${includeRawData ? 'rawData: raw_data' : ''}
          }
        }
        orderCount: orders_aggregate {
          aggregate {
            count
          }
        }
      }
      customersAgg: wholesale_customer_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
      [fragments.customer, fragments.location]
    ),
  inventory: (includeRawData: boolean) =>
    graphql(
      `
    query GetWholesaleInventory(
      $pageSize: Int!
      $from: Int!
      $orderBy: [wholesale_inventory_order_by!]
      $where: wholesale_inventory_bool_exp!
    ) {
      inventory: wholesale_inventory(where: $where, limit: $pageSize, offset: $from, order_by: $orderBy) {
        ...InventoryRecord
        ${includeRawData ? 'rawData: raw_data' : ''}
        product {
          ...ProductRecord
          ${includeRawData ? 'rawData: raw_data' : ''}
        }
      }
      inventoryAgg: wholesale_inventory_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
      [fragments.inventory, fragments.product]
    ),
  orders: (includeRawData: boolean) =>
    graphql(
      `
    query GetWholesaleOrders(
      $pageSize: Int!
      $from: Int!
      $where: wholesale_wholesale_order_bool_exp
      $productWhere: wholesale_order_item_bool_exp
      $orderBy: [wholesale_wholesale_order_order_by!]
    ) {
      orders: wholesale_wholesale_order(limit: $pageSize, offset: $from, where: $where, order_by: $orderBy) {
        ...OrderRecord
        ${includeRawData ? 'rawData: raw_data' : ''}
        customer {
          ...CustomerRecord
        }
        orderItems: order_items (where: $productWhere) {
          ...OrderItemRecord
          ${includeRawData ? 'rawData: raw_data' : ''}
          product {
            ...ProductRecord
          }
        }
      }
      orderCount: wholesale_wholesale_order_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
      [fragments.order, fragments.orderItem, fragments.product, fragments.customer]
    ),
  orderCount: graphql(`
    query WholesaleOrderCountBySubscription($subscriptionId: uuid!) {
      orderCount: wholesale_wholesale_order_aggregate(where: { subscription_id: { _eq: $subscriptionId } }) {
        aggregate {
          count
        }
      }
    }
  `)
}

const simulatedColumnsToSearch = {
  customers: ['name'],
  inventory: ['product.name'],
  orders: ['orderItems[].product.name']
}

export const useWholesaleSimulatedData = <Tkey extends keyof WholesaleSimulatedData, TSortField extends string>(
  key: Tkey,
  { searchTerm, dispensaryId, cmId }: Pick<WholesaleOptions<TSortField>, 'searchTerm' | 'dispensaryId' | 'cmId'>
) => {
  const simulatedData = useWholesaleStore((state) => state.simulatedData)

  const parsedSimulatedData = useMemo(() => {
    let data = simulatedData?.[key]
    if (dispensaryId && key === 'orders') {
      data = (data as WholesaleOrder[])?.filter((order) => order.customer.dispensaryId === dispensaryId) as
        | WholesaleSimulatedData[Tkey]
        | undefined
    }
    if (cmId && key === 'orders') {
      data = (data as WholesaleOrder[])?.filter((order) =>
        order.orderItems.find((orderItem) => orderItem.product.cmId === cmId)
      ) as WholesaleSimulatedData[Tkey] | undefined
    }
    // Filter the simulated data by the search term
    const lowerSearchTerm = searchTerm.toLowerCase()
    const filteredData =
      lowerSearchTerm && data
        ? [...data].filter((item) =>
            simulatedColumnsToSearch[key].some((column) => {
              const value = getValueByPath<string | string[]>(item, column)
              if (Array.isArray(value)) {
                return value.some((subValue) => subValue?.toLowerCase().includes(lowerSearchTerm))
              }
              return value?.toLowerCase().includes(lowerSearchTerm)
            })
          )
        : data
    return filteredData
  }, [simulatedData, key, dispensaryId, cmId, searchTerm])

  const simulatedCount = useMemo(() => parsedSimulatedData?.length, [parsedSimulatedData])

  return {
    simulatedData: parsedSimulatedData as WholesaleSimulatedData[Tkey] | undefined,
    simulatedCount,
    isSimulated: !!simulatedData
  }
}

export const useWholesaleSubscription = () => {
  const { account } = useUser()
  const subscriptionId = useWholesaleStore((state) => state.subscriptionId)
  const subscriptionName = useWholesaleStore((state) => state.subscriptionName)
  const setSubscription = useWholesaleStore((state) => state.setSubscription)

  // Default to the user's subscription if no subscription is set
  useEffect(() => {
    if (!subscriptionId && account?.id) {
      setSubscription(account.id, account.name)
    }
  }, [account?.id, account?.name, setSubscription, subscriptionId])

  return {
    subscriptionId: !subscriptionId && account?.id ? account.id : subscriptionId,
    subscriptionName: !subscriptionName && account?.name ? account.name : subscriptionName
  }
}

export const useWholesaleCustomers = ({
  pageSize,
  from,
  orderByField,
  orderByDirection,
  searchTerm,
  subscriptionId,
  filters
}: WholesaleOptions<CustomerSortField>) => {
  useWholesaleSubscription()
  const { isDebugEnabled } = useDebug()
  const where: any = addWhereClause({}, 'orders.subscription_id', subscriptionId || '')
  addSearchTerm(where, 'customer_name', searchTerm, false, 2)

  if (filters) {
    addFiltersToWhereClause(where, applyFilterMapping(filters, customerFilterFieldMapping))
  }

  const variables = { pageSize, from, where, orderBy: set({}, orderByField, orderByDirection) }

  const { simulatedData, simulatedCount, isSimulated } = useWholesaleSimulatedData('customers', {
    searchTerm,
    orderByDirection,
    orderByField,
    subscriptionId,
    pageSize,
    from
  } as WholesaleOptions<CustomerSortField>)

  const response = useGraphqlQuery<WholesaleCustomersResponse>(
    {
      query: queries.customers(isDebugEnabled),
      variables
    },
    {
      enabled: !!subscriptionId && !isSimulated,
      keepPreviousData: true
    }
  )

  const customers: WholesaleCustomer[] | undefined = useMemo(
    () =>
      simulatedData ??
      response.data?.customers.map(
        (customer: WholesaleCustomerItemResponse) =>
          ({
            ...customer,
            orderCount: customer.orderCount.aggregate.count,
            locations: customer.locations.map(({ location }) => location)
          } as WholesaleCustomer)
      ),
    [simulatedData, response.data?.customers]
  )

  const customersCount = useMemo(
    () => simulatedCount ?? response.data?.customersAgg.aggregate.count,
    [simulatedCount, response.data?.customersAgg.aggregate.count]
  )

  return {
    ...response,
    customersCount,
    customers
  }
}

export const useWholesaleInventory = ({
  pageSize,
  from,
  orderByField,
  orderByDirection,
  searchTerm,
  subscriptionId,
  filters
}: WholesaleOptions<InventorySortField>) => {
  useWholesaleSubscription()
  const { isDebugEnabled } = useDebug()
  const where: any = addWhereClause({}, 'subscription_id', subscriptionId || '')
  addSearchTerm(where, 'product.product_name', searchTerm, false, 2)

  if (filters) {
    addFiltersToWhereClause(where, applyFilterMapping(filters, inventoryFilterFieldMapping))
  }

  const variables = { pageSize, from, where, orderBy: set({}, orderByField, orderByDirection) }

  const { simulatedData, simulatedCount, isSimulated } = useWholesaleSimulatedData('inventory', {
    searchTerm,
    orderByDirection,
    orderByField,
    pageSize,
    from
  } as WholesaleOptions<InventorySortField>)

  const response = useGraphqlQuery<WholesaleInventoryResponse>(
    {
      query: queries.inventory(isDebugEnabled),
      variables
    },
    {
      enabled: !!subscriptionId && !isSimulated,
      keepPreviousData: true
    }
  )

  return {
    ...response,
    inventoryCount: simulatedCount ?? response.data?.inventoryAgg.aggregate.count,
    inventory: simulatedData ?? response.data?.inventory
  }
}

export const useWholesaleOrders = ({
  pageSize,
  from,
  orderByField,
  orderByDirection,
  searchTerm,
  subscriptionId,
  dispensaryId,
  cmId,
  filters
}: WholesaleOptions<OrderSortField>) => {
  useWholesaleSubscription()
  const { isDebugEnabled } = useDebug()
  const where: any = addWhereClause({}, 'subscription_id', subscriptionId || '')
  const productWhere = {}
  if (dispensaryId) {
    addWhereClause(where, 'customer.dispensary_id', dispensaryId)
  }
  if (cmId) {
    addWhereClause(where, 'order_items.product.cm_id', cmId)
    addWhereClause(productWhere, 'product.cm_id', cmId)
  }
  addSearchTerm(where, 'order_items.product.product_name', searchTerm, false, 2)

  if (filters) {
    addFiltersToWhereClause(where, applyFilterMapping(filters, ordersFilterFieldMapping))
  }

  const variables = {
    pageSize,
    from,
    where,
    productWhere,
    orderBy: set({}, orderByField, orderByDirection)
  }

  const { simulatedData, simulatedCount, isSimulated } = useWholesaleSimulatedData('orders', {
    searchTerm,
    orderByDirection,
    orderByField,
    pageSize,
    from,
    dispensaryId,
    cmId
  } as WholesaleOptions<OrderSortField>)

  const response = useGraphqlQuery<WholesaleOrdersResponse>(
    {
      query: queries.orders(isDebugEnabled),
      variables
    },
    {
      enabled: !!subscriptionId && !isSimulated,
      keepPreviousData: true
    }
  )

  const orders = useMemo(() => {
    return (
      simulatedData ??
      response.data?.orders.map((order) => ({
        ...order,
        unitQty: order.orderItems.reduce((acc, item) => acc + item.unitQuantity ?? 0, 0),
        orderItems: searchTerm
          ? order.orderItems.filter((item) => item.product.name.toLowerCase().includes(searchTerm.toLowerCase()))
          : order.orderItems
      }))
    )
  }, [response.data?.orders, searchTerm, simulatedData])

  return {
    ...response,
    orderCount: simulatedCount ?? response.data?.orderCount.aggregate.count,
    orders
  }
}

export const useWholesaleDispensariesLastOrder = (options: { dispensaryIds: string[] }) => {
  const { hasPermission: hasWholesaleModuleAccess } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)

  // If data is simulated, search for the latest order for the specified dispensaries in the simulated data
  const simulatedData = useWholesaleStore((state) => state.simulatedData)

  const { subscriptionId } = useWholesaleSubscription()
  // A cache of all the dispensaryIds that we have already queried for
  const [queriedIds, setQueriedIds] = useState<string[]>([])
  // A map of dispensaryIds to the latest order item summary
  const [orders, setOrders] = useState<Record<string, WholesaleLastOrder>>({})

  // Only query for dispensaryIds that we haven't already queried for
  const newDispensaryIds = useMemo(
    () => options.dispensaryIds.filter((dispensaryId) => !queriedIds.includes(dispensaryId)),
    [queriedIds, options.dispensaryIds]
  )

  const variables = useMemo(() => {
    // We only care about orders placed in the last 90 days
    const where: Record<string, unknown> = {
      created_at: {
        _gte: dayjs().subtract(90, 'days').format('YYYY-MM-DD')
      },
      subscription_id: {
        _eq: subscriptionId
      }
    }

    // Check only for products matched to these dispensaryIds
    addWhereClause(where, 'customer.dispensary_id', newDispensaryIds)
    return { where }
  }, [newDispensaryIds, subscriptionId])

  const response = useGraphqlQuery<WholesaleDispensariesLastOrderResponse>(
    {
      query: queries.dispensariesLastOrder,
      variables
    },
    {
      enabled: !simulatedData && hasWholesaleModuleAccess && !!subscriptionId && newDispensaryIds.length > 0
    }
  )

  const hasChanged = useMemo(() => !deepEqual(options.dispensaryIds, queriedIds), [queriedIds, options.dispensaryIds])

  useEffect(() => {
    if (hasChanged && response.data) {
      // Update the list of dispensaryIds that we have already queried for
      setQueriedIds((prevState) => [
        ...prevState,
        ...options.dispensaryIds.filter((dispId) => !prevState.includes(dispId))
      ])
      setOrders((prevState) => {
        const newState = {
          ...prevState
        }
        // and update the order summary for each dispensary fetched
        response.data.orders.forEach((order) => {
          newState[order.customer.dispensaryId as string] = order
        })
        return newState
      })
    }
  }, [hasChanged, options.dispensaryIds, response.data])

  if (simulatedData) {
    return {
      isLoading: false,
      isFetching: false,
      fetchingIds: [] as string[],
      data: options.dispensaryIds.reduce((acc, dispensaryId) => {
        const latestOrder = simulatedData.orders?.filter((order) => order.customer.dispensaryId === dispensaryId)[0]
        if (latestOrder) {
          acc[dispensaryId] = latestOrder
        }
        return acc
      }, {} as Record<string, WholesaleLastOrder>)
    }
  }

  return {
    ...response,
    fetchingIds: response.isFetching ? newDispensaryIds : [],
    data: orders
  }
}

export const useWholesaleOrdersByCmids = (options: { dispensaryId?: string; cmids: string[] }) => {
  const { hasPermission: hasWholesaleModuleAccess } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)
  const { isSuperAdmin } = useIsSuperAdmin()
  const { subscriptionId } = useWholesaleSubscription()
  // A cache of all the CMIDs that we have already queried for
  const [cmids, setCmids] = useState<string[]>([])
  // A map of CMID to the latest order item summary
  const [orders, setOrders] = useState<Record<string, WholesaleProductStatus>>({})

  const simulatedData = useWholesaleStore((state) => state.simulatedData)

  // Only query for CMIDs that we haven't already queried for
  const newCmids = useMemo(() => options.cmids.filter((cmid) => !cmids.includes(cmid)), [cmids, options.cmids])

  const variables = useMemo(() => {
    const v = {
      productsWhere: {},
      orderItemsWhere: {}
    }
    // For regular users, only fetch products and order items for their subscription
    if (!isSuperAdmin && !!subscriptionId) {
      addWhereClause(v.productsWhere, 'subscription_id', subscriptionId)
      addWhereClause(v.orderItemsWhere, 'order.subscription_id', subscriptionId)
    }

    // Check only for products matched to these CMIDs
    addWhereClause(v.productsWhere, 'cm_id', newCmids)

    // If a dispensary is selected, also restrict the check to orders for that dispensary
    if (options.dispensaryId) {
      addWhereClause(v.orderItemsWhere, 'order.customer.dispensary_id', options.dispensaryId)
    }
    return v
  }, [isSuperAdmin, newCmids, options.dispensaryId, subscriptionId])

  const response = useGraphqlQuery<WholesaleOrdersByCmidsResponse>(
    {
      query: queries.ordersByCmids,
      variables
    },
    {
      enabled: !simulatedData && hasWholesaleModuleAccess && (!!subscriptionId || isSuperAdmin) && newCmids.length > 0
    }
  )

  const hasChanged = useMemo(() => !deepEqual(options.cmids, cmids), [cmids, options.cmids])

  useEffect(() => {
    if (hasChanged && response.data?.product.length) {
      // Update the list of CMIDs that we have already queried for
      setCmids((prevState) => [...prevState, ...options.cmids.filter((cmid) => !prevState.includes(cmid))])
      setOrders((prevState) => {
        const newState = {
          ...prevState
        }
        // and update the order item and inventory summary for each product fetched
        response.data.product.forEach((product) => {
          if (product.orderItems.length > 0) {
            newState[product.cmid] = newState[product.cmid] || {}
            newState[product.cmid].latestOrder = product.orderItems[0]
          }
          if (product.inventories.length > 0) {
            newState[product.cmid] = newState[product.cmid] || {}
            newState[product.cmid].inventory = product.inventories[0]
          }
        })
        return newState
      })
    }
  }, [hasChanged, options.cmids, response.data])

  if (simulatedData) {
    return {
      isLoading: false,
      isFetching: false,
      data: options.cmids.reduce((acc, cmid) => {
        const latestOrder = simulatedData.orders?.filter(
          (order) =>
            order.orderItems[0].product.cmId === cmid &&
            (!options.dispensaryId || options.dispensaryId === order.customer.dispensaryId)
        )[0]
        const inventory = simulatedData.inventory?.filter((item) => item.product.cmId === cmid)[0]
        if (latestOrder && inventory) {
          acc[cmid] = {
            latestOrder: {
              unitQuantity: latestOrder.unitQty,
              // orderTotal: latestOrder.orderTotal,
              order: latestOrder
            },
            inventory
          }
        }
        return acc
      }, {} as Record<string, WholesaleProductStatus>)
    }
  }

  return {
    ...response,
    data: orders
  }
}

export const useWholesaleDispensaryLatestOrder = (dispensaryId: string) => {
  const { hasPermission: hasWholesaleModuleAccess } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)
  const { subscriptionId } = useWholesaleSubscription()
  const simulatedData = useWholesaleStore((state) => state.simulatedData)
  const response = useGraphqlQuery<WholesaleDispensariesLastOrderResponse>(
    {
      query: queries.dispensaryLastOrder,
      variables: {
        dispensaryId,
        subscriptionId: subscriptionId || ''
      }
    },
    {
      enabled: !simulatedData && hasWholesaleModuleAccess && !!subscriptionId
    }
  )

  if (simulatedData) {
    return {
      isLoading: false,
      isFetching: false,
      data: simulatedData.orders?.filter((order) => order.customer.dispensaryId === dispensaryId)[0] ?? null
    }
  }

  return {
    ...response,
    data: response.isLoading ? undefined : response.data?.orders[0] ?? null
  }
}

export const useWholesaleOrderCount = () => {
  const { hasPermission: hasWholesaleModuleAccess } = usePermission(Permission.MODULE_ACCESS_WHOLESALE)
  const { subscriptionId } = useWholesaleSubscription()
  const simulatedData = useWholesaleStore((state) => state.simulatedData)

  const response = useGraphqlQuery<WholesaleOrderCountResponse>(
    {
      query: queries.orderCount,
      variables: { subscriptionId }
    },
    {
      enabled: !simulatedData && hasWholesaleModuleAccess && !!subscriptionId
    }
  )

  const orderCount = simulatedData?.orders?.length ?? response.data?.orderCount.aggregate.count

  return {
    ...response,
    data: orderCount
  }
}
