import { useEventTracker } from '@client/hooks/use-event-tracker'
import { TrackableProps } from '@client/types/tracking'
import { ComponentType } from 'react'

export type ChangeEventTrackerBaseProps = {
  onChange?: (event: any, value: any) => void
} & TrackableProps

export function withChangeEventTracker<TProps extends object>(Component: ComponentType<TProps>) {
  return function WrappedWithChangeTracking({
    gaEvent,
    gaLabel,
    onChange,
    ...props
  }: TProps & ChangeEventTrackerBaseProps) {
    const { handleTracking } = useEventTracker({ gaEvent, gaLabel, callback: onChange })
    return <Component {...(props as TProps)} onChange={handleTracking} />
  }
}
