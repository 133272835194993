import deepEqual from 'fast-deep-equal'
import { memo, useCallback } from 'react'
import { RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'
import {
  HierarchicalRefinementExposedProps,
  TagsHierarchicalRefinement as InnerTagsHierarchicalRefinement
} from './TagsHierarchicalRefinement'

export const ConnectedTagsHierarchicalRefinement: React.FC<
  RefinementListProvided & HierarchicalRefinementExposedProps
> = memo(function TagsHierarchicalRefinement({ refine, currentRefinement, ...props }) {
  const handleOnChange = useCallback(
    (attribute: string, tags: string[]) => {
      refine(tags)
    },
    [refine]
  )

  return <InnerTagsHierarchicalRefinement {...props} onChange={handleOnChange} values={currentRefinement} />
}, deepEqual)

export const TagsHierarchicalRefinement = connectRefinementList(ConnectedTagsHierarchicalRefinement)
