import { ButtonBase, darken, InputBase } from '@mui/material'
import styled from 'styled-components'

const TransitionBase = '300ms ease-in-out'

const SearchAdornment = `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;

  svg {
    width: 22px;
    height: 22px;
  }
`

export const Search = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: background-color ${TransitionBase};
  &.active {
    background-color: ${(props) => darken(props.theme.header.background, 0.05)};
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 200px;
    background-color: ${(props) => darken(props.theme.header.background, 0.05)};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 300px;
  }
`

export const SearchIconWrapper = styled.div`
  ${SearchAdornment}
`

export const Input = styled(InputBase)`
  transition: opacity ${TransitionBase};
  ${({ theme }) => theme.breakpoints.down('md')} {
    opacity: 0;
    .active > & {
      opacity: 1;
    }
  }
  > input {
    padding: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    color: ${(props) => props.theme.header.search.color};
  }
`

export const CloseWrapper = styled(ButtonBase)`
  ${SearchAdornment}
  top: 0;
  right: 0;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  transition: opacity ${TransitionBase};
`
