import { Box, IconButton } from '@mui/material'
import { Typography } from '@client/styles/theme/typography'
import styled from 'styled-components'

export const FilterSection = styled.div`
  padding: 0 ${(props) => props.theme.spacing(4)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

export const FilterSectionTitleBar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing(2)} 0 ${(props) => props.theme.spacing(4)};
  margin: 0 -${(props) => props.theme.spacing(4)};
  background-color: ${(props) => props.theme.palette.lighter};
`

export const FilterSectionTitle = styled(Typography)<{ disabled?: boolean }>`
  flex: 1;
  ${({ disabled, theme }) =>
    disabled
      ? `
    color: ${theme.palette.text.secondary};
    font-style: italic;
  `
      : `
    font-weight: bold;
  `}
`

export const ExpandButton = styled(IconButton)`
  padding: 0;
`
