import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { TreeView } from '@mui/x-tree-view'
import HRN from 'human-readable-numbers'
import { memo, useCallback, useMemo, useState } from 'react'
import * as S from './HierarchicalMenu.style'

export interface HierarchicalMenuItem {
  count: number
  isRefined: boolean
  label: string
  value: string
  items?: HierarchicalMenuItem[]
}

interface HierarchicalTreeItemProps {
  parentNodeId?: string
  separator: string
  item: HierarchicalMenuItem
}

interface HierarchicalNodeIds {
  root: string
  nodeIds: string[]
}

const getNodeId = (label: string, separator = ' > ', parentNodeId = '') =>
  parentNodeId ? `${parentNodeId}${separator}${label}` : label

const HierarchicalTreeItem: React.FC<HierarchicalTreeItemProps> = memo<HierarchicalTreeItemProps>(
  function HierarchicalTreeItem({ item, parentNodeId, separator }) {
    const { isMobile } = useResponsiveState()
    const nodeId = getNodeId(item.label, separator, parentNodeId)

    const subItems = useMemo(
      () => item.items?.filter((subItem) => subItem.label !== item.label) ?? [],
      [item.items, item.label]
    )

    return (
      <S.TreeItem
        icon={item.isRefined && subItems.length ? <ExpandMore /> : <ChevronRight />}
        data-testid={`hierarchical-menu-item-${item.label}`}
        nodeId={nodeId}
        label={
          <Box alignItems="center" flexDirection="row" display="flex" my={isMobile ? 2 : 1}>
            <S.TreeItemLabelText>{item.label}</S.TreeItemLabelText>
            <Typography ml={2} variant="caption" color="primaryGreen">
              {HRN.toHumanString(item.count.toString())}
            </Typography>
          </Box>
        }
      >
        {subItems.map((subItem) => {
          const subNodeId = `${nodeId}${separator}${subItem.label}`
          return <HierarchicalTreeItem key={subNodeId} item={subItem} separator={separator} parentNodeId={nodeId} />
        })}
      </S.TreeItem>
    )
  }
)

export interface HierarchicalMenuProps {
  items: HierarchicalMenuItem[]
  currentRefinement: string
  refine: (value: string) => void
  separator: string
  attributes: string[]
  createURL: (...args: any[]) => any
}

export const HierarchicalMenu: React.FC<HierarchicalMenuProps> = memo<HierarchicalMenuProps>(function HierarchicalMenu({
  items,
  currentRefinement,
  separator,
  refine
}) {
  // Convert a nodeId in the format 'FACET_0 > FACET_1 > FACET_2 > ...' into
  // a hierarchical array of nodeIds, e.g. [ 'FACET_0', 'FACET_0 > FACET_1', 'FACET_0 > FACET_1 > FACET_2' ]
  const getExpandedNodeIds = useCallback(
    (nodeId: string): string[] => {
      const parts = nodeId.split(separator)
      const expandedNodeIds: HierarchicalNodeIds = {
        root: '',
        nodeIds: []
      }
      parts.reduce((acc: HierarchicalNodeIds, part: string) => {
        acc.root = getNodeId(part, separator, acc.root)
        acc.nodeIds.push(acc.root)
        return acc
      }, expandedNodeIds)
      return expandedNodeIds.nodeIds
    },
    [separator]
  )

  const [expanded, setExpanded] = useState<string[]>(getExpandedNodeIds(currentRefinement || ''))

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: any, nodeId: string) => {
    refine(nodeId)
    const expandedNodeIds = getExpandedNodeIds(nodeId || '')
    setExpanded(expandedNodeIds)
  }

  return (
    <TreeView expanded={expanded} selected={currentRefinement} onNodeToggle={handleToggle} onNodeSelect={handleSelect}>
      {items.map((item) => (
        <HierarchicalTreeItem key={item.label} item={item} separator={separator} />
      ))}
    </TreeView>
  )
})
