import { MouseEventHandler, useState } from 'react'

// This is a Material UI convention for using a single React state object to track two things:
// 1. Whether the menu should be open
// 2. What element the menu should be anchored to
export const useMenuAnchor = () => {
  const [menuAnchor, setMenuAnchor] = useState<EventTarget & HTMLElement>()

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuAnchor(event.currentTarget)
  }

  const handleClose = (event?: Event | React.MouseEvent<HTMLElement>) => {
    event && event.preventDefault()
    event && event.stopPropagation()
    setMenuAnchor(undefined)
  }

  return {
    menuAnchor,
    handleClick,
    handleClose
  }
}
