import { DEFAULT_HELP_SORT_ORDER, HelpLinkData } from '@client/types/help'
import { SetRequired } from 'type-fest'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type HelpLinkDataWithInstances = SetRequired<HelpLinkData, 'order'> & { instances: number }

export interface HelpLinksStore {
  links: Map<string, HelpLinkDataWithInstances>
  addLink: (data: HelpLinkData) => void
  removeLink: (tooltip: string) => void
}

export const useHelpLinksStore = create(
  devtools<HelpLinksStore>(
    (set) => ({
      links: new Map(),
      addLink: (data) =>
        set(
          (state) => {
            const links = new Map(state.links)
            links.set(data.tooltip, {
              ...data,
              order: data.order ?? DEFAULT_HELP_SORT_ORDER,
              instances: (state.links.get(data.tooltip)?.instances ?? 0) + 1
            })
            return { ...state, links }
          },
          false,
          'addLink'
        ),
      removeLink: (tooltip) => {
        set(
          (state) => {
            const links = new Map(state.links)
            const link = links.get(tooltip)
            if (link) {
              if (link.instances === 1) {
                links.delete(tooltip)
              } else {
                links.set(tooltip, {
                  ...link,
                  instances: link.instances - 1
                })
              }
            }
            return {
              ...state,
              links
            }
          },
          false,
          'removeLink'
        )
      }
    }),
    {
      name: `@hoodie:help-links-store`
    }
  )
)
