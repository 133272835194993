import { DesktopTooltip } from '@client/components/desktop-tooltip/DesktopTooltip'
import { KeyboardDoubleArrowDown } from '@mui/icons-material'
import { IconButton, IconButtonProps } from '@mui/material'
import { memo } from 'react'

interface ToggleCollapseAllButtonProps extends Omit<IconButtonProps, 'onClick'> {
  isCollapsed: boolean
  onToggle: () => void
}

export const ToggleCollapseAllButton = memo(function ToggleCollapseAllButton({
  isCollapsed,
  onToggle,
  disabled,
  ...props
}: ToggleCollapseAllButtonProps) {
  return (
    <DesktopTooltip title={!disabled ? `${isCollapsed ? 'Expand' : 'Collapse'} all sections` : ''}>
      <IconButton
        size="small"
        edge="end"
        data-testid="toggle-collapse-all-sections"
        {...props}
        onClick={onToggle}
        disabled={disabled}
      >
        <KeyboardDoubleArrowDown
          data-testid={isCollapsed ? 'toggle-collapse-all-sections--expand' : 'toggle-collapse-all-sections--collapse'}
          fontSize="small"
          sx={{
            transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: ({ transitions }) =>
              transitions.create('transform', {
                duration: transitions.duration.shortest
              })
          }}
        />
      </IconButton>
    </DesktopTooltip>
  )
})
