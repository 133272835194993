import { slugify } from '@client/helpers/strings'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useTags } from '@client/providers/tags-provider'
import { TagsOutlinedIcon } from '@client/styles/global'
import {
  TAG_ALGOLIA_SEPARATOR,
  TagFilterOption,
  TagFilterTarget,
  getAttributeLabel,
  getAttributeValue
} from '@client/types/filterset'
import { tagTypeToName } from '@client/types/tags'
import { ChipProps, Popover } from '@mui/material'
import { ReactNode, memo, useMemo } from 'react'
import * as S from './FilterChip.style'

type RefinementDisplay = {
  attribute: string
  label: string
  currentRefinement: string | undefined
  hideSecondaryLabel?: boolean
}

const FilterChipLabel = memo(function FilterChipLabel({
  attribute,
  label,
  currentRefinement,
  hideSecondaryLabel
}: RefinementDisplay) {
  // Convert the attribute to a human readable label
  const primary = useMemo(
    () => getAttributeValue(label, attribute, currentRefinement),
    [attribute, currentRefinement, label]
  )
  const secondary = useMemo(() => getAttributeLabel(attribute), [attribute])

  return (
    <>
      <span data-testid="filter-chip--primary-label">{primary}</span>
      {!hideSecondaryLabel && (
        <S.LabelSecondary data-testid="filter-chip--secondary-label">{secondary}</S.LabelSecondary>
      )}
    </>
  )
})

const TagFilterChipLabel = memo(function TagFilterChipLabel(props: RefinementDisplay) {
  const { getTagLabel } = useTags()

  const { label, attribute } = useMemo(() => {
    const [tagId, tagType] = props.label.split(TAG_ALGOLIA_SEPARATOR)
    return {
      label: getTagLabel(tagId) ?? 'Unknown',
      attribute: tagTypeToName[tagType as TagFilterTarget]
    }
  }, [props.label, getTagLabel])

  return <FilterChipLabel {...props} label={label} attribute={attribute} />
})

export interface FilterChipProps {
  attribute: string
  label: string
  color?: ChipProps['color']
  popoverContent?: ReactNode
  currentRefinement?: string
  onDelete: () => void
  hideSecondaryLabel?: boolean
}

export const FilterChip: React.FC<FilterChipProps> = memo<FilterChipProps>(function FilterChip({
  attribute,
  color = 'success',
  label,
  popoverContent = '',
  currentRefinement,
  onDelete,
  hideSecondaryLabel
}) {
  const { handleClick: openPopover, handleClose: closePopover, menuAnchor: popoverRef } = useMenuAnchor()

  const Component = useMemo(
    () => (attribute === TagFilterOption.attribute ? TagFilterChipLabel : FilterChipLabel),
    [attribute]
  )
  return (
    <>
      <S.Chip
        $hideSecondaryLabel={hideSecondaryLabel}
        size="small"
        icon={attribute === TagFilterOption.attribute ? <TagsOutlinedIcon /> : undefined}
        color={color}
        onMouseOver={popoverContent ? openPopover : undefined}
        onMouseLeave={popoverContent ? closePopover : undefined}
        label={
          <Component
            attribute={attribute}
            label={label}
            currentRefinement={currentRefinement}
            hideSecondaryLabel={hideSecondaryLabel}
          />
        }
        onDelete={onDelete}
        aria-label={`current-refinement--${slugify(label)}`}
      />
      {popoverContent && (
        <Popover
          sx={{
            mt: 1,
            pointerEvents: 'none'
          }}
          open={!!popoverRef}
          anchorEl={popoverRef}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={(e) => closePopover(e as any)}
          keepMounted={false}
        >
          {popoverContent}
        </Popover>
      )}
    </>
  )
})
