import { breakpoints as customBreakpoints } from '@client/styles/theme/breakpoints'
import { palette } from '@client/styles/theme/palette'
import { typography as customTypography } from '@client/styles/theme/typography'
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/system'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import breakpoints from './breakpoints'
import overrides from './overrides'
import typography from './typography'
import variants from './variants'

/*
Keep the code below for `theme.spacing()` to work.
Error: Property 'spacing' does not exist on type 'DefaultTheme'
Solution: makeStyles is now exported from @mui/styles package which does not know about Theme in the core package.
To fix this, you need to augment the DefaultTheme (empty object) in @mui/styles with Theme from the core.
*/
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const spacingIncrement = 4

const createTheme = (name = 'DEFAULT') => {
  let themeConfig = variants.find((variant) => variant.name === name)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`))
    themeConfig = variants[0]
  }

  return createMuiTheme({
    spacing: spacingIncrement,
    breakpoints: { ...breakpoints, ...customBreakpoints },
    components: { ...overrides } as ThemeOptions['components'],
    typography: { ...typography, ...customTypography },
    palette: { ...themeConfig.palette, ...palette },
    name: themeConfig.name,
    header: themeConfig.header,
    footer: themeConfig.footer,
    sidebar: themeConfig.sidebar
  })
}

export default createTheme()
