import { Card } from '@mui/material'
import styled from 'styled-components'

const top = '52px'

export const Wrapper = styled(Card)`
  position: fixed;
  top: ${top};
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  .MuiList-subheader {
    background-color: white;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: auto;
    bottom: auto;
    left: auto;
    width: 500px;
    max-width: 500px;
    max-height: calc(100vh - ${top});
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
  }
`
