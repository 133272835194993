import { urls } from '@client/helpers/urls'
import { useReportsDashboards } from '@client/hooks/logi/use-reports-dashboards'
import { useDistinctData, useDistinctProducts } from '@client/hooks/use-distinct-data'
import { useHasura } from '@client/providers/hasura'
import { BRAND_INDEX, DISPENSARY_INDEX, VARIANT_INDEX } from '@client/services/algolia'
import { ItemTagContent, TagContentType, TagType, getItemTagContentType } from '@client/types/tags'
import { ItemTag } from '@hoodie/hoodie-filters/lib/tags'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const TAG_ITEMS_KEY = 'tag-items'

type Options = {
  enabled?: boolean
  keepPreviousData?: boolean
}

export const useTagItems = (tagId?: string, options?: Options) => {
  const { hasuraClient } = useHasura()
  const { enabled } = { enabled: true, ...(options ?? {}) }

  // Loading all items from the tag
  return useQuery(
    [TAG_ITEMS_KEY, tagId],
    async () => {
      return tagId ? await hasuraClient?.getTagItems(tagId) : null
    },
    {
      enabled: !!tagId && enabled && !!hasuraClient
    }
  )
}

export const useTagItemsContent = (items: ItemTag[], options?: Options) => {
  const { enabled, keepPreviousData } = { enabled: true, keepPreviousData: false, ...(options ?? {}) }

  // Separating items into their respective types
  const {
    brand: brandIds,
    dispensary: dispensaryIds,
    masteredProduct: masteredProductIds,
    report: reportIds,
    unmasteredProduct: unmasteredProductIds
  } = useMemo(() => {
    const ids: Record<TagContentType, string[]> = {
      brand: [],
      dispensary: [],
      masteredProduct: [],
      report: [],
      unmasteredProduct: [],
      filterset: [],
      product: []
    }

    items?.forEach((item) => {
      const { contentId, type } = getItemTagContentType(item)
      ids[type].push(contentId)
    })

    return ids
  }, [items])

  // Loading BRANDS from Elastic
  const {
    isPreviousData: isBrandPreviousData,
    isError: isErrorBrands,
    isFetching: isFetchingBrands,
    data: dataBrands
  } = useDistinctData(
    {
      distinct: 'BRAND',
      fields: ['BRAND'],
      size: brandIds.length,
      dataComplement: {
        filterKey: 'brands',
        index: BRAND_INDEX
      },
      filterset: {
        filterBy: {
          brands: brandIds
        }
      }
    },
    { enabled: enabled && !!brandIds.length, keepPreviousData }
  )

  // Loading DISPENSARIES from Elastic
  const {
    isPreviousData: isDispensariesPreviousData,
    isError: isErrorDispensaries,
    isFetching: isFetchingDispensaries,
    data: dataDispensaries
  } = useDistinctData(
    {
      distinct: 'MASTER_ID',
      fields: ['DISPENSARY_NAME'],
      size: dispensaryIds.length,
      dataComplement: {
        filterKey: 'dispensaryIds',
        index: DISPENSARY_INDEX
      },
      filterset: {
        filterBy: {
          dispensaryIds
        }
      }
    },
    { enabled: enabled && !!dispensaryIds.length, keepPreviousData }
  )

  // Loading PRODUCTS from Elastic
  const {
    isPreviousData: isProductsPreviousData,
    isError: isErrorProducts,
    isFetching: isFetchingProducts,
    data: dataProducts
  } = useDistinctProducts(
    {
      fields: ['NAME'],
      size: masteredProductIds.length + unmasteredProductIds.length,
      dataComplement: { index: VARIANT_INDEX },
      filterset: {
        filterBy: {
          cmIds: masteredProductIds,
          menuIds: unmasteredProductIds
        }
      }
    },
    {
      enabled: enabled && (!!masteredProductIds.length || !!unmasteredProductIds.length),
      keepPreviousData
    }
  )

  // Loading REPORTS
  const { reportsMap: dataReports, isFetching: isFetchingReports } = useReportsDashboards(enabled)

  const brandsContent = useMemo(() => {
    if (!brandIds?.length || (isFetchingBrands && !isBrandPreviousData)) {
      return []
    }
    return (
      dataBrands?.map<ItemTagContent>((brand) => ({
        id: brand.BRAND,
        label: brand.BRAND,
        link: urls.brandPage(brand.BRAND, true, '/#/'),
        type: TagType.brand
      })) || []
    )
  }, [brandIds?.length, dataBrands, isBrandPreviousData, isFetchingBrands])

  const dispensariesContent = useMemo(() => {
    if (!dispensaryIds?.length || (isFetchingDispensaries && !isDispensariesPreviousData)) {
      return []
    }
    return (
      dataDispensaries?.map<ItemTagContent>((dispensary) => ({
        id: dispensary.MASTER_ID,
        label: dispensary.DISPENSARY_NAME,
        link: urls.dispensaryPage(dispensary.MASTER_ID, '/#/'),
        type: TagType.dispensary
      })) || []
    )
  }, [dispensaryIds?.length, dataDispensaries, isDispensariesPreviousData, isFetchingDispensaries])

  const productsContent = useMemo(() => {
    if (
      (!masteredProductIds?.length && !unmasteredProductIds.length) ||
      (isFetchingProducts && !isProductsPreviousData)
    ) {
      return []
    }
    return (
      dataProducts?.map<ItemTagContent>((product) => ({
        id: product.CM_ID || product.MENU_ID,
        isMastered: !!product.CM_ID,
        label: product.NAME || '',
        link: urls.productDetails({ CM_ID: product.CM_ID, objectID: product.MENU_ID }, '/#/'),
        type: TagType.product
      })) || []
    )
  }, [
    masteredProductIds?.length,
    unmasteredProductIds.length,
    isFetchingProducts,
    isProductsPreviousData,
    dataProducts
  ])

  const reportsContent = useMemo(() => {
    if (dataReports) {
      return reportIds
        .map<ItemTagContent | null>((id) => {
          const report = dataReports[id]
          if (!report) {
            return null
          }
          return {
            id: report.id,
            label: report.name,
            link: urls.reportTabPage(report.id, '/#/'),
            type: TagType.report
          }
        })
        .filter(Boolean) as ItemTagContent[]
    } else {
      return []
    }
  }, [dataReports, reportIds])

  const itemTagsContent = useMemo(() => {
    return [...brandsContent, ...dispensariesContent, ...productsContent, ...reportsContent]
  }, [brandsContent, dispensariesContent, productsContent, reportsContent])

  return {
    data: itemTagsContent,
    isLoading: isFetchingBrands || isFetchingProducts || isFetchingDispensaries || isFetchingReports,
    error: isErrorBrands || isErrorProducts || isErrorDispensaries
  }
}
