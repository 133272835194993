import { useCallback, useState } from 'react'

export const useBooleanState = (initialState: boolean) => {
  const [value, setValue] = useState(initialState)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggleValue = useCallback(() => setValue((prevState) => !prevState), [])

  return {
    value,
    setValue,
    setTrue,
    setFalse,
    toggleValue
  }
}
