import { Box } from '@client/styles/theme/box'
import { Typography } from '@client/styles/theme/typography'
import deepEqual from 'fast-deep-equal'
import React, { memo } from 'react'
import type { Hit } from 'react-instantsearch-core'
import { HitsProvided } from 'react-instantsearch-core'

export interface ItemHitProps<TItem> {
  item: Hit<TItem>
  index: number
}

export interface HitsPropsExposed<TItem> {
  HitComponent: React.ComponentType<ItemHitProps<TItem>>
  EmptyListPlaceholder?: React.ComponentType<any>
  hitProps?: any
}

export interface HitsProps<TItem> extends HitsPropsExposed<TItem>, HitsProvided<TItem> {}

export const DefaultEmptyListPlaceholder = () => (
  <Box p={4} display="flex" alignItems="center" justifyContent="center">
    <Typography variant="body1" fontStyle="italic">
      No results
    </Typography>
  </Box>
)

export const HitsList = memo(function HitsList<TItem>({
  hits,
  hitProps,
  HitComponent,
  EmptyListPlaceholder = DefaultEmptyListPlaceholder
}: HitsProps<TItem>) {
  return (
    <>
      {hits.map((hit, index) => (
        <HitComponent key={hit.objectID} item={hit} index={index} {...hitProps} />
      ))}
      {!hits.length && <EmptyListPlaceholder />}
    </>
  )
},
deepEqual)
