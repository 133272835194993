import { ProductHit } from '@client/services/algolia'
import { ConnectedComponentClass, HitsProvided } from 'react-instantsearch-core'
import { Hits, HitsPropsExposed } from './Hits'
import { InfiniteHits, InfiniteHitsPropsExposed } from './InfiniteHits'

type ProductHitsType = new () => React.Component<InfiniteHitsPropsExposed<ProductHit>>

export const ProductHits: ConnectedComponentClass<
  InfiniteHitsPropsExposed<ProductHit>,
  HitsProvided<ProductHit>
> = InfiniteHits as ProductHitsType

type SimpleProductHitsType = new () => React.Component<HitsPropsExposed<ProductHit>>

export const SimpleProductHits: ConnectedComponentClass<
  HitsPropsExposed<ProductHit>,
  HitsProvided<ProductHit>
> = Hits as SimpleProductHitsType
