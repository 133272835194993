import { getBackgroundColor } from '@client/helpers/color'
import { Avatar as MuiAvatar } from '@mui/material'
import { DefaultTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { spacing } from '@mui/system'
import styled from 'styled-components'

export const defaultBgColor = '#bdbdb'

export const Avatar = styled(MuiAvatar)(spacing)

export const useAvatarStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: '0.75rem'
  },
  medium: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: '0.8rem'
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: '1.2rem'
  },
  xlarge: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: '1.8rem'
  }
}))

export const useAvatarClasses = makeStyles<DefaultTheme, { initials: string | null }>((theme) => ({
  colorDefault: ({ initials }) => {
    const backgroundColor = initials ? getBackgroundColor(initials) : defaultBgColor
    return {
      backgroundColor,
      color: theme.palette.getContrastText(backgroundColor)
    }
  }
}))
