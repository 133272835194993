import deepEqual from 'fast-deep-equal'
import { useCallback, useMemo, useState } from 'react'

export const useIsDirty = <TObj>(initialState: TObj) => {
  const [initialValue, setInitialValue] = useState<TObj>(initialState)
  const [value, setValue] = useState<TObj>(initialState)
  const isDirty = useMemo(() => {
    return !deepEqual(initialValue, value)
  }, [initialValue, value])
  const reset = useCallback((state: TObj) => {
    setInitialValue(state)
    setValue(state)
  }, [])

  return {
    value,
    initialValue,
    isDirty,
    setValue,
    reset
  }
}
