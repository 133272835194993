import { LibraryBooks, OpenInNew as MuiOpenInNew } from '@mui/icons-material'
import styled from 'styled-components'

export const MasteredIcon = styled(LibraryBooks).withConfig<{ mastered?: boolean }>({
  shouldForwardProp: (prop) => prop !== 'mastered'
})`
  font-size: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme, mastered: selected }) => (selected ? theme.palette.lightBlue : theme.palette.medium)};
`

export const OpenInNew = styled(MuiOpenInNew)`
  font-size: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.palette.darkest};
`
