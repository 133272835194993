import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(() => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  leftSideContainer: {
    marginRight: 'auto'
  }
}))
