import { SubscriptionTierCode } from '@lib/types/accounts'
import { JwtPayload } from 'jsonwebtoken'

export const appClaimsKey = 'https://app.hoodieanalytics.com/jwt/claims'

export interface HoodieJwtPayload extends JwtPayload {
  'https://hoodie.app.com/userMetadata': { firstLogin: boolean }
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': Array<'HoodieWholesaleUser'>
    'x-hasura-application-id': string
    'x-hasura-default-role': 'HoodieWholesaleUser'
    'x-hasura-organization-id'?: string
    'x-hasura-subscription-id': string
    'x-hasura-subscription-name'?: string
    'x-hasura-user-id': string
  }
  [appClaimsKey]: {
    accountId?: string
    accountName?: string
    tierId?: string
    tierCode?: SubscriptionTierCode
    tierName?: string
  }
  given_name?: string
  family_name?: string
  nickname?: string
  name: string
  picture?: string
  updated_at: string
  email: string
  email_verified: boolean
}
