import { useAuth0 } from '@auth0/auth0-react'
import { useHasura } from '@client/providers/hasura'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { apiClient } from '@client/services/api/api-client'
import { ComparisonFilterset, ComparisonResponse, UserDataKeys } from '@client/services/hasura'
import { useComparisonStore } from '@client/stores/use-comparison-state'
import { useKpiSettingsStore } from '@client/stores/use-kpi-settings-store'
import { Comparison, ComparisonItem, DraftComparison, KpiSettingsItem } from '@client/types/comparison'
import { Favorite } from '@client/types/favorites'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import deepmerge from 'deepmerge'
import { useCallback, useMemo } from 'react'
import { SetRequired } from 'type-fest'
import { useDebouncedCallback } from 'use-debounce'
import { useEffectOnce } from 'usehooks-ts'
import { useEventTracker } from './use-event-tracker'
import { useFavorites } from './use-favorites'
import { useToastNotification } from './use-toast-notification'

const { mutate: purgeNotificationPreferencesForComparison } = apiClient.notificationPreferences.purgeOrphans

const mapComparisonResponseToItem = (comparisonFilterset: ComparisonFilterset): ComparisonItem => ({
  id: comparisonFilterset.filterset.filterset_id,
  name: comparisonFilterset.filterset.filterset_name,
  filters: deepmerge(emptyDraftFilterset().filters, comparisonFilterset.filterset.filters)
})

export const parseComparison = (comparison: ComparisonResponse): Comparison => {
  const items: ComparisonItem[] = []
  if (comparison.context !== 'custom' && comparison.items) {
    items.push(...comparison.items)
  } else if (comparison.context === 'custom' && comparison.comparison_filtersets) {
    items.push(...comparison.comparison_filtersets.map(mapComparisonResponseToItem))
  }
  return {
    id: comparison.comparison_id,
    userId: comparison.user_id,
    subscriptionId: comparison.subscription_id ?? undefined,
    name: comparison.name,
    context: comparison.context as Comparison['context'],
    filters: comparison.filters,
    items
  }
}

const sortComparison = <T extends DraftComparison = DraftComparison>(comparison: T) => {
  const sortedItems = [...comparison.items]
  sortedItems.sort((a, b) => {
    if (a.benchmark && !b.benchmark) {
      return -1 // `a` has benchmark, `b` doesn't, `a` comes first
    } else if (!a.benchmark && b.benchmark) {
      return 1 // `b` has benchmark, `a` doesn't, `b` comes first
    }
    return 0 // Both have benchmark or neither have benchmark, maintain the order
  })
  return { ...comparison, items: sortedItems }
}

const sortComparisons = (favorites: Favorite[]) => (a: Comparison, b: Comparison) => {
  // Sort reports by favorites, then alphabetically
  const aIsFavorite = favorites.find((f) => f.comparisonId === a.id)
  const bIsFavorite = favorites.find((f) => f.comparisonId === b.id)

  if (aIsFavorite && !bIsFavorite) {
    return -1
  }

  if (!aIsFavorite && bIsFavorite) {
    return 1
  }

  return a.name.localeCompare(b.name)
}

export const useComparison = (id?: string, autoFetch = false) => {
  const { hasuraClient } = useHasura()
  const { user } = useAuth0()
  const { toast } = useToastNotification()
  const {
    isReady: isFavoritesReady,
    favorites,
    updateFavoriteName,
    getFavoriteByKey,
    removeFavorite
  } = useFavorites('comparisons')

  const { eventTracker } = useEventTracker({ category: TrackableCategory.comparisons })

  const draftComparison = useComparisonStore((state) => state.draftComparison)
  const unsortedComparisons = useComparisonStore((state) => state.savedComparisons)
  const isInitialized = useComparisonStore((state) => state.isInitialized)
  const setSavedComparisons = useComparisonStore((state) => state.setSavedComparisons)
  const saveDraft = useComparisonStore((state) => state.saveDraft)
  const upsertComparison = useComparisonStore((state) => state.upsertComparison)
  const removeComparison = useComparisonStore((state) => state.removeComparison)
  const setSavedSettings = useKpiSettingsStore((state) => state.setSavedSettings)

  const { deleteAlertsByComparisonID } = useNotificationPreferences()

  const comparisons = useMemo((): DraftComparison[] => {
    // Keep the draft comparison at the top of the list
    const sortedComparisons = [...unsortedComparisons].sort(sortComparisons(favorites))
    return draftComparison ? [draftComparison, ...sortedComparisons] : sortedComparisons
    // Don't update the order when the favorites are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftComparison, unsortedComparisons, isFavoritesReady])

  const currentComparison = useMemo(() => {
    if (id) {
      return unsortedComparisons.find((c) => c.id === id)
    }
    return draftComparison
  }, [draftComparison, unsortedComparisons, id])

  const trackEvent = useCallback(
    (comparison: DraftComparison, action: TrackableAction) => {
      eventTracker({
        action,
        dimension1: comparison.id ?? 'draft-comparison',
        dimension2: comparison.name ?? 'Draft Comparison',
        dimension3: comparison.context,
        dimension4: comparison.items.map(({ name }) => name).join(', ')
      })
    },
    [eventTracker]
  )

  useEffectOnce(() => {
    const fetchComparisons = async () => {
      const [comparisons, settings] = await Promise.all([
        hasuraClient?.getComparisons(),
        hasuraClient?.getUserDataByKey<KpiSettingsItem[] | null>(
          UserDataKeys.DispensaryAnalyticsKPISettings,
          'GetMenuAnalyticsKPISettings'
        )
      ])
      if (comparisons) {
        setSavedComparisons(comparisons.map(parseComparison))
      }
      if (settings) {
        setSavedSettings(settings)
      }
    }
    if (autoFetch) {
      fetchComparisons()
    }
  })

  const createDraftComparison = useCallback(
    (comparison: DraftComparison) => {
      const sortedComparison = { ...sortComparison(comparison), id: undefined }
      upsertComparison(sortedComparison)
      trackEvent(sortedComparison, TrackableAction.comparisonCreated)
    },
    [trackEvent, upsertComparison]
  )

  const validateComparisonName = useCallback(
    async (comparisonName: string, comparisonId?: string) => {
      if (hasuraClient) {
        return await hasuraClient.isValidComparisonName({ comparisonName, comparisonId, userId: user!.sub! })
      }
    },
    [hasuraClient, user]
  )

  const validateComparisonNameDebounced = useDebouncedCallback(validateComparisonName, 300)

  const saveDraftComparison = useCallback(
    async (comparison: SetRequired<DraftComparison, 'name'>) => {
      if (hasuraClient) {
        const sortedComparison = sortComparison(comparison)
        return await hasuraClient
          .insertComparison(sortedComparison)
          .then((savedComparison) => {
            const parsedComparison = parseComparison(savedComparison)
            saveDraft(parsedComparison)
            trackEvent(sortedComparison, TrackableAction.comparisonSaved)
            toast.success(`Comparison ${parsedComparison.name} saved`)
            return parsedComparison
          })
          .catch(() => {
            toast.error(`Could not save the comparison ${comparison.name}`)
          })
      }
    },
    [trackEvent, saveDraft, toast, hasuraClient]
  )

  const saveComparison = useCallback(
    async (comparison: DraftComparison, silently = false): Promise<Comparison | null> => {
      if (hasuraClient) {
        const sortedComparison = sortComparison(comparison)
        // If the comparison is being unshared or transferred (and not shared), purge any now-orphaned
        // notification preferences associated with this comparison. In the case of transferring a comparison,
        // for security this has to be done before updating the comparison (while the authenticated user
        // still owns the comparison)
        if (sortedComparison.id && sortedComparison.userId) {
          const isUnshared = currentComparison?.subscriptionId && !sortedComparison.subscriptionId
          const isTransferred = sortedComparison.userId !== user?.sub && !sortedComparison.subscriptionId
          if (isUnshared || isTransferred) {
            await purgeNotificationPreferencesForComparison({
              comparisonId: sortedComparison.id,
              newComparisonOwnerId: sortedComparison.userId
            })
          }
        }
        const savedComparison = await hasuraClient.updateComparison(sortedComparison as Comparison)
        if (savedComparison) {
          const parsedComparison = parseComparison(savedComparison)
          // If the comparison is not owned by the current user and it is not a shared comparison, remove it from the list, otherwise update it
          if (parsedComparison.userId !== user?.sub && !parsedComparison.subscriptionId) {
            removeComparison(comparison.id)
          } else {
            upsertComparison(parsedComparison)
          }
          // Update the favorites if the comparison is favorited
          if (sortedComparison.id) {
            updateFavoriteName('comparisonId', sortedComparison.id, savedComparison.name)
          }
          trackEvent(sortedComparison, TrackableAction.comparisonSaved)
          if (!silently) {
            toast.success(`Comparison ${parsedComparison.name} saved`)
          }
          return parsedComparison
        } else {
          if (!silently) {
            toast.error(`Could not save the comparison ${comparison.name}`)
          }
        }
      }
      return null
    },
    [
      hasuraClient,
      currentComparison?.subscriptionId,
      user?.sub,
      trackEvent,
      removeComparison,
      upsertComparison,
      updateFavoriteName,
      toast
    ]
  )

  const deleteComparison = useCallback(
    async (comparison: DraftComparison) => {
      if (hasuraClient) {
        if (comparison.id) {
          const isFavorited = getFavoriteByKey(comparison.id, 'comparisonId')
          return await Promise.all([
            hasuraClient.deleteComparison(comparison.id),
            deleteAlertsByComparisonID(comparison.id),
            isFavorited ? removeFavorite(isFavorited, undefined, true) : Promise.resolve()
          ])
            .then(() => {
              removeComparison(comparison.id)
              trackEvent(comparison, TrackableAction.comparisonDeleted)
              toast.success(`Comparison ${comparison.name} deleted`)
              return true
            })
            .catch(() => {
              toast.error(`Could not delete the comparison ${comparison.name}`)
              return false
            })
        } else {
          toast.success(`Draft comparison discarded`)
          removeComparison(comparison.id)
          return true
        }
      }
      return false
    },
    [hasuraClient, getFavoriteByKey, deleteAlertsByComparisonID, removeFavorite, removeComparison, trackEvent, toast]
  )

  return {
    currentComparison,
    comparisons,
    isInitialized,
    createDraftComparison,
    saveDraftComparison,
    saveComparison,
    deleteComparison,
    validateComparisonName,
    validateComparisonNameDebounced
  }
}
