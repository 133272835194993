import { Box } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import styled from 'styled-components'

export const AutocompletePopper = styled(Box)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  [`& .${autocompleteClasses.listbox}`]: {
    paddingTop: 0,
    [`& .${autocompleteClasses.option} svg`]: {
      display: 'inline-block',
      fontSize: theme.typography.size.s2,
      marginRight: theme.spacing(1),
      verticalAlign: 'sub'
    }
  }
}))
