import { SortOrder } from '@lib/types/orderBy'
import { StringKeyOf } from 'type-fest'

type SortableCollection = Sortable[]
type Sortable = {
  criteria: unknown[]
  index: number
  value: Record<string, unknown>
}
type SortResult = -1 | 0 | 1
type Compareable = string | number | boolean | null | undefined

const compareAsc = (a: Compareable, b: Compareable) => {
  if (a === b) {
    return 0
  }

  if (a === null || a === undefined) {
    return 1
  }

  if (b === null || b === undefined) {
    return -1
  }

  return typeof a === 'string' ? a.localeCompare(b.toString()) : a < b ? -1 : 1
}

const compare = (a: Sortable, b: Sortable, sortOrders: SortOrder[]): SortResult => {
  let index = -1
  const criteriaA = a.criteria
  const criteriaB = b.criteria
  const { length } = a.criteria
  const { length: ordersLength } = sortOrders

  while (++index < length) {
    const order = index < ordersLength ? sortOrders[index] : null
    const result = compareAsc(criteriaA[index] as Compareable, criteriaB[index] as Compareable)

    if (result) {
      return (result * (order === 'desc' ? -1 : 1)) as SortResult
    }
  }

  return (a.index - b.index) as SortResult
}

const sort = (sortable: SortableCollection, comparer: (a: Sortable, b: Sortable) => SortResult) => {
  let { length } = sortable

  const sortedCollection = new Array(length)
  sortable.sort(comparer)

  while (length--) {
    sortedCollection[length] = sortable[length].value
  }

  return sortedCollection
}

const order = (collection: SortableCollection, sortKeys: string[], sortOrders: SortOrder[]) => {
  const sortable = collection.reduce(
    (sortable: SortableCollection, value: Record<string, unknown>, index: number) =>
      sortable.concat([
        {
          value,
          index,
          criteria: sortKeys.map((key) => value?.[key])
        }
      ]),
    [] as SortableCollection
  )

  return sort(sortable, (obj1, obj2) => compare(obj1, obj2, sortOrders))
}

const orderBy = <TItem extends Record<string, unknown>, Tkey extends StringKeyOf<TItem>>(
  collection: TItem[],
  sortKeys: Array<Tkey> | Tkey,
  sortOrders?: SortOrder[] | SortOrder
): TItem[] => {
  if (collection == null) {
    return []
  }

  if (!Array.isArray(sortKeys)) {
    sortKeys = sortKeys == null ? [] : [sortKeys]
  }

  if (!Array.isArray(sortOrders)) {
    sortOrders = sortOrders == null ? [] : [sortOrders]
  }

  return order(collection as unknown as SortableCollection, sortKeys, sortOrders)
}

export default orderBy
