import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  WarningAmberOutlined
} from '@mui/icons-material'
import { IconVariant } from 'notistack'

const snackbarIconProps = { sx: { mr: 3 } }
export const snackbarIcons: Partial<IconVariant> = {
  error: <ErrorOutlineOutlined {...snackbarIconProps} />,
  warning: <WarningAmberOutlined {...snackbarIconProps} />,
  info: <InfoOutlined {...snackbarIconProps} />,
  success: <CheckCircleOutlineOutlined {...snackbarIconProps} />
}
