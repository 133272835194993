import { Chip as MuiChip, darken, lighten } from '@mui/material'
import styled from 'styled-components'

export const Chip = styled(MuiChip)<{ $tagColor: string }>`
  background-color: ${({ $tagColor }) => $tagColor};
  border: 1px solid ${({ $tagColor }) => darken($tagColor, 0.1)};
  color: ${({ theme }) => theme.palette.lightest};
  font-size: ${({ theme }) => theme.typography.size.xs2};
  height: 20px;
  border-radius: 4px;
  margin: 0;
  transition: ${({ theme }) =>
    theme.transitions.create(['font-size', 'height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    })};

  &.MuiButtonBase-root:hover {
    background-color: ${({ $tagColor }) => lighten($tagColor, 0.3)};
  }
  .MuiChip-label {
    transition: ${({ theme }) =>
      theme.transitions.create(['padding'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      })};
  }
  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.palette.lightest} !important;
    opacity: 0.7;
    transition: ${({ theme }) =>
      theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      })};
    &:hover {
      opacity: 1;
    }
  }
  svg {
    margin-left: ${({ theme }) => theme.spacing(1)};
    margin-right: ${({ theme }) => theme.spacing(-1)};
  }
`

export const ItemCount = styled.span<{ $tagColor: string }>`
  font-size: ${({ theme }) => theme.typography.size.xs2};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  margin-left: ${({ theme }) => theme.spacing(1)};
  padding: 0 ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ $tagColor }) => lighten($tagColor, 0.4)};
`
