import { useAuth0 } from '@auth0/auth0-react'
import { FilterCheckboxIcon } from '@client/components/FilterCheckbox/FilterCheckbox'
import { TrackableRefNavLink } from '@client/components/Trackables'
import { EventArgs } from '@client/configs/tracking'
import { urls } from '@client/helpers/urls'
import { useTags } from '@client/providers/tags-provider'
import { TAG_ALGOLIA_SEPARATOR, TagFilterTarget } from '@client/types/filterset'
import { TagFilterWithData, TagType, parseTagsToFilter, tagTypeToName } from '@client/types/tags'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { OpenInNew, PeopleOutline } from '@mui/icons-material'
import { Link, Typography } from '@mui/material'
import { memo, useMemo } from 'react'
import { RefinementListExposed } from 'react-instantsearch-core'
import { HierarchicalRefinement, TreeOption } from '../HierarchicalRefinement/HierarchicalRefinement'

const gaEvent: EventArgs = {
  action: TrackableAction.tagManagerFromFilters,
  category: TrackableCategory.navigation
}

const NoTags = memo(function NoTags() {
  return (
    <>
      <Typography variant="body1" mb={4}>
        You do not have any tags defined.
      </Typography>
      <Typography variant="body1">
        Use the{' '}
        <Link
          component={TrackableRefNavLink}
          to={urls.tagManagerPage()}
          gaEvent={gaEvent}
          aria-label="tag-manager-link"
        >
          Tag Manager
        </Link>{' '}
        to tag some brands, dispensaries and products!
      </Typography>
    </>
  )
})
export type HierarchicalRefinementExposedProps = RefinementListExposed & {
  omitTargets?: TagFilterTarget[]
}
export type DetachedTagsHierarchicalRefinementProps = HierarchicalRefinementExposedProps & {
  attribute: string
  tags: TagFilterWithData[]
  values: string[]
  onChange: (attribute: string, values: string[]) => void
}

export const DetachedTagsHierarchicalRefinement: React.FC<DetachedTagsHierarchicalRefinementProps> = ({
  attribute,
  values: currentRefinement,
  omitTargets,
  tags,
  onChange
}) => {
  const options = useMemo(() => {
    return tags.reduce<TreeOption[]>((acc, tag) => {
      const children = tag.types.reduce<TreeOption[]>((typesAcc, type, index) => {
        // Don't show the tag type if it's in the omitTargets list
        if (omitTargets?.includes(type)) {
          return typesAcc
        }
        typesAcc.push({
          value: `${tag.tagId}${TAG_ALGOLIA_SEPARATOR}${type}`,
          label: tagTypeToName[type as TagType],
          count: tag.typesCount[index]
        })
        return typesAcc
      }, [])
      if (children.length > 0) {
        const icons: FilterCheckboxIcon[] = []
        if (tag.isSharedWithMe) {
          icons.push({
            icon: PeopleOutline,
            tooltip: 'Shared with me'
          })
        }
        acc.push({
          value: tag.tagId,
          label: tag.tagLabel,
          icons: [
            ...icons,
            {
              icon: OpenInNew,
              href: urls.tagManagerPage(`${tag.tagId}/details`, '/#/'),
              tooltip: 'Open in Tag Manager'
            }
          ],
          children
        })
      }
      return acc
    }, [])
  }, [omitTargets, tags])

  return (
    <HierarchicalRefinement
      attribute={attribute}
      currentRefinement={currentRefinement}
      options={options}
      onChange={onChange}
      showMore
      searchable
      searchUpToLevel={0}
      NoOptionsComponent={<NoTags />}
    />
  )
}

export type TagsHierarchicalRefinementProps = Omit<DetachedTagsHierarchicalRefinementProps, 'tags'>

export const TagsHierarchicalRefinement: React.FC<TagsHierarchicalRefinementProps> = memo(
  function TagsHierarchicalRefinement(props) {
    const { personalTags, sharedTags } = useTags()
    const { user } = useAuth0()

    const tags = useMemo(
      () => parseTagsToFilter([...personalTags, ...sharedTags], user?.sub),
      [personalTags, sharedTags, user?.sub]
    )

    return <DetachedTagsHierarchicalRefinement {...props} tags={tags} />
  }
)
