import { getOperationName } from '@apollo/client/utilities'
import { apiClient } from '@client/services/api/api-client'
import { HasuraCustomRole } from '@lib/types/graphql'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { DocumentNode, print } from 'graphql'

const { mutate } = apiClient.graphql.gqlMutation
const { query } = apiClient.graphql.gqlQuery

export type GqlRequest = {
  query: DocumentNode
  variables?: Record<string, any>
  requestedRole?: HasuraCustomRole
}

const inputFromRequest = (request: GqlRequest) => ({
  operationName: getOperationName(request.query) || 'UnnamedOperation',
  query: print(request.query),
  variables: request.variables,
  requestedRole: request.requestedRole
})

export const useGraphqlQuery = <T>(request: GqlRequest, options?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>) => {
  // Note: trpc's UseQuery hook has an issue with type-inference when using the 'refetch' function
  // So instead we use the react-query hook directly
  return useQuery(
    ['/graphql/query', print(request.query), request.variables || {}, request.requestedRole],
    async () => {
      return graphqlQuery<T>(request)
    },
    options
  )
}

export const graphqlQuery = <T>(request: GqlRequest): T => {
  return query(inputFromRequest(request)) as T
}

export const graphqlMutation = <T>(request: GqlRequest): Promise<T> => {
  return mutate(inputFromRequest(request)) as Promise<T>
}
