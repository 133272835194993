import { darken, lighten } from '@mui/system'

export interface Palette {
  // Primary
  navyBlue: string
  paleBlue: string
  lightBlue: string
  primaryGreen: string
  red: string
  primaryLinear: string
  orange: string

  // Secondary
  deepTinRust: string
  seafoamGreen: string
  purpleBlue: string
  purple: string

  // Domain
  newStock: string
  lowStock: string
  outOfStock: string
  price: string
  inventory: string
  promotions: string
  brands: string
  mapDefault: string
  carried: string
  salesProspects: string
  winBacks: string
  currentAccount: string
  other: string
  noMenu: string
  dispensaryAnalytics: string
  brandCatalog: string
  benchmark: string
  wholesale: {
    main: string
    contrastText: string
    dark: string
  }
  marketIntelligence: string

  snooze: {
    main: string
    contrastText: string
    dark: string
  }
  import: {
    main: string
    contrastText: string
    dark: string
    light: string
  }
  toggle: {
    main: string
    contrastText: string
    dark: string
  }

  // Monochrome

  lightest: string
  lighter: string
  light: string
  lightGray2: string
  mediumlight: string
  medium: string
  mediumdark: string
  borderGray: string
  dark: string
  darkGray: string
  darker: string
  darkest: string

  brand: {
    brand1: string
  }

  background: {
    default: string
  }
  success: {
    main: string
    contrastText: string
  }
  help: {
    main: string
    dark: string
    light: string
    contrastText: string
  }
  warn: string
  danger: {
    light: string
  }
  // KPIs
  positive: {
    main: string
    dark: string
    light: string
    border: string
  }
  negative: {
    main: string
    dark: string
    light: string
    border: string
  }
  flat: {
    main: string
    dark: string
    light: string
    border: string
  }
}

export const palette: Palette = {
  // Primary
  navyBlue: '#1d3663',
  paleBlue: '#e6f0ff',
  lightBlue: '#2F7CF6',
  primaryGreen: '#51bc81',
  red: '#EC4E3D',
  orange: '#f6b367',

  // Domain
  newStock: '#51bc81',
  lowStock: '#f6b367',
  outOfStock: '#EC4E3D',
  price: '#5bc6d0',
  inventory: '#21767b',
  promotions: '#622870',
  brands: '#2F7CF6',
  mapDefault: '#21767b',
  carried: '#4CBB17', // kelly green
  salesProspects: '#5bc6d0',
  winBacks: '#880808',
  currentAccount: '#21767b',
  noMenu: '#e0e0e0',
  other: '#757575',
  dispensaryAnalytics: '#019b9d',
  brandCatalog: '#e11e7a',
  benchmark: '#e3ecf1',
  wholesale: {
    main: '#5bc6d0',
    contrastText: '#ffffff',
    dark: darken('#5bc6d0', 0.2)
  },
  marketIntelligence: '#b069c2',

  snooze: {
    main: '#43d0d3',
    contrastText: '#ffffff',
    dark: '#068487'
  },
  toggle: {
    main: '#999999',
    contrastText: '#ffffff',
    dark: '#888888'
  },
  primaryLinear: `linear-gradient(
    303.08deg,
    #6db885 3.37%,
    #60a27f 15.79%,
    #548c79 27.82%,
    #477673 40.24%,
    #3a606c 52.66%,
    #2e4a66 64.3%,
    #213460 77.11%
  )`,

  // Secondary
  deepTinRust: '#21767b',
  seafoamGreen: '#5bc6d0',
  purpleBlue: '#362d79',
  purple: '#622870',

  // Monochrome
  lightest: '#FFFFFF',
  lighter: '#F8F8F8',
  light: '#F3F3F3',
  lightGray2: '#E5E5EA',
  mediumlight: '#EEEEEE',
  medium: '#DDDDDD',
  mediumdark: '#999999',
  borderGray: '#c4c4c4',
  dark: '#666666',
  darkGray: '#8E8E93',
  darker: '#444444',
  darkest: '#222222',

  brand: {
    brand1: '#1d3463'
  },

  background: {
    default: '#f3f3f3'
  },
  success: {
    main: '#51bc81',
    contrastText: '#ffffff'
  },
  warn: '#FFF3E5',
  danger: {
    light: '#fdeded'
  },
  help: {
    main: '#f6b367',
    dark: darken('#f6b367', 0.2),
    light: lighten('#f6b367', 0.2),
    contrastText: '#ffffff'
  },
  import: {
    main: '#3dafb2',
    contrastText: '#ffffff',
    dark: '#007d80',
    light: '#a5f3f5'
  },

  // KPIs
  positive: {
    main: '#b6d7a8',
    dark: '#9fbd94',
    light: '#d4e7ca',
    border: '#b6d7a8'
  },
  negative: {
    main: '#ea9999',
    dark: '#ce8686',
    light: '#f2c1c1',
    border: '#eeaead'
  },
  flat: {
    main: '#f9cb9c',
    dark: '#dbb288',
    light: '#fadfc2',
    border: '#fad4af'
  }
}
