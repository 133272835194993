import { RefObject, useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'

export const useScrollListener = <T extends HTMLElement>({
  ref,
  threshold = 50
}: {
  ref: RefObject<T>
  threshold?: number
}) => {
  const { value: scrolled, setTrue: setScrolled, setFalse: clearScrolled } = useBoolean(false)

  useEffect(() => {
    if (ref.current) {
      const handleScroll = () => {
        const mainContentNode = ref.current
        const scrollTop = mainContentNode!.scrollTop
        const scrollHeight = mainContentNode!.scrollHeight
        const clientHeight = mainContentNode!.clientHeight

        if (scrollTop > threshold || scrollTop + clientHeight >= scrollHeight) {
          setScrolled()
        } else {
          clearScrolled()
        }
      }
      const mainContentNode = ref.current
      mainContentNode.addEventListener('scroll', handleScroll)
      return () => mainContentNode?.removeEventListener('scroll', handleScroll)
    }
  }, [clearScrolled, ref, setScrolled, threshold])

  return {
    scrolled
  }
}
