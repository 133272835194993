import { Box, CircularProgress } from '@mui/material'
import styled from 'styled-components'

export const LoadingWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const standardLoaderBackground = '#ffffffd4'

export const PageLoader: React.FC<{ backgroundColor?: string }> = ({ backgroundColor }) => (
  <LoadingWrapper data-testid="page-loader" sx={{ backgroundColor }}>
    <CircularProgress data-testid="circular-loading" color="secondary" />
  </LoadingWrapper>
)
