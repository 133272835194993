import { EventArgs, tracking } from '@client/configs/tracking'
import { toKebabCase } from '@client/helpers/strings'
import { TrackableCategory, TrackableLabel } from '@client/types/tracking'
import { useCallback } from 'react'

export interface EventTrackerArgs {
  isClickable?: boolean
  category?: TrackableCategory
  gaEvent?: EventArgs
  gaLabel?: TrackableLabel
  callback?: (...args: any) => void
}

export const useEventTracker = ({ gaEvent, gaLabel, callback, category, isClickable }: EventTrackerArgs = {}) => {
  const handleTracking = useCallback(
    (...args: any) => {
      if (gaEvent) {
        const event = { ...gaEvent }
        const validArgs = [...args].filter(
          (arg) => arg !== null && arg !== undefined && !(Array.isArray(arg) && !arg.length)
        )
        const isEvent = !!validArgs.length && !!validArgs[0]?.type
        if (validArgs?.length > 1 || (!isEvent && validArgs.length)) {
          const arg = isEvent ? validArgs[1] : validArgs[0]
          if (isNaN(arg)) {
            event.dimension1 = Array.isArray(arg) ? arg.map(toKebabCase).join(', ') : toKebabCase(arg)
          } else {
            event.value = arg
          }
        }
        if (!!isClickable || validArgs.length) {
          tracking.trackEvent(event)
        }
      }
      if (gaLabel) {
        tracking.trackOutboundLink(gaLabel)
      }
      callback?.(...args)
    },
    [callback, gaEvent, gaLabel, isClickable]
  )

  const eventTracker = useCallback(
    (args: Omit<EventArgs, 'category'> & { category?: TrackableCategory }) => {
      const gaCategory = args.category ?? category
      if (gaCategory) {
        tracking.trackEvent({ ...args, category: gaCategory })
      } else {
        throw new Error('No category informed for eventTracker')
      }
    },
    [category]
  )

  return { handleTracking, eventTracker }
}
