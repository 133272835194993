import { lazy } from '@client/routes/lazy'
import { EXPIRED_SESSION_ROUTE } from '@client/types/routes'
import { Navigate, Route, Routes } from 'react-router-dom'

const UnsubscribePage = lazy(() => import('@client/pages/public/unsubscribe/UnsubscribePage'))
const SignInPage = lazy(() => import('@client/pages/auth/sign-in/SignIn'))
const ExpiredSessionPage = lazy(() => import('@client/pages/auth/expired-session/ExpiredSession'))
const DeniedPage = lazy(() => import('@client/pages/auth/denied/Denied'))

// Public routes that should not redirect to the sign-in page
export const publicRoutes = ['/unsubscribe']

function PublicRoutes() {
  return (
    <Routes>
      <Route path="/access-denied" element={<DeniedPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/unsubscribe" element={<UnsubscribePage />} />
      <Route path={EXPIRED_SESSION_ROUTE} element={<ExpiredSessionPage />} />
      <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
  )
}

export default PublicRoutes
