export const localStorageUserKey = '@hoodie:user-id'

export const usersLocalStorage = {
  getItem: <T>(name: string) => {
    const userId = localStorage.getItem(localStorageUserKey)
    const data = localStorage.getItem(name)
    const parsedData: Record<string, any> = data ? JSON.parse(data) : {}
    if (userId && Object.prototype.hasOwnProperty.call(parsedData, userId)) {
      return parsedData[userId] as T
    }
    return null
  },
  setItem: <T>(name: string, value: T) => {
    const userId = localStorage.getItem(localStorageUserKey)
    const data = localStorage.getItem(name)
    const parsedData = data ? JSON.parse(data) : {}
    if (userId) {
      localStorage.setItem(
        name,
        JSON.stringify({
          ...parsedData,
          [userId]: value
        })
      )
    }
  },
  removeItem: (name: string) => {
    const userId = localStorage.getItem(localStorageUserKey)
    const data = localStorage.getItem(name)
    const parsedData = data ? JSON.parse(data) : {}
    if (userId) {
      delete parsedData?.[userId]
      localStorage.setItem(name, JSON.stringify(parsedData))
    }
  }
}
