import { TrackableListItemButton } from '@client/components/Trackables'
import { SearchableHit, SearchableIndex, indexAttributes } from '@client/components/global-search/types'
import { IMG_WIDTH } from '@client/helpers/image/image'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { BRAND_INDEX, DISPENSARY_INDEX, DispensaryHit, VARIANT_INDEX } from '@client/services/algolia'
import { BrandIcon, DispensaryIcon, ProductsIcon } from '@client/styles/global'
import { Box } from '@client/styles/theme/box'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { ListItemText, Typography } from '@mui/material'
import HRN from 'human-readable-numbers'
import pluralize from 'pluralize'
import { ReactNode, memo, useCallback, useMemo } from 'react'
import * as S from './SearchHit.style'

const HitConfig: Record<
  SearchableIndex,
  { renderIcon: () => ReactNode; renderSecondaryText: (hit: any) => ReactNode; prefix: string }
> = {
  [VARIANT_INDEX]: {
    renderIcon: () => <BrandIcon fontSize="small" />,
    renderSecondaryText: (hit: any) => (
      <>
        {hit.BRAND} ({HRN.toHumanString(hit.DISPENSARY_COUNT)} {pluralize('dispensary', hit.DISPENSARY_COUNT)})
      </>
    ),
    prefix: '/products/'
  },
  [DISPENSARY_INDEX]: {
    renderIcon: () => <DispensaryIcon fontSize="small" />,
    renderSecondaryText: (hit: SearchableHit) => (
      <>{[(hit as DispensaryHit).CITY, (hit as DispensaryHit).STATE].join(', ')}</>
    ),
    prefix: '/dispensaries/'
  },
  [BRAND_INDEX]: {
    renderIcon: () => <ProductsIcon fontSize="small" />,
    renderSecondaryText: (hit: any) => (
      <>
        {HRN.toHumanString(hit.ACTIVE_VARIANTS)} {pluralize('active SKU', hit.ACTIVE_VARIANTS)}
      </>
    ),
    prefix: '/brands/'
  }
}

export interface SearchHitProps {
  index: SearchableIndex
  // TODO: strongly type this prop!
  hit: any
}

export const SearchHit: React.FC<SearchHitProps> = memo(function SearchHit({ index, hit }) {
  const navigate = useNavigateRef()

  const id = hit.CM_ID ?? hit.objectID

  const gaEvent = {
    category: TrackableCategory.navigation,
    dimension1: id,
    action:
      index === VARIANT_INDEX
        ? TrackableAction.globalSearchMasterProductClicked
        : index === DISPENSARY_INDEX
        ? TrackableAction.globalSearchDispensaryClicked
        : TrackableAction.globalSearchBrandClicked
  }

  const { nameAttribute, imageAttribute } = useMemo(() => {
    return indexAttributes[index]
  }, [index])

  const onItemSelected = useCallback(() => {
    const urlParams = index === VARIANT_INDEX && hit.MASTERED_STATUS === 'mastered' ? '?mastered=true' : ''
    navigate.current(`${HitConfig[index].prefix}${id}${urlParams}`)
  }, [navigate, index, hit, id])

  return (
    <TrackableListItemButton sx={{ py: 0 }} onClick={onItemSelected} data-testid={`search-hit-${id}`} gaEvent={gaEvent}>
      <Box minWidth="56px" display="flex" flexShrink={0}>
        <S.Image
          src={(hit as any)[imageAttribute]}
          width={IMG_WIDTH.product.xs}
          category={hit.CATEGORY_2}
          searchIndex={index}
        />
      </Box>
      <ListItemText
        primaryTypographyProps={{ component: 'div' }}
        secondaryTypographyProps={{ component: 'div' }}
        primary={
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography noWrap>{(hit as any)[nameAttribute]}</Typography>
          </Box>
        }
        secondary={
          <Box display="flex" alignItems="center">
            {HitConfig[index].renderIcon()}
            <S.SecondaryText variant="caption" noWrap>
              {HitConfig[index].renderSecondaryText(hit)}
            </S.SecondaryText>
          </Box>
        }
      />
    </TrackableListItemButton>
  )
})
