import { TransferOwnership } from '@client/components/TransferOwnership/TransferOwnership'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpLink } from '@client/components/help-link/HelpLink'
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { ShareCheckbox } from '@client/components/share-checkbox/ShareCheckbox'
import { useCustomTerritories } from '@client/hooks/use-custom-territories'
import { useShareTransfer } from '@client/hooks/use-share-transfer'
import { useSavedViews } from '@client/providers/saved-views'
import { useUser } from '@client/providers/user'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { AccountSummary } from '@client/types/account'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { View } from '@hoodie/hoodie-filters/lib/filterset'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

export interface ShareViewDrawerContentProps {
  account: AccountSummary | null
  onClose: () => void
}

export const ShareViewDrawerContent: React.FC<ShareViewDrawerContentProps> = ({ onClose, account }) => {
  const { drawer, setDrawer } = useDrawerState()
  const [busy, setBusy] = useState(false)
  const { updateSavedView } = useSavedViews()
  const { isTerritory } = useCustomTerritories()

  const view: View = useMemo(() => drawer?.state?.filterset, [drawer?.state?.filterset])
  const isCustomTerritory = useMemo(() => isTerritory(view), [isTerritory, view])

  const { isShared, transferTarget, applyShareTransfer, handleShareChange, handleTransferTargetChange } =
    useShareTransfer({
      label: isCustomTerritory ? 'Territory' : 'View',
      data: view,
      onSave: updateSavedView,
      tracking: {
        category: TrackableCategory.savedViews,
        shareAction: TrackableAction.viewShared,
        unshareAction: TrackableAction.viewUnshared,
        transferAction: TrackableAction.viewTransferred
      }
    })

  const handleCancel = useCallback(() => {
    if (drawer?.state?.prevDrawer) {
      setDrawer(drawer.state.prevDrawer)
    } else {
      onClose()
    }
  }, [onClose, drawer?.state?.prevDrawer, setDrawer])

  const handleSave = useCallback(async () => {
    setBusy(true)
    await applyShareTransfer()
    setBusy(false)
    onClose()
  }, [applyShareTransfer, onClose])

  return (
    <>
      <DrawerHeader>
        <DrawerTitle data-testid="drawer-title">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            Share/Transfer {isCustomTerritory ? 'territory' : 'view'}
            <Box display="flex" flexDirection="row" alignItems="center">
              <HelpLink
                helpLink="sales-enablement/filters-views#sharetransfer-a-view"
                tooltip="View help on sharing and transferring views"
              />
              <IconButton edge="end" onClick={handleCancel} data-testid="close-drawer-button">
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <Box p={4}>
          <Typography fontWeight="600" variant="h5" gutterBottom>
            Share <strong>&apos;{view.name}&apos;</strong>
          </Typography>
          <ShareCheckbox
            isShared={isShared}
            onChange={handleShareChange}
            accountName={account?.name}
            label={isCustomTerritory ? 'territory' : 'view'}
          />
          <Divider sx={{ my: 4 }} />
          <Typography fontWeight="600" variant="h5" gutterBottom>
            Transfer <strong>&apos;{view.name}&apos;</strong>
          </Typography>
          <TransferOwnership
            onTransferTargetChange={handleTransferTargetChange}
            transferTarget={transferTarget}
            accountName={account?.name}
            label={isCustomTerritory ? 'territory' : 'view'}
          />
        </Box>
      </DrawerContent>
      <DrawerFooter>
        <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
          <Button variant="text" color="primary" onClick={handleCancel} sx={{ mr: 2 }} data-testid="cancel-button">
            Cancel
          </Button>
          <LoadingButton
            data-testid="save-button"
            variant="contained"
            loading={busy}
            loadingIndicator="Saving..."
            color="primary"
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </Box>
      </DrawerFooter>
    </>
  )
}

export const ShareViewDrawer: React.FC<any> = () => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  const { account } = useUser()
  return (
    <Drawer
      open={drawer?.id === DrawerId.shareView}
      anchor="right"
      classes={drawerStyles}
      onClose={clearDrawer}
      data-testid="share-view-drawer"
    >
      {drawer?.id === DrawerId.shareView && (
        <ErrorBoundary>
          <ShareViewDrawerContent onClose={clearDrawer} account={account} />
        </ErrorBoundary>
      )}
    </Drawer>
  )
}
