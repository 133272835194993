import { Close, Search } from '@mui/icons-material'
import { InputAdornment, TextFieldProps } from '@mui/material'
import { ChangeEventHandler, memo, useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import * as S from './SearchInput.style'

interface SearchInputProps extends Pick<TextFieldProps, 'sx' | 'margin' | 'size' | 'variant' | 'inputRef'> {
  disabled?: boolean
  searchTerm: string
  onSearch: (searchTerm: string) => void
  debounceMs?: number
  placeholder?: string
  autoFocus?: boolean
}

export const SearchInput: React.FC<SearchInputProps> = memo(function SearchInput({
  searchTerm,
  onSearch,
  debounceMs,
  placeholder,
  inputRef,
  disabled,
  sx,
  margin,
  size = 'medium',
  variant = 'standard',
  autoFocus = false
}) {
  const [searchTermText, setSearchTermText] = useState<string>(searchTerm || '')
  const debounced = useDebouncedCallback(onSearch, debounceMs)

  useEffect(() => {
    setSearchTermText(searchTerm || '')
  }, [searchTerm])

  const updateSearchTerm: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (ev) => {
    setSearchTermText(ev.target.value)
    debounced(ev.target.value)
  }

  const clearSearchTerm = useCallback(() => {
    setSearchTermText('')
    onSearch('')
  }, [setSearchTermText, onSearch])

  return (
    <S.SearchField
      sx={sx}
      margin={margin}
      size={size}
      variant={variant}
      placeholder={placeholder || 'Search...'}
      inputRef={inputRef}
      inputProps={{
        'data-testid': 'search-input'
      }}
      disabled={disabled}
      value={searchTermText}
      onChange={updateSearchTerm}
      InputProps={{
        autoFocus,
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <S.ClearSearchButton
              size="small"
              disabled={!searchTermText.length}
              onClick={clearSearchTerm}
              aria-label="clear-search-input"
            >
              <Close />
            </S.ClearSearchButton>
          </InputAdornment>
        )
      }}
    />
  )
})
