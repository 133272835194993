import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

interface DebugContext {
  isDebugEnabled: boolean
}

const debugKey = '@hoodie:debug-mode'

export const PageDebugKey = 'page'

const DebugContext = createContext<DebugContext>({} as DebugContext)

export const DebugProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDebugEnabled, setIsDebugEnabled] = useState(localStorage.getItem(debugKey) === 'true')
  const [debugModeUrlParam, setDebugModeUrlParam] = useQueryParam('hoodieDebugMode', StringParam, {
    enableBatching: true
  })

  useEffect(() => {
    if (debugModeUrlParam && debugModeUrlParam.length) {
      setIsDebugEnabled(debugModeUrlParam === 'true')
      localStorage.setItem(debugKey, debugModeUrlParam === 'true' ? 'true' : 'false')
      setDebugModeUrlParam(undefined, 'replaceIn')
    }
  }, [debugModeUrlParam, setDebugModeUrlParam])

  return <DebugContext.Provider value={{ isDebugEnabled }}>{children}</DebugContext.Provider>
}

export const useDebug = () => {
  const context = useContext(DebugContext)

  if (!context) {
    throw new Error('useDebug must be used within a DebugProvider.')
  }

  return context
}
