import { useAuth0 } from '@auth0/auth0-react'
import { TAG_ITEMS_QUERY_KEY } from '@client/hooks/use-item-tags-by-type'
import { TAG_ITEMS_KEY } from '@client/hooks/use-tag-items'
import {
  DraftTag,
  ItemTagKey,
  ItemTagWithTag,
  RequiredItemTag,
  TagType,
  TagWithCount,
  TagWithItems,
  getItemTagType,
  groupItemTagsByType,
  tagKeys,
  tagTypeKeys
} from '@client/types/tags'
import { Filters, TAG_FILTER_TARGETS, TagFilterTarget } from '@hoodie/hoodie-filters/lib/filterset'
import { ItemTag, Tag } from '@hoodie/hoodie-filters/lib/tags'
import { toSorted } from '@lib/helpers/objects'
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query'
import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react'
import { SetRequired } from 'type-fest'
import { useHasura } from './hasura'

export const TAGS_QUERY_KEY = 'tags'
type TagContext = 'global' | 'personal' | 'shared'

type GetByLabelOptions = {
  personalTagsOnly?: boolean
}

const sortTags = (tagA: Tag, tagB: Tag) => tagA.label.localeCompare(tagB.label)

const decreaseItemsCount = (tag: SetRequired<TagWithCount, 'itemsCount'>, type: TagType) => {
  return {
    ...tag,
    itemsCount: {
      ...tag.itemsCount,
      [type]: Math.max(0, tag.itemsCount[type] - 1)
    }
  }
}

export interface TagsContextInterface {
  globalTags: TagWithCount[]
  personalTags: TagWithCount[]
  sharedTags: TagWithCount[]
  archivedTags: TagWithCount[]
  isFetching: boolean
  getTagLabel: (tagId: string) => string | undefined
  getTagByLabel: (tagLabel: string, options?: GetByLabelOptions) => TagWithCount | undefined
  getTagById: (tagId: string) => TagWithCount | undefined
  getTargetItemTagsCount: (target: TagFilterTarget) => number
  upsertTag: (tag: DraftTag, itemTagsToRemove?: RequiredItemTag[]) => Promise<TagWithItems | undefined>
  toggleArchiveTag: (tag: Tag) => Promise<TagWithItems | undefined>
  deleteTag: (tag: Tag) => Promise<boolean>
  removeTagFromItem: (itemTag: SetRequired<ItemTag, 'itemTagId'>, tagId: string) => Promise<boolean | undefined>
}

const TagsContext = createContext<TagsContextInterface>({
  globalTags: [],
  personalTags: [],
  sharedTags: [],
  archivedTags: [],
  isFetching: false,
  getTagLabel: () => {
    throw new Error('getTagLabel() not implemented')
  },
  getTagByLabel: () => {
    throw new Error('getTagByLabel() not implemented')
  },
  getTagById: () => {
    throw new Error('getTagById() not implemented')
  },
  getTargetItemTagsCount: () => {
    throw new Error('getTargetItemTagsCount() not implemented')
  },
  upsertTag: () => {
    throw new Error('upsertTag() not implemented')
  },
  toggleArchiveTag: () => {
    throw new Error('toggleArchiveTag() not implemented')
  },
  deleteTag: () => {
    throw new Error('deleteTag() not implemented')
  },
  removeTagFromItem: () => {
    throw new Error('removeTagFromItem() not implemented')
  }
})

export const shouldUpdateTagItemsQuery = ({
  queryKey,
  itemTags
}: {
  queryKey: QueryKey
  itemTags: ItemTag[]
}): boolean => {
  // If it's not a tag item query, just return false
  if (queryKey[0] !== TAG_ITEMS_QUERY_KEY) {
    return false
  }
  // Group items by tag type
  const groupedItems = groupItemTagsByType(itemTags)

  return Object.keys(groupedItems).some((type) => {
    // If the query key type is different from the item tag type, return false
    if (queryKey[1] !== type) {
      return false
    }
    // If there are no ids in the query key, return true
    if (!queryKey[2]) {
      return true
    }
    // If there are ids in the query key, check if any of them are in the item tags
    return tagTypeKeys[type as TagType].some((key: ItemTagKey) =>
      itemTags.some((it) => it[key] && (queryKey[2] as string[]).includes(it[key] as string))
    )
  })
}

export const shouldInvalidateOpenSearch = ({
  queryKey,
  tagId,
  types
}: {
  queryKey: QueryKey
  tagId: string
  types: TagFilterTarget[]
}): boolean => {
  // If there are no types, return false
  if (types.length === 0) {
    return false
  }
  // Check if the query key is an open search query
  if (typeof queryKey[0] === 'string' && queryKey[0].startsWith('/open-search/')) {
    // Validate if some of the queryKeys is a filterset
    return queryKey.some((key) => {
      // If it's a filterset, check if it has reference for the tagId
      if (key && Object.hasOwn(key, 'filterBy')) {
        return !!(key as Filters).tags?.some(
          (tag) => tag.tagId === tagId && types.some((type) => tag.types.includes(type))
        )
      }
      return false
    })
  }
  return false
}

export const TagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { hasuraClient } = useHasura()
  const { user } = useAuth0()
  const queryClient = useQueryClient()

  const { data: tags, isFetching } = useQuery(
    [TAGS_QUERY_KEY],
    async () => {
      return await hasuraClient?.getTags()
    },
    {
      enabled: !!hasuraClient
    }
  )

  const { archivedTags, globalTags, personalTags, sharedTags, tagsMap, tagsFilterByType } = useMemo(() => {
    const archivedTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const globalTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const personalTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const sharedTags: SetRequired<TagWithCount, 'itemsCount'>[] = []
    const tagsMap: Record<string, { tag: TagWithCount; context: TagContext }> = {}
    const tagsFilterByType: Record<TagFilterTarget, number> = {
      brand: 0,
      dispensary: 0,
      product: 0
    }
    if (tags) {
      const sortedTags = toSorted(tags, sortTags)
      for (const tag of sortedTags) {
        let context: TagContext = 'global'
        if (!tag.userId) {
          globalTags.push(tag)
        } else {
          TAG_FILTER_TARGETS.forEach((target) => {
            tagsFilterByType[target] += tag.itemsCount[target]
          })
          if (tag.archivedAt) {
            archivedTags.push(tag)
          }
          if (tag.userId === user?.sub) {
            context = 'personal'
            !tag.archivedAt && personalTags.push(tag)
          } else {
            context = 'shared'
            !tag.archivedAt && sharedTags.push(tag)
          }
        }
        tagsMap[tag.id] = { tag, context }
      }
    }
    return {
      archivedTags,
      globalTags,
      personalTags,
      sharedTags,
      tagsMap,
      tagsFilterByType
    }
  }, [tags, user])

  const getTargetItemTagsCount = useCallback(
    (target: TagFilterTarget) => tagsFilterByType[target] ?? 0,
    [tagsFilterByType]
  )

  const getTagLabel = useCallback((tagId: string) => tagsMap[tagId]?.tag.label, [tagsMap])

  const getTagByLabel = useCallback(
    (tagLabel: string, options?: GetByLabelOptions) => {
      return (options?.personalTagsOnly ? personalTags : tags)?.find(({ label }) => label === tagLabel)
    },
    [tags, personalTags]
  )

  const getTagById = useCallback(
    (tagId: string) => {
      const tag = tagsMap[tagId]
      return tag?.context !== 'global' ? tag?.tag : undefined
    },
    [tagsMap]
  )

  const clearTagCache = useCallback(
    async (tag: Tag) => {
      await Promise.all([
        // Update tag in cache
        queryClient.setQueryData<Tag[]>(
          [TAGS_QUERY_KEY],
          (prevState) => prevState?.map((t) => (t.id === tag.id ? tag : t)) ?? []
        ),
        // Update tag data in all ItemTagWithTag queries
        queryClient.setQueriesData(
          {
            predicate: ({ queryKey }) => queryKey[0] === TAG_ITEMS_QUERY_KEY && queryKey.length > 0
          },
          (data?: ItemTagWithTag[]) => {
            return data?.map((it) => {
              return it.tag.id === tag.id ? { ...it, tag: tag } : it
            })
          }
        ),
        // Should invalidate item tags check on the import validation
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === tag.id && queryKey.length > 2,
          refetchType: 'none'
        })
      ])
    },
    [queryClient]
  )

  const upsertTag = useCallback(
    async (tag: DraftTag, itemTagsToRemove?: RequiredItemTag[]) => {
      if (hasuraClient) {
        const { items, ...updatedTag } = tag.id
          ? await hasuraClient.updateTag(tag as TagWithItems, itemTagsToRemove)
          : await hasuraClient.insertTag(tag)

        const newItems: ItemTag[] = []
        // Update the query cache with the new tag
        if (updatedTag) {
          if (!tag.id) {
            // Adding new tag to cache
            queryClient.setQueryData<Tag[]>([TAGS_QUERY_KEY], (prevState) => [...(prevState ?? []), updatedTag])
            newItems.push(...items)
          } else {
            const removedIds = itemTagsToRemove?.map((it) => it.itemTagId) ?? []
            if (tag.items?.length) {
              newItems.push(
                ...(tag.items
                  .filter((it) => it.itemTagId === undefined)
                  .map((it) => items.find((i) => tagKeys.every((key) => i[key] === it[key]))) as ItemTag[])
              )
            }
            const newAndRemovedGroupedItems = groupItemTagsByType([...newItems, ...(itemTagsToRemove ?? [])])
            const affectedTypes = Object.keys(newAndRemovedGroupedItems) as TagFilterTarget[]

            await Promise.all([
              // Update caches related to the tag
              clearTagCache(updatedTag),
              // Update useTagItems cache
              queryClient.setQueriesData<RequiredItemTag[]>(
                { queryKey: [TAG_ITEMS_KEY, tag.id], exact: true },
                (data) => [
                  // Filter out the item tags that are being removed
                  ...(data?.filter((it) => !removedIds.includes(it.itemTagId)) || []),
                  // Add the new item tags
                  ...(items as RequiredItemTag[]).filter((it) => !data?.some((item) => item.itemTagId === it.itemTagId))
                ]
              ),
              // If there's new tags or tags to remove it should invalidate the open search queries that uses the tag
              affectedTypes.length
                ? queryClient.invalidateQueries({
                    predicate: ({ queryKey }) =>
                      shouldInvalidateOpenSearch({ queryKey, tagId: updatedTag.id, types: affectedTypes }),
                    refetchType: 'none'
                  })
                : undefined,
              // Remove the item from the item tag queries
              itemTagsToRemove?.length
                ? queryClient.setQueriesData(
                    {
                      predicate: ({ queryKey }) => shouldUpdateTagItemsQuery({ queryKey, itemTags: itemTagsToRemove })
                    },
                    (data?: ItemTagWithTag[]) =>
                      data?.filter((it) => !itemTagsToRemove.some((item) => it.itemTagId === item.itemTagId))
                  )
                : undefined
            ])
          }

          // Update the query cache with the new item tags
          await Promise.all(
            newItems.map((itemTag) => {
              return queryClient.setQueriesData(
                {
                  predicate: ({ queryKey }) => shouldUpdateTagItemsQuery({ queryKey, itemTags: [itemTag] })
                },
                (data?: ItemTagWithTag[]) => {
                  const result = [...(data || [])]
                  const newItem: ItemTagWithTag = { ...itemTag, tag: updatedTag }
                  return [...result, newItem]
                }
              )
            })
          )
        }
        return { ...updatedTag, items }
      }
    },
    [hasuraClient, queryClient, clearTagCache]
  )

  const toggleArchiveTag = useCallback(
    async (tag: Tag) => {
      if (hasuraClient) {
        const updatedTag = !tag.archivedAt
          ? await hasuraClient.archiveTag(tag.id)
          : await hasuraClient.restoreTag(tag.id)
        if (updatedTag) {
          await clearTagCache(updatedTag)
        }
        return updatedTag
      }
    },
    [hasuraClient, clearTagCache]
  )

  const removeTagFromItem = useCallback(
    async (itemTag: SetRequired<ItemTag, 'itemTagId'>, tagId: string) => {
      const removed = await hasuraClient?.removeItemTag(itemTag.itemTagId)
      if (removed) {
        const tag = getTagById(tagId)
        const type = getItemTagType(itemTag)
        await Promise.all([
          // Decrement one item from the item count of the tag
          queryClient.setQueryData<SetRequired<TagWithCount, 'itemsCount'>[]>(
            [TAGS_QUERY_KEY],
            (prevState) => prevState?.map((t) => (t.id === tag?.id ? decreaseItemsCount(t, type) : t)) ?? []
          ),
          // Remove the item from the item tag queries
          queryClient.setQueriesData(
            {
              predicate: ({ queryKey }) => shouldUpdateTagItemsQuery({ queryKey, itemTags: [itemTag] })
            },
            (data?: ItemTagWithTag[]) => data?.filter((it) => it.itemTagId !== itemTag.itemTagId)
          ),
          ...(tag?.id
            ? [
                // Update useTagItems cache
                queryClient.setQueriesData<ItemTag[]>({ queryKey: [TAG_ITEMS_KEY, tag.id], exact: true }, (items) => {
                  return items?.filter((item) => item.itemTagId !== itemTag.itemTagId)
                }),
                queryClient.invalidateQueries({
                  predicate: ({ queryKey }) =>
                    shouldInvalidateOpenSearch({ queryKey, tagId: tag.id, types: [type as TagFilterTarget] }),
                  refetchType: 'none'
                }),
                // Should invalidate item tags check on the import validation
                queryClient.invalidateQueries({
                  predicate: ({ queryKey }) =>
                    queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === tag.id && queryKey.length > 2,
                  refetchType: 'none'
                })
              ]
            : [])
        ])
      }
      return !!removed
    },
    [hasuraClient, queryClient, getTagById]
  )

  const deleteTag = useCallback(
    async (tag: Tag) => {
      const items = await hasuraClient?.getTagItems(tag.id)
      try {
        const deleted = await hasuraClient?.deleteTag(tag.id)
        if (deleted) {
          await Promise.all([
            // Remove the tag from the query cache
            queryClient.setQueryData<Tag[]>([TAGS_QUERY_KEY], (prevState) => prevState?.filter((t) => t.id !== tag.id)),
            // Update useTagItems cache
            queryClient.setQueriesData({ queryKey: [TAG_ITEMS_KEY, tag.id], exact: true }, []),
            // Should invalidate item tags check on the import validation
            queryClient.invalidateQueries({
              predicate: ({ queryKey }) =>
                queryKey[0] === TAG_ITEMS_KEY && queryKey[1] === tag.id && queryKey.length > 2,
              refetchType: 'none'
            }),
            // Remove the related item tags from all query caches
            items?.length
              ? queryClient.setQueriesData(
                  {
                    predicate: ({ queryKey }) => shouldUpdateTagItemsQuery({ queryKey, itemTags: items })
                  },
                  (data?: ItemTagWithTag[]) =>
                    data?.filter((it) => !items.some((item) => it.itemTagId === item.itemTagId))
                )
              : undefined
          ])
        }
        return !!deleted
      } catch (e) {
        return false
      }
    },
    [queryClient, hasuraClient]
  )

  return (
    <TagsContext.Provider
      value={{
        isFetching,
        archivedTags,
        globalTags,
        personalTags,
        sharedTags,
        deleteTag,
        getTagById,
        getTagLabel,
        getTagByLabel,
        getTargetItemTagsCount,
        removeTagFromItem,
        toggleArchiveTag,
        upsertTag
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

export const useTags = () => {
  const context = useContext(TagsContext)

  if (!context) {
    throw new Error('useTags must be used within a TagsProvider.')
  }

  return context
}
