import { MuiIcon } from '@client/styles/global'

export const DEFAULT_HELP_SORT_ORDER = 20

export type HelpLinkData = {
  tooltip: string
  helpLink: string
  icon?: MuiIcon
  /** Order in which the help link should be displayed (0-100)
   * @default 20
   */
  order?: number
}
