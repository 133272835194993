import { Backdrop as MuiBackdrop, SpeedDial as MuiSpeedDial, SpeedDialAction } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import styled from 'styled-components'

const FAB_SIZE = 56
const SPEEDDIAL_MARGIN = 48

export const Backdrop = styled(MuiBackdrop)`
  padding: ${({ theme }) => theme.spacing(2, 6, 4)};
  align-items: flex-end;
  justify-content: flex-end;
  background-color: rgb(0 0 0 / 85%);
  /* Make sure the backdrop is above the sidebar */
  z-index: 1201;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(2, 2.5, 0)};
  }
`

export const SpeedDial = styled(MuiSpeedDial)<{ $linksCount: number }>`
  > .MuiFab-root {
    display: none;
  }
  .MuiSpeedDial-actions {
    align-items: stretch;
    .MuiSpeedDialAction-staticTooltip {
      justify-content: flex-end;
    }
    .MuiSpeedDialAction-staticTooltipLabel {
      position: relative;
      right: 0;
      white-space: normal;
      text-align: right;
      max-width: calc(100vw - ${SPEEDDIAL_MARGIN}px - ${FAB_SIZE}px);
      cursor: pointer;
    }
    &::after {
      content: 'Help on this page';
      display: block;
      color: ${({ theme }) => theme.palette.lightest};
      font-weight: bold;
      white-space: nowrap;
      text-align: right;
      padding-bottom: ${({ theme }) => theme.spacing(2)};
      padding-right: ${({ theme }) => theme.spacing(2)};
      transform: scale(1);
      opacity: 1;
      transform-origin: 100% 50%;
      transition: ${({ theme }) =>
        theme.transitions.create(['transform', 'opacity'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter
        })};
      transition-delay: ${({ $linksCount }) => `${$linksCount * 30}ms`};
    }
    &.MuiSpeedDial-actionsClosed::after {
      transition-delay: 0ms;
      transform: scale(0);
      opacity: 0;
    }
  }
`

export const FeedbackSpeedDialAction = styled(SpeedDialAction)`
  background-color: ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.lightest};
  &:hover {
    background-color: ${({ theme }) => theme.palette.success.dark} !important;
  }
`
export const HelpSpeedDialAction = styled(SpeedDialAction)`
  background-color: ${({ theme }) => theme.palette.help.main};
  color: ${({ theme }) => theme.palette.lightest};
  &:hover {
    background-color: ${({ theme }) => theme.palette.help.dark} !important;
  }
`

export const useSpeedDialDivisor = makeStyles(() =>
  createStyles({
    staticTooltip: {
      '&:not(:last-child)': {
        marginTop: 8,
        paddingTop: 8,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 8,
          borderTop: '1px solid rgba(255, 255, 255, .3)',
          width: 'calc(100% - 8px)'
        }
      }
    }
  })
)
