import { useAuth0 } from '@auth0/auth0-react'
import { TrackableButton, TrackableRefIconButton } from '@client/components/Trackables'
import { AccessControl } from '@client/components/access-control/AccessControl'
import { DialogPopover } from '@client/components/dialog/Dialog'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { useNotifications } from '@client/providers/notifications'
import { useUser } from '@client/providers/user'
import { PulseBadge } from '@client/styles/global'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Permission } from '@lib/types/permission'
import { Box, Button, Checkbox, FormControlLabel, List, Paper, Tooltip, Typography } from '@mui/material'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Bell } from 'react-feather'
import { HelpLink } from '../help-link/HelpLink'
import { Notification, NotificationSkeleton } from './Notification'
import * as S from './NotificationsDropdown.style'

const gaEvent = { action: TrackableAction.notificationDropdownOpened, category: TrackableCategory.navigation }

const whatsNewGaEvent = {
  action: TrackableAction.whatsNewButtonClicked,
  category: TrackableCategory.navigation
}

const markAllAsReadEvent = {
  action: TrackableAction.notificationMarkAllAsReadClicked,
  category: TrackableCategory.notifications
}

export type NotificationsDropdownProps = Record<string, never>

export const NotificationsDropdown: React.FC<NotificationsDropdownProps> = React.memo(function NotificationsDropdown() {
  const { isMobile } = useResponsiveState()

  const ref = useRef<HTMLButtonElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { userData } = useUser()
  const { user } = useAuth0()
  const { value: isOpen, setTrue: handleOpen, setFalse: handleClose } = useBooleanState(false)
  const { value: onlyUnread, setFalse: displayAll, toggleValue: toggleAllUnread } = useBooleanState(false)
  const [unreadBeamerPosts, setUnreadBeamerPosts] = useState(0)
  const { value: isBeamerInitialized, setTrue: setBeamerInitialized } = useBooleanState(false)
  const {
    isLoading,
    notifications,
    totalUnread,
    hasMoreRecords,
    increaseLimit,
    changeNotificationStatus,
    markAllNotificationsAsRead,
    setFilterByUnread
  } = useNotifications()
  const { eventTracker } = useEventTracker()
  const { settingsPreferencesKeys, deleteNotificationPreference, getChannelsByPreferenceId } =
    useNotificationPreferences()
  const { toast } = useToastNotification()

  useEffect(() => {
    setFilterByUnread(onlyUnread)
    scrollRef.current?.scrollTo(0, 0)
  }, [onlyUnread, setFilterByUnread])

  useEffect(() => {
    if (!isOpen || !totalUnread) {
      displayAll()
    }
  }, [isOpen, totalUnread, displayAll])

  useEffect(() => {
    const beamerConfig = {
      user_firstname: userData?.fname || undefined,
      user_lastname: userData?.lname || undefined,
      user_email: user?.email || undefined,
      user_id: user?.sub || undefined
    }
    window.Beamer?.update(beamerConfig)
  }, [user?.email, user?.sub, userData?.fname, userData?.lname])

  const onBeamerInitialized = (numPosts: number) => {
    setUnreadBeamerPosts(numPosts)
    setBeamerInitialized()
  }

  const onMarkRead = useCallback(
    (notification: Notification, close?: boolean) => {
      close && handleClose()
      !notification.isRead && changeNotificationStatus(notification, true)
    },
    [handleClose, changeNotificationStatus]
  )

  const onUnsubscribe = useCallback(
    (preferencesKey: string) => {
      if (preferencesKey) {
        return deleteNotificationPreference(preferencesKey).then((deleted) => {
          if (deleted) {
            toast.success('Alert preferences updated')
            eventTracker({
              action: TrackableAction.notificationPreferenceDeleted,
              category: TrackableCategory.notifications,
              dimension1: 'from-notification-menu'
            })
          } else {
            toast.error('Could not delete alert')
          }
        })
      }
      return Promise.resolve()
    },
    [deleteNotificationPreference, eventTracker, toast]
  )

  const parseNotification = useCallback(
    (notification: Notification) => {
      if (notification.notificationPreferenceId && settingsPreferencesKeys[notification.notificationPreferenceId]) {
        return {
          ...notification,
          channels: getChannelsByPreferenceId(notification.notificationPreferenceId)
        }
      }
      return notification
    },
    [getChannelsByPreferenceId, settingsPreferencesKeys]
  )

  useEffect(() => {
    window.Beamer?.update({ callback: onBeamerInitialized, onopen: () => setUnreadBeamerPosts(0) })
    window.Beamer?.init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWhatsNewClick = useCallback(() => {
    window.Beamer?.show()
    handleClose()
  }, [handleClose])

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <TrackableRefIconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
          size="large"
          gaEvent={gaEvent}
          sx={{ p: 2 }}
        >
          <S.Indicator badgeContent={totalUnread || null}>
            <PulseBadge
              color="success"
              badgeContent={unreadBeamerPosts || null}
              invisible={!unreadBeamerPosts}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <Bell />
            </PulseBadge>
          </S.Indicator>
        </TrackableRefIconButton>
      </Tooltip>
      <DialogPopover isOpen={isOpen} onClose={handleClose} dialogTitle="Notifications" currentRef={ref}>
        <S.NotificationHeader pt={4} pb={1} px={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" color="textPrimary">
              {totalUnread} new {pluralize('notification', totalUnread)}
            </Typography>
            <HelpLink
              helpLink="sales-enablement/alerts#viewing-alerts"
              tooltip="Help on viewing alerts"
              buttonProps={{
                sx: { ml: 'auto' }
              }}
            />
            {isBeamerInitialized && (
              <S.Indicator badgeContent={unreadBeamerPosts || null}>
                <S.WhatsNewButton id="whats-new" onClick={handleWhatsNewClick} gaEvent={whatsNewGaEvent}>
                  What&apos;s new
                </S.WhatsNewButton>
              </S.Indicator>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FormControlLabel
              disabled={!totalUnread}
              control={<Checkbox size="small" color="secondary" checked={onlyUnread} onChange={toggleAllUnread} />}
              label={<S.UnreadLabel>Show only unread</S.UnreadLabel>}
            />
            <TrackableButton
              onClick={markAllNotificationsAsRead}
              gaEvent={markAllAsReadEvent}
              size="small"
              color="secondary"
              disabled={!totalUnread}
              sx={{ px: 2 }}
              data-testid="notifications-mark-all-as-read"
            >
              Mark all as read
            </TrackableButton>
          </Box>
        </S.NotificationHeader>
        <AccessControl
          permission={Permission.NOTIFICATION_PREFERENCE_CREATE}
          emailSubject="Notifications access request"
          variant="outlined"
          message="Notifications are not included with your account. Please contact Hoodie Analytics if you'd like to add this feature."
        >
          <Paper
            sx={{
              maxHeight: isMobile ? undefined : 385,
              overflow: 'auto',
              border: 'none',
              boxShadow: 'none',
              borderTop: '2px solid',
              borderRadius: 0
            }}
            ref={scrollRef}
          >
            <List disablePadding>
              {notifications.map((notification) => (
                <Notification
                  key={notification.notification_id}
                  notification={parseNotification(notification)}
                  preferencesKey={
                    notification.notificationPreferenceId
                      ? settingsPreferencesKeys[notification.notificationPreferenceId]
                      : undefined
                  }
                  onMarkRead={onMarkRead}
                  onUnsubscribe={onUnsubscribe}
                />
              ))}
              {!!isLoading && <NotificationSkeleton />}
            </List>
          </Paper>
          <Box p={1} display="flex" justifyContent="center">
            <Button
              size="small"
              onClick={increaseLimit}
              disabled={!hasMoreRecords}
              data-testid="notifications-load-more"
            >
              Load more notifications
            </Button>
          </Box>
        </AccessControl>
      </DialogPopover>
    </React.Fragment>
  )
})
