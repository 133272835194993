import { Box as MuiBox } from '@mui/material'
import { borders, display, flexbox, grid, palette, positions, shadows, sizing, spacing, typography } from '@mui/system'
import styled from 'styled-components'

// TODO: The Material UI box should _already_ provide these styles - but it doesn't!

export const Box = styled(MuiBox)`
  ${borders}
  ${display}
  ${flexbox}
  ${grid}
  ${palette}
  ${positions}
  ${shadows}
  ${sizing}
  ${spacing}
  ${typography}
`
