import {
  CollapsibleIconButton,
  CollapsibleIconButtonProps as ExposedCollapsibleIconButtonProps
} from '@client/components/CollapsibleIconButton/CollapsibleIconButton'
import { MenuItem } from '@client/components/menu'
import { withChangeEventTracker } from '@client/hoc/withChangeEventTracker'
import {
  ClickEventTrackerBaseProps,
  WithLinkComponentProps,
  withClickEventTracker
} from '@client/hoc/withClickEventTracker'
import { PopupMenuItem } from '@client/styles/theme/menu'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  Button,
  CardActionArea,
  IconButton,
  IconButtonProps,
  Link,
  LinkProps,
  ListItemButton,
  Tabs
} from '@mui/material'
import { ComponentProps, forwardRef } from 'react'
import { NavLink, NavLinkProps, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

// onChange trackable components
export const TrackableTabs = withChangeEventTracker(Tabs)

type WithTarget<T> = T & { target?: string }

// Temp work-around for the fact that 'withClickEventTracker' does not seem to expose props of underlying native element,
// in this case, 'a'.
type ButtonProps = WithTarget<ComponentProps<typeof Button>>
type CollapsibleIconButtonProps = WithTarget<ExposedCollapsibleIconButtonProps>

interface CardActionAreaProps extends ComponentProps<typeof CardActionArea> {
  href?: string
}

// onClick trackable components
export const TrackableButton = withClickEventTracker<ButtonProps>(Button)
export const TrackableCardActionArea = withClickEventTracker<CardActionAreaProps>(CardActionArea)
export const TrackableCardActionAreaLink = withClickEventTracker<WithLinkComponentProps>(CardActionArea)
export const TrackableIconButton = withClickEventTracker(IconButton)
export const TrackableCollapsibleIconButton = withClickEventTracker<CollapsibleIconButtonProps>(CollapsibleIconButton)
export const TrackableLink = withClickEventTracker(Link)
export const TrackableListItemButton = withClickEventTracker(ListItemButton)
export const TrackableLoadingButton = withClickEventTracker<LoadingButtonProps>(LoadingButton)
export const TrackableNavLink = withClickEventTracker(NavLink)
export const TrackablePopupMenuItem = withClickEventTracker(PopupMenuItem)
export const TrackableRouterLink = withClickEventTracker(RouterLink)
export const TrackableMenuItem = withClickEventTracker(MenuItem)

// Trackable components with forwardRef ability
export const TrackableRefButton = forwardRef<HTMLButtonElement, ButtonProps & ClickEventTrackerBaseProps>(
  function TrackableForwardIconButton(props, ref) {
    return <TrackableButton {...props} innerRef={ref} />
  }
)
export const TrackableRefLink = forwardRef<HTMLAnchorElement, LinkProps & ClickEventTrackerBaseProps>(
  function TrackableForwardLink(props, ref) {
    return <TrackableLink {...props} innerRef={ref} />
  }
)
export const TrackableRefRouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps & ClickEventTrackerBaseProps>(
  function TrackableForwardRouterLink(props, ref) {
    return <TrackableRouterLink {...props} innerRef={ref} />
  }
)
export const TrackableRefNavLink = forwardRef<HTMLAnchorElement, NavLinkProps & ClickEventTrackerBaseProps>(
  function TrackableForwardNavLink(props, ref) {
    return <TrackableNavLink {...props} innerRef={ref} />
  }
)
export const TrackableRefIconButton = forwardRef<HTMLButtonElement, IconButtonProps & ClickEventTrackerBaseProps>(
  function TrackableForwardIconButton(props, ref) {
    return <TrackableIconButton {...props} innerRef={ref} />
  }
)
export const TrackableRefCollapsibleIconButton = forwardRef<
  HTMLButtonElement,
  CollapsibleIconButtonProps & ClickEventTrackerBaseProps
>(function TrackableForwardCollapsibleIconButton(props, ref) {
  return <TrackableCollapsibleIconButton {...props} innerRef={ref} />
})
