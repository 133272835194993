import { HasuraService, UserDataKeys } from '@client/services/hasura'
import { KpiSettingsItem, VisibleKpiSettingsItem, comparisonKPIs } from '@client/types/comparison'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface KpiSettingsStore {
  savedSettings: KpiSettingsItem[]
  computed: { visibleKpis: VisibleKpiSettingsItem[] }
  setSavedSettings: (items: KpiSettingsItem[], hasuraClient?: HasuraService | null) => void
}

export const initialKpiSettings = [...comparisonKPIs.entries()].map(([kpi, { label }], index) => ({
  kpi,
  label,
  isActive: true,
  order: index
}))

export const useKpiSettingsStore = create(
  devtools<KpiSettingsStore>(
    (set, get) => ({
      savedSettings: initialKpiSettings,
      computed: {
        get visibleKpis() {
          return (
            (get()
              ?.savedSettings.filter(({ isActive }) => isActive)
              .sort(({ order: a }, { order: b }) => a - b)
              .map(({ kpi }) => ({ kpi, ...comparisonKPIs.get(kpi) })) as Array<VisibleKpiSettingsItem>) ?? []
          )
        }
      },
      setSavedSettings: (newSettings, hasuraClient) => {
        let savedSettings = [...newSettings]
        // Make sure the initial settings are always present in case we add new KPIs
        if (!hasuraClient) {
          // Remove the settings that are not in the initial settings anymore
          savedSettings = newSettings.filter(({ kpi }) =>
            initialKpiSettings.find(({ kpi: initialKpi }) => initialKpi === kpi)
          )
          const hasDeletedKpis = savedSettings.length !== newSettings.length
          // If the saved settings are not the same length as the initial settings, it means we added new KPIs
          const hasNewKpis = savedSettings.length !== initialKpiSettings.length
          if (hasNewKpis) {
            savedSettings.push(
              ...initialKpiSettings.filter(({ kpi }) => !savedSettings.find(({ kpi: savedKpi }) => savedKpi === kpi))
            )
          }
          // Fix the sort order if necessary
          if (hasDeletedKpis || hasNewKpis) {
            savedSettings = savedSettings.map((setting, index) => ({ ...setting, order: index }))
          }
        }
        // If passed a hasuraClient, save the settings to the user's profile
        hasuraClient?.setUserDataByKey(
          UserDataKeys.DispensaryAnalyticsKPISettings,
          savedSettings,
          'SaveMenuAnalyticsKPISettings'
        )
        // Update the store
        set({ savedSettings })
      }
    }),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: 'comparison-kpi-settings'
    }
  )
)
