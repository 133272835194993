import { useEffect } from 'react'

export const useHandleCloseEventErrors = () => {
  useEffect(() => {
    const handleErrors = (event: ErrorEvent) => {
      if (event.error instanceof CloseEvent) {
        console.warn('Caught a CloseEvent error:', event.error)
        event.preventDefault()
      }
    }
    window.addEventListener('error', handleErrors, true)
    return () => {
      window.removeEventListener('error', handleErrors, true)
    }
  }, [])
}
