import { Permission } from '@lib/types/permission'
import { AccountSummary } from './account'

export const NEW_URL_KEY = 'new'

export const editRelationshipsPermissions = [Permission.RELATIONSHIPS_EDIT, Permission.HOODIE_SUPER_ADMIN]

const RelationshipTypes = ['POS', 'WHOLESALE']

export const RelationshipTypeLabels: Record<RelationshipType, string> = {
  POS: 'Point of Sale Systems',
  WHOLESALE: 'Wholesale'
}

export type RelationshipType = (typeof RelationshipTypes)[number]

// A relationship is 'inbound' if 'toAccount' is the user's account
// and 'outbound' if 'fromAccount' is the user's account
export type RelationshipDirection = 'OUTBOUND' | 'INBOUND'

export type Relationship = {
  id: string
  fromAccount: AccountSummary
  toAccount: AccountSummary
  direction: RelationshipDirection
  name: string
  type: RelationshipType
}

export type DraftRelationship = Partial<Relationship>

export const emptyDraftRelationship = (): DraftRelationship => ({})
