import { SearchHit } from '@client/components/global-search/components/search-hit/SearchHit'
import { SearchableHit, SearchableIndex } from '@client/components/global-search/types'
import { slugify } from '@client/helpers/strings'
import {
  BRAND_INDEX,
  BrandHit,
  DISPENSARY_INDEX,
  DispensaryHit,
  ProductHit,
  VARIANT_INDEX
} from '@client/services/algolia'
import { Divider, List, ListItem, ListItemText, ListSubheader } from '@mui/material'
import * as S from './SearchResults.style'

export interface SearchResultsProps {
  products?: ProductHit[]
  dispensaries?: DispensaryHit[]
  brands?: BrandHit[]
}

interface IndexSearchResultsProps {
  index: SearchableIndex
  header: string
  hits: Array<SearchableHit>
}

const IndexSearchResults: React.FC<IndexSearchResultsProps> = ({ index, header, hits }) => (
  <List
    data-testid={`search-results-${slugify(header)}`}
    key={index}
    subheader={<ListSubheader id="nested-list-subheader">{header}</ListSubheader>}
  >
    {hits.length ? (
      hits.map((hit) => <SearchHit key={hit.objectID} index={index} hit={hit} />)
    ) : (
      <ListItem>
        <ListItemText primary="No matches" />
      </ListItem>
    )}
  </List>
)

export const SearchResults: React.FC<SearchResultsProps> = ({ products, dispensaries, brands }) => {
  return dispensaries || brands || products ? (
    <S.Wrapper>
      <IndexSearchResults header="Dispensaries" index={DISPENSARY_INDEX} hits={dispensaries || []} />
      <Divider />
      <IndexSearchResults header="Brands" index={BRAND_INDEX} hits={brands || []} />
      <Divider />
      <IndexSearchResults header="Products" index={VARIANT_INDEX} hits={products || []} />
    </S.Wrapper>
  ) : null
}
