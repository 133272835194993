import { slugify } from '@client/helpers/strings'
import { ChipThemeColor } from '@client/styles/theme/MUI/theme-colors'
import { Box } from '@client/styles/theme/box'
import { typography } from '@client/styles/theme/typography'
import { BoxProps, Button, Chip, Tooltip, TooltipProps, Typography } from '@mui/material'
import React, { ReactElement, ReactNode, memo, useCallback, useMemo, useState } from 'react'

type DelimitedItem = {
  id: string
  title: string
}

interface ToggleProps {
  onToggle: (item: string) => void
  toggledValue: string | undefined
  toggledIcon: ReactElement
  unToggledIcon: ReactElement
  toggleTooltipText: string
  unToggleTooltipText: string
}
interface DelimitedTagListProps extends BoxProps {
  list: Array<string | DelimitedItem>
  delimiter?: ReactNode
  color?: ChipThemeColor
  maxWidth?: number
  TooltipProps?: Omit<TooltipProps, 'title'>
  onRemove?: (item: string) => void
  onClearAll?: () => void
  /**
   * Render icon is ignored when toggleProps is defined
   */
  renderIcon?: (id: string) => ReactElement | undefined
  toggleProps?: ToggleProps
}

export const DelimitedTagList: React.FC<DelimitedTagListProps> = memo(function DelimitedTagList({
  color = 'success',
  delimiter = <Typography fontSize={typography.size.xs3}>vs</Typography>,
  list,
  maxWidth,
  onRemove,
  onClearAll,
  renderIcon,
  TooltipProps,
  toggleProps,
  ...props
}) {
  const [chipTooltipOpen, setChipTooltipOpen] = useState<string | undefined>(undefined)
  const [iconTooltipOpen, setIconTooltipOpen] = useState<string | undefined>(undefined)

  const formattedList = useMemo(
    () =>
      (typeof list?.[0] === 'string'
        ? list.map((item) => ({
            id: item,
            title: item
          }))
        : list) as DelimitedItem[],
    [list]
  )

  const getColor = useCallback(
    (id: string) => {
      if (!toggleProps?.toggledValue) {
        return color
      }
      return toggleProps?.toggledValue === id ? color : 'default'
    },
    [color, toggleProps?.toggledValue]
  )

  const iconDisplay = useCallback(
    (id: string) => {
      if (!toggleProps?.onToggle) {
        return renderIcon?.(id)
      }

      const isToggled = toggleProps.toggledValue === id
      const tooltipText = isToggled ? toggleProps.unToggleTooltipText : toggleProps.toggleTooltipText
      const icon = isToggled ? toggleProps.toggledIcon : toggleProps.unToggledIcon

      return (
        <Tooltip
          title={tooltipText}
          placement="top"
          arrow
          enterTouchDelay={0}
          onOpen={() => {
            setIconTooltipOpen(id)
          }}
          onClose={() => setIconTooltipOpen(undefined)}
        >
          <Box onClick={() => toggleProps.onToggle(id)} display="flex" data-testid={`toggle-icon-${id}`}>
            {icon}
          </Box>
        </Tooltip>
      )
    },
    [renderIcon, toggleProps]
  )

  return formattedList.length ? (
    <Box display="flex" alignItems="center" gap={1} flexWrap="wrap" {...props}>
      {formattedList.map(({ id, title }, index) => (
        <React.Fragment key={id}>
          {index > 0 && delimiter}
          <Tooltip
            // Only show tooltip if name is too long (best-guess!)
            title={maxWidth && title.length > Math.ceil(maxWidth / 8) ? title : ''}
            placement="bottom"
            arrow
            enterTouchDelay={0}
            {...TooltipProps}
            open={chipTooltipOpen === id && !iconTooltipOpen}
            onClose={() => setChipTooltipOpen(undefined)}
            onOpen={() => {
              setChipTooltipOpen(id)
            }}
          >
            <Chip
              icon={iconDisplay(id)}
              size="small"
              color={toggleProps ? getColor(id) : color}
              label={title}
              sx={{ mr: 0, maxWidth: maxWidth ? `${maxWidth}px !important` : undefined, fontSize: typography.size.xs3 }}
              onDelete={onRemove ? () => onRemove(id) : undefined}
              aria-label={`delimited-item--${slugify(title)}`}
            />
          </Tooltip>
        </React.Fragment>
      ))}
      {!!onClearAll && (
        <Button
          size="small"
          variant="text"
          onClick={onClearAll}
          sx={{ fontSize: typography.size.xs3 }}
          data-testid="clear-all-delimited-tags"
        >
          Clear All
        </Button>
      )}
    </Box>
  ) : null
})
