import { GlobalSearch } from '@client/components/global-search/GlobalSearch'
import { NotificationsDropdown } from '@client/components/notifications-dropdown/NotificationsDropdown'
import { UserAvatar } from '@client/components/user-avatar'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useFeatureToggle } from '@client/hooks/use-feature-toggle'
import { usePermission } from '@client/hooks/use-permissions'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { APP_BAR_LEFT_BUTTONS_REF, useRefsStore } from '@client/stores/use-refs-store'
import { Box } from '@client/styles/theme/box'
import { Permission } from '@lib/types/permission'
import { Menu as MenuIcon } from '@mui/icons-material'
import {
  Grid,
  IconButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Slide,
  Toolbar,
  useScrollTrigger
} from '@mui/material'
import clsx from 'clsx'
import React, { FC, PropsWithChildren, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts'
import { ErrorBoundary } from '../error-boundary/ErrorBoundary'
import * as S from './AppBar.style'
import { FavoritesDrawerButton } from './components/favorites-drawer-button'

const GLOBAL_SEARCH_ROUTE_REGEXS = [/^\/brand\/sales-enablement/, /^\/brands/, /^\/products/, /^\/dispensaries/]

const AppBarScrollListener: FC<PropsWithChildren<{ isSearchActive: boolean; children: React.ReactElement }>> = ({
  isSearchActive: forceShow,
  children
}) => {
  const hideTrigger = useScrollTrigger({ target: window ?? undefined })
  return (
    <Slide appear={false} direction="down" in={!hideTrigger || forceShow}>
      {children}
    </Slide>
  )
}

export type AppBarProps = MuiAppBarProps & {
  isDrawerOpen: boolean
  onOpenDrawer: () => void
}

const AppBarComponent: FC<PropsWithChildren<AppBarProps>> = ({ isDrawerOpen, onOpenDrawer, ...rest }) => {
  const { isMobile } = useResponsiveState()
  const { hasPermission: hasSEPermission } = usePermission(Permission.MODULE_ACCESS_SALES_ENABLEMENT)
  const classes = S.useStyles()
  const { isEnabled, toggles } = useFeatureToggle()
  const location = useLocation()
  const showSearchBar = useMemo(() => {
    if (!hasSEPermission) {
      return false
    }
    return GLOBAL_SEARCH_ROUTE_REGEXS.some((regex) => regex.test(location.pathname)) && isEnabled(toggles.global_search)
  }, [hasSEPermission, isEnabled, location.pathname, toggles.global_search])
  const { value: isSearchActive, setTrue: onActivateSearch, setFalse: onDeactivateSearch } = useBooleanState(false)
  const displayButtons = useMemo(() => !isSearchActive || !isMobile, [isMobile, isSearchActive])

  const customAppButtonsRef = useRef<HTMLDivElement>(null)
  const setRef = useRefsStore((state) => state.setRef)
  const removeRef = useRefsStore((state) => state.removeRef)

  // Set the ref for the custom app bar buttons container work as portal, so we can render the buttons outside the AppBar on it
  useEffectOnce(() => {
    setRef(APP_BAR_LEFT_BUTTONS_REF, customAppButtonsRef)
    return () => {
      removeRef(APP_BAR_LEFT_BUTTONS_REF)
    }
  })

  useUpdateEffect(() => {
    if (displayButtons) {
      setRef(APP_BAR_LEFT_BUTTONS_REF, customAppButtonsRef)
    } else {
      removeRef(APP_BAR_LEFT_BUTTONS_REF)
    }
  }, [displayButtons])
  return (
    <AppBarScrollListener isSearchActive={isSearchActive}>
      <MuiAppBar
        position="fixed"
        className={clsx(classes.appBarBase, {
          [classes.appBarDesktop]: !isMobile,
          [classes.appBarShift]: isDrawerOpen && !isMobile
        })}
        {...rest}
      >
        <Toolbar>
          <Grid container alignItems="center" flexWrap="nowrap">
            <Grid item>
              <IconButton
                sx={{ ml: -2 }}
                color="inherit"
                aria-label="Open drawer"
                onClick={onOpenDrawer}
                className={clsx(isDrawerOpen && classes.hide)}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs ml={{ md: 1.5 }}>
              {showSearchBar && (
                <ErrorBoundary variant="compact">
                  <GlobalSearch onActivated={onActivateSearch} onDeactivated={onDeactivateSearch} />
                </ErrorBoundary>
              )}
            </Grid>
            {displayButtons && (
              <Grid item display="flex" alignItems="center" gap={2}>
                <Box display="flex" alignItems="center" gap={2} ref={customAppButtonsRef} />
                <ErrorBoundary variant="compact">
                  <FavoritesDrawerButton />
                </ErrorBoundary>
                <ErrorBoundary variant="compact">
                  <NotificationsDropdown />
                </ErrorBoundary>
                <UserAvatar sx={{ ml: -2, mr: isMobile ? -2 : -0.5 }} />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </AppBarScrollListener>
  )
}

export const AppBar = React.memo(AppBarComponent)
