import { MasteredIcon } from '@client/components/ProductSkuFacet/ProductSkuFacet.style'
import { MasteredStatusValue, MasteredStatusWithEmptyValue } from '@client/types/filterset'
import { Button, Typography } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import { memo } from 'react'

export interface MasteredStatusFilterProps {
  attribute: string
  currentRefinement: MasteredStatusWithEmptyValue
  refine: (status: MasteredStatusWithEmptyValue) => void
}

const legends: Array<{
  label: string
  value: MasteredStatusValue
  icon: JSX.Element
}> = [
  {
    label: 'Mastered',
    value: 'mastered',
    icon: <MasteredIcon mastered />
  },
  {
    label: 'Not mastered',
    value: 'un-mastered',
    icon: <MasteredIcon />
  }
]

export const MasteredStatusFilter = memo(function MasteredStatusFilter({
  currentRefinement,
  refine
}: MasteredStatusFilterProps) {
  return (
    <>
      <Typography whiteSpace="nowrap">Filter products by:</Typography>
      {legends.map(({ label, icon, value }) => (
        <Button
          key={label}
          variant={currentRefinement === value ? 'outlined' : 'text'}
          size="small"
          color="inherit"
          startIcon={icon}
          sx={{ px: 2 }}
          onClick={() => refine(currentRefinement === value ? '' : value)}
        >
          <Typography
            fontSize={11}
            color={currentRefinement === value ? 'darkest' : 'dark'}
            fontWeight={currentRefinement === value ? 'bold' : 'normal'}
            whiteSpace="nowrap"
          >
            {label}
          </Typography>
        </Button>
      ))}
    </>
  )
},
deepEqual)
