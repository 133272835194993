import { TrackableListItemButton } from '@client/components/Trackables'
import { MenuItemIcon, MenuItemText } from '@client/components/menu'
import { EventArgs } from '@client/configs/tracking'
import { upperCaseFirst } from '@client/helpers/strings'
import { urls } from '@client/helpers/urls'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useFavorites } from '@client/hooks/use-favorites'
import { useMenuAnchor } from '@client/hooks/use-menu-anchor'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { PopupMenu, PopupMenuItem } from '@client/styles/theme/menu'
import { palette } from '@client/styles/theme/palette'
import { typography } from '@client/styles/theme/typography'
import { Favorite } from '@client/types/favorites'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Delete, MoreVert } from '@mui/icons-material'
import { Collapse, IconButton, ListItemText, Typography } from '@mui/material'
import { singular } from 'pluralize'
import { memo, useCallback } from 'react'
import { useIsMounted } from 'usehooks-ts'

export interface FavoriteListItemProps {
  favorite: Favorite
  onClick: () => void
  isActive?: boolean
}

export const FavoriteListItem: React.FC<FavoriteListItemProps> = memo(function FavoriteListItem({
  favorite,
  isActive,
  onClick
}) {
  const gaEvent: EventArgs = {
    category: TrackableCategory.navigation,
    action: TrackableAction.favoriteClicked,
    dimension1: favorite.name
  }
  const navigate = useNavigateRef()
  const { handleClose: closeMenu, handleClick: openMenu, menuAnchor } = useMenuAnchor()
  const { value: deleting, setTrue: setDeleting, setFalse: unsetDeleting } = useBooleanState(false)
  const isMounted = useIsMounted()

  const { removeFavorite } = useFavorites()

  const handleFavoriteClick = useCallback(() => {
    switch (favorite.type) {
      case 'brands':
        navigate.current(`/brands/${favorite.brandId}`)
        break
      case 'dispensaries':
        navigate.current(`/dispensaries/${favorite.dispensaryId}`)
        break
      case 'products':
        navigate.current(
          urls.productDetails(
            favorite.cmId ? { CM_ID: favorite.cmId, objectID: favorite.cmId } : { objectID: favorite.menuId as string }
          )
        )
        break
      case 'reports':
        navigate.current(`/reports?selectedTab=${favorite.reportId}&tabs=${favorite.reportId}`)
        break
      case 'comparisons':
        navigate.current(urls.comparisonPage(favorite.comparisonId))
        break
    }
    onClick()
  }, [favorite, navigate, onClick])

  const handleDelete = useCallback(
    async (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      closeMenu()
      // Set the deleting state for quick UI feedback
      setDeleting()
      const isDeleted = await removeFavorite(favorite)
      // If something went wrong, unset the deleting state so the user can try again
      if (!isDeleted && isMounted()) {
        unsetDeleting()
      }
    },
    [setDeleting, closeMenu, removeFavorite, favorite, isMounted, unsetDeleting]
  )

  return (
    <Collapse in={!deleting}>
      <TrackableListItemButton
        data-testid={`favorite-list-item-${favorite.favoriteId}`}
        selected={isActive}
        key={favorite.favoriteId}
        onClick={handleFavoriteClick}
        gaEvent={gaEvent}
        divider
      >
        <ListItemText
          primaryTypographyProps={{
            component: 'div'
          }}
          primary={
            <Typography data-testid="favorite-list-item--name" fontWeight={600} mb={1}>
              {favorite.name}
            </Typography>
          }
          secondaryTypographyProps={{
            component: 'div'
          }}
          secondary={
            <Typography fontSize={typography.size.xs2} color={palette.mediumdark}>
              {upperCaseFirst(singular(favorite.type))}
            </Typography>
          }
        />
        <IconButton edge="end" onClick={openMenu} data-testid="favorite-list-item--menu">
          <MoreVert />
        </IconButton>
      </TrackableListItemButton>
      <PopupMenu anchorEl={menuAnchor} onClose={() => closeMenu()} open={!!menuAnchor}>
        <PopupMenuItem onClick={handleDelete} data-testid="favorite-list-item--menu-delete-favorite">
          <MenuItemIcon>
            <Delete fontSize="small" />
          </MenuItemIcon>
          <MenuItemText>Remove favorite</MenuItemText>
        </PopupMenuItem>
      </PopupMenu>
    </Collapse>
  )
})
