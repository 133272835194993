import { posRelationship1, wholesaleRelationship1 } from '@client/mocks/fixtures/relationships'
import { Relationship } from '@client/types/relationships'
import { useCallback, useState } from 'react'

export const useRelationships = () => {
  // TODO: Fetch relationships from the API
  const [relationships] = useState<Relationship[]>([
    {
      ...posRelationship1
    },
    {
      ...wholesaleRelationship1
    }
  ])

  const getRelationshipById = useCallback(
    (relationshipId: string): Relationship | undefined => {
      return relationships.find((relationship) => relationship.id === relationshipId)
    },
    [relationships]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteRelationship = useCallback((relationshipId: string): Promise<boolean> => {
    // TODO: Delete relationship using the API
    return Promise.resolve(true)
  }, [])

  return {
    isFetching: false,
    relationships,
    getRelationshipById,
    deleteRelationship
  }
}
