import produce from 'immer'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

export enum DrawerId {
  comparisons = 'comparisons',
  favorites = 'favorites',
  filters = 'filters',
  saveView = 'saveView',
  shareView = 'shareView',
  shareComparison = 'shareComparison',
  views = 'views'
}

export type DrawerState = {
  id: DrawerId
  state?: any
}

export interface DrawerAppState {
  isDrawerOpen: boolean
  drawer?: DrawerState
  clearDrawer: () => void
  setDrawer: (drawer: DrawerState) => void
}

export const useDrawerState = create(
  devtools<DrawerAppState>(
    (set) => ({
      isDrawerOpen: false,
      drawer: undefined,
      setDrawer: (drawer: DrawerState) =>
        set(
          produce((state: DrawerAppState) => {
            state.drawer = drawer
            state.isDrawerOpen = true
          }),
          false,
          'setDrawer'
        ),
      clearDrawer: () =>
        set(
          produce((state: DrawerAppState) => {
            state.drawer = undefined
            state.isDrawerOpen = false
          }),
          false,
          'clearDrawer'
        )
    }),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: 'drawer-state'
    }
  )
)
