import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Tooltip, TooltipProps } from '@mui/material'

export const DesktopTooltip: React.FC<TooltipProps> = ({ title, ...props }) => {
  const { isMobile } = useResponsiveState()
  return (
    <Tooltip
      placement="left"
      componentsProps={{ popper: { style: { pointerEvents: 'none' } } }}
      disableFocusListener
      arrow
      {...props}
      title={!isMobile ? title : ''}
    />
  )
}
