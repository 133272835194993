import { DraftFilterset, emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { SubscriptionTierCode } from '@lib/types/accounts'
import { PageFilterOptions } from '@lib/types/page-filters'
import { SetOptional } from 'type-fest'
import { CountAggregate } from './graphql'

export const UNASSIGNED_ACCOUNT_ID = '_'

export type SubscriptionStatus = 'active' | 'suspended'

export type SubscriptionTier = {
  code: SubscriptionTierCode
  name: string
  id?: string
}

export type AccountSummary = {
  id: string
  name: string
  deletedAt?: string | null
}

export type AccountSummaryWithTier = AccountSummary & {
  subscriptionTier: SubscriptionTier
}

export type Account = AccountSummary & {
  filterset: DraftFilterset
  subscriptionTier: SubscriptionTier
  subscriptionStatus: SubscriptionStatus
  membersCount?: number
}

export type DraftAccount = {
  id?: string
  name: string
  deletedAt?: string | null
  filterset: DraftFilterset
  subscriptionTier: SetOptional<SubscriptionTier, 'code'>
  subscriptionStatus: SubscriptionStatus
}

export const emptyDraftSubscription: DraftAccount = {
  name: '',
  subscriptionTier: { name: '' },
  filterset: emptyDraftFilterset(),
  subscriptionStatus: 'active'
}

export enum AccountsSortField {
  NAME = 'name',
  MEMBERS_COUNT = 'membersCount',
  CREATION_DATE = 'creationDate'
}

export type AccountsOptions = PageFilterOptions<AccountsSortField> & { tiers?: string[] }

export const accountsSortOptions: { label: string; value: AccountsSortField }[] = [
  {
    label: 'Account name',
    value: AccountsSortField.NAME
  },
  {
    label: 'Number of members',
    value: AccountsSortField.MEMBERS_COUNT
  },
  {
    label: 'Creation date',
    value: AccountsSortField.CREATION_DATE
  }
]

export interface SubscriptionTierItemResponse {
  subscription_tier_code: string
  subscription_tier_name: string
  subscription_tier_id: string
}

export interface SubscriptionItemResponse {
  subscription_id: string
  subscription_name: string
  subscription_tier: SubscriptionTierItemResponse
  deleted_at: string | null
  suspended_at: string | null
  user_subscription_roles_aggregate: CountAggregate
  subscription_filterset?: {
    filterset: DraftFilterset
    filterset_id?: string
  }
}

export type SubscriptionListItem = {
  id: string
  name: string
}

export type SubscriptionListItemResponse = Pick<
  SubscriptionItemResponse,
  'subscription_id' | 'subscription_name' | 'deleted_at'
>

export interface SubscriptionListResponse {
  subscription: SubscriptionListItemResponse[]
}

export interface SubscriptionResponse {
  subscription_by_pk: SubscriptionItemResponse
}

export interface InsertSubscriptionFiltersetResponse {
  insert_subscription_filterset_one: {
    filterset_id: string
    subscription_id: string
  }
}

export interface UpdateFiltersetResponse {
  update_filterset_by_pk: {
    filterset_id: string
  }
}

export interface SubscriptionsNameResponse {
  subscription: Partial<Pick<SubscriptionItemResponse, 'subscription_id' | 'subscription_name'>>[]
}

export interface SubscriptionsResponse {
  subscription: SubscriptionItemResponse[]
  subscription_aggregate: CountAggregate
}

export interface SubscriptionTiersResponse {
  subscription_tier: SubscriptionTierItemResponse[]
}
