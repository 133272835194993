import { Button as MuiButton } from '@mui/material'
import styled from 'styled-components'

export const Button = styled(MuiButton)<{ $isOpen: boolean }>`
  white-space: nowrap;
  min-width: 0;
  width: auto;
  .MuiButton-startIcon {
    margin-right: ${({ theme, $isOpen, size }) =>
      $isOpen ? theme.spacing(2) : theme.spacing(size === 'small' ? -0.5 : -1)};
    transition: ${({ theme }) =>
      theme.transitions.create(['margin-right'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      })};
  }
  .MuiButton-endIcon {
    margin-left: ${({ theme, $isOpen, size }) =>
      $isOpen ? theme.spacing(2) : theme.spacing(size === 'small' ? -0.5 : -1)};
    transition: ${({ theme }) =>
      theme.transitions.create(['margin-left'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
      })};
  }
`
