import { AppBar } from '@client/components/app-bar/AppBar'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpFloatingButton } from '@client/components/help/help-floating-button/HelpFloatingButton'
import { RightDrawer } from '@client/components/right-drawer/RightDrawerWrapper'
import { Sidebar } from '@client/components/sidebar/Sidebar'
import { featureToggleMap } from '@client/hooks/use-feature-toggle'
import { usePermissions } from '@client/hooks/use-permissions'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import {
  applicationsRoutes,
  homeRoute,
  resourcesRoutes,
  settingsRoutes,
  wholesaleRoutes
} from '@client/routes/SidebarRoutes'
import { useSidebar } from '@client/stores/use-sidebar'
import { drawerWidth } from '@client/styles/global'
import { Route, RouteBase, RouteChild } from '@client/types/routes'
import { Permission } from '@lib/types/permission'
import { Box, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100%'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      maxWidth: '100%',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }
  })
)

/**
 * Get the child routes with the active state set according to the current url
 * @param url The current url
 * @param routes The child routes
 * @returns The child routes with the active state set
 */
export const getChildRoutesWithStatus = (url: string, routes: RouteChild[]) => {
  // Reset the active state of the child routes
  routes.forEach((childRoute) => (childRoute.isActive = false))
  // Sort the children by path length, so that the longest path is first and then check if it's an active path
  ;[...routes]
    .sort((a, b) => (a.path.length < b.path.length ? 1 : -1))
    .some((childRoute) => {
      if (url.startsWith(childRoute.path)) {
        childRoute.isActive = true
        return true
      }
      return false
    })
  return routes
}

/**
 * Verify if a route is authorized for the user
 * @param route The route to verify
 * @param hasPermission A method to check if the user has a specific permission
 * @returns A boolean indicating if the route is authorized
 */
export const isAuthorizedRoute = (route: RouteBase, hasPermission: (val: Permission) => boolean) => {
  if (route.hideIfNoPermission && route.permission) {
    if (![route.permission].flat().some(hasPermission)) {
      return false
    }
  }
  // Routes behind a feature flag should be filtered out if the feature flag is not enabled
  if (route.featureFlag && !featureToggleMap[route.featureFlag]) {
    return false
  }
  return true
}

/**
 * Parse a list of groups of routes and filter out the ones that are not authorized, returning a flat list of routes
 * @param routeGroups A list of groups of routes
 * @param currentPath The current path
 * @param hasPermission A method to check if the user has a specific permission
 * @returns A flat list of authorized routes
 */
export const parseAuthorizedRoutes = (
  routeGroups: Route[][],
  currentPath: string,
  hasPermission: (val: Permission) => boolean
) => {
  return routeGroups
    .map((group) => {
      // Store the first header in the group in case the route is filtered out
      const header = group[0]?.header
      return group
        .filter((route) => isAuthorizedRoute(route, hasPermission))
        .map((route, index) => {
          if (route.children) {
            // If the route has children, filter out the ones that are not enabled
            const children = route.children.filter((childRoute) => isAuthorizedRoute(childRoute, hasPermission))
            return {
              ...route,
              header: index === 0 ? header : undefined,
              children: getChildRoutesWithStatus(currentPath, children)
            }
          }
          return {
            ...route,
            header: index === 0 ? header : undefined
          }
        })
    })
    .flat()
}

export const SidebarLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useResponsiveState()
  const { pathname, search } = useLocation()

  const isDrawerOpen = useSidebar((state) => state.isOpen ?? !isMobile)
  const openDrawer = useSidebar((state) => state.openSidebar)
  const closeDrawer = useSidebar((state) => state.closeSidebar)

  const classes = useStyles()
  const { hasPermission } = usePermissions()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMobile) {
        closeDrawer()
      } else {
        openDrawer()
      }
    }, 1)
    return () => clearTimeout(timer)
  }, [closeDrawer, isMobile, openDrawer])

  const authorizedSidebarRoutes = useMemo(
    () =>
      parseAuthorizedRoutes(
        [[homeRoute], applicationsRoutes, wholesaleRoutes, settingsRoutes, resourcesRoutes],
        `${pathname}${search}`,
        hasPermission
      ),
    [pathname, search, hasPermission]
  )

  return (
    <div className={classes.root}>
      <AppBar isDrawerOpen={isDrawerOpen} onOpenDrawer={openDrawer} />
      <RightDrawer />
      <Sidebar routes={authorizedSidebarRoutes} open={isDrawerOpen} onClose={closeDrawer} />
      <Box
        component="main"
        position="relative"
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen || isMobile
        })}
      >
        <div className={classes.drawerHeader} />
        <ErrorBoundary resetKeys={[pathname]}>
          <Outlet />
          {children}
        </ErrorBoundary>
      </Box>
      <HelpFloatingButton />
    </div>
  )
}
