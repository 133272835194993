import { z } from 'zod'
import { zBoolEnvVar } from './helpers/zod'

export const HOODIE_ENVS = ['development', 'staging', 'production'] as const

// All env vars here should be prefixed with REACT_APP_
// to be picked up by the build system
const envSchema = z.object({
  REACT_APP_HASURA_URI: z.string().nonempty(),
  REACT_APP_DEBUG_SENTRY: zBoolEnvVar().default('false'),
  REACT_APP_SENTRY_DSN: z.string().nonempty(),
  REACT_APP_ENVIRONMENT: z.enum(HOODIE_ENVS),
  REACT_APP_PRODUCTION_SITE: z.string().default('https://app.hoodieanalytics.com/')
})

export const sharedEnv = envSchema.parse(process.env)

export const isProductionEnv = () => sharedEnv.REACT_APP_ENVIRONMENT === 'production'
export const isStagingEnv = () => sharedEnv.REACT_APP_ENVIRONMENT === 'staging'
export const isDevelopmentEnv = () => sharedEnv.REACT_APP_ENVIRONMENT === 'development'
