import { formatDayMonthDateClean } from '@client/helpers/dates'
import { slugify } from '@client/helpers/strings'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useToastNotification } from '@client/hooks/use-toast-notification'
import { InfoOutlined, Snooze } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
  Typography
} from '@mui/material'
import pluralize from 'pluralize'
import { useCallback, useState } from 'react'

type CustomDurationUnit = 'day' | 'week' | 'month'

type SnoozeOption = { label: string; value: number }

const defaultSnoozeOptions: SnoozeOption[] = [
  { label: 'for 3 days', value: 60 * 24 * 3 },
  { label: 'for 1 week', value: 60 * 24 * 7 },
  { label: 'for 2 weeks', value: 60 * 24 * 14 }
]

export const minutesFromUnitAndCount = (count: number, unit: CustomDurationUnit): number => {
  if (count < 1) {
    throw new Error('Count must be greater than 0')
  }
  return 60 * 24 * (unit === 'day' ? count : unit === 'week' ? count * 7 : count * 30)
}

export interface NotificationSnoozeProps {
  onSnooze: (minutes: number) => Promise<void>
  onUnsnooze: () => Promise<void>
  snoozedUntil: Date | undefined
  snoozeOptions?: SnoozeOption[]
  defaultCustomUnit?: CustomDurationUnit
  defaultCustomCount?: number
  subtitle?: string
  sx?: SxProps<Theme>
  indentation?: number
}

export const NotificationSnooze: React.FC<NotificationSnoozeProps> = ({
  onSnooze,
  onUnsnooze,
  snoozedUntil,
  defaultCustomCount = 1,
  defaultCustomUnit = 'month',
  subtitle,
  snoozeOptions = defaultSnoozeOptions,
  sx,
  indentation = 6
}) => {
  const { toast } = useToastNotification()
  const { value: busy, setTrue: setBusy, setFalse: clearBusy } = useBooleanState(false)
  const [count, setCount] = useState(defaultCustomCount)
  const [unit, setUnit] = useState<CustomDurationUnit>(defaultCustomUnit)

  const isSnoozed = snoozedUntil && snoozedUntil > new Date()

  const handleCountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Do not allow values less than 1
    setCount(Math.max(1, parseInt(e.target.value)))
  }, [])

  const handleSnooze = useCallback(
    (minutes: number) => {
      if (busy) {
        return
      }
      setBusy()
      onSnooze(minutes)
        .catch(() => {
          toast.error('An error occurred while snoozing this alert. Please try again.')
        })
        .finally(() => clearBusy())
    },
    [busy, clearBusy, onSnooze, setBusy, toast]
  )

  const handleUnsnooze = useCallback(() => {
    if (busy) {
      return
    }
    setBusy()
    onUnsnooze()
      .catch(() => {
        toast.error('An error occurred while un-snoozing this alert. Please try again.')
      })
      .finally(() => clearBusy())
  }, [busy, clearBusy, onUnsnooze, setBusy, toast])

  const handleCustomSnooze = useCallback(() => {
    handleSnooze(minutesFromUnitAndCount(count, unit))
  }, [count, handleSnooze, unit])

  return (
    <List
      sx={{ ...sx, minWidth: '278px' }}
      subheader={
        subtitle ? (
          <>
            <ListSubheader data-testid="notification-snooze--list-subheader">{subtitle}</ListSubheader>
            <Divider />
          </>
        ) : undefined
      }
    >
      <Collapse in={!!isSnoozed} data-testid="notification-snooze--unsnooze-collapse">
        <Alert
          sx={{ pl: indentation }}
          severity="warning"
          icon={<InfoOutlined fontSize="small" />}
          action={
            <Button
              data-testid="notification-snooze--unsnooze-button"
              variant="text"
              size="small"
              onClick={handleUnsnooze}
            >
              Un-snooze
            </Button>
          }
        >
          <Typography data-testid="notification-snooze--unsnooze-alert-text">
            Snoozed until {snoozedUntil && formatDayMonthDateClean(snoozedUntil)}
          </Typography>
        </Alert>
      </Collapse>
      {snoozeOptions.map(({ value, label }) => (
        <ListItem key={value} disablePadding>
          <ListItemButton
            sx={{ pl: indentation }}
            onClick={() => onSnooze(value)}
            data-testid={`notification-snooze--${slugify(label)}-button`}
          >
            <ListItemText primary={label} />
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem
        sx={{ pl: indentation }}
        secondaryAction={
          <IconButton
            edge="end"
            size="small"
            color="snooze"
            data-testid="notification-snooze--custom-button"
            onClick={handleCustomSnooze}
          >
            <Snooze fontSize="small" />
          </IconButton>
        }
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography>for </Typography>
          <TextField
            sx={{ width: '34px' }}
            variant="standard"
            type="number"
            inputProps={{
              'data-testid': 'notification-snooze--custom-count-input',
              sx: { textAlign: 'right' },
              min: 1,
              step: 1
            }}
            value={count}
            onChange={handleCountChange}
          />
          <Select
            sx={{ width: '68px' }}
            variant="standard"
            value={unit}
            inputProps={{
              'data-testid': 'notification-snooze--custom-unit-select'
            }}
            onChange={(e) => setUnit(e.target.value as any)}
          >
            <MenuItem value="day">{pluralize('day', count)}</MenuItem>
            <MenuItem value="week">{pluralize('week', count)}</MenuItem>
            <MenuItem value="month">{pluralize('month', count)}</MenuItem>
          </Select>
        </Box>
      </ListItem>
    </List>
  )
}
