import { DispensaryHit } from '@client/services/algolia'
import { Geolocation, Point } from '@lib/types/utils'

export const hasValidGeolocation = (dispensary: DispensaryHit) => {
  const geolocation = dispensary._geoloc
  return (
    !!geolocation &&
    geolocation.lat !== undefined &&
    geolocation.lng !== undefined &&
    geolocation.lat !== null &&
    geolocation.lng !== null
  )
}

export const isInsideBoundingBox = (dispensary: DispensaryHit, bbox: [Point, Point]) => {
  if (!hasValidGeolocation(dispensary)) {
    return false
  }
  const geolocation = dispensary._geoloc as Geolocation
  return (
    geolocation.lng >= bbox[0][0] &&
    geolocation.lng <= bbox[1][0] &&
    geolocation.lat >= bbox[0][1] &&
    geolocation.lat <= bbox[1][1]
  )
}

export const formatAddress = (dispensary: DispensaryHit) => {
  const commaSeparatedParts = [
    [dispensary.STREET_ADDRESS, dispensary.CITY, dispensary.STATE],
    [dispensary.POSTAL_CODE, dispensary.COUNTRY_CODE]
  ]
  const formattedParts = commaSeparatedParts.map((arr) => arr.filter(Boolean).join(', '))
  if (!formattedParts[0] && (dispensary.FULL_ADDRESS || dispensary.ADDRESS)) {
    return dispensary.FULL_ADDRESS || dispensary.ADDRESS
  }
  return formattedParts.filter(Boolean).join(' ')
}
