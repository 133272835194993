import { AccessControlIndicator } from '@client/components/access-control/AccessControl'
import { Onboarding } from '@client/components/onboarding'
import { slugify } from '@client/helpers/strings'
import { useLogout } from '@client/hooks/use-logout'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useOnboardingTour, useOnboardingTours } from '@client/hooks/use-onboarding-tour'
import { usePermissions } from '@client/hooks/use-permissions'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { HoodieLineIcon } from '@client/img/logo/hoodie-line'
import { Box } from '@client/styles/theme/box'
import { Route, RouteChild } from '@client/types/routes'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { ChevronLeft } from '@mui/icons-material'
import { Button, Collapse, Drawer, DrawerProps } from '@mui/material'
import React, { ComponentProps, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { LogOut } from 'react-feather'
import { Props } from 'react-joyride'
import { NavLink, useLocation } from 'react-router-dom'
import { useUpdateEffect } from 'usehooks-ts'
import * as S from './Sidebar.style'

const floaterProps: Props['floaterProps'] = {
  styles: {
    floater: {
      width: '350px'
    }
  }
}

const getSidebarTourId = (route: Route | RouteChild) => `sidebar-${slugify('id' in route ? route.id : route.name)}`

const SidebarOnboarding: React.FC<{ route: Route | RouteChild; sidebarScrollEl?: HTMLElement }> = memo(
  function SidebarOnboarding({ route, sidebarScrollEl }) {
    const navigate = useNavigateRef()
    const { hasPermission } = usePermissions()
    const { isMobile } = useResponsiveState()

    const onboarding = useMemo(() => {
      if (typeof route.onboarding === 'function') {
        return route.onboarding(hasPermission)
      }
      return route.onboarding
    }, [hasPermission, route])

    const navigateToPage = useCallback(() => {
      navigate.current(route.path)
    }, [navigate, route.path])

    const tourId = useMemo(() => getSidebarTourId(route), [route])
    const { hasBeenViewed, hasExpired } = useOnboardingTour({
      tourId,
      expiry: onboarding?.expiry
    })

    const isOnboardingActive = !hasBeenViewed && !hasExpired

    const steps = useMemo(
      () => [
        {
          target:
            'name' in route
              ? `[data-testid="sidebar-link-${slugify(route.name)}"]`
              : `[data-testid="sidebar-category-${slugify(route.id)}"]`,
          title: onboarding?.title,
          content: onboarding?.content,
          placement: isMobile ? ('bottom' as const) : ('right' as const),
          disableBeacon: true,
          isFixed: true,
          ctaProps: route.path
            ? {
                label: 'Take me there',
                onClick: navigateToPage
              }
            : undefined
        }
      ],
      [isMobile, navigateToPage, onboarding?.content, onboarding?.title, route]
    )

    // Force the sidebar to scroll to the first step so it does not get outside the viewport
    useEffect(() => {
      const el = document.querySelector(steps[0].target)
      if (sidebarScrollEl && el && isOnboardingActive) {
        sidebarScrollEl.scrollTop = el.getBoundingClientRect().top
      }
    }, [isOnboardingActive, sidebarScrollEl, steps])

    return onboarding ? (
      <Onboarding tourId={tourId} continuous steps={steps} floaterProps={floaterProps} expiry={onboarding.expiry} />
    ) : null
  }
)

type SidebarCategoryPropsType = {
  route: Route
  gaAction?: TrackableAction
  classes?: string
  isOpen?: boolean
  isCollapsable: boolean
  activeClassName?: string
  onClick?: () => void
  to?: string
  component?: typeof NavLink
  sidebarScrollEl?: HTMLElement
}

const SidebarCategory: React.FC<SidebarCategoryPropsType> = React.memo(function SidebarCategory({
  route,
  gaAction,
  isOpen,
  isCollapsable,
  to,
  sidebarScrollEl,
  ...rest
}) {
  const content = (
    <>
      {route.icon}
      <S.CategoryText>{route.title ? <route.title /> : route.id}</S.CategoryText>
      {route.permission && <AccessControlIndicator permission={route.permission} color="inherit" />}
      {isCollapsable ? isOpen ? <S.CategoryIconMore /> : <S.CategoryIconLess /> : null}
      {route.badge ? <S.CategoryBadge label={route.badge} /> : ''}
    </>
  )

  if ((isCollapsable && !to) || !gaAction) {
    return (
      <>
        <S.Category {...rest} to={to}>
          {content}
        </S.Category>
        {route.onboarding && <SidebarOnboarding route={route} sidebarScrollEl={sidebarScrollEl} />}
      </>
    )
  }

  const gaEvent = { category: TrackableCategory.navigation, action: gaAction }
  const isExternal = !!to?.startsWith('http')

  const props = isExternal
    ? {
        component: 'a' as ComponentProps<typeof S.TrackableCategory>['component'],
        href: to,
        target: '_blank'
      }
    : {
        component: NavLink,
        to
      }

  return (
    <>
      <S.TrackableCategory {...rest} gaEvent={gaEvent} {...props}>
        {content}
      </S.TrackableCategory>
      {route.onboarding && <SidebarOnboarding route={route} sidebarScrollEl={sidebarScrollEl} />}
    </>
  )
})

type SidebarLinkPropsType = {
  route: RouteChild
  onClick?: () => void
}

const SidebarLink: React.FC<SidebarLinkPropsType> = React.memo(function SidebarLink({ route, onClick }) {
  const gaEvent = { category: TrackableCategory.navigation, action: route.gaAction }
  return (
    <>
      <S.Link
        onClick={onClick}
        dense
        component={NavLink}
        to={route.path}
        activeClassName={route.isActive ? 'active' : ''}
        gaEvent={gaEvent}
        data-testid={`sidebar-link-${slugify(route.name)}`}
      >
        <S.LinkText>{route.name}</S.LinkText>
        {route.permission && <AccessControlIndicator permission={route.permission} color="info" />}
        {route.badge ? <S.LinkBadge label={route.badge} /> : ''}
      </S.Link>
      {route.onboarding && <SidebarOnboarding route={route} />}
    </>
  )
})

type SidebarPropsType = Omit<DrawerProps, 'onClose'> & {
  routes: Route[]
  onClose?: () => void
}

const RoutedSidebar: React.FC<SidebarPropsType> = ({ routes, onClose, ...rest }) => {
  const [sidebarScrollEl, setSidebarScrollEl] = useState<HTMLElement>()
  const { pathname } = useLocation()
  const { hasPermission } = usePermissions()
  const { hasTourBeenViewed, isLoading: isOnboardingLoading } = useOnboardingTours()
  const { logout } = useLogout()
  const classes = S.useStyles()
  const drawerClasses = S.useDrawerStyles()
  const { isMobile } = useResponsiveState()
  const gaBrandEvent = { category: TrackableCategory.navigation, action: TrackableAction.sidebarLogoClicked }

  type tplotOptions = {
    [key: number]: boolean
  }
  const initOpenRoutes = useCallback((): tplotOptions => {
    let _routes = {}

    routes.forEach((route: Route, index: number | string) => {
      const isActive = pathname.indexOf(route.path) === 0
      const isOpen = !!route.open
      const isHome = !!route.containsHome && pathname === '/'
      const hasActiveChild = !!route.children?.some(({ isActive }) => !!isActive)
      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome || hasActiveChild
      })
    })

    return _routes
  }, [routes, pathname])

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes())

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] || setOpenRoutes((prevOpenRoutes) => Object.assign({}, prevOpenRoutes, { [item]: false }))
    )

    // Toggle selected element
    setOpenRoutes((prevOpenRoutes) => Object.assign({}, prevOpenRoutes, { [index]: !prevOpenRoutes[index] }))
  }

  // Re-calculate open routes when onboarding tour state has been fetched
  useUpdateEffect(() => {
    if (!isOnboardingLoading) {
      setOpenRoutes((prevOpenRoutes) => {
        const nextOpenRoutes = { ...prevOpenRoutes }
        routes.forEach((route, index) => {
          const hasUnseenOnboarding = route.children?.some((childRoute) => {
            const childRouteOnboarding =
              typeof childRoute.onboarding === 'function' ? childRoute.onboarding(hasPermission) : childRoute.onboarding
            if (isOnboardingLoading || !childRouteOnboarding) {
              return false
            }
            const tourId = getSidebarTourId(childRoute)
            return !hasTourBeenViewed(tourId)
          })
          if (hasUnseenOnboarding) {
            nextOpenRoutes[index] = true
          }
        })
        return nextOpenRoutes
      })
    }
  }, [isOnboardingLoading])

  useEffect(() => {
    routes.some((route: Route, index: number | string) => {
      const hasActiveChild = route.children?.some(({ isActive }) => !!isActive)
      if (hasActiveChild) {
        setOpenRoutes((prevOpenRoutes) => Object.assign({}, prevOpenRoutes, { [index]: true }))
        return true
      }
      return false
    })
  }, [setOpenRoutes, pathname, routes])

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      classes={drawerClasses}
      variant={isMobile ? 'temporary' : 'persistent'}
      {...rest}
    >
      <Box pl={7} className={classes.drawerHeader}>
        <S.TrackableBrandLink to="/" onClick={isMobile ? onClose : undefined} gaEvent={gaBrandEvent}>
          <Box display="flex" alignItems="center">
            <HoodieLineIcon />
            <S.Brand>Hoodie Analytics</S.Brand>
          </Box>
        </S.TrackableBrandLink>
        {!isMobile && (
          <S.CloseButton onClick={onClose} color="inherit">
            <ChevronLeft />
          </S.CloseButton>
        )}
      </Box>
      <S.Scrollbar containerRef={setSidebarScrollEl}>
        <S.List disablePadding>
          <S.Items>
            {routes.map((category: Route, index: number) => (
              <React.Fragment key={index}>
                {category.header ? <S.SidebarSection>{category.header}</S.SidebarSection> : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      gaAction={!isMobile && !openRoutes[index] ? category.children[0].gaAction : undefined}
                      onClick={() => toggle(index)}
                      data-testid={`sidebar-category-${slugify(category.id)}`}
                      route={category}
                      sidebarScrollEl={sidebarScrollEl}
                    />

                    <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                      {category.children.map((route: RouteChild, index: number) => (
                        <SidebarLink
                          key={`${index}-${route.name}-${route.isActive}`}
                          route={route}
                          onClick={isMobile ? onClose : undefined}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    route={category}
                    to={category.path}
                    onClick={isMobile ? onClose : undefined}
                    activeClassName="active"
                    component={NavLink}
                    data-testid={`sidebar-category-${slugify(category.id)}`}
                    sidebarScrollEl={sidebarScrollEl}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </S.Items>
        </S.List>
      </S.Scrollbar>
      <S.SidebarFooter>
        <Button id="btn-sign-out" data-testid="sign-out-sidebar" variant="text" onClick={logout}>
          <S.Category>
            <LogOut />
            <S.CategoryText>Sign out</S.CategoryText>
          </S.Category>
        </Button>
      </S.SidebarFooter>
    </Drawer>
  )
}

export const Sidebar = React.memo(RoutedSidebar)
