export type HoodieLineIconProps = {
  fill?: string
  height?: number
  width?: number
}

export const HoodieLineIcon: React.FC<HoodieLineIconProps> = ({ fill = 'white', height = 26, width = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 21.2057C8.48528 21.2057 10.5 18.9304 10.5 16.1237C10.5 13.3169 8.48528 11.0417 6 11.0417C3.51472 11.0417 1.5 13.3169 1.5 16.1237C1.5 18.9304 3.51472 21.2057 6 21.2057ZM11.9949 16.406C11.8638 20.0173 9.22996 22.8997 6 22.8997C2.68629 22.8997 0 19.8659 0 16.1237C0 12.3814 2.68629 9.34766 6 9.34766C8.88506 9.34766 11.2945 11.6473 11.8696 14.712H14.1304C14.7055 11.6473 17.1149 9.34766 20 9.34766C23.3137 9.34766 26 12.3814 26 16.1237C26 19.8659 23.3137 22.8997 20 22.8997C16.77 22.8997 14.1362 20.0173 14.0051 16.406H11.9949ZM20 21.2057C22.4853 21.2057 24.5 18.9304 24.5 16.1237C24.5 13.3169 22.4853 11.0417 20 11.0417C17.5147 11.0417 15.5 13.3169 15.5 16.1237C15.5 18.9304 17.5147 21.2057 20 21.2057Z"
      fill={fill || 'white'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9928 8.21783C22.1619 4.28778 18.0117 1.44183 13.0005 1.44183C7.98926 1.44183 3.83907 4.28778 2.00822 8.21783H0.886719C2.77779 3.59055 7.48854 0.3125 13.0005 0.3125C18.5125 0.3125 23.2232 3.59055 25.1143 8.21783H23.9928Z"
      fill={fill || 'white'}
    />
  </svg>
)
