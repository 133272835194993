import { useBooleanState } from '@client/hooks/use-boolean-state'
import { Box, BoxProps, ButtonProps, Collapse, Skeleton } from '@mui/material'
import { Fragment, ReactNode, memo, useMemo } from 'react'
import * as S from './CollapsibleHorizontalList.style'

export const DEFAULT_HIGHLIGHTED_MAX_COUNT = 3

export interface ExposedCollapsibleHorizontalListProps {
  /**
   * Number of items to show before collapsing
   * @default 3
   */
  higlightedMaxCount?: number
  isLoading?: boolean
  gap?: BoxProps['gap']
  flexWrap?: BoxProps['flexWrap']
  moreButtonLabel?: string
  lessButtonLabel?: string
  moreLessButtonProps?: Omit<ButtonProps, 'onClick'>
}

interface CollapsibleHorizontalListProps<TItem> extends ExposedCollapsibleHorizontalListProps {
  items: TItem[]
  SkeletonComponent?: ReactNode
  renderItem: (item: TItem) => ReactNode
  AdditionalButtons?: ReactNode
}

const UnmemoedCollapsibleHorizontalList = <TItem,>({
  renderItem,
  items,
  isLoading,
  higlightedMaxCount = DEFAULT_HIGHLIGHTED_MAX_COUNT,
  gap = 2.5,
  flexWrap = 'wrap',
  moreButtonLabel = 'Show more',
  lessButtonLabel = 'Show less',
  moreLessButtonProps,
  SkeletonComponent,
  AdditionalButtons
}: CollapsibleHorizontalListProps<TItem>) => {
  const { value: expanded, toggleValue: toggleExpanded } = useBooleanState(false)

  const highlights = useMemo(() => items.slice(0, higlightedMaxCount), [items, higlightedMaxCount])
  const otherFilters = useMemo(() => items.slice(higlightedMaxCount), [items, higlightedMaxCount])

  if (isLoading) {
    return (
      <S.BoxRow gap={gap}>
        {[...Array(Math.min(DEFAULT_HIGHLIGHTED_MAX_COUNT, higlightedMaxCount)).keys()].map((key) => (
          <Fragment key={key}>
            {SkeletonComponent ?? (
              <Skeleton
                variant="rectangular"
                width={100}
                height={32}
                sx={{ borderRadius: 1.5 }}
                data-testid="collapsible-horizontal-list--skeleton"
              />
            )}
          </Fragment>
        ))}
      </S.BoxRow>
    )
  }

  return (
    <S.BoxRow rowGap={gap} flexWrap={flexWrap}>
      {highlights.map((item, index) => (
        <Box pr={gap} key={index}>
          {renderItem(item)}
        </Box>
      ))}
      {otherFilters?.map((item, index) => (
        <Collapse in={expanded} key={index} orientation="horizontal" unmountOnExit>
          <Box pr={gap}>{renderItem(item)}</Box>
        </Collapse>
      ))}
      {!!otherFilters.length && (
        <S.Button
          {...moreLessButtonProps}
          onClick={toggleExpanded}
          data-testid="collapsible-horizontal-list--expand-button"
        >
          {expanded ? lessButtonLabel : moreButtonLabel}
        </S.Button>
      )}
      {AdditionalButtons}
    </S.BoxRow>
  )
}

export const CollapsibleHorizontalList = memo(
  UnmemoedCollapsibleHorizontalList
) as typeof UnmemoedCollapsibleHorizontalList
