import { usersLocalStorage } from '@client/helpers/usersLocalStorage'
import { WholesaleSimulatedData } from '@client/types/wholesale'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface WholesaleStore {
  hasWholesaleOrders: boolean
  simulatedData: WholesaleSimulatedData | undefined
  simulatedBrands: string[]
  subscriptionId: string | undefined
  subscriptionName: string | undefined
  setSubscription: (subscriptionId: string | undefined, subscriptionName: string | undefined) => void
  setSimulatedData: (data?: WholesaleSimulatedData) => void
  setSimulatedBrands: (brands?: string[]) => void
  clearSimulatedData: () => void
  setHasWholesaleOrders: (hasWholesaleOrders: boolean) => void
}

export const useWholesaleStore = create(
  devtools(
    persist<WholesaleStore>(
      (set) => ({
        hasWholesaleOrders: false,
        simulatedData: undefined,
        simulatedBrands: [],
        subscriptionId: undefined,
        subscriptionName: undefined,
        setSubscription: (subscriptionId, subscriptionName) =>
          set((state) => {
            return { ...state, subscriptionId, subscriptionName }
          }),
        setSimulatedData: (simulatedData) =>
          set((state) => {
            return { ...state, simulatedData }
          }),
        setSimulatedBrands: (brands) =>
          set((state) => {
            return { ...state, simulatedBrands: brands ?? [] }
          }),
        clearSimulatedData: () =>
          set((state) => {
            return { ...state, simulatedData: undefined, simulatedBrands: [] }
          }),
        setHasWholesaleOrders: (hasWholesaleOrders) => set({ hasWholesaleOrders })
      }),
      {
        name: '@hoodie:wholesale',
        getStorage: () => usersLocalStorage
      }
    ),
    {
      enabled: process.env.NODE_ENV === 'development',
      name: `@hoodie:wholesale-store`
    }
  )
)
