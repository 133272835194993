import { BugReport, DataObject, ExpandLess, ExpandMore, TableRows } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { CopyToClipboard } from '@client/components/copy-to-clipboard/CopyToClipboard'
import { InfoPopover } from '@client/components/info_popover/InfoPopover'
import { useBooleanState } from '@client/hooks/use-boolean-state'
import { useDebug } from '@client/providers/debug-provider'
import { CodeBlock } from '@client/styles/global'
import { palette } from '@client/styles/theme/palette'
import * as S from './DebugPopover.style'

type DebugDataRecords = Record<string, any>

export interface DebugPopoverProps extends BoxProps {
  title: string
  summaryData?: DebugDataRecords
  detailedData?: DebugDataRecords
  overrideDebugEnabled?: boolean
}

enum DebugView {
  table = 'table',
  json = 'json'
}

/**
 * A popover that displays arbitrary key/value pair data in a
 * tabular or JSON format in a popover from an icon button.
 */
export const DebugPopover: React.FC<DebugPopoverProps> = ({
  title,
  summaryData,
  detailedData,
  overrideDebugEnabled,
  ...rest
}) => {
  const { isDebugEnabled } = useDebug()
  const { value: expanded, toggleValue: toggleExpanded } = useBooleanState(false)
  const [view, setView] = useState(DebugView.table)
  const tableData = expanded && detailedData ? detailedData : summaryData

  return (isDebugEnabled || overrideDebugEnabled) && tableData ? (
    <Box className="debug-popover" {...rest} data-testid="debug-popover">
      <InfoPopover Icon={BugReport} on="click" color={palette.mediumdark}>
        <Box display="flex" flexDirection="column" p={2} width={500} maxWidth="100%" maxHeight={600}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h5">{title}</Typography>
            <ToggleButtonGroup value={view} exclusive onChange={(e, value) => setView(value)} size="small">
              <ToggleButton value={DebugView.table} title="Table view" data-testid="debug-popover-tabular-view-button">
                <TableRows />
              </ToggleButton>
              <ToggleButton value={DebugView.json} title="JSON view" data-testid="debug-popover-json-view-button">
                <DataObject />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box overflow="auto">
            {view === DebugView.table ? (
              <Table size="small" data-testid="debug-popover-tabular-data">
                <TableHead>
                  <TableRow>
                    <S.TableHeaderCell>Key</S.TableHeaderCell>
                    <S.TableHeaderCell>Value</S.TableHeaderCell>
                    <S.TableHeaderCell></S.TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(tableData).map(([key, value]) => {
                    const strValue = typeof value === 'string' ? value : JSON.stringify(value, null, 2)
                    const multiLine = strValue?.includes('\n') || false
                    const cellStyle = multiLine ? { verticalAlign: 'top' } : undefined
                    return (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row" style={cellStyle}>
                          {key}
                        </TableCell>
                        <S.TableValueCell style={cellStyle}>{strValue}</S.TableValueCell>
                        <TableCell style={cellStyle} align="right">
                          {strValue && <CopyToClipboard text={strValue} size="small" />}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box display="flex" flexDirection="column" data-testid="debug-popover-json-data">
                <Box alignSelf="flex-end">
                  <CopyToClipboard text={JSON.stringify(tableData, null, 2)} size="small" />
                </Box>
                <CodeBlock>{JSON.stringify(tableData, null, 2)}</CodeBlock>
              </Box>
            )}
          </Box>
          <Button
            data-testid="debug-popover-toggle-details"
            sx={{ mt: 2 }}
            startIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            variant="text"
            onClick={toggleExpanded}
          >
            {expanded ? 'Show summary' : 'Show details'}
          </Button>
        </Box>
      </InfoPopover>
    </Box>
  ) : null
}
