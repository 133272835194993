import { useTags } from '@client/providers/tags-provider'
import { getFilterSummary } from '@client/types/filterset'
import { DraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { useMemo } from 'react'

export const useFilterSummary = ({ filterset, maxItems = 2 }: { filterset?: DraftFilterset; maxItems?: number }) => {
  const { getTagLabel } = useTags()

  const filterSummary = useMemo(() => {
    if (!filterset) {
      return []
    }
    return getFilterSummary(filterset, { maxItems, getTagLabel })
  }, [filterset, getTagLabel, maxItems])

  return {
    filterSummary
  }
}
