import { IconButton, TextField } from '@mui/material'
import styled from 'styled-components'

export const SearchField = styled(TextField)`
  margin-bottom: ${({ theme, margin }) => theme.spacing(margin === 'none' ? 0 : 2)};
`

export const ClearSearchButton = styled(IconButton)`
  margin-right: -8px;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  transition: opacity 300ms linear;
`
