import { GrowTransition } from '@client/components/transitions'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { Close } from '@mui/icons-material'
import { Dialog, DialogProps, PopoverProps, SxProps, Theme, Typography } from '@mui/material'
import { FC, PropsWithChildren, ReactNode, RefObject, memo } from 'react'
import * as S from './Dialog.style'

export interface DialogTitleProps {
  title: ReactNode
  onClose?: () => void
  children?: ReactNode
  image?: ReactNode
  sx?: SxProps<Theme>
}

export const DialogTitle: FC<PropsWithChildren<DialogTitleProps>> = memo(function DialogTitle({
  title,
  onClose,
  children,
  image,
  sx
}) {
  const content =
    typeof title === 'string' ? (
      <Typography variant="h2" noWrap data-testid="dialog-title">
        {title}
      </Typography>
    ) : (
      title
    )
  const closeButton = (
    <S.CloseButton onClick={onClose} aria-label="close-dialog">
      <Close />
    </S.CloseButton>
  )
  return image ? (
    <>
      {onClose && (
        <Box display="flex" justifyContent="flex-end" m={2}>
          {closeButton}
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {image}
        <S.DialogTitle {...({ component: 'div' } as any)} justifyContent="center">
          {content}
        </S.DialogTitle>
      </Box>
    </>
  ) : (
    <S.DialogTitle {...({ component: 'div' } as any)} sx={{ pr: onClose ? 4 : undefined, ...sx }}>
      {content}
      <Box display="flex" alignItems="center" ml={1}>
        {children}
        {onClose && closeButton}
      </Box>
    </S.DialogTitle>
  )
})

export const DialogContent = S.DialogContent

export const MobileDialogContent = S.MobileDialogContent

export type DialogPopoverProps = {
  onClose: () => void
  dialogTitle: ReactNode
  isOpen: boolean
  currentRef: RefObject<HTMLElement>
  dialogProps?: Omit<DialogProps, 'open'>
  popoverProps?: Omit<PopoverProps, 'open'>
}

export const DialogPopover: FC<PropsWithChildren<DialogPopoverProps>> = memo(function NotificationsDropdown({
  children,
  dialogTitle = '',
  onClose,
  isOpen,
  currentRef,
  dialogProps,
  popoverProps
}) {
  const { isMobile } = useResponsiveState()

  return isMobile ? (
    <Dialog
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
      keepMounted={false}
      open={isOpen}
      onClose={onClose}
      TransitionComponent={GrowTransition}
      data-testid="mobile-dialog"
      {...dialogProps}
    >
      <DialogTitle title={dialogTitle} onClose={onClose} />
      <MobileDialogContent dividers>{children}</MobileDialogContent>
    </Dialog>
  ) : (
    <S.Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      slotProps={{
        paper: { sx: { width: 300 } }
      }}
      anchorEl={currentRef.current}
      onClose={onClose}
      open={isOpen}
      data-testid="desktop-popover"
      {...popoverProps}
    >
      {children}
    </S.Popover>
  )
})
