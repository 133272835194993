import { Box } from '@client/styles/theme/box'
import { Chip as MuiChip, listItemButtonClasses } from '@mui/material'
import styled from 'styled-components'

export const PopoverBody = styled(Box)`
  position: relative;
  max-width: 320px;
  padding: ${({ theme }) => theme.spacing(0, 0, 0, 1)};
  pointer-events: auto;
  &::before {
    position: absolute;
    top: ${({ theme }) => theme.spacing(-0.75)};
    left: ${({ theme }) => `calc(50% - ${theme.spacing(-0.75)})`};
    display: block;
    width: ${({ theme }) => theme.spacing(1.5)};
    height: ${({ theme }) => theme.spacing(1.5)};
    content: '';
    background-color: white;
    transform: rotate(45deg);
  }
  .${listItemButtonClasses.root} {
    margin-right: ${({ theme }) => theme.spacing(-2)};
  }
`

export const Chip = styled(MuiChip)`
  align-self: flex-start;
  max-width: calc(50% - ${(props) => props.theme.spacing(2)});
  margin: 0;
  color: #fff;
  background-color: ${(props) => props.theme.palette.primaryGreen};
`
