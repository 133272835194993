import { Onboarding, OnboardingStep } from '@client/components/onboarding'
import { NewReleases } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { memo } from 'react'
import { Props } from 'react-joyride'
import { useBoolean, useTimeout } from 'usehooks-ts'

const floaterProps: Props['floaterProps'] = {
  styles: {
    floater: {
      width: '350px'
    }
  }
}

const steps: OnboardingStep[] = [
  {
    target: '[data-testid="filter-section--actualprice"]',
    title: (
      <Stack gap={2} direction="row" alignItems="center">
        <NewReleases color="success" />
        <Typography variant="h5" fontWeight={600}>
          New: Filter by Price
        </Typography>
      </Stack>
    ),
    content: (
      <Typography variant="body1" gutterBottom>
        You can now filter by Product Price range, including any promotional discounts.
      </Typography>
    ),
    placement: 'top',
    disableBeacon: true
  }
]

export const FilterByPriceOnboardingTour = memo(function FilterByPriceOnboardingTour() {
  const { value: run, setTrue: startTour } = useBoolean(false)

  useTimeout(() => {
    startTour()
  }, 1000)

  return (
    <Onboarding
      run={run}
      expiry={new Date(2024, 10, 22)}
      tourId="filter-by-price"
      steps={steps}
      floaterProps={floaterProps}
    />
  )
})
