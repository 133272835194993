import { ErrorDisplay, ErrorDisplayContainer } from '@client/components/ErrorDisplay/ErrorDisplay'
import { PageLoader } from '@client/components/page-loader/PageLoader'
import { getTwoMonthsAgo, getYesterdaysDate } from '@client/helpers/dates'
import { useAuthorization } from '@client/hooks/use-authorization'
import { useFiltersWithTags } from '@client/hooks/use-filters-with-tags'
import { OpenSearchApiService } from '@client/services/open-search-api-service'
import { Box, Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { useUser } from './user'

export interface OpenSearchApiContextInterface {
  openSearchApiService: OpenSearchApiService
  latestIndexDate: string
  latestEventsIndexDate: string
  latestMarketIntelIndexDate: string
}

export const OpenSearchApiContext = createContext<OpenSearchApiContextInterface>({} as OpenSearchApiContextInterface)

export const OpenSearchApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userToken } = useUser()
  const { data: authData } = useAuthorization()
  const { normalizeFilterWithTags, isLoading: isLoadingTags } = useFiltersWithTags()

  const [openSearchApiService, setOpenSearchApiService] = useState<OpenSearchApiService>()

  useEffect(() => {
    if (!isLoadingTags && userToken && authData?.subscriptionKey && authData?.permissionsKey) {
      setOpenSearchApiService(new OpenSearchApiService({ normalizeFilterWithTags }))
    }
  }, [isLoadingTags, normalizeFilterWithTags, userToken, authData?.subscriptionKey, authData?.permissionsKey])

  const { error, data } = useQuery(
    ['/open-search/latest-index'],
    async () => {
      try {
        const [latestHoodieIndexDate, latestEventsIndexDate, latestMarketIntelIndexDate] = await Promise.all([
          openSearchApiService?.openSearchLatestIndex('hoodie'),
          openSearchApiService?.openSearchLatestIndex('events'),
          openSearchApiService?.openSearchLatestIndex('market-intel')
        ])
        if (!latestHoodieIndexDate || !latestEventsIndexDate) {
          throw new Error('Could not get latest index dates')
        }
        return {
          latestHoodieIndexDate: latestHoodieIndexDate?.date ?? getYesterdaysDate(),
          latestEventsIndexDate: latestEventsIndexDate?.date ?? getYesterdaysDate(),
          latestMarketIntelIndexDate: latestMarketIntelIndexDate?.date ?? getTwoMonthsAgo()
        }
      } catch (e) {
        console.error('Error in openSearchLatestIndex', e)
        throw e
      }
    },
    //refetchInterval: every hour (60 min * 60 sec * 1000 ms)
    { enabled: !!openSearchApiService, refetchInterval: 60 * 60 * 1000 }
  )

  if (error) {
    return (
      <Box width="100%" height="100%" m="auto auto">
        <ErrorDisplayContainer>
          <ErrorDisplay message="Service Error">
            <Button variant="outlined" onClick={() => window.location.reload()}>
              Try again
            </Button>
          </ErrorDisplay>
        </ErrorDisplayContainer>
      </Box>
    )
  }

  return openSearchApiService && data ? (
    <OpenSearchApiContext.Provider
      value={{
        openSearchApiService,
        latestIndexDate: data.latestHoodieIndexDate,
        latestEventsIndexDate: data.latestEventsIndexDate,
        latestMarketIntelIndexDate: data.latestMarketIntelIndexDate
      }}
    >
      {children}
    </OpenSearchApiContext.Provider>
  ) : (
    <PageLoader />
  )
}

/**
 * Hook to access the OpenSearchApiService
 */
export const useOpenSearchApi = () => {
  const context = useContext(OpenSearchApiContext)

  if (!Object.keys(context).length) {
    throw new Error('useOpenSearchApi must be used within a OpenSearchApiProvider.')
  }

  return context
}
