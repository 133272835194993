import { Close } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { ChangeEventHandler, memo, useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useDebouncedCallback } from 'use-debounce'
import * as S from './SearchBox.style'

export interface SearchBoxProps {
  isActive: boolean
  searchTerm: string
  onSearch: (searchTerm: string) => void
  onActivate?: () => void
  onDeactivate?: () => void
}

export const SearchBox: React.FC<SearchBoxProps> = memo(function SearchBox({
  isActive,
  searchTerm = '',
  onSearch,
  onActivate,
  onDeactivate
}) {
  const theme = useTheme()
  const [searchTermText, setSearchTermText] = useState<string>(searchTerm || '')
  const debounced = useDebouncedCallback(onSearch, 300)

  useEffect(() => {
    setSearchTermText(searchTerm || '')
  }, [searchTerm])

  const onSearchTermChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setSearchTermText(ev.target.value)
    debounced(ev.target.value)
  }

  const onBlur = () => {
    // Allow for click handler on drop-down search results to be handled first
    setTimeout(() => {
      onDeactivate && onDeactivate()
    }, 200)
  }

  return (
    <S.Search className={isActive ? 'active' : ''}>
      <S.SearchIconWrapper>
        <SearchIcon color={theme.palette.info.main} />
      </S.SearchIconWrapper>
      <S.Input
        inputProps={{
          'data-testid': 'global-search-input'
        }}
        role="searchbox"
        onFocus={onActivate}
        onBlur={onBlur}
        placeholder="Search..."
        value={searchTermText}
        onChange={onSearchTermChange}
      />
      <S.CloseWrapper role="button" disabled={!isActive || !searchTermText.length} onClick={onDeactivate}>
        <Close />
      </S.CloseWrapper>
    </S.Search>
  )
})
