import { Grow, Slide, SlideProps } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

export const SlideTransition: any = (direction: SlideProps['direction']) =>
  forwardRef(function SlideTransition(
    props: TransitionProps & {
      children: React.ReactElement
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction={direction} ref={ref} {...props} />
  })

export const GrowTransition: any = forwardRef(function GrowTransition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Grow in ref={ref} {...props} />
})
