import { TransferOwnership } from '@client/components/TransferOwnership/TransferOwnership'
import { ErrorBoundary } from '@client/components/error-boundary/ErrorBoundary'
import { HelpLink } from '@client/components/help-link/HelpLink'
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  useDrawerStyles
} from '@client/components/right-drawer/RightDrawer.style'
import { ShareCheckbox } from '@client/components/share-checkbox/ShareCheckbox'
import { urls } from '@client/helpers/urls'
import { useActiveComparison } from '@client/hooks/use-active-comparison'
import { useComparison } from '@client/hooks/use-comparison'
import { useNavigateRef } from '@client/hooks/use-navigate-ref'
import { useShareTransfer } from '@client/hooks/use-share-transfer'
import { useUser } from '@client/providers/user'
import { DrawerId, useDrawerState } from '@client/stores/use-drawer-state'
import { AccountSummary } from '@client/types/account'
import { COMPARISON_NEW_URL_KEY, Comparison } from '@client/types/comparison'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

export interface ShareComparisonDrawerContentProps {
  account: AccountSummary | null
  onClose: () => void
}

export const ShareComparisonDrawerContent: React.FC<ShareComparisonDrawerContentProps> = ({ onClose, account }) => {
  const { drawer, setDrawer } = useDrawerState()
  const [busy, setBusy] = useState(false)
  const { activeComparisonId } = useActiveComparison()
  const navigate = useNavigateRef()

  const comparison: Comparison = useMemo(() => drawer?.state?.comparison, [drawer?.state?.comparison])
  const { saveComparison } = useComparison(comparison.id)

  const { isShared, transferTarget, applyShareTransfer, handleShareChange, handleTransferTargetChange } =
    useShareTransfer({
      label: 'Comparison',
      data: comparison,
      onSave: (data: Comparison) => saveComparison(data, true),
      tracking: {
        category: TrackableCategory.comparisons,
        shareAction: TrackableAction.comparisonShared,
        unshareAction: TrackableAction.comparisonUnshared,
        transferAction: TrackableAction.comparisonTransferred
      }
    })

  const handleCancel = useCallback(() => {
    if (drawer?.state?.prevDrawer) {
      setDrawer(drawer.state.prevDrawer)
    } else {
      onClose()
    }
  }, [onClose, drawer?.state?.prevDrawer, setDrawer])

  const handleSave = useCallback(async () => {
    setBusy(true)
    const status = await applyShareTransfer()
    // If the comparison was transferred, we need to redirect the user to the new comparison page if he's looking at the old one
    if (status.transferred && !isShared && comparison.id === activeComparisonId) {
      navigate.current(urls.comparisonPage(COMPARISON_NEW_URL_KEY), { replace: true })
    }
    setBusy(false)
    onClose()
  }, [activeComparisonId, applyShareTransfer, comparison.id, isShared, navigate, onClose])

  return (
    <>
      <DrawerHeader>
        <DrawerTitle data-testid="drawer-title">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            Share/Transfer comparison
            <Box display="flex" flexDirection="row" alignItems="center">
              <HelpLink
                helpLink="dispensary-analytics/create-comparison#sharetransfer-a-comparison"
                tooltip="Comparison help on sharing comparisons"
              />
              <IconButton edge="end" onClick={handleCancel} data-testid="close-drawer-button">
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <Box p={4}>
          <Typography fontWeight="600" variant="h5" gutterBottom>
            Share <strong>&apos;{comparison.name}&apos;</strong>
          </Typography>
          <ShareCheckbox
            isShared={isShared}
            onChange={handleShareChange}
            accountName={account?.name}
            label="comparison"
          />
          <Divider sx={{ my: 4 }} />
          <Typography fontWeight="600" variant="h5" gutterBottom>
            Transfer <strong>&apos;{comparison.name}&apos;</strong>
          </Typography>
          <TransferOwnership
            onTransferTargetChange={handleTransferTargetChange}
            transferTarget={transferTarget}
            accountName={account?.name}
            label="comparison"
          />
        </Box>
      </DrawerContent>
      <DrawerFooter>
        <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
          <Button variant="text" color="primary" onClick={handleCancel} sx={{ mr: 2 }} data-testid="cancel-button">
            Cancel
          </Button>
          <LoadingButton
            data-testid="save-button"
            variant="contained"
            loading={busy}
            loadingIndicator="Saving..."
            color="primary"
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </Box>
      </DrawerFooter>
    </>
  )
}

export const ShareComparisonDrawer: React.FC<any> = () => {
  const { drawer, clearDrawer } = useDrawerState()
  const drawerStyles = useDrawerStyles()
  const { account } = useUser()

  return (
    <Drawer
      open={drawer?.id === DrawerId.shareComparison}
      anchor="right"
      classes={drawerStyles}
      onClose={clearDrawer}
      data-testid="share-comparison-drawer"
    >
      {drawer?.id === DrawerId.shareComparison && (
        <ErrorBoundary>
          <ShareComparisonDrawerContent onClose={clearDrawer} account={account} />
        </ErrorBoundary>
      )}
    </Drawer>
  )
}
