import { MenuSectionItem, SectionTypography } from './Menu.style'
export { DangerMenuItem, Menu, MenuItem, MenuItemIcon, MenuItemText } from './Menu.style'
export { NestedMenuItem } from './NestedMenuItem'

export interface MenuSectionProps {
  label: string
}

export const MenuSection: React.FC<MenuSectionProps> = ({ label, ...rest }) => (
  <MenuSectionItem disabled dense {...rest}>
    <SectionTypography variant="caption">{label}</SectionTypography>
  </MenuSectionItem>
)
