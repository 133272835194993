import HRN from 'human-readable-numbers'
import pluralize from 'pluralize'
import slugifyFn from 'slugify'

export const slugify = (text: string, options?: Parameters<typeof slugifyFn>[1]): string => {
  return slugifyFn(text, options || { strict: true, lower: true })
}

export const formatPercentage = (number: number | null, decimalPlaces = 1, nonNumberResponse = ''): string => {
  if (number !== null && isFinite(number)) {
    return `${Number.parseFloat(number.toFixed(decimalPlaces))}%`
  } else {
    return nonNumberResponse
  }
}

export const numberWithCommas = (x: string): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toPrice = (number: number | string | null, omitCurrency = false): string => {
  if (number === null) {
    return omitCurrency ? '-.--' : '$-.--'
  }
  if (typeof number === 'string') {
    number = Number.parseFloat(number)
  }
  const prefix = `${number < 0 ? '-' : ''}${!omitCurrency ? '$' : ''}`
  // If it is a whole number, don't show the decimal places
  if (number % 1 === 0) {
    return `${prefix}${numberWithCommas(Math.abs(number).toString())}`
  }
  return `${prefix}${numberWithCommas(Math.abs(number).toFixed(2))}`
}

/**
 * Returns a string representation of a number with a specified precision while
 * falling back to a fixed notation if the number is in scientific notation.
 */
export function toPrecision(number: number, precision: number, fallbackDecimalPlaces = 2) {
  const precisionNumber = number.toPrecision(precision)
  // Check if the number is in scientific notation and fallback to fixed notation
  if (precisionNumber.indexOf('e') > -1) {
    return Number.parseFloat(number.toFixed(fallbackDecimalPlaces)).toString()
  }
  return precisionNumber
}

export const percentageValue = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    return numerator === 0 ? 0 : NaN
  }
  return (numerator / denominator) * 100
}

export const percentage = (numerator: number, denominator: number, decimalPlaces = 2, displaySign?: boolean) => {
  const result = percentageValue(numerator, denominator)
  if (isNaN(result)) {
    return 'N/A'
  }
  return `${displaySign && result > 0 ? '+' : ''}${formatPercentage(
    displaySign ? result : Math.abs(result),
    decimalPlaces
  )}`
}

export const sign = (value: number): string => {
  return value < 0 ? '-' : value === 0 ? '' : '+'
}

export function signed(value: number, prefix?: string, decimalPlaces?: number): string {
  return `${sign(value)}${prefix || ''}${
    decimalPlaces === undefined ? toHumanString(Math.abs(value)) : Math.abs(value).toFixed(decimalPlaces)
  }`
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function toKebabCase(str: string) {
  return str
    .replace(/_/g, '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase()
}

export function toSnakeCase(str: string, uppercase?: boolean) {
  const snakeCase = str
    .replace(/\s+|-/g, '_') // Replace spaces and hyphens with underscores
    .replace(/[^a-zA-Z0-9_]/g, '') // Remove all non-alphanumeric characters except _
  return uppercase ? snakeCase.toUpperCase() : snakeCase.toLowerCase()
}

export const camelToSnakeCase = (str: string) => {
  if (str.match(/[\s_-]/g)) {
    throw new Error('String must be camelCase')
  }
  if (str.match(/[^a-zA-Z0-9]/g)) {
    throw new Error('String should only contain alphanumeric characters')
  }
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function tryParseFloat(str: string) {
  if (!str) {
    return null
  }
  const res = Number.parseFloat(str)
  return isNaN(res) ? str : res
}

export const upperCaseFirst = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

export const toHumanString = (number: number, asPrice = false): string => {
  let formattedString: string
  if (number !== 0 && number > -100 && number < 100 && number % 1 !== 0) {
    formattedString = number.toFixed(2)
  } else {
    formattedString = HRN.toHumanString(number.toString())
  }
  if (asPrice) {
    const isNegative = number < 0
    formattedString = `${isNegative ? '-' : ''}$${formattedString.replace('-', '')}`
  }
  // Replace G by B (billion)
  formattedString = formattedString.replace(/G$/, 'B')
  return formattedString
}

export const formatStringArray = <T extends string | object>(
  arr: T[],
  delimiter = ', ',
  lastItemDelimited = ' and '
): string => {
  const arrayLength = arr.length

  if (arrayLength === 0) {
    return ''
  }

  if (arrayLength === 1) {
    return arr[0].toString()
  }

  return `${arr.slice(0, arrayLength - 1).join(delimiter)}${lastItemDelimited}${arr[arrayLength - 1]}`
}

export function countObjectToString(
  obj?: Record<string, number>,
  options?: { delimiter?: string; lastItemDelimited?: string; removeZeroCounts?: boolean }
) {
  const { delimiter = ', ', lastItemDelimited, removeZeroCounts = true } = options ?? {}

  let entries = Object.entries(obj ?? {})
  if (removeZeroCounts) {
    entries = entries.filter(([, count]) => count > 0)
  }

  const list = entries
    .sort(([keyA, countA], [keyB, countB]) => (countB === countA ? keyA.localeCompare(keyB) : countB - countA))
    .map(([key, count]) => `${count} ${pluralize(key, count)}`)

  if (lastItemDelimited) {
    return formatStringArray(list, delimiter, lastItemDelimited)
  }
  return list.join(delimiter)
}

export const asteriskToBold = (str: string): string => {
  return str.replace(/\*([^*]+?)\*/g, '<b>$1</b>')
}
