import { ALGOLIA_INDEX } from '@client/types/algolia'
import { DatabaseFilterMap, TAG_ALGOLIA_SEPARATOR } from '@client/types/filterset'
import type { HitItem } from '@client/types/hitItem'
import { ItemTagKey, TagTargetTagsMap, TagType, tagTypeKeys } from '@client/types/tags'
import { TagFilterTarget } from '@hoodie/hoodie-filters/lib/filterset'
import deepEqual from 'fast-deep-equal'
import type { Hit } from 'react-instantsearch-core'
import { Refinement } from 'react-instantsearch-core'

export const generateItems = (requiredNumItems: number): Hit<HitItem>[] => {
  return [...Array(requiredNumItems).keys()]
    .map(function (_, i) {
      return i
    })
    .map((index: number) => ({
      _highlightResult: {
        label: {
          matchLevel: 'none',
          matchedWords: [],
          value: `Item ${index}`
        },
        value: [
          {
            matchLevel: 'none',
            matchedWords: [],
            value: `Item ${index}`
          }
        ]
      },
      objectID: 'id',
      count: index,
      isRefined: false,
      label: `Item ${index}`,
      value: [`Item ${index}`]
    }))
}

export const sortFacets = (a: HitItem, b: HitItem) => {
  if (a.count > b.count) {
    return -1
  } else if (b.count > a.count) {
    return 1
  }
  return a.label.localeCompare(b.label)
}

export const generateAlgoliaResponse = ([request]: any, hits?: unknown[]) => {
  if (!request.params.facets?.length) {
    return Promise.resolve({
      results: [
        {
          hits: hits || generateItems(10),
          nbPages: 2,
          page: 1
        }
      ]
    })
  }
  return Promise.resolve({
    results: [
      {
        hits: [],
        facets: Object.fromEntries(
          request.params.facets?.map((facet: string) => [
            facet,
            Object.fromEntries([...Array(10).keys()].map((_, i) => [`${facet} ${i + 1}`, 10 - i]))
          ]) ?? []
        )
      }
    ]
  })
}

export const convertTagsToFacets = ({
  facet,
  tags,
  index,
  attribute: tagAttribute
}: {
  facet: string | string[]
  tags: TagTargetTagsMap
  index: ALGOLIA_INDEX
  attribute: string
}): { facetFilters: string[][]; facets: string[] } => {
  const itemKeyToDatabaseKey: Partial<Record<ItemTagKey, keyof typeof DatabaseFilterMap>> = {
    brandId: 'brands',
    dispensaryId: 'dispensaryIds',
    menuId: 'menuIds',
    cmId: 'cmIds'
  }
  const facetsToAdd: Record<TagFilterTarget, string[]> = {
    brand: [],
    dispensary: [],
    product: []
  }
  const facets = new Set<string>()
  const facetsList = Array.isArray(facet) ? facet : [facet]
  facetsList.forEach((facetFilter) => {
    const [tagId, type] = (typeof facetFilter === 'string' ? facetFilter : facetFilter[0]).split(TAG_ALGOLIA_SEPARATOR)
    const itemTags = tags[type as TagFilterTarget][tagId.replace(`${tagAttribute}:`, '')]?.items ?? []
    itemTags.forEach((itemTag) => {
      const attr = tagTypeKeys[type as TagType].find((key) => !!itemTag[key])!
      const facet = DatabaseFilterMap[itemKeyToDatabaseKey[attr]!][index]
      if (facet) {
        facets.add(facet)
        facetsToAdd[type as TagFilterTarget].push(`${facet}:${itemTag[attr]}`)
      }
    })
  })
  return {
    facetFilters: Object.values(facetsToAdd).filter((facet) => facet.length > 0),
    facets: Array.from(facets)
  }
}

export function facetToValue(str: string) {
  return str.startsWith('-') ? str.substring(1) : str
}

export function valueToFacet(value: string, valueExcluded: boolean) {
  return value.indexOf('-') === 0 ? (valueExcluded ? value : value.substring(1)) : valueExcluded ? '-' + value : value
}

type PropsWithRefinementItems = {
  items: Refinement[]
  [key: string]: any
}

const mapItem = ({ attribute, currentRefinement, index }: Refinement) => ({ attribute, currentRefinement, index })

export const algoliaPropsWithItemsAreEqual = (
  prevProps: PropsWithRefinementItems,
  nextProps: PropsWithRefinementItems
) => {
  const { items: prevItems, ...prev } = prevProps
  const { items: nextItems, ...next } = nextProps

  if (prevItems.length !== nextItems.length) {
    return false
  } else if (!deepEqual(prevItems.map(mapItem), nextItems.map(mapItem))) {
    return false
  }
  return deepEqual(prev, next)
}
