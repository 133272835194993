import { Badge } from '@mui/material'
import styled from 'styled-components'

export const SnoozeBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: ${({ theme }) => theme.spacing(4)};
    background-color: ${({ theme }) => theme.palette.snooze.main};
    top: 2px;
    right: ${({ theme }) => theme.spacing(-4)};
  }
`
