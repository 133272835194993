import { UserAccountAndRoles } from '@client/types/user-accounts-subscriptions'
import { AddUserToAccount, RemoveUserFromAccount, UpdateUserAccountRoles } from '@lib/types/accounts'

export function userDisplay(user: { firstName?: string; lastName?: string; email: string }) {
  const trimmedName = `${user.firstName?.trim() ?? ''} ${user.lastName?.trim() ?? ''}`.trim()
  return trimmedName || user.email
}

type WithoutUser<T> = Omit<T, 'user'>

/**
 * Split the user's subscription roles into three lists: accounts to remove, accounts to update, and accounts to insert.
 * @param prevUserAccountAndRoles The previous list of user accounts and roles
 * @param nextUserAccountAndRoles The next list of user accounts and roles
 * @returns An object with three lists: accountsToRemove, accountsToUpdate, and accountsToInsert
 */
export function splitUserAccountRoles(
  prevUserAccountAndRoles: UserAccountAndRoles[],
  nextUserAccountAndRoles: UserAccountAndRoles[]
): {
  accountsToRemove: WithoutUser<RemoveUserFromAccount>[]
  accountsToUpdate: WithoutUser<UpdateUserAccountRoles>[]
  accountsToInsert: WithoutUser<AddUserToAccount>[]
} {
  // New and updated accounts
  const accountsToInsert: WithoutUser<AddUserToAccount>[] = []
  const accountsToUpdate: WithoutUser<UpdateUserAccountRoles>[] = []

  nextUserAccountAndRoles.forEach((account) => {
    const prevAccount = prevUserAccountAndRoles.find((prev) => prev.account.id === account.account.id)
    // If the account does not exist in the previous list, it is new
    if (!prevAccount) {
      accountsToInsert.push({
        subscription: account.account,
        roleIds: account.roles.map(({ id }) => id)
      })
    } else {
      // If the account exists in the previous list, check if the roles have changed
      const isChanged =
        account.roles.length !== prevAccount.roles.length ||
        account.roles.some((role) => !prevAccount.roles.some((prevRole) => prevRole.id === role.id))
      if (isChanged) {
        accountsToUpdate.push({
          subscription: account.account,
          roleIds: account.roles.map(({ id }) => id),
          prevRoleIds: prevAccount.roles.map(({ id }) => id)
        })
      }
    }
  })
  // Removed accounts
  const accountsToRemove = prevUserAccountAndRoles
    .filter((account) => !nextUserAccountAndRoles.some((next) => next.account.id === account.account.id))
    .map((account) => ({
      subscription: account.account
    }))

  return {
    accountsToInsert,
    accountsToUpdate,
    accountsToRemove
  }
}
