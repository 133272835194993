import OptimizedImage from '@client/components/optimized-image/OptimizedImage'
import { ListItem as MuiListItem, ListItemButton as MuiListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'

export const ListItem = styled(MuiListItem)`
  padding: 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }
`

export const ListItemButton = styled(MuiListItemButton)`
  opacity: 1 !important;
  padding: ${({ theme }) => theme.spacing(2, 1)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(2, 2, 2, 0)};
  }

  /* Disable hover effect when onClick is not provided */
  ${({ onClick }) =>
    !onClick &&
    css`
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    `}

  &.Mui-disabled > * {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }
`

export const Image = styled(OptimizedImage)`
  flex-shrink: 0;
  flex-grow: 0;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: ${({ theme }) => theme.spacing(2)};
`
