import { MultipleQueriesQuery } from '@algolia/client-search'
import { useAlgolia } from '@client/providers/algolia'
import { ALGOLIA_INDEX, MAX_FILTERS, MAX_QUERIES_PER_REQUEST } from '@client/services/algolia'
import { Filters, JoinRule, filtersToFiltersString } from '@client/types/filterset'
import { splitArrayIntoChunks } from '@lib/helpers/objects'
import { useQuery } from '@tanstack/react-query'

type PageParams = {
  page?: number
  hitsPerPage?: number
  offset?: number
}

type AlgoliaListOptions = {
  open?: boolean
  enabled?: boolean
  keepPreviousData?: boolean
  distinct?: number
  attributesToRetrieve?: string[]
  pageParams?: PageParams
}

/** React hook to fetch a list of objects from Algolia */
export const useAlgoliaList = <THit, TFilters extends Filters = Filters>(
  filters: TFilters,
  index: ALGOLIA_INDEX,
  listOption?: AlgoliaListOptions,
  joinRule?: JoinRule
) => {
  const {
    open = false,
    enabled = true,
    keepPreviousData = false,
    distinct,
    attributesToRetrieve,
    pageParams
  } = listOption ?? {}
  const { algoliaSearchClients, globalAlgoliaSearchClients } = useAlgolia()
  const options: Record<string, unknown> = {}
  if (distinct) {
    options['distinct'] = distinct
  }
  if (attributesToRetrieve) {
    options['attributesToRetrieve'] = attributesToRetrieve
  }
  return useQuery<THit[] | undefined>(
    ['get-algolia-list', filters, index, open],
    async () => {
      const searchIndex = open ? globalAlgoliaSearchClients[index] : algoliaSearchClients[index]
      const searchString = filtersToFiltersString(filters, index, joinRule)

      const queryParams = {
        ...options,
        indexName: index,
        query: filters.keywordSearch ?? '',
        params: {
          filters: searchString,
          ...pageParams
        }
      }

      // If hits per page is set and page is not set, we check if it's higher than the max hits per page allowed, and then we paginate it
      if (pageParams?.hitsPerPage && !pageParams.page && pageParams.hitsPerPage > MAX_FILTERS) {
        const pages = Math.ceil(pageParams.hitsPerPage / MAX_FILTERS)
        const queriesParams = Array(pages)
          .fill(MAX_FILTERS)
          .map((hitsPerPage, page) => {
            return {
              ...queryParams,
              params: {
                ...queryParams.params,
                hitsPerPage,
                page
              }
            } as MultipleQueriesQuery
          })

        // Batch the requests to avoid hitting the max queries per request
        const alogliaResponses = await Promise.all(
          splitArrayIntoChunks(queriesParams, MAX_QUERIES_PER_REQUEST).map((queries) => searchIndex.search(queries))
        )

        return alogliaResponses.flatMap((response) => response.results.flatMap((result) => result.hits) as THit[])
      }

      const algoliaResponse = await searchIndex.search([queryParams])
      return algoliaResponse.results[0].hits as THit[] | undefined
    },
    {
      enabled,
      keepPreviousData
    }
  )
}
