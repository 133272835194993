import {
  ApolloClient,
  FetchResult,
  gql,
  MutationOptions,
  Observable,
  OperationVariables,
  QueryOptions
} from '@apollo/client'
import { camelToSnakeCase } from '@client/helpers/strings'
import { Comparison, DraftComparison } from '@client/types/comparison'
import { DraftFavorite, Favorite, parseFavorite } from '@client/types/favorites'
import { FiltersetGraphQL } from '@client/types/filterset'
import {
  DraftNotificationPreference,
  NotificationOptions,
  NotificationPreference,
  preparePreferenceRequest,
  toOptionsFromResult,
  toPreferenceFromResult,
  toPreferencesFromResults
} from '@client/types/notification'
import {
  DraftTag,
  ItemTagDb,
  itemTagsToInClause,
  ItemTagWithTag,
  parseTagWithItemsCount,
  RequiredItemTag,
  tagKeys,
  TagType,
  tagTypeKeys,
  TagWithCount,
  TagWithItems,
  toDbItemTagFormat,
  toDbTagFormat
} from '@client/types/tags'
import type { DraftView, Filters, View } from '@hoodie/hoodie-filters/lib/filterset'
import { emptyDraftFilterset } from '@hoodie/hoodie-filters/lib/filterset'
import { ItemTag, Tag } from '@hoodie/hoodie-filters/lib/tags'
import { graphql, ResultOf } from '@lib/clients/graphql'
import { setOperationName } from '@lib/helpers/graphql'
import { HasuraCustomRole } from '@lib/types/graphql'
import { UserSubscription } from '@lib/types/users'
import { ArrayObjectType, NonNullableValues } from '@lib/types/utils'
import dayjs from 'dayjs'
import deepmerge from 'deepmerge'
import { SetOptional, SetRequired } from 'type-fest'

export enum UserDataKeys {
  SavedViews = 'saved_views',
  ChartsSettings = 'charts_settings',
  DispensaryAnalyticsKPISettings = 'menu_analytics_kpi_settings',
  DashboardGrid = 'dashboard_grid',
  UserFlags = 'user_flags',
  ProductsGridOptions = 'products_grid_options',
  PimDataGridOptions = 'pim_data_grid_options'
}

export type UserData = {
  userId: string
  fname: string
  lname: string
  email: string
  phone: string | null
  iconImageUrl: string | null
  preferredTimezone: string | null
}

const NOTIFICATION_OPTIONS = graphql(`
  query NotificationOptions {
    notification_channel {
      notification_channel_id
      notification_channel_name
      notification_channel_description
    }
    notification_category {
      parent_notification_category_id
      notification_category_id
      category_name
      category_code
      description
      group
    }
  }
`)

export type NotificationOptionsResult = ResultOf<typeof NOTIFICATION_OPTIONS>
export type NotificationChannelResult = ArrayObjectType<NotificationOptionsResult['notification_channel']>
export type NotificationCategoryResult = ArrayObjectType<NotificationOptionsResult['notification_category']>

const NOTIFICATION_PREFERENCES = graphql(`
  query NotificationPreferences {
    notification_preference {
      notification_preference_id
      filterset_id
      comparison_id
      is_enabled
      disabled_until
      schedule
      data
      notification_preference_notification_channels {
        notification_channel_id
      }
      notification_preference_notification_categories {
        notification_category_id
      }
    }
  }
`)

export type NotificationPreferenceResult = ArrayObjectType<
  ResultOf<typeof NOTIFICATION_PREFERENCES>['notification_preference']
>

// At the moment, gql.tada is not able to generate the correct types for the subscription
// (it just returns as type 'never')
const NOTIFICATIONS = gql`
  subscription subscribeToNotifications($thirtyDaysAgo: timestamptz!) {
    notification(order_by: { created_at: desc }, where: { created_at: { _gte: $thirtyDaysAgo } }) {
      notification_id
      web_read_at
      created_at
      subject
      message
      data
    }
  }
`

const NOTIFICATION_MUTATIONS = {
  ChangeNotificationStatus: graphql(`
    mutation ChangeNotificationStatus($notificationId: uuid!, $readAt: timestamptz) {
      update_notification_by_pk(pk_columns: { notification_id: $notificationId }, _set: { web_read_at: $readAt }) {
        notification_id
        web_read_at
      }
    }
  `),
  UpdateAllUnreadNotifications: graphql(`
    mutation UpdateAllUnreadNotifications($readAt: timestamptz) {
      update_notification(where: { web_read_at: { _is_null: true } }, _set: { web_read_at: $readAt }) {
        affected_rows
      }
    }
  `),
  InsertNotificationPreference: graphql(`
    mutation InsertNotficationPreference($preference: notification_preference_insert_input!) {
      insert_notification_preference_one(object: $preference) {
        notification_preference_id
        filterset_id
        comparison_id
        disabled_until
        is_enabled
        schedule
        data
        notification_preference_notification_channels {
          notification_channel_id
        }
        notification_preference_notification_categories {
          notification_category_id
        }
      }
    }
  `),
  UpdateNotificationPreference: graphql(`
    mutation UpdateNotificationPreference(
      $preferenceID: uuid!
      $deletedAt: timestamptz
      $preference: notification_preference_insert_input!
    ) {
      update_notification_preference_notification_category(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_notification_channel(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      insert_notification_preference_one(
        object: $preference
        on_conflict: { constraint: notification_preference_pkey, update_columns: [data, schedule, disabled_until] }
      ) {
        notification_preference_id
        filterset_id
        comparison_id
        is_enabled
        disabled_until
        schedule
        data
        notification_preference_notification_channels {
          notification_channel_id
        }
        notification_preference_notification_categories {
          notification_category_id
        }
      }
    }
  `),
  DeleteNotificationPreference: graphql(`
    mutation SoftDeleteNotificationPreference($preferenceID: uuid!, $deletedAt: timestamptz) {
      update_notification_preference_notification_category(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_notification_channel(
        where: { notification_preference_id: { _eq: $preferenceID } }
        _set: { deleted_at: $deletedAt }
      ) {
        affected_rows
      }
      update_notification_preference_by_pk(
        pk_columns: { notification_preference_id: $preferenceID }
        _set: { deleted_at: $deletedAt }
      ) {
        notification_preference_id
      }
    }
  `)
}

const COMPARISON_FRAGMENT = graphql(`
  fragment ComparisonRecord on comparison {
    comparison_id
    user_id
    subscription_id
    context
    filters
    items
    name
    comparison_filtersets {
      filterset {
        filters
        filterset_id
        filterset_name
      }
    }
  }
`)

const COMPARISONS_QUERIES = {
  GET_COMPARISONS: graphql(
    `
      query GetComparisons {
        comparison(where: { deleted_at: { _is_null: true } }) {
          ...ComparisonRecord
        }
      }
    `,
    [COMPARISON_FRAGMENT]
  ),
  CHECK_NAME: graphql(`
    query CheckComparisonName($name: String!, $userId: String!) {
      comparison(where: { deleted_at: { _is_null: true }, name: { _eq: $name }, user_id: { _eq: $userId } }) {
        comparison_id
      }
    }
  `)
}

export type ComparisonFilterset = ArrayObjectType<ResultOf<typeof COMPARISON_FRAGMENT>['comparison_filtersets']>
export type ComparisonResponse = ResultOf<typeof COMPARISON_FRAGMENT>

const COMPARISON_MUTATIONS = {
  InsertComparison: graphql(
    `
      mutation InsertComparison(
        $name: String!
        $context: String!
        $subscriptionId: uuid
        $items: jsonb
        $filters: jsonb
        $views: comparison_filterset_arr_rel_insert_input
      ) {
        insert_comparison_one(
          object: {
            name: $name
            context: $context
            subscription_id: $subscriptionId
            items: $items
            filters: $filters
            comparison_filtersets: $views
          }
        ) {
          ...ComparisonRecord
        }
      }
    `,
    [COMPARISON_FRAGMENT]
  ),
  UpdateComparison: graphql(
    `
      mutation UpdateComparison(
        $id: uuid!
        $userId: String!
        $subscriptionId: uuid
        $name: String!
        $context: String!
        $items: jsonb
        $filters: jsonb
        $views: [comparison_filterset_insert_input!]!
      ) {
        delete_comparison_filterset(where: { comparison_id: { _eq: $id } }) {
          affected_rows
        }
        insert_comparison_filterset(objects: $views) {
          affected_rows
        }
        update_comparison_by_pk(
          pk_columns: { comparison_id: $id }
          _set: {
            name: $name
            context: $context
            items: $items
            filters: $filters
            user_id: $userId
            subscription_id: $subscriptionId
          }
        ) {
          ...ComparisonRecord
        }
      }
    `,
    [COMPARISON_FRAGMENT]
  ),
  DeleteComparison: graphql(`
    mutation DeleteComparison($comparisonId: uuid!, $deletedAt: timestamptz!) {
      update_comparison(where: { comparison_id: { _eq: $comparisonId } }, _set: { deleted_at: $deletedAt }) {
        affected_rows
      }
    }
  `)
}

const TAG_FRAGMENT = graphql(`
  fragment TagRecord on tag {
    id: tag_id
    label: tag_label
    color
    userId: user_id
    data
    archivedAt: archived_at
  }
`)

export type TagGraphQL = ResultOf<typeof TAG_FRAGMENT>

const ITEM_TAGS_COUNT_FRAGMENT = graphql(`
  fragment ItemTagsCountRecord on tag {
    itemsCount: item_tags_aggregate {
      brands: aggregate {
        count(columns: brand_id)
      }
      dispensaries: aggregate {
        count(columns: dispensary_id)
      }
      reports: aggregate {
        count(columns: report_id)
      }
      filtersets: aggregate {
        count(columns: filterset_id)
      }
      masteredProducts: aggregate {
        count(columns: cm_id)
      }
      nonMasteredProducts: aggregate {
        count(columns: menu_id)
      }
    }
  }
`)

const ITEM_TAG_FRAGMENT = graphql(`
  fragment ItemTagRecord on item_tag {
    itemTagId: item_tag_id
    subscriptionId: subscription_id
    brandId: brand_id
    cmId: cm_id
    menuId: menu_id
    dispensaryId: dispensary_id
    filtersetId: filterset_id
    reportId: report_id
    data: meta_data
  }
`)

export type ItemTagRecord = ResultOf<typeof ITEM_TAG_FRAGMENT>

export const TAGS_QUERIES = {
  ListTags: graphql(
    `
      query ListTags {
        tag(order_by: { tag_label: asc }) {
          ...TagRecord
          ...ItemTagsCountRecord
        }
      }
    `,
    [TAG_FRAGMENT, ITEM_TAGS_COUNT_FRAGMENT]
  ),
  ListItemTags: graphql(
    `
      query ListItemTags($where: item_tag_bool_exp!) {
        item_tag(where: $where, order_by: { tag: { user_id: asc_nulls_first, tag_label: asc } }) {
          ...ItemTagRecord
          tag {
            ...TagRecord
          }
        }
      }
    `,
    [ITEM_TAG_FRAGMENT, TAG_FRAGMENT]
  ),
  ListItemsFromTag: graphql(
    `
      query ListItemsFromTag($where: item_tag_bool_exp!) {
        items: item_tag(where: $where) {
          ...ItemTagRecord
        }
      }
    `,
    [ITEM_TAG_FRAGMENT]
  )
}
export const TAGS_MUTATIONS = {
  InsertTag: graphql(
    `
      mutation InsertTag($label: String!, $color: String!, $items: item_tag_arr_rel_insert_input) {
        insert_tag_one(object: { tag_label: $label, color: $color, item_tags: $items }) {
          ...TagRecord
          ...ItemTagsCountRecord
          items: item_tags {
            ...ItemTagRecord
          }
        }
      }
    `,
    [TAG_FRAGMENT, ITEM_TAG_FRAGMENT, ITEM_TAGS_COUNT_FRAGMENT]
  ),
  UpdateTag: graphql(
    `
      mutation UpdateTag(
        $tagId: uuid!
        $label: String!
        $color: String!
        $addItems: [item_tag_insert_input!]!
        $updateItems: [item_tag_updates!]!
        $removeItems: [uuid!]!
      ) {
        insert_item_tag(objects: $addItems) {
          returning {
            ...ItemTagRecord
          }
        }
        update_item_tag_many(updates: $updateItems) {
          returning {
            ...ItemTagRecord
          }
        }
        delete_item_tag(where: { item_tag_id: { _in: $removeItems } }) {
          affected_rows
        }
        update_tag_by_pk(pk_columns: { tag_id: $tagId }, _set: { color: $color, tag_label: $label }) {
          ...TagRecord
          ...ItemTagsCountRecord
        }
      }
    `,
    [TAG_FRAGMENT, ITEM_TAG_FRAGMENT, ITEM_TAGS_COUNT_FRAGMENT]
  ),
  InsertItemTag: graphql(
    `
      mutation InsertItemTag($object: item_tag_insert_input!) {
        insert_item_tag_one(object: $object) {
          ...ItemTagRecord
        }
      }
    `,
    [ITEM_TAG_FRAGMENT]
  ),
  UpdateItemTag: graphql(
    `
      mutation UpdateItemTag($itemTagId: uuid!, $subscriptionId: uuid) {
        update_item_tag_by_pk(pk_columns: { item_tag_id: $itemTagId }, _set: { subscription_id: $subscriptionId }) {
          ...ItemTagRecord
        }
      }
    `,
    [ITEM_TAG_FRAGMENT]
  ),
  RemoveItemTag: graphql(
    `
      mutation RemoveItemTag($itemTagId: uuid!) {
        delete_item_tag_by_pk(item_tag_id: $itemTagId) {
          ...ItemTagRecord
        }
      }
    `,
    [ITEM_TAG_FRAGMENT]
  ),
  ArchiveTag: graphql(
    `
      mutation ArchiveTag($tagId: uuid!, $archivedAt: timestamptz) {
        tag: update_tag_by_pk(pk_columns: { tag_id: $tagId }, _set: { archived_at: $archivedAt }) {
          ...TagRecord
          ...ItemTagsCountRecord
        }
      }
    `,
    [TAG_FRAGMENT, ITEM_TAGS_COUNT_FRAGMENT]
  ),
  RestoreTag: graphql(
    `
      mutation RestoreTag($tagId: uuid!) {
        tag: update_tag_by_pk(pk_columns: { tag_id: $tagId }, _set: { archived_at: null }) {
          ...TagRecord
          ...ItemTagsCountRecord
        }
      }
    `,
    [TAG_FRAGMENT, ITEM_TAGS_COUNT_FRAGMENT]
  ),
  DeleteTag: graphql(
    `
      mutation DeleteTag($tagId: uuid!) {
        delete_tag_by_pk(tag_id: $tagId) {
          ...TagRecord
        }
      }
    `,
    [TAG_FRAGMENT]
  )
}

export type TagWithItemsCountResponse = ArrayObjectType<ResultOf<typeof TAGS_QUERIES.ListTags>['tag']>
type ItemTagWithTagGraphQL = ArrayObjectType<ResultOf<typeof TAGS_QUERIES.ListItemTags>['item_tag']>
type ItemTagGraphQL = ArrayObjectType<ResultOf<typeof TAGS_QUERIES.ListItemsFromTag>['items']>

const parseTag = (tag: TagGraphQL): Tag => {
  return {
    ...tag,
    archivedAt: tag.archivedAt ?? undefined,
    userId: tag.userId ?? undefined
  }
}

const parseItemTag = (itemTag: ItemTagGraphQL): ItemTag | undefined => {
  const itemId = tagKeys.find((key) => !!itemTag[key])
  if (!itemId) {
    return undefined
  }
  return {
    itemTagId: itemTag.itemTagId,
    data: itemTag.data,
    subscriptionId: itemTag.subscriptionId ?? undefined,
    [itemId]: itemTag[itemId]
  } as ItemTag
}

const parseItemTagWithTag = (itemTag: ItemTagWithTagGraphQL): ItemTagWithTag => {
  return {
    ...parseItemTag(itemTag),
    tag: parseTag(itemTag.tag)
  } as ItemTagWithTag
}

const FILTERSET_FRAGMENT = graphql(
  `
    fragment FiltersetRecord on filterset {
      filterset_id
      filterset_name
      userId: user_id
      subscriptionId: subscription_id
      filters
      tags: filterset_tags {
        tag {
          ...TagRecord
        }
      }
    }
  `,
  [TAG_FRAGMENT]
)

const LIST_FILTERSETS = graphql(
  `
    query ListFiltersets {
      filterset(order_by: { created_at: asc }) {
        ...FiltersetRecord
      }
    }
  `,
  [FILTERSET_FRAGMENT]
)

const INSERT_FILTERSET = graphql(
  `
    mutation InsertFilterset(
      $name: String!
      $subscriptionId: uuid
      $filters: jsonb!
      $tags: filterset_tag_arr_rel_insert_input
    ) {
      filterset: insert_filterset_one(
        object: { filterset_name: $name, subscription_id: $subscriptionId, filters: $filters, filterset_tags: $tags }
      ) {
        ...FiltersetRecord
      }
    }
  `,
  [FILTERSET_FRAGMENT]
)

const UPDATE_FILTERSET = graphql(`
  mutation UpdateFilterset(
    $filtersetId: uuid!
    $name: String
    $filters: jsonb
    $userId: String!
    $subscriptionId: uuid
  ) {
    filterset: update_filterset_by_pk(
      pk_columns: { filterset_id: $filtersetId }
      _set: { filters: $filters, filterset_name: $name, user_id: $userId, subscription_id: $subscriptionId }
    ) {
      filterset_id
    }
  }
`)

const DELETE_FILTERSET = graphql(`
  mutation UpdateFilterset($filtersetId: uuid!, $deletedAt: timestamptz) {
    filterset: update_filterset_by_pk(pk_columns: { filterset_id: $filtersetId }, _set: { deleted_at: $deletedAt }) {
      filterset_id
    }
  }
`)

const FAVORITES_FRAGMENT = graphql(`
  fragment FavoriteRecord on favorite {
    menuId: menu_id
    favoriteId: favorite_id
    dispensaryId: dispensary_id
    cmId: cm_id
    brandId: brand_id
    reportId: report_id
    comparisonId: comparison_id
    favoritedAt: created_at
    name
  }
`)

export type FavoriteItem = ResultOf<typeof FAVORITES_FRAGMENT>

const LIST_FAVORITES = graphql(
  `
    query ListFavorites {
      favorite(order_by: { created_at: desc }) {
        ...FavoriteRecord
      }
    }
  `,
  [FAVORITES_FRAGMENT]
)

const FAVORITES_MUTATIONS = {
  InsertFavorite: graphql(
    `
      mutation InsertFavorite(
        $name: String!
        $dispensaryId: String
        $cmId: String
        $brandId: String
        $menuId: String
        $reportId: String
        $comparisonId: uuid
      ) {
        insert_favorite_one(
          object: {
            name: $name
            dispensary_id: $dispensaryId
            cm_id: $cmId
            brand_id: $brandId
            menu_id: $menuId
            report_id: $reportId
            comparison_id: $comparisonId
          }
        ) {
          ...FavoriteRecord
        }
      }
    `,
    [FAVORITES_FRAGMENT]
  ),
  UpdateFavoriteName: graphql(
    `
      mutation UpdateFavoriteName($favoriteId: uuid!, $name: String!) {
        update_favorite_by_pk(pk_columns: { favorite_id: $favoriteId }, _set: { name: $name }) {
          ...FavoriteRecord
        }
      }
    `,
    [FAVORITES_FRAGMENT]
  ),
  DeleteFavorite: graphql(
    `
      mutation DeleteFavorite($favoriteId: uuid!) {
        delete_favorite_by_pk(favorite_id: $favoriteId) {
          ...FavoriteRecord
        }
      }
    `,
    [FAVORITES_FRAGMENT]
  )
}

const USER_SUBSCRIPTION_FRAGMENT = gql`
  fragment UserSubscriptionRecord on user_subscription {
    userSubscriptionId: user_subscription_id
    userId: user_id
    subscriptionId: subscription_id
    organization
    department
    jobTitle: job_title
  }
`

export const USER_SUBSCRIPTION_QUERIES = {
  GetUserSubscription: gql`
    ${USER_SUBSCRIPTION_FRAGMENT}
    query GetUserSubscription($subscriptionId: uuid!) {
      userSubscription: user_subscription(where: { subscription_id: { _eq: $subscriptionId } }) {
        ...UserSubscriptionRecord
      }
    }
  `
}

export const USER_SUBSCRIPTION_MUTATIONS = {
  UpsertUserSubscription: gql`
    ${USER_SUBSCRIPTION_FRAGMENT}
    mutation UpsertUserSubscription($organization: String, $department: String, $jobTitle: String) {
      insert_user_subscription_one(
        object: { organization: $organization, department: $department, job_title: $jobTitle }
        on_conflict: {
          constraint: user_subscription_user_id_subscription_id_key
          update_columns: [organization, department, job_title]
        }
      ) {
        ...UserSubscriptionRecord
      }
    }
  `
}

const SUBSCRIPTION_MUTATIONS = {
  BulkUpdateAccountTier: gql`
    mutation UpdateAccountTier($accountIds: [uuid!]!, $tierId: uuid!) {
      update_subscription(where: { subscription_id: { _in: $accountIds } }, _set: { subscription_tier_id: $tierId }) {
        affected_rows
      }
    }
  `
}

const USER_FRAGMENT = gql`
  fragment UserRecord on user {
    userId: user_id
    fname
    lname
    phone
    email
    iconImageUrl: icon_image_url
    preferredTimezone: preferred_timezone
  }
`

export const USER_QUERIES = {
  GetUser: gql`
    ${USER_FRAGMENT}
    query GetUser($userId: String!) {
      user: user_by_pk(user_id: $userId) {
        ...UserRecord
      }
    }
  `
}

/**
 * Convert a GraphQL representation of a user's filterset to a Filterset object
 *
 * Note - this function should not handle subscription filtersets where no userId is set
 * @param filtersetResult The GraphQL representation of a filterset
 * @returns The Filterset object
 */
export function filtersetResultToRecord(filtersetResult: FiltersetGraphQL): SetOptional<View, 'userId'> {
  const filters: Filters = deepmerge(emptyDraftFilterset().filters, filtersetResult.filters)
  return {
    id: filtersetResult.filterset_id,
    name: filtersetResult.filterset_name,
    userId: filtersetResult.userId ?? undefined,
    subscriptionId: filtersetResult.subscriptionId ?? undefined,
    tags: filtersetResult.tags?.map(({ tag }) => tag),
    filters
  }
}

export const USER_DATA_QUERIES = {
  getUserData: graphql(`
    query GetUserData($key: String!) {
      user_data(where: { key: { _eq: $key } }) {
        value
        key
      }
    }
  `)
}

export const USER_DATA_MUTATIONS = {
  upsertUserData: graphql(`
    mutation UpsertUserData($value: json!, $key: String!) {
      insert_user_data_one(
        object: { value: $value, key: $key }
        on_conflict: { constraint: user_data_user_id_application_id_key_key, update_columns: [value] }
      ) {
        value
        key
      }
    }
  `)
}

export type HasuraParams = {
  apolloClient: ApolloClient<any>
}

export class UserDataNotSetError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'UserDataNotSetError'
  }
}

type HasuraGraphQLOptions = { useRoles?: boolean; operationName?: string }

type HasuraQueryOptions<T extends object, V extends OperationVariables = OperationVariables> = QueryOptions<V, T>

type HasuraMutationOptions<T extends object, V extends OperationVariables = OperationVariables> = MutationOptions<T, V>

export class HasuraService {
  apolloClient: ApolloClient<any>
  private _role: HasuraCustomRole | undefined

  constructor(params: HasuraParams) {
    this.apolloClient = params.apolloClient
  }

  get role() {
    return this._role
  }

  set role(role: HasuraCustomRole | undefined) {
    this._role = role
  }

  private applyRolesToOptions<T extends object, V extends OperationVariables = OperationVariables>(
    options: Pick<HasuraQueryOptions<T, V>, 'context'>,
    hoodieOptions?: HasuraGraphQLOptions
  ) {
    if (hoodieOptions?.useRoles && this.role) {
      if (!options.context) {
        options.context = {}
      }
      if (!options.context.headers) {
        options.context.headers = {}
      }
      options.context.headers['x-hasura-role'] = this.role
    }
  }

  async query<T extends object, V extends OperationVariables = OperationVariables>(
    options: HasuraQueryOptions<T, V>,
    hoodieOptions?: HasuraGraphQLOptions
  ): Promise<T> {
    this.applyRolesToOptions(options, hoodieOptions)
    if (hoodieOptions?.operationName) {
      options.query = setOperationName(options.query, hoodieOptions.operationName)
    }
    const res = await this.apolloClient.query<T, V>(options)
    if (res.errors) {
      throw new Error('GraphQL error')
    }
    return res.data as Promise<T>
  }

  private async mutate<T extends object, V extends OperationVariables = OperationVariables>(
    options: HasuraMutationOptions<T, V>,
    hoodieOptions?: HasuraGraphQLOptions
  ): Promise<NonNullableValues<T>> {
    this.applyRolesToOptions(options, hoodieOptions)
    if (hoodieOptions?.operationName) {
      options.mutation = setOperationName(options.mutation, hoodieOptions.operationName)
    }
    const res = await this.apolloClient.mutate<T, V>(options)
    if (res.errors) {
      throw new Error('GraphQL error')
    }
    return res.data as Promise<NonNullableValues<T>>
  }

  async listFiltersets(): Promise<View[]> {
    const data = await this.query({ query: LIST_FILTERSETS })
    return data.filterset.map((f) => filtersetResultToRecord(f as FiltersetGraphQL)) as View[]
  }

  async changeNotificationStatus(notificationId: string, isRead: boolean): Promise<void> {
    await this.mutate({
      mutation: NOTIFICATION_MUTATIONS.ChangeNotificationStatus,
      variables: {
        notificationId,
        readAt: isRead ? new Date().toISOString() : null
      }
    })
  }

  async markAllNotificationsAsRead(): Promise<void> {
    await this.mutate({
      mutation: NOTIFICATION_MUTATIONS.UpdateAllUnreadNotifications,
      variables: { readAt: new Date().toISOString() }
    })
  }

  async notifications(): Promise<Observable<FetchResult<any, Record<string, any>, Record<string, any>>>> {
    return await this.apolloClient.subscribe({
      query: NOTIFICATIONS,
      variables: {
        thirtyDaysAgo: dayjs().subtract(30, 'days').format('YYYY-MM-DD')
      }
    })
  }

  async notificationOptions(): Promise<NotificationOptions> {
    const data = await this.query({ query: NOTIFICATION_OPTIONS })
    return toOptionsFromResult(data)
  }

  async notificationPreferences(): Promise<NotificationPreference[]> {
    const data = await this.query({ query: NOTIFICATION_PREFERENCES })
    return toPreferencesFromResults(data.notification_preference)
  }

  async insertNotificationPreference(preference: DraftNotificationPreference): Promise<NotificationPreference> {
    const data = await this.mutate({
      mutation: NOTIFICATION_MUTATIONS.InsertNotificationPreference,
      variables: { preference: preparePreferenceRequest(preference) }
    })

    return toPreferenceFromResult(data.insert_notification_preference_one)
  }

  async updateNotificationPreference(preference: NotificationPreference): Promise<NotificationPreference> {
    const data = await this.mutate({
      mutation: NOTIFICATION_MUTATIONS.UpdateNotificationPreference,
      variables: {
        preferenceID: preference.id,
        deletedAt: new Date().toISOString(),
        preference: preparePreferenceRequest(preference)
      }
    })

    return toPreferenceFromResult(data.insert_notification_preference_one)
  }

  async deleteNotificationPreference(preference: NotificationPreference): Promise<boolean> {
    await this.mutate({
      mutation: NOTIFICATION_MUTATIONS.DeleteNotificationPreference,
      variables: { preferenceID: preference.id, deletedAt: new Date().toISOString() }
    })

    return true
  }

  async insertFilterset(filterset: DraftView & { subscriptionId: View['subscriptionId'] }): Promise<View> {
    const data = await this.mutate({
      mutation: INSERT_FILTERSET,
      variables: {
        name: filterset.name,
        subscriptionId: filterset.subscriptionId,
        filters: filterset.filters,
        tags: { data: filterset.tags?.map(({ id }) => ({ tag_id: id })) ?? [] }
      }
    })
    return filtersetResultToRecord(data.filterset as FiltersetGraphQL) as View
  }

  async updateFilterset(updatedFilterset: View): Promise<View> {
    await this.mutate({
      mutation: UPDATE_FILTERSET,
      variables: {
        filtersetId: updatedFilterset.id,
        name: updatedFilterset.name,
        filters: updatedFilterset.filters,
        userId: updatedFilterset.userId,
        subscriptionId: updatedFilterset.subscriptionId || null
      }
    })
    return updatedFilterset
  }

  async deleteFilterset(filtersetId: string): Promise<void> {
    await this.mutate({
      mutation: DELETE_FILTERSET,
      variables: { filtersetId, deletedAt: new Date().toISOString() }
    })
  }

  async getUserDataByKey<T>(key: UserDataKeys, operationName?: string): Promise<T | null> {
    const { user_data } = await this.query(
      { query: USER_DATA_QUERIES.getUserData, variables: { key } },
      { operationName }
    )
    return user_data?.length ? (user_data[0].value as T) : null
  }

  async setUserDataByKey<T>(key: UserDataKeys, value: T, operationName?: string): Promise<T> {
    try {
      const data = await this.mutate(
        {
          mutation: USER_DATA_MUTATIONS.upsertUserData,
          variables: { value, key }
        },
        { operationName }
      )
      this.apolloClient.cache.modify({
        fields: {
          user_data: (ref, details) => {
            if (details.storeFieldName === 'user_data') {
              // Update all_user_data cache:
              if (ref.find((entry: any) => entry.key === key)) {
                return ref.map((entry: any) => {
                  if (entry.key === key) {
                    return data.insert_user_data_one
                  }
                  return entry
                })
              } else {
                return [...ref, data.insert_user_data_one]
              }
              // Update specific key request
            } else if (details.storeFieldName.includes(key)) {
              return [data.insert_user_data_one]
            }
            // Ignore others
            return ref
          }
        }
      })
      return data.insert_user_data_one.value as T
    } catch (err) {
      throw new Error('GraphQL error')
    }
  }

  invalidateComparisonCache() {
    this.apolloClient.cache.modify({
      fields: {
        comparison: (ref, { DELETE }) => DELETE
      }
    })
  }

  async isValidComparisonName({
    comparisonName,
    comparisonId,
    userId
  }: {
    comparisonName: string
    comparisonId?: string
    userId: string
  }): Promise<boolean> {
    const data = await this.query({
      query: COMPARISONS_QUERIES.CHECK_NAME,
      variables: { name: comparisonName, userId }
    })

    return data.comparison.filter(({ comparison_id }) => comparison_id !== comparisonId).length === 0
  }

  async getComparisons(): Promise<ComparisonResponse[]> {
    const data = await this.query({ query: COMPARISONS_QUERIES.GET_COMPARISONS })
    return data.comparison
  }

  async insertComparison(comparison: SetRequired<DraftComparison, 'name'>): Promise<ComparisonResponse> {
    const { items, subscriptionId, ...params } = comparison
    const data = await this.mutate({
      mutation: COMPARISON_MUTATIONS.InsertComparison,
      variables: {
        ...params,
        subscriptionId: subscriptionId ?? null,
        items: params.context === 'custom' ? [] : items,
        views: params.context === 'custom' ? { data: items.map((v) => ({ filterset_id: v.id })) } : undefined
      }
    })

    this.invalidateComparisonCache()

    return data.insert_comparison_one
  }

  async updateComparison(comparison: Comparison): Promise<ComparisonResponse> {
    const { items, subscriptionId, ...params } = comparison
    const data = await this.mutate({
      mutation: COMPARISON_MUTATIONS.UpdateComparison,
      variables: {
        ...params,
        subscriptionId: subscriptionId || null,
        items: params.context === 'custom' ? [] : items,
        views:
          params.context === 'custom' ? items.map((v) => ({ comparison_id: comparison.id, filterset_id: v.id })) : []
      }
    })

    this.invalidateComparisonCache()

    return data.update_comparison_by_pk
  }

  async deleteComparison(comparisonId: string): Promise<boolean> {
    const data = await this.mutate({
      mutation: COMPARISON_MUTATIONS.DeleteComparison,
      variables: { comparisonId, deletedAt: new Date().toISOString() }
    })

    this.invalidateComparisonCache()

    return !!data.update_comparison.affected_rows
  }

  async getTags(): Promise<SetRequired<TagWithCount, 'itemsCount'>[]> {
    const { tag } = await this.query({
      fetchPolicy: 'network-only', // Avoid cache as we're caching with useQuery
      query: TAGS_QUERIES.ListTags
    })

    return tag.map(parseTagWithItemsCount)
  }

  async getTagsByType(type: TagType, ids?: string[]): Promise<ItemTagWithTag[]> {
    const { item_tag } = await this.query({
      fetchPolicy: 'network-only', // Avoid cache as we're caching with useQuery
      query: TAGS_QUERIES.ListItemTags,
      variables: {
        where: {
          _or: tagTypeKeys[type].map((key) => ({
            [camelToSnakeCase(key)]: ids?.length ? { _in: ids } : { _is_null: false }
          }))
        }
      }
    })

    return item_tag.map(parseItemTagWithTag)
  }

  async getTagItems(tagId: string, orItems: ItemTag[] = []): Promise<ItemTag[]> {
    const { items } = await this.query({
      fetchPolicy: 'network-only', // Avoid cache as we're caching with useQuery
      query: TAGS_QUERIES.ListItemsFromTag,
      variables: {
        where: {
          tag_id: { _eq: tagId },
          ...(orItems.length ? { _or: itemTagsToInClause(orItems) } : {})
        }
      }
    })

    return items.map(parseItemTag).filter(Boolean) as ItemTag[]
  }

  async insertTag(tag: DraftTag): Promise<SetRequired<TagWithItems, 'items' | 'itemsCount'>> {
    const parsedTag = toDbTagFormat(tag)

    const { insert_tag_one } = await this.mutate({
      mutation: TAGS_MUTATIONS.InsertTag,
      variables: {
        label: tag.label,
        color: tag.color,
        items: parsedTag.item_tags.length ? { data: parsedTag.item_tags } : undefined
      }
    })

    return parseTagWithItemsCount(insert_tag_one) as SetRequired<TagWithItems, 'items' | 'itemsCount'>
  }

  async updateTag(
    tag: TagWithItems,
    itemTagsToRemove?: RequiredItemTag[]
  ): Promise<SetRequired<TagWithItems, 'items' | 'itemsCount'>> {
    const { addItems, updateItems } = (tag.items || []).reduce(
      ({ addItems, updateItems }, item) => {
        if (item.itemTagId) {
          updateItems.push({
            subscription_id: item.subscriptionId || null,
            item_tag_id: item.itemTagId
          })
        } else {
          addItems.push({
            ...toDbItemTagFormat(item),
            tag_id: tag.id
          })
        }
        return { addItems, updateItems }
      },
      { addItems: [] as ItemTagDb[], updateItems: [] as { subscription_id: string | null; item_tag_id: string }[] }
    )

    // Update tag
    const data = await this.mutate({
      mutation: TAGS_MUTATIONS.UpdateTag,
      variables: {
        tagId: tag.id,
        label: tag.label,
        color: tag.color,
        addItems,
        updateItems: updateItems.map(({ item_tag_id, ...item }) => {
          return {
            _set: item,
            where: { item_tag_id: { _eq: item_tag_id } }
          }
        }),
        removeItems: itemTagsToRemove?.map(({ itemTagId }) => itemTagId) ?? []
      }
    })

    const insertedItems = data.insert_item_tag.returning
    const updatedItems = data.update_item_tag_many.filter(Boolean).flatMap((i) => i && i.returning) as ItemTagRecord[]

    return {
      ...parseTagWithItemsCount(data.update_tag_by_pk),
      items: [...insertedItems, ...updatedItems].map(parseItemTag).filter(Boolean) as ItemTag[]
    }
  }

  async removeItemTag(itemTagId: string): Promise<ItemTag> {
    const { delete_item_tag_by_pk } = await this.mutate({
      mutation: TAGS_MUTATIONS.RemoveItemTag,
      variables: { itemTagId }
    })

    return parseItemTag(delete_item_tag_by_pk) as ItemTag
  }

  async deleteTag(tagId: string): Promise<Tag | undefined> {
    try {
      const { delete_tag_by_pk } = await this.mutate({
        mutation: TAGS_MUTATIONS.DeleteTag,
        variables: { tagId }
      })

      return parseTag(delete_tag_by_pk)
    } catch (error) {
      return undefined
    }
  }

  async archiveTag(tagId: string): Promise<Tag | undefined> {
    try {
      const { tag } = await this.mutate<{ tag: TagWithItemsCountResponse }>({
        mutation: TAGS_MUTATIONS.ArchiveTag,
        variables: {
          tagId,
          archivedAt: new Date().toISOString()
        }
      })
      return parseTagWithItemsCount(tag)
    } catch (error) {
      return undefined
    }
  }

  async restoreTag(tagId: string): Promise<Tag | undefined> {
    try {
      const { tag } = await this.mutate<{ tag: TagWithItemsCountResponse }>({
        mutation: TAGS_MUTATIONS.RestoreTag,
        variables: { tagId }
      })
      return parseTagWithItemsCount(tag)
    } catch (error) {
      return undefined
    }
  }

  async getFavorites(): Promise<Favorite[]> {
    const data = await this.query({
      query: LIST_FAVORITES
    })

    // Filter out any favorites of a type that we don't yet support
    return data.favorite.map(parseFavorite).filter(Boolean) as Favorite[]
  }

  async insertFavorite(favorite: DraftFavorite, abortSignal?: AbortSignal): Promise<Favorite> {
    const data = await this.mutate({
      mutation: FAVORITES_MUTATIONS.InsertFavorite,
      variables: favorite,
      context: abortSignal
        ? {
            fetchOptions: {
              signal: abortSignal
            }
          }
        : undefined
    })
    const insertedFavorite = parseFavorite(data.insert_favorite_one)
    if (!insertedFavorite) {
      throw new Error('Invalid favorite item')
    }
    return insertedFavorite
  }

  async updateFavoriteName(favoriteId: string, name: string): Promise<Favorite> {
    const data = await this.mutate({
      mutation: FAVORITES_MUTATIONS.UpdateFavoriteName,
      variables: {
        favoriteId,
        name
      }
    })
    const updatedFavorite = parseFavorite(data.update_favorite_by_pk)
    if (!updatedFavorite) {
      throw new Error('Invalid favorite item')
    }
    return updatedFavorite
  }

  async deleteFavorite(favoriteId: string, abortSignal?: AbortSignal): Promise<boolean> {
    const data = await this.mutate({
      mutation: FAVORITES_MUTATIONS.DeleteFavorite,
      variables: { favoriteId },
      context: abortSignal
        ? {
            fetchOptions: {
              signal: abortSignal
            }
          }
        : undefined
    })

    return !!data.delete_favorite_by_pk
  }

  async bulkUpdateAccountTier(accountIds: string[], tierId: string) {
    const data = await this.mutate<{ update_subscription: { affected_rows: number } }>(
      {
        mutation: SUBSCRIPTION_MUTATIONS.BulkUpdateAccountTier,
        variables: { accountIds, tierId }
      },
      { useRoles: true }
    )

    return data.update_subscription.affected_rows
  }

  async getUser(id: string): Promise<UserData> {
    const data = await this.query<{ user: UserData }>({
      fetchPolicy: 'network-only',
      query: USER_QUERIES.GetUser,
      variables: {
        userId: id
      }
    })

    return data.user
  }

  async getUserSubscription(subscriptionId: string): Promise<UserSubscription> {
    const data = await this.query<{ userSubscription: UserSubscription[] }>({
      fetchPolicy: 'network-only',
      query: USER_SUBSCRIPTION_QUERIES.GetUserSubscription,
      variables: {
        subscriptionId
      }
    })

    return data.userSubscription?.[0] || null
  }

  async upsertUserSubscription(userSubscription: Partial<UserSubscription>) {
    const data = await this.mutate<{ insert_user_subscription_one: UserSubscription }>({
      mutation: USER_SUBSCRIPTION_MUTATIONS.UpsertUserSubscription,
      variables: {
        department: userSubscription.department,
        jobTitle: userSubscription.jobTitle,
        organization: userSubscription.organization
      }
    })

    return data.insert_user_subscription_one
  }
}
