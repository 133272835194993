import { TrackableButton } from '@client/components/Trackables'
import { HelpLink } from '@client/components/help-link/HelpLink'
import { useResponsiveState } from '@client/hooks/use-responsive-state'
import { Box } from '@client/styles/theme/box'
import { Card } from '@client/styles/theme/card'
import { Typography } from '@client/styles/theme/typography'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { Close } from '@mui/icons-material'
import { SpacingProps } from '@mui/system'
import { useCallback, useMemo } from 'react'
import { Step, TooltipRenderProps } from 'react-joyride'
import * as S from './OnboardingTooltip.style'

export interface OnboardingStep extends Step {
  helpLink?: string
  spacingProps?: SpacingProps
  ctaProps?: {
    label: string
    onClick: () => void
  }
}

interface OnboardingTooltipProps extends TooltipRenderProps {
  step: OnboardingStep
}

export const OnboardingTooltip: React.FC<OnboardingTooltipProps> = ({
  continuous,
  index,
  size,
  step,
  isLastStep,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps
}) => {
  const { isMobile } = useResponsiveState()
  const buttonSize = useMemo(() => (isMobile ? 'small' : undefined), [isMobile])

  const handleCta: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      step.ctaProps?.onClick()
      primaryProps.onClick(e)
    },
    [primaryProps, step.ctaProps]
  )

  const gaEvents = useMemo(() => {
    const common = {
      category: TrackableCategory.onboarding,
      dimension1: step.title?.toString() || undefined,
      dimension2: index.toString()
    }
    return {
      back: {
        ...common,
        action: TrackableAction.back
      },
      skip: {
        ...common,
        action: TrackableAction.skip
      },
      cta: {
        ...common,
        action: TrackableAction.onboardingCta
      },
      primary: {
        ...common,
        action: TrackableAction.primary
      },
      close: {
        ...common,
        action: TrackableAction.close
      }
    }
  }, [index, step.title])
  return (
    <Card
      p={3}
      sx={{ minWidth: '300px', maxWidth: '500px' }}
      elevation={0}
      data-testid={`onboarding-tooltip-${index}`}
      {...step.spacingProps}
      {...tooltipProps}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {step.title ? (
          <Typography variant="h5" fontWeight={600} data-testid="onboarding-tooltip-title">
            {step.title}
          </Typography>
        ) : (
          <div />
        )}
        <Box display="flex" alignItems="center">
          {step.helpLink && <HelpLink helpLink={step.helpLink} tooltip="View help" />}
          {!step.hideCloseButton && (
            <S.CloseButton
              size="small"
              {...closeProps}
              gaEvent={gaEvents.close}
              data-testid="onboarding-tooltip-close-button"
            >
              <Close />
            </S.CloseButton>
          )}
        </Box>
      </Box>
      {typeof step.content === 'string' ? (
        <Typography variant="body1" gutterBottom data-testid="onboarding-tooltip-content">
          {step.content}
        </Typography>
      ) : (
        step.content
      )}
      <Box display="flex" justifyContent="space-between" mt={4}>
        {continuous && !isLastStep && (
          <TrackableButton
            {...skipProps}
            size={buttonSize}
            gaEvent={gaEvents.skip}
            data-testid="onboarding-tooltip-skip-button"
          >
            Skip
          </TrackableButton>
        )}
        <Box display="flex" justifyContent="flex-end" flex={1} gap={2}>
          {continuous && index > 0 && !step.hideBackButton && (
            <TrackableButton
              {...backProps}
              size={buttonSize}
              gaEvent={gaEvents.back}
              data-testid="onboarding-tooltip-back-button"
            >
              Back
            </TrackableButton>
          )}
          {step.ctaProps && (
            <TrackableButton
              onClick={handleCta}
              size={buttonSize}
              gaEvent={gaEvents.cta}
              data-testid="onboarding-tooltip-cta-button"
            >
              {step.ctaProps.label}
            </TrackableButton>
          )}
          <TrackableButton
            variant="contained"
            {...primaryProps}
            size={buttonSize}
            gaEvent={gaEvents.primary}
            data-testid="onboarding-tooltip-primary-button"
          >
            {continuous && !isLastStep ? `Next (${index + 1}/${size})` : 'Got it!'}
          </TrackableButton>
        </Box>
      </Box>
    </Card>
  )
}
