import { useAuth0 } from '@auth0/auth0-react'
import { minutesFromNowToDate } from '@client/helpers/dates'
import { useEventTracker } from '@client/hooks/use-event-tracker'
import { useNotificationPreferences } from '@client/providers/notification-preferences'
import { TrackableAction, TrackableCategory } from '@client/types/tracking'
import { useCallback, useMemo } from 'react'
import {
  NotificationSnooze as InnerNotificationSnooze,
  NotificationSnoozeProps as InnerNotificationSnoozeProps
} from './NotificationSnooze'

export { SnoozeBadge } from './SnoozeBadge'

export interface NotificationSnoozeProps
  extends Omit<InnerNotificationSnoozeProps, 'snoozedUntil' | 'onSnooze' | 'onUnsnooze'> {
  onSnooze?: (minutes: number) => void
  onUnsnooze?: () => void
  notificationPreferenceId: string
  context: 'notifications-dropdown' | 'notification-settings' | 'preference-settings' | 'scheduled-report-list'
}

export const NotificationSnooze: React.FC<NotificationSnoozeProps> = ({
  onSnooze,
  onUnsnooze,
  notificationPreferenceId,
  context,
  ...rest
}) => {
  const { user } = useAuth0()
  const { eventTracker } = useEventTracker()
  const { getPreference, saveNotificationPreference, settingsPreferencesKeys } = useNotificationPreferences()

  const preference = useMemo(() => getPreference(notificationPreferenceId), [getPreference, notificationPreferenceId])

  const handleSnooze = useCallback(
    async (minutes?: number) => {
      if (!preference) {
        return
      }
      const newPreference = {
        ...preference,
        disabledUntil: minutesFromNowToDate(minutes)
      }
      await saveNotificationPreference(newPreference, settingsPreferencesKeys[notificationPreferenceId])
      eventTracker({
        action: minutes
          ? TrackableAction.notificationPreferenceSnoozed
          : TrackableAction.notificationPreferenceUnsnoozed,
        category: TrackableCategory.notifications,
        dimension1: user?.sub,
        dimension2: notificationPreferenceId,
        dimension3: newPreference.disabledUntil?.toISOString(),
        dimension4: context
      })
      if (minutes) {
        onSnooze && onSnooze(minutes)
      } else {
        onUnsnooze && onUnsnooze()
      }
    },
    [
      context,
      eventTracker,
      notificationPreferenceId,
      onSnooze,
      onUnsnooze,
      preference,
      saveNotificationPreference,
      settingsPreferencesKeys,
      user?.sub
    ]
  )

  return (
    <InnerNotificationSnooze
      {...rest}
      onSnooze={handleSnooze}
      onUnsnooze={handleSnooze}
      snoozedUntil={preference?.disabledUntil}
    />
  )
}
